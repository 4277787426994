import { LayoutBase } from '../../components/LayoutBase';
import Form from './components/Form';

const CreateItem = () => {
  return (
    <LayoutBase title="Criar item">
      <Form />
    </LayoutBase>
  );
};

export default CreateItem;
