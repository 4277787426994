import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import { Button, Icon, Input } from "oialbert-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import * as z from "zod";
import { LayoutBase } from "../../components/LayoutBase";
import { Loading } from "../../components/Loading";
import { useCameraGallery } from "../../contexts/CameraGalleryContext";
import useForm from "../../hooks/useForm";
import { useMaskHandler } from "../../hooks/useMaskHandler";
import { useOffers } from "../../hooks/useOffers";
import SelectMenuItem from "../CreateItem/components/SelectMenuItem";
import { Product } from "../CreateItem/schema";
import SelectOfferItem from "../CreateOffer/components/SelectOfferItem";
import { useProductCategories } from "../Menus/hooks";
import { Category } from "../Menus/types";

const EditOffer = () => {
  const history = useHistory();
  const { id }: any = useParams();

  const { openActions, clearResource, resource } = useCameraGallery();
  const { update, loadingUpdateOffer, getOffer, loadingOffer, offer } =
    useOffers();
  const { categories, getProductCategories } = useProductCategories();

  const [preview, setPreview] = useState<string>(null!);
  const [category, setCategory] = useState<Category>({} as Category);
  const [product, setProduct] = useState<Product | any>({
    price_albert_in_cents: 0,
    price_cashback_in_cents: 0,
  } as Product);

  const [categoriesVisible, showCategoriesModal] = useState<boolean>(false);
  const [offerItensVisible, showOfferItemsModal] = useState<boolean>(false);

  const schema = useMemo(
    () =>
      z.object({
        photo: z.any().optional().default(""),
        value_in_cents: z
          .number({ required_error: "campo obrigatório" })
          .max(
            product.price_albert_in_cents,
            `não pode ser maior que R$ ${formatCurrency(
              offer?.product?.price_albert_in_cents ??
                product.price_albert_in_cents / 100
            )}`
          ),
        price_cashback_in_cents: z
          .number({ required_error: "campo obrigatório" })
          .min(
            product.price_cashback_in_cents,
            `não pode ser menor que R$ ${formatCurrency(
              offer?.product?.price_cashback_in_cents ??
                product.price_cashback_in_cents / 100
            )}`
          ),
        start_date: z.string().nonempty({ message: "campo obrigatório" }),
        end_date: z.string().nonempty({ message: "campo obrigatório" }),
      }),
    [product, offer]
  );

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    schema,
  });

  const fields = watch();

  const valueInCents = useMaskHandler("value_in_cents", control, 0);

  const cashbackInCents = useMaskHandler("price_cashback_in_cents", control, 0);

  const cashbackProduct = useMemo(
    () =>
      Math.round(
        isNaN(
          fields?.product?.price_cashback_in_cents /
            fields?.product?.price_albert_in_cents
        )
          ? 0
          : (fields.product.price_cashback_in_cents /
              fields.product.price_albert_in_cents) *
              100
      ),
    [fields.product]
  );

  const onUpdateOffer = useCallback(
    async (data) => {
      const form = new FormData();

      Object.keys(data).map((key) => {
        form.append(key, data[key]);
      });

      form.append("product_id", product?.id);

      await update(offer.id, form);
      history.push("/offers");
    },
    [product?.id, update, offer.id, history]
  );

  useEffect(() => {
    getProductCategories();
    register("product");
    register("photo", { value: "" });

    return () => {
      setProduct(null);
    };
  }, [getProductCategories, register]);

  const setImage = useCallback(
    async (resource) => {
      if (resource) {
        const image = await fetch(resource?.[0].webPath as string);
        const blob = await image.blob();
        setValue("photo", blob as any);
        setPreview(resource?.[0].webPath as string);
      } else {
        setValue("photo", null as any);
        setPreview("");
      }
    },
    [setValue]
  );

  useEffect(() => {
    setImage(resource);
  }, [resource, setImage]);

  useEffect(() => {
    return () => {
      setImage(null);
      clearResource();
    };
  }, [clearResource, setImage]);

  useEffect(() => {
    getOffer(id);
  }, [getOffer, id]);

  useEffect(() => {
    if (offer?.id) {
      const { photo, ...restOffer } = offer;
      setProduct(restOffer.product);
      reset({
        ...restOffer,
        start_date: restOffer?.start_date?.split("T")?.[0] ?? "",
        end_date: restOffer?.end_date?.split("T")?.[0] ?? "",
      });
      trigger();
    }
  }, [offer, reset, trigger]);

  return (
    <LayoutBase title="Editar oferta">
      {loadingOffer && !offer?.id ? (
        <Loading />
      ) : (
        <section className="mt-5 px-5 pb-8">
          <form action="">
            <section className="flex flex-col space-y-4 mb-4">
              {!id && (
                <section className="flex-1">
                  <button
                    type="button"
                    onClick={() => showCategoriesModal(true)}
                    className="border border-gray-900 rounded h-12 w-full p-4 flex items-center justify-between text-gray-500"
                  >
                    {category?.title ?? "selecionar categoria"}
                  </button>
                </section>
              )}
              <section className="flex flex-1 space-x-2">
                <section>
                  <section className="w-32 h-32 bg-white border border-gray-200 rounded-md overflow-hidden relative">
                    {((preview && preview !== "") ||
                      product?.photo?.url ||
                      offer?.photo?.url) && (
                      <img
                        src={
                          preview !== ""
                            ? preview
                            : product?.photo?.url ?? offer?.photo?.url
                        }
                        className="w-32 h-32 absolute t-0 l-0"
                        alt={product?.title ?? "Produto"}
                      />
                    )}
                    <button
                      onClick={() => openActions()}
                      type="button"
                      className="flex flex-col items-center justify-center w-full h-full"
                    >
                      <Icon.MdAddCircleOutline className="text-neon-900 text-3xl" />
                      Adicionar foto
                    </button>
                  </section>
                </section>
                <section className="w-full">
                  <button
                    disabled={!category?.id}
                    type="button"
                    onClick={() => showOfferItemsModal(true)}
                    className={`border border-gray-900 rounded h-12 w-full p-4 flex items-center justify-between text-gray-500 ${
                      !category?.id && "opacity-50"
                    }`}
                  >
                    {fields?.product?.title ?? "selecionar produto"}
                  </button>

                  <section className="flex flex-col space-y-2 text-sm my-4">
                    <p>
                      preço do produto:{" "}
                      <strong className="text-neon-900 uppercase">
                        {formatCurrency(
                          (fields?.product?.price_albert_in_cents ?? 0) / 100
                        )}
                      </strong>
                    </p>

                    <p>
                      cashback do produto:{" "}
                      <strong className="text-neon-900 uppercase">{`R$ ${formatCurrency(
                        (fields?.product?.price_cashback_in_cents ?? 0) / 100
                      )} / ${cashbackProduct}%`}</strong>
                    </p>
                  </section>
                </section>
              </section>
            </section>

            <div className="flex space-x-4 mb-4">
              <Input
                type="date"
                label="Inicia em"
                {...register("start_date")}
                error={errors.start_date?.message?.toString()}
                pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"
              />
              <Input
                type="date"
                label="Termina em"
                {...register("end_date")}
                error={errors.end_date?.message?.toString()}
                pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"
              />
            </div>

            <section className="my-2 pb-2 border-b border-crema-500">
              <h3 className="font-sm font-bold text-gray-500 mb-220,00">
                preço sugestão
              </h3>
              <Input
                type="tel"
                placeholder="0,00"
                mask="MONEY"
                className="bg-white border-0 rounded-none shadow-none font-bold text-2xl text-gray-500 px-0 placeholder-gray-500::placeholder focus:border-0 focus-within:ring-0"
                moneyProps={{
                  ...valueInCents,
                  prefix: "",
                }}
                error={errors.value_in_cents?.message?.toString()}
                {...register("value_in_cents")}
              />
            </section>

            <section className="my-2 pb-2 border-b border-crema-500">
              <h3 className="font-sm font-bold text-gray-500 mb-220,00">
                valor do cashback
              </h3>
              <Input
                type="tel"
                placeholder="0,00"
                mask="MONEY"
                className="bg-white border-0 rounded-none shadow-none font-bold text-2xl text-gray-500 px-0 placeholder-gray-500::placeholder focus:border-0 focus-within:ring-0"
                moneyProps={{
                  ...cashbackInCents,
                  prefix: "",
                }}
                error={errors.price_cashback_in_cents?.message?.toString()}
                {...register("price_cashback_in_cents")}
              />
            </section>

            <footer className="mt-6">
              <Button
                full
                variant="solid"
                color="neon"
                disabled={loadingUpdateOffer || !isValid}
                onClick={handleSubmit(onUpdateOffer)}
              >
                {loadingUpdateOffer ? "aguarde" : "salvar oferta"}
              </Button>
            </footer>
          </form>
        </section>
      )}

      {!id && (
        <SelectMenuItem
          onClose={() => showCategoriesModal(false)}
          open={categoriesVisible}
          categories={categories ?? []}
          onSelect={setCategory}
          category={category}
          showMenu={false}
        />
      )}
      <SelectOfferItem
        onClose={() => showOfferItemsModal(false)}
        open={offerItensVisible}
        category={category}
        onSelect={(p) => {
          showOfferItemsModal(false);
          setValue("product", p);
          setProduct(p);
        }}
      />
    </LayoutBase>
  );
};

export default EditOffer;
