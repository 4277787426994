import { LayoutBase } from '../../components/LayoutBase';
import { Icon } from 'oialbert-ui';
import Drawer from '../../components/Drawer';
import { useEffect, useState } from 'react';
import Cart from './components/Cart';
import { ActiveProduct } from './components/ActiveProduct';
import useAuth from '../../hooks/useAuth';
import { useCart } from './context';

const ExtraServices = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { company } = useAuth();

  const { items, getItems, getCurrentCart } = useCart();

  const handleCloseModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (company?.id) {
      getItems(company?.id);
      getCurrentCart(company?.id);
    }
  }, [company?.id, getCurrentCart, getItems]);

  return (
    <LayoutBase title="Serviços extras">
      <Drawer isOpen={isOpen} onClose={handleCloseModal}>
        <Cart />
      </Drawer>
      <section className="sm:flex lg:p-3 gap-3 min-h-full bg-gray-50 ">
        <section className="p-6 rounded-xl w-full shadow-lg shadow-gray-100 bg-white flex flex-col">
          <div className="flex justify-between items-center mb-6">
            <div className="flex-grow">
              <h1 className="text-xl font-semibold">Serviços extras</h1>
            </div>

            <div
              onClick={() => setIsOpen(true)}
              className="cart flex justify-start gap-3 items-center pl-3 border-b border-gray-100 border-opacity-25 cursor-pointer hover:bg-gray-50 py-4 text-gray-500 hover:text-black "
            >
              <span className="text-sm">
                ir para carrinho {`(${items.length})`}
              </span>
              <Icon.MdOutlineShoppingCart className="w-5 h-5 text-neon-900" />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 max-w-7xl gap-4">
            <ActiveProduct />
          </div>
        </section>
      </section>
    </LayoutBase>
  );
};

export default ExtraServices;
