// @ts-nocheck
import { useCallback, useMemo, useState, Ref } from "react";
import { useHistory } from "react-router";
import useForm from "../../../../hooks/useForm";
import useAuth from "../../../../hooks/useAuth";
import * as z from "zod";
import errorHandling from "../../../../utils/error_handling";

import { Input, Button } from "oialbert-ui";

import {
  createDepositOrder,
  createTokenCard,
} from "../../../../services/financial/subscription";

import cep from "cep-promise";

type FormProps = {
  formRef: Ref<HTMLFormElement>;
  amount: number;
  paymentType: string;
  isAmountValid: boolean;
};

const schema = z
  .object({
    number: z
      .string()
      .regex(
        /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
        "número inválido"
      )
      .nonempty({ message: "campo obrigatório" }),
    holder_name: z
      .string()
      .regex(/^[a-zA-Z]{4,}(?: [a-zA-Z]+){0,3}$/, "nome inválido")
      .nonempty({ message: "campo obrigatório" }),
    holder_document: z.string().nonempty({ message: "campo obrigatório" }),
    exp: z.string().nonempty({ message: "campo obrigatório" }),
    cvv: z
      .string()
      .regex(/^[0-9]{3,}$/, "cvv inválido")
      .nonempty({ message: "campo obrigatório" })
      .min(3, "cvv inválido")
      .max(4),
    phone: z.string().optional().default(""),
    address: z.object({
      zip_code: z.string().nonempty({ message: "campo obrigatório" }),
      street: z.string().nonempty({ message: "campo obrigatório" }),
      street_number: z
        .string()
        .regex(/^(\s*|\d+)$/)
        .nonempty({ message: "campo obrigatório" }),
      neighborhood: z.string().nonempty({ message: "campo obrigatório" }),
      city: z.string().nonempty({ message: "campo obrigatório" }),
      state: z.string().nonempty({ message: "campo obrigatório" }),
    }),
  })
  .refine(
    (data) => {
      const today = new Date();
      const monthToday = today.getMonth() + 1;
      const yearToday = today.getFullYear().toString().substr(-2);

      const [expMonth, expYear] = data.exp.split("/");

      if (Number(expYear) < Number(yearToday)) {
        return false;
      } else if (
        Number(expMonth) > 12 ||
        (Number(expMonth) < monthToday && Number(expYear) <= Number(yearToday))
      ) {
        return false;
      }

      return true;
    },
    {
      path: ["exp"],
      message: "data inválida",
    }
  );

export const FormCreditCard = ({
  formRef,
  amount,
  paymentType,
  isAmountValid,
}: FormProps) => {
  const history = useHistory();
  const { user, company } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    watch,
    formState: { errors, isValid },
  } = useForm({
    schema,
    mode: "onChange",
  });

  const field = watch();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);

  const searchZipcode = useCallback(async () => {
    setLoadingAddress(true);
    try {
      const dataZipcode = await cep(field?.address?.zip_code);
      setValue("address.neighborhood", dataZipcode.neighborhood);
      setValue("address.city", dataZipcode.city);
      setValue("address.state", dataZipcode.state);
      setValue("address.street", dataZipcode.street);
      setValue("address.street_number", "");

      // eslint-disable-next-line array-callback-return
      Object.keys(field?.address).map((value) => {
        trigger(`address.${value}` as any);
      });
    } catch (err) {
      errorHandling(null, "não foi possível encontrar o cep", "crema");
    } finally {
      setLoadingAddress(false);
    }
  }, [field?.address, setValue, trigger]);

  const zipCode: string | null = useMemo(
    () => watch("address.zip_code"),
    [watch("address.zip_code")]
  );

  const zipCodeDisabled = useMemo(
    () => zipCode?.length === 8 && true,
    [zipCode]
  );

  const onCreateOrder = useCallback(
    async (data) => {
      setLoading(true);
      try {
        const {
          address: {
            city,
            state,
            street_number,
            street,
            neighborhood,
            zip_code,
            ...restAddress
          },
        } = data;

        const { token_card } = await createTokenCard({
          exp_year: data.exp.split("/")[1],
          exp_month: data.exp.split("/")[0],
          cvv: data?.cvv?.replace(/\D/g, ""),
          number: data.number,
          holder_name: data.holder_name,
          holder_document: data.holder_document,
          billing_address: {
            ...restAddress,
            state,
            street,
            city,
            number: street_number,
            street_number,
            neighborhood,
            line_1: `${street_number}, ${street}, ${neighborhood}`,
            line_2: neighborhood,
            zip_code,
            country: "BR",
          },
        });

        await createDepositOrder({
          type: "deposit",
          items: [
            {
              amount,
              description: "Deposito",
              quantity: 1,
            },
          ],
          customer: {
            address: {
              zip_code,
              country: "BR",
              line_1: `${street_number}, ${street}, ${neighborhood}`,
              number: street_number,
              street_number,
              city,
              state,
            },
            code: user?.id ?? "",
            name: user?.partner?.full_name ?? "",
            email: user?.partner?.email ?? "",
            document:
              user?.partner?.document_number?.replace(/[^a-zA-Z0-9]/g, "") ??
              data.holder_document ??
              "",
            type: "individual",
            phones: {
              mobile_phone: {
                country_code: "55",
                area_code:
                  company?.phone?.slice?.(0, 2) ??
                  user?.partner?.cellphone?.slice?.(0, 2),
                number:
                  company?.phone?.slice?.(2, 11) ??
                  user?.partner?.cellphone?.slice?.(2, 11),
              },
            },
          },
          payments: [
            {
              payment_method: "credit_card",
              amount,
              credit_card: {
                installments: 1,
                card_token: token_card,
                card: {
                  billing_address: {
                    ...restAddress,
                    state,
                    street,
                    city,
                    number: street_number,
                    street_number,
                    neighborhood,
                    line_1: `${street_number}, ${street}, ${neighborhood}`,
                    line_2: neighborhood,
                    zip_code,
                    country: "BR",
                  },
                },
              },
            },
          ],
          metadata: {
            type: "deposit",
            user_id: user?.id ?? "",
            user_type: "partner",
            company_id: company?.id ?? "",
          },
        });

        history.push({
          pathname: "/receipt",
          state: {
            value_in_cents: amount,
            payment_type: paymentType,
            context: "deposit",
          },
        });
      } catch (err) {
        errorHandling(err, "Não foi possível fazer sua transação", "crema");
      } finally {
        setLoading(false);
      }
    },
    [amount, user, company, history, paymentType]
  );

  const validateForm = useMemo(
    () => isAmountValid && isValid,
    [isAmountValid, isValid]
  );

  return (
    <form ref={formRef}>
      <section className="flex flex-col mt-3 mb-4 space-y-4">
        <Input
          required
          type="tel"
          placeholder="0000 0000 0000 0000"
          mask="NUMBER_CARD"
          disabled={loading}
          error={errors.number?.message?.toString()}
          {...register("number", {
            setValueAs: (value) => value.replace(/\s/g, ""),
          })}
        />
        <Input
          required
          placeholder="Nome completo"
          disabled={loading}
          error={errors.holder_name?.message?.toString()}
          {...register("holder_name")}
        />
        <section className="flex items-start space-x-4">
          <Input
            required
            type="tel"
            placeholder="MM/YY"
            mask="VALIDATE_CARD"
            disabled={loading}
            error={errors.exp?.message?.toString()}
            {...register("exp")}
          />
          <Input
            required
            type="tel"
            placeholder="0000"
            mask="CODE_CARD"
            disabled={loading}
            error={errors.cvv?.message?.toString()}
            {...register("cvv", {
              setValueAs: (value) => value?.replace(/\D/g, ""),
            })}
          />
        </section>
        <Input
          required
          type="tel"
          placeholder="CPF"
          mask="CPF"
          disabled={loading}
          error={errors.holder_document?.message?.toString()}
          {...register("holder_document", {
            setValueAs: (value) => value.replace(/[_\.-]/g, ""),
          })}
        />
        {!user?.partner?.cellphone && !company?.phone && (
          <Input
            required
            type="tel"
            placeholder="Telefone"
            mask="CELLPHONE"
            disabled={loading}
            {...register("cellphone", {
              setValueAs: (value) => value.replace(/[^0-9]/g, ""),
            })}
          />
        )}
      </section>

      <h3 className="text-base font-bold">Endereço do cartão</h3>

      <section className="flex flex-col mt-3 mb-4 space-y-4">
        <Input
          type="tel"
          placeholder="CEP"
          mask="CEP"
          error={errors.address?.zip_code?.message?.toString()}
          disabled={loading}
          {...register("address.zip_code", {
            setValueAs: (value) => value.replace(/[_\\.-]/g, ""),
          })}
          onBlur={(e) => {
            e.target.value.length === 9 && searchZipcode();
          }}
        />

        <Input
          required
          placeholder="Endereço"
          disabled={loadingAddress || loading}
          error={errors.address?.street?.message?.toString()}
          {...register("address.street")}
        />

        <section className="flex space-x-4 mb-4">
          <Input
            required
            placeholder="Número"
            disabled={loadingAddress || loading}
            error={errors.address?.street_number?.message?.toString()}
            {...register("address.street_number")}
          />
          <Input
            required
            placeholder="Bairro"
            disabled={loadingAddress || loading}
            error={errors.address?.neighborhood?.message?.toString()}
            {...register("address.neighborhood")}
          />
        </section>

        <section className="flex space-x-4 mb-4">
          <Input
            required
            placeholder="Cidade"
            disabled={loadingAddress || loading || zipCodeDisabled}
            error={errors.address?.city?.message?.toString()}
            {...register("address.city")}
          />
          <Input
            required
            placeholder="Estado"
            disabled={loadingAddress || loading || zipCodeDisabled}
            error={errors.address?.state?.message?.toString()}
            {...register("address.state")}
          />
        </section>
      </section>

      <section className="mt-4">
        <Button
          full
          onClick={handleSubmit(onCreateOrder)}
          variant="solid"
          color="neon"
          disabled={loading || !validateForm}
        >
          {loading ? "Aguarde..." : "Adicionar Saldo"}
        </Button>
      </section>
    </form>
  );
};
