import { api } from './config';

export const tokenApi = async (companyId?: string) => {
  const headers: Record<string, string> = {};

  if (companyId) {
    headers['company_id'] = companyId;
  }

  const { status, data } = await api.post(
    '/v2/partners/public-api/token/generate',
    {},
    {
      headers: headers,
    }
  );

  if (status === 201 && data) {
    return data;
  }

  return false;
};
