import { Card, Icon, IconBs } from 'oialbert-ui';
import { useState } from 'react';
import useWallet from '../../../../hooks/useWallet';
import { formatInCents } from '../../../../utils/money';
import BalanceAvailableInfo from '../../FinancialInfo/BalanceAvailableInfo';

export const BalanceItem = () => {
  const { wallets } = useWallet();

  const valueInCents = Number(wallets?.total_balance_in_cents);
  const [balanceModal, setBalanceModal] = useState<boolean>(false);

  return (
    <Card className="flex items-center justify-between rounded py-6 px-4">
      <section className="flex items-center">
        <Icon.MdCheckCircleOutline className="w-6 h-6 text-neon-900 mr-1" />
        <p className="text-base text-gray-500">saldo disponível</p>
        <button
          onClick={() => {
            setBalanceModal(true);
          }}
        >
          <p className="px-2">
            <IconBs.BsInfoCircle className="w-3 h-3 text-grey-400 mr-1" />
          </p>
        </button>
      </section>
      <p className="text-base font-bold text-gray-500 uppercase">
        {formatInCents(valueInCents ?? 0)}
      </p>
      <BalanceAvailableInfo open={balanceModal} setOpen={setBalanceModal} />
    </Card>
  );
};
