import { Listbox, Transition } from "@headlessui/react";
import { useIonAlert } from "@ionic/react";
import classNames from "classnames";
import { Button, Icon, Input } from "oialbert-ui";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { LayoutBase } from "../../components/LayoutBase";
import { Loading } from "../../components/Loading";
import { NotAccess } from "../../components/NotAccess";
import PromotionContextProvider, {
  usePromotionContext,
} from "../../contexts/PromotionContext";
import {
  checkLimit,
  checkPartnersContractsItemsReportAccessPermission,
} from "../../services/contracts";
import { PartnersContractsItemsPermission } from "../../types/partner-contracts-items-permission";
import PromotionCard from "./components/PromotionCard";
import { LIMIT_TYPE, PromotionType } from "./types";

type StatusValue =
  | "pending"
  | "all"
  | ""
  | "actived"
  | "paused"
  | "expired"
  | "deleted"
  | "refused";

const STATUS_TYPES = [
  {
    label: "promoções",
    value: "",
  },
  {
    label: "mostrar todos",
    value: "all",
  },
  {
    label: "ativados",
    value: "actived",
  },
  {
    label: "em análise",
    value: "pending",
  },
  {
    label: "pausados",
    value: "paused",
  },
  {
    label: "recusados",
    value: "refused",
  },
  {
    label: "expirados",
    value: "expired",
  },
  {
    label: "deletados",
    value: "deleted",
  },
];

const Promotions = () => {
  const [presentAlert] = useIonAlert();
  const history = useHistory();
  let { promotions, handleGetPromotion, loading, meta } = usePromotionContext();
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [status, setStatus] = useState(STATUS_TYPES[0]);

  const [reportPermission, setReportPermission] =
    useState<PartnersContractsItemsPermission>();
  const hasPermissionInPromotionPage = reportPermission?.success;

  const handleCheckLimit = useCallback(async () => {
    try {
      const response = await checkLimit(LIMIT_TYPE.limit_promotions);
      if (response?.available <= 0) {
        const message = `Parece que você atingiu o limite de promoções ativas simultâneas. Verifique as suas promoções ativas para liberar espaço para um novo cadastro.

          Se precisar de ajuda, entre em contato, ok?! Estamos prontos para te ajudar.`;

        const buttons = [
          {
            text: "ok",
            role: "confirm",
          },
        ];

        presentAlert({
          header: "Hey! Limite de promoções ativas excedido.",
          message,
          buttons,
        });
      } else {
        history.push("/promotions/create");
      }
    } catch (error) {}
  }, [history, presentAlert]);

  function getStatusClass(statusValue: StatusValue): string {
    switch (statusValue) {
      case "pending":
        return "bg-blue-400";
      case "":
        return "bg-gray-300";
      case "all":
        return "bg-neon-400 ";
      case "actived":
        return "bg-teal-300";
      case "paused":
        return "bg-yellow-300";
      case "expired":
        return "bg-gray-300";
      case "deleted":
        return "bg-gray-900";
      case "refused":
        return "bg-red-500";
      default:
        return "bg-teal-500";
    }
  }

  const handleGetCheckPermission = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await checkPartnersContractsItemsReportAccessPermission(
        "limit_promotions"
      );
      setReportPermission(response);
    } catch (error) {
      console.log("ocorreu um erro ao carregar permissões");
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await handleGetCheckPermission();
    })();
  }, [handleGetCheckPermission]);

  useEffect(() => {
    handleGetPromotion({ query, status: status.value });
  }, [handleGetPromotion, query, status]);

  return (
    <LayoutBase title="Promoções">
      <section className="sm:flex lg:p-3 gap-3 min-h-full bg-gray-50 ">
        <section className="p-6 rounded-xl w-full shadow-lg shadow-gray-100 bg-white ">
          <div className="hidden sm:flex flex-col">
            <h1 className="text-xl font-semibold">Promoções</h1>
            <h2 className="text-gray-500">
              lista para criar, editar e acompanhar o andamento das promoções.
            </h2>
          </div>

          {(loading || isLoading) && (
            <div className="flex justify-center items-center h-96">
              <div className="flex flex-col justify-center items-center shadow-lg border border-gray-50 p-4 rounded-lg gap-3">
                <Loading />
                <span className="text-gray-500 text-sm">carregando</span>
              </div>
            </div>
          )}

          {!hasPermissionInPromotionPage && !isLoading && (
            <NotAccess>
              <strong className="mt-4">
                Ops! Esta ação não está disponível no seu plano atual.
              </strong>
              <text className="mt-2">
                Que tal fazer um upgrade e liberar essa funcionalidade?
              </text>
            </NotAccess>
          )}

          {!loading && hasPermissionInPromotionPage && (
            <>
              <div className="grid grid-cols-1 lg:grid-cols-3 items-end gap-3 sm:py-6 pb-6 xl:max-w-7xl ">
                <div className="flex w-full flex-col ">
                  <label className="text-sm text-gray-500 mb-2" htmlFor="search">
                    pesquise por palavras-chave
                  </label>
                  <div className="flex justify-start items-center ">
                    <Icon.MdOutlineSearch className="w-5 h-5 text-neon-900 absolute z-10 ml-3" />
                    <Input
                      id="search"
                      name="search"
                      placeholder="faça buscas por palavras-chave..."
                      className="border-none pl-9 bg-gray-100 text-sm ring-1 ring-neon-900 ring-opacity-5 focus:outline-none focus:ring-2 focus:ring-neon-900"
                      onChange={(e) => setQuery(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex w-full flex-col ">
                  <Listbox value={status} onChange={setStatus}>
                    {({ open }) => (
                      <div className="relative w-full">
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-3 pl-3 pr-10 text-left text-gray-500 shadow-sm ring-1 ring-inset ring-gray-200 focus:outline-none focus:ring-2 focus:ring-neon-900 sm:text-sm sm:leading-6">
                          <span className="flex items-center">
                            <span
                              className={classNames(
                                getStatusClass(status.value as StatusValue),
                                "inline-block h-2 w-2 flex-shrink-0 rounded-full"
                              )}
                            />
                            <span className="ml-3 block truncate">
                              {status.label}
                            </span>
                          </span>
                        </Listbox.Button>
                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-neon-900 ring-opacity-5 focus:outline-none sm:text-sm">
                            {STATUS_TYPES.map((status) => (
                              <Listbox.Option
                                key={status.value}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "bg-gray-100 text-gray-700"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                  )
                                }
                                value={status}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <div className="flex items-center">
                                      <span
                                        className={classNames(
                                          getStatusClass(
                                            status.value as StatusValue
                                          ),
                                          "inline-block h-2 w-2 flex-shrink-0 rounded-full"
                                        )}
                                        aria-hidden="true"
                                      />
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "ml-3 block truncate"
                                        )}
                                      >
                                        {status.label}
                                        <span className="sr-only">
                                          {" "}
                                          is{" "}
                                          {status.value ? "online" : "offline"}
                                        </span>
                                      </span>
                                    </div>
                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active ? "text-white" : "text-black",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}
                                      >
                                        {/* <CheckIcon className='h-5 w-5' aria-hidden='true' /> */}
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    )}
                  </Listbox>
                </div>
                <div className="flex w-full xl:max-w-xs">
                  <Button
                    onClick={handleCheckLimit}
                    color={"neon"}
                    variant={"solid"}
                    full
                  >
                    adicionar promoção
                  </Button>
                </div>
              </div>
              {promotions?.length === 0 ? (
                <section className="w-full flex justify-center py-4">
                  <h3 className="text-center text-lg text-gray-500">
                    nenhuma promoção encontrada, que tal adicionar uma nova
                    promoção :)
                  </h3>
                </section>
              ) : (
                <>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4">
                    {promotions?.map((promotion: PromotionType) => (
                      <PromotionCard
                        promotion={promotion}
                        key={`key-promotion-${promotion.id}`}
                        getAllPromotion={() => {
                          handleGetPromotion({ query, status: status.value });
                        }}
                      />
                    ))}
                  </div>
                  <div className="flex justify-between w-full py-6 max-w-7xl">
                    <button
                      className={classNames(
                        meta?.previous_page_url
                          ? "cursor-pointer text-neon-900"
                          : "cursor-not-allowed text-gray-500"
                      )}
                      onClick={() => {
                        if (meta?.previous_page_url) {
                          handleGetPromotion({
                            query,
                            page: meta.previous_page_url.replace("/?page=", ""),
                          });
                        }
                      }}
                    >
                      Anterior
                    </button>
                    <button
                      className={classNames(
                        meta?.next_page_url
                          ? "cursor-pointer text-neon-900"
                          : "cursor-not-allowed text-gray-500"
                      )}
                      onClick={() => {
                        if (meta?.next_page_url) {
                          handleGetPromotion({
                            query,
                            page: meta?.next_page_url.replace("/?page=", ""),
                          });
                        }
                      }}
                    >
                      Próximo
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </section>
      </section>
    </LayoutBase>
  );
};

export default () => (
  <PromotionContextProvider>
    <Promotions />
  </PromotionContextProvider>
);
