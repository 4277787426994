import React from 'react';

type VisaIconProps = {
  width?: string;
  height?: string;
};

export const VisaIcon: React.FC<VisaIconProps> = ({
  width = '28',
  height = '18',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.23804 0.886719H25.7494C26.4914 0.886719 27.0929 1.48655 27.0929 2.22644V16.2935C27.0929 17.0335 26.4914 17.6333 25.7494 17.6333H2.23804C1.49605 17.6333 0.894531 17.0335 0.894531 16.2935V2.22644C0.894531 1.48655 1.49605 0.886719 2.23804 0.886719Z"
        fill="#0E4595"
      />
      <path
        d="M10.742 12.5676L11.8625 6.01099H13.6548L12.5334 12.5676H10.742ZM19.0083 6.15233C18.6532 6.0195 18.0968 5.87695 17.402 5.87695C15.6311 5.87695 14.3837 6.76619 14.3731 8.04071C14.3631 8.98287 15.2636 9.50844 15.9434 9.82207C16.641 10.1434 16.8755 10.3484 16.8722 10.6354C16.8678 11.0748 16.3151 11.2756 15.8 11.2756C15.0827 11.2756 14.7016 11.1763 14.113 10.9315L13.8821 10.8273L13.6305 12.2951C14.0491 12.4781 14.8232 12.6366 15.6269 12.6448C17.5109 12.6448 18.7338 11.7658 18.7478 10.4047C18.7544 9.65889 18.277 9.09128 17.243 8.62332C16.6166 8.32004 16.2329 8.11761 16.237 7.81051C16.237 7.53798 16.5617 7.24652 17.2633 7.24652C17.8494 7.23748 18.274 7.36492 18.6048 7.49772L18.7654 7.57341L19.0083 6.15233ZM23.6202 6.01089H22.2353C21.8063 6.01089 21.4852 6.12768 21.2968 6.55462L18.6352 12.5633H20.5172C20.5172 12.5633 20.8248 11.7553 20.8944 11.578C21.1001 11.578 22.9283 11.5808 23.1897 11.5808C23.2433 11.8103 23.4077 12.5633 23.4077 12.5633H25.0708L23.6202 6.01069V6.01089ZM21.4229 10.2448C21.5711 9.86698 22.137 8.41191 22.137 8.41191C22.1264 8.42939 22.2841 8.0323 22.3746 7.78613L22.4957 8.35146C22.4957 8.35146 22.8389 9.91655 22.9106 10.2448H21.4229ZM9.22054 6.01089L7.46592 10.4822L7.27894 9.57355C6.9523 8.52612 5.93459 7.39131 4.79688 6.82317L6.40122 12.5572L8.29745 12.555L11.119 6.01082H9.22054"
        fill="white"
      />
      <path
        d="M5.82686 6.01088H2.93694L2.91406 6.14729C5.16236 6.69002 6.65002 8.00157 7.2677 9.57732L6.63928 6.56428C6.53079 6.14914 6.21617 6.02524 5.82692 6.01074"
        fill="#F2AE14"
      />
    </svg>
  );
};
