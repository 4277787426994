import { Button, Radio } from 'oialbert-ui';
import { useOrderContext } from '../../../contexts/OrdersContext';

type StepOneProps = {
  setIsUsedCashback: (_value: boolean) => void;
  closeModal: () => void;
  isUsedCashback: boolean;
  IsThreeBiggerCashback: boolean;
  onNext: () => void;
};

const StepOne = ({
  setIsUsedCashback,
  closeModal,
  isUsedCashback,
  IsThreeBiggerCashback,
  onNext,
}: StepOneProps) => {
  const { preview, customer } = useOrderContext();

  return (
    <>
      <section className="w-full">
        <h3 className="text-md">{customer.customer.full_name}</h3>
        <p className="text-md font-medium">
          {customer.customer.document_number}
        </p>
        <div className="w-full h-0.5 bg-gray-100 my-5"></div>
      </section>
      <section>
        <h3 className="text-xl text-center ">
          você confirma a utilização de{' '}
          <span className="font-semibold text-neon-900 uppercase">
            {preview.used_cashback}
          </span>{' '}
          de cashback?
        </h3>
      </section>

      <section className="flex justify-center items-center gap-x-2 my-6">
        <section className="flex flex-1 border border-gray-100 rounded py-4 px-4 items-center justify-center">
          <Radio
            label="sim"
            id="isUsed"
            name="isUsed"
            value="credit_card"
            titleClassName="font-base"
            defaultChecked={isUsedCashback === true}
            onChange={() => setIsUsedCashback(true)}
          />
        </section>
        <section className="flex flex-1 border border-gray-100 rounded py-4 px-4 items-center justify-center">
          <Radio
            label="não"
            id="isUsed"
            name="isUsed"
            value="credit_card"
            titleClassName="font-base"
            defaultChecked={isUsedCashback === false}
            onChange={() => setIsUsedCashback(false)}
          />
        </section>
      </section>
      {!IsThreeBiggerCashback ? (
        <section className=" mb-6">
          <h3 className="text-center text-neon-800 text-md">
            O associado está deixando de utilizar{' '}
            <span className="font-semibold text-neon-900 uppercase">
              {preview.cashback_surplus}
            </span>{' '}
            do seu cashback. Para utilizar o valor total, a compra deve ser
            maior que{' '}
            <span className="font-semibold text-neon-900 uppercase">
              {preview.best_buy}
            </span>
            .
          </h3>
        </section>
      ) : null}
      <section className="flex flex-row justify-center items-center gap-x-2 ">
        <Button
          type="button"
          onClick={closeModal}
          full
          normal
          variant="outline"
          color="neon"
          disabled={false}
        >
          {'voltar'}
        </Button>
        <Button
          type="button"
          onClick={() => onNext()}
          full
          normal
          variant="solid"
          color="neon"
          disabled={false}
        >
          {'próximo passo'}
        </Button>
      </section>
    </>
  );
};
export default StepOne;
