/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Header, Icon, IconBs, Pagination } from 'oialbert-ui';
import useWallet from '../../../../hooks/useWallet';
import { useEffect } from 'react';
import { formatInCents } from '../../../../utils/money';
import format from 'date-fns/format';
import locale from 'date-fns/locale/pt-BR';
import { LayoutBase } from '../../../../components/LayoutBase';

const TicketList = () => {
  const {
    getBalanceCharges,
    pageBalanceCharges,
    totalBalanceCharge,
    setPageBalanceCharges,
    balanceCharge,
  } = useWallet();

  useEffect(() => {
    getBalanceCharges();
  }, [getBalanceCharges]);

  return (
    <>
      <LayoutBase title="Lista de Boletos">
        <div className="px-3 py-6">
          {balanceCharge.map((balance: any) => (
            <>
              <section className="bg-white w-full h-full rounded-t border-crema-500 border px-2 py-2">
                <div className="flex flex-col w-full">
                  <div className="flex items-center justify-between w-full">
                    <h3 className="text-md font-bold">valor:</h3>
                    <span className="uppercase text-md text-gray-500 font-bold">
                      {formatInCents(
                        balance.charge?.charges[0]?.last_transaction?.amount
                      )}
                    </span>
                  </div>
                  {/*  */}
                  <div className="flex items-center justify-between w-full">
                    <h3 className="text-md font-bold">vencimento:</h3>
                    <span className="text-sm text-gray-500 font-bold">
                      {format(
                        new Date(
                          balance.charge?.charges[0]?.last_transaction?.due_at
                        ),
                        "dd 'de' MMMM 'de' yyyy",
                        { locale }
                      )}
                    </span>
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <h3 className="text-md font-bold">status:</h3>
                    {balance.charge?.charges[0]?.last_transaction?.status ? (
                      <div className="w-full flex justify-end">
                        <span className="text-sm text-warning-500 text-crema-500 text-gray-500 font-bold">
                          {balance?.status === 'processing' && 'processando'}
                        </span>
                        <span className="text-sm text-success-500 text-crema-500 text-gray-500 font-bold">
                          {balance?.status === 'approved' && 'pago'}
                        </span>
                        <span className="text-sm text-coral-500 text-crema-500 text-gray-500 font-bold">
                          {balance?.status === 'refused' && 'recusado'}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </section>
              <div className="w-full mb-5">
                {balance?.status === 'processing' ? (
                  <a
                    className="flex flex-row items-center gap-x-1 text-neon-900 text-md w-full flex justify-center items-center py-1.5 rounded-b"
                    href={balance.charge?.charges[0]?.last_transaction?.pdf}
                    download
                    target="_blank"
                  >
                    <div className="mt-1">
                      <Icon.MdFileDownload />
                    </div>
                    <span>baixar boleto</span>
                  </a>
                ) : null}
              </div>
            </>
          ))}
        </div>
        {totalBalanceCharge > 20 && (
          <section className="flex justify-center mt-4">
            <Pagination
              onPrev={() => setPageBalanceCharges(pageBalanceCharges - 1)}
              onNext={() => setPageBalanceCharges(pageBalanceCharges + 1)}
              onPage={(_page) => setPageBalanceCharges(_page)}
              page={pageBalanceCharges}
              total={totalBalanceCharge}
              perPage={20}
              variant='secondary'
            />
          </section>
        )}
      </LayoutBase>
    </>
  );
};

export default TicketList;
