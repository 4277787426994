import { useState, useCallback } from 'react';
import { useHistory } from 'react-router';
import { Browser } from '@capacitor/browser';
import { formatInCents } from '../../../../utils/money';
import { Loading } from '../../../../components/Loading';
import type { ReceiptLocationState } from '../..';
import { Button, Toast } from 'oialbert-ui';

import { PaymentTypeEnum } from '../../../../enums/financial/subscription';
import { copyToClipboard } from '../../../../utils/copy';

type DataTransactionProps = {
  line: string;
  url: string;
};

const Transactions = ({
  data: { value_in_cents, payment_type, id, order },
}: {
  data: ReceiptLocationState;
}) => {
  const history = useHistory();

  const [loadingData] = useState<boolean>(false);
  const [dataTransaction] = useState<DataTransactionProps | null>(null);
  const [dataTransactionError] = useState<boolean>(false);

  const onOpenBrowser = useCallback(async (url) => {
    await Browser.open({ url });
  }, []);

  return (
    <section className="mt-5 px-5 space-y-4">
      {payment_type === 'credit_card' && (
        <>
          <section className="w-full p-4 bg-crema-500 rounded space-y-2">
            <h1>
              seu pagamento está sendo{' '}
              <strong className="text-neon-900">processado</strong>!
            </h1>
            <p className="text-sm">
              em breve, você receberá a confirmação por e-mail.
            </p>
          </section>

          <section className="space-y-4">
            <h3 className="text-base font-bold">resumo do depósito</h3>
            <section className="flex items-center justify-between">
              <ul className="m-0 space-y-4 text-sm">
                <li>valor</li>
                <li>forma de Pagamento</li>
              </ul>
              <ul className="m-0 ml-4 flex-1 space-y-4 text-sm text-right font-bold">
                <li className="uppercase">
                  {formatInCents(value_in_cents ?? 0)}
                </li>
                <li>
                  {
                    PaymentTypeEnum[
                      payment_type as keyof typeof PaymentTypeEnum
                    ]
                  }
                </li>
              </ul>
            </section>
          </section>
        </>
      )}

      {!dataTransaction && loadingData ? (
        <section className="flex h-full py-12">
          <Loading text="aguarde, carregando dados..." />
        </section>
      ) : (
        <>
          {!dataTransactionError ? (
            <>
              {payment_type === 'boleto' && (
                <section className="w-full p-4 bg-crema-500 rounded space-y-2">
                  <h1>
                    seu pagamento{' '}
                    <strong className="text-neon-900">está pendente</strong>!
                  </h1>
                  <p className="text-sm">
                    seu boleto chegará por <strong>e-mail</strong>. fique
                    atento, você deverá realizar o pagamento do boleto em até 24
                    horas.
                  </p>
                </section>
              )}

              {payment_type === 'pix' && (
                <section className="w-full p-4 bg-crema-500 rounded space-y-2">
                  <h1>
                    seu pagamento está sendo{' '}
                    <strong className="text-neon-900">processado</strong>!
                  </h1>
                  <p className="text-sm">
                    fique atento, você deverá realizar o pagamento em até 2
                    horas.
                  </p>
                </section>
              )}

              {payment_type !== 'credit_card' && (
                <section className="space-y-4">
                  <h3 className="text-base font-bold">comprovante</h3>
                  <section className="flex items-center justify-between">
                    <ul className="m-0 space-y-4 text-sm">
                      <li>valor</li>
                      <li>forma de pagamento</li>
                    </ul>
                    <ul className="m-0 ml-4 flex-1 space-y-4 text-sm text-right font-bold">
                      <li className="uppercase">
                        {formatInCents(value_in_cents ?? 0)}
                      </li>
                      <li>
                        {
                          PaymentTypeEnum[
                            payment_type as keyof typeof PaymentTypeEnum
                          ]
                        }
                      </li>
                    </ul>
                  </section>
                </section>
              )}

              {(dataTransaction ?? order) && (
                <section className="space-y-4">
                  <h3 className="text-base font-bold">
                    código do {payment_type}
                  </h3>
                  <p className="w-full text-center bg-gray-100 bg-opacity-50 rounded p-4 select-all">
                    {dataTransaction?.line ??
                      order?.charges?.line ??
                      order?.charges?.qr_code}
                  </p>
                  <span className="text-xs">
                    copie o código e cole no aplicativo do seu banco ou
                    instituição financeira.
                  </span>

                  <Button
                    full
                    variant="outline"
                    color="neon"
                    onClick={async () => {
                      await copyToClipboard(
                        dataTransaction?.line ??
                          order?.charges?.line ??
                          order?.charges?.qr_code
                      );
                      Toast.success('código copiado');
                    }}
                  >
                    copiar código
                  </Button>

                  {payment_type === 'boleto' && (
                    <Button
                      full
                      variant="solid"
                      color="neon"
                      onClick={() =>
                        onOpenBrowser(
                          dataTransaction?.url ?? order?.charges?.url
                        )
                      }
                    >
                      imprimir boleto
                    </Button>
                  )}
                </section>
              )}
            </>
          ) : (
            <>
              <section className="w-full flex flex-col items-center justify-center text-center space-y-4">
                <h1>
                  seu pagamento foi <strong>recusado</strong>!
                </h1>
                <Button
                  full
                  variant="solid"
                  color="neon"
                  onClick={() => history.push('/balance')}
                >
                  tentar novamente
                </Button>
              </section>
            </>
          )}
        </>
      )}
    </section>
  );
};

export default Transactions;
