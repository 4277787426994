import { useEffect } from 'react';
import ItemCart from '../ItemCart';
import { formatInCents } from '../../../../utils/money';
import { Button } from 'oialbert-ui';
import { useCart } from '../../context';
import useAuth from '../../../../hooks/useAuth';
import { useHistory } from 'react-router';

const Cart = () => {
  const { items, cart, getItems, getCurrentCart } = useCart();
  const { company } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (company?.id) {
      getCurrentCart(company?.id);
      getItems(company?.id);
    }
  }, [company?.id, getCurrentCart, getItems]);

  return (
    <div>
      <h3 className="text-base font-bold">Meu carrinho</h3>
      {items?.length > 0 ? (
        <>
          <section className="mt-4 grid grid-cols-1 gap-x-4 gap-y-4">
            {items.map((currentItem) => (
              <ItemCart key={currentItem.package_id} item={currentItem} />
            ))}
          </section>
          <section className="mt-4 bg-gray-100">
            <div className="flex p-4 justify-between">
              <div className="text-lg font-bold">Total</div>
              <div className="text-lg font-bold">
                {formatInCents(cart?.total_price_in_cents ?? 0)}
              </div>
            </div>
            <div className="p-4">
              <Button
                full
                variant="solid"
                color="neon"
                onClick={() => {
                  history.push(`/extra-services/checkout/${cart?.token}`);
                }}
              >
                {'Fechar pedido'}
              </Button>
            </div>
          </section>
        </>
      ) : (
        <div className="flex flex-col items-center p-10">
          <p className="text-gray-500">Seu carrinho está vazio</p>
        </div>
      )}
    </div>
  );
};

export default Cart;
