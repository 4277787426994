export type Card = {
  id: string;
  first_six_digits: string;
  last_four_digits: string;
  holder_name: string;
  holder_document: string;
  number: string;
  exp_year: string;
  exp_month: string;
  cvv: string;
  billing_address: {
    city: string;
    state: string;
    zip_code: string;
    country: string;
    line_1: string;
    line_2: string;
  };
};

export type CardSelect = {
  id: string;
  first_six_digits: string;
  last_four_digits: string;
  brand: CardBrandEnum;
  holder_name: string;
  holder_document: string;
  exp_month: number;
  exp_year: number;
  status: string;
  type: string;
  created_at: string;
  updated_at: string;
  billing_address: {
    city: string;
    state: string;
    zip_code: string;
    country: string;
    line_1: string;
    line_2: string;
  };
  metadata: {
    company_id: string;
  };
};

export type CardResponse = Card[];

export enum CardBrandEnum {
  Elo = 'Elo',
  Mastercard = 'Mastercard',
  Visa = 'Visa',
  Amex = 'Amex',
  JCB = 'JCB',
  Aura = 'Aura',
  Hipercard = 'Hipercard',
  Diners = 'Diners',
  Discover = 'Discover',
}
