import { Icon, Card } from 'oialbert-ui';
import { LayoutBase } from '../../components/LayoutBase';

const Notifications = () => {
  return (
    <LayoutBase title="Notificações">
      <section className="mt-5 px-5">
        <h3 className="text-base font-bold">Novas notificações</h3>
      </section>
      <section className="mt-4 px-5 space-y-4">
        <Card className="flex py-2 pr-4 rounded justify-between">
          <section className="flex">
            <span className="bg-neon-900 h-4 px-1 rounded-r-sm my-1"></span>
            <section className="flex flex-col ml-4 mr-2">
              <h4 className="text-base font-bold mb-2">Cashback solicitado</h4>
              <p className="text-sm">
                Fernanda está solicitando cashback referente ao serviço: Corte
                de cabelo
              </p>
            </section>
          </section>
          <section className="flex flex-col items-center justify-between">
            <span className="w-10 text-xs bg-neon-900 text-white px-1 rounded text-center">
              5min
            </span>
            <button className="w-6 h-6 mb-2">
              <Icon.MdMoreVert className="w-6 h-6 text-gray-500" />
            </button>
          </section>
        </Card>
        <Card className="flex py-2 pr-4 rounded justify-between">
          <section className="flex">
            <span className="bg-neon-900 h-4 px-1 rounded-r-sm my-1"></span>
            <section className="flex flex-col ml-4 mr-2">
              <h4 className="text-base font-bold mb-2">Cashback solicitado</h4>
              <p className="text-sm">
                Fernanda está solicitando cashback referente ao serviço: Corte
                de cabelo
              </p>
            </section>
          </section>
          <section className="flex flex-col items-center justify-between">
            <span className="w-10 text-xs bg-neon-900 text-white px-1 rounded text-center">
              2h
            </span>
            <button className="w-6 h-6 mb-2">
              <Icon.MdMoreVert className="w-6 h-6 text-gray-500" />
            </button>
          </section>
        </Card>
      </section>
      <section className="mt-4 px-5 pb-5">
        <section className="pt-4 space-y-4 border-t border-crema-500">
          <Card className="flex py-2 pr-4 rounded justify-between">
            <section className="flex">
              <span className="bg-white h-4 px-1 rounded-r-sm my-1"></span>
              <section className="flex flex-col ml-4 mr-2">
                <h4 className="text-base font-bold mb-2">
                  Atualização do plano
                </h4>
                <p className="text-sm">
                  Renove agora seu plano e continue utilizando todos os
                  benefícios
                </p>
              </section>
            </section>
            <section className="flex flex-col items-center justify-between">
              <span className="w-10 text-xs bg-neon-900 text-white px-1 rounded text-center">
                2d
              </span>
              <button className="w-6 h-6 mb-2">
                <Icon.MdMoreVert className="w-6 h-6 text-gray-500" />
              </button>
            </section>
          </Card>
        </section>
      </section>
    </LayoutBase>
  );
};

export default Notifications;
