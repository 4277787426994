import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Category } from '../pages/Menus/types';
import { getData, storeData } from '../utils/storage';

type CategoryContextProps = {
  setCategory: React.Dispatch<React.SetStateAction<Category>>;
  categoryTitle: string;
};

type CategoryContextProviderProps = {
  children: ReactNode;
};

const CategoryContext = createContext<CategoryContextProps>({
  setCategory: () => {},
  categoryTitle: null!,
});

const key = '@AlbertPartners::selectedCategory';

export default function CategoryContextProvider({
  children,
}: CategoryContextProviderProps) {
  const [selectedCategory, setCategory] = useState<Category>(null!);
  const [categoryTitle, setCategoryTitle] = useState<string>(null!);

  useEffect(() => {
    if (selectedCategory) {
      const title = ` - ${selectedCategory?.title}`;
      storeData(key, JSON.stringify(selectedCategory));
      setCategoryTitle(title);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedCategory) {
      getData(key).then((data) => {
        const category = data?.value && JSON.parse(data?.value);

        setCategory((category as Category) || null!);
      });
    }
  }, [selectedCategory]);

  return (
    <CategoryContext.Provider value={{ setCategory, categoryTitle }}>
      {children}
    </CategoryContext.Provider>
  );
}

export const useCategoryContext = () =>
  useContext<CategoryContextProps>(CategoryContext);
