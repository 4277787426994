
// @ts-nocheck
import { CustomDialog } from "../../../components/CustomDialog";
import { Promotion } from "../../../types/promotion";
import { PromotionForm } from "./PromotionForm";

import { format } from "date-fns";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { TYPE } from "../types";
import { Button } from "oialbert-ui";
import { useState } from "react";
import EditPromotionConfirmationModal from "./EditPromotionConfirmationModal";
import { updateBanner, updatePromotion } from "../../../services/promotions";
import errorHandling from "../../../utils/error_handling";


interface EditPromotionModalProps {
  isOpen: boolean;
  closeModal(): void;
  afterSave: () => void;
  promotion: Promotion;
}

export const promotionSchema = z
  .object({
    type: z.enum([TYPE.discount, TYPE.offer, TYPE.combo]),
    status: z.enum(["pending", "actived", "paused"]).optional(),
    name: z
      .string({
        required_error: "campo obrigatório",
      })
      .min(5, { message: "deve ter pelo menos 5 caracteres" })
      .max(170, { message: "deve ter no máximo 170 caracteres" })
      .nonempty({ message: "campo obrigatório" }),
    description: z
      .string({
        required_error: "campo obrigatório",
      })
      .min(12, { message: "deve ter pelo menos 5 caracteres" })
      .max(255, {
        message:
          "deve ter no máximo 255 caracteres, para mais caracteres adicione dentro das regras.",
      })
      .nonempty({ message: "campo obrigatório" }),
    role: z
      .string({
        required_error: "campo obrigatório",
      })
      .min(17, { message: "deve ter pelo menos 10 caracteres" })
      .max(700, { message: "deve ter no máximo 700 caracteres." })
      .nonempty({ message: "campo obrigatório" }),
    promotion_limit_by_customer: z
      .number({
        invalid_type_error: "por favor, insira um número válido",
        required_error: "campo obrigatório",
      })
      .int("por favor, insira um número inteiro")
      .min(0, "O valor deve ser igual a 0 ou maior")
      .default(0),
    discount_amount: z
      .number({
        invalid_type_error: "por favor, insira um número válido",
        required_error: "campo obrigatório",
      })
      .int("por favor, insira um número inteiro")
      .min(0, "O valor deve ser igual a 0 ou maior")
      .default(0),
    tags: z
      .string()
      .array()
      .nonempty({ message: "campo obrigatório" })
      .min(3, { message: "deve ter pelo menos 3 palavras-chave" })
      .max(20, {
        message: "deve ter no máximo 20 palavras-chave, por favor exclua um.",
      }),
    start_date: z.coerce
      .date()
      .refine(
        (data) =>
          format(data, "yyyy-MM-dd HH:mm") >
          format(new Date(), "yyyy-MM-dd HH:mm"),
        {
          message: "a data de início deve ser maior que a data atual.",
        }
      )
      .transform((data) => format(data, "yyyy-MM-dd HH:mm")),
    end_date: z.coerce
      .date()
      .refine(
        (data) =>
          format(data, "yyyy-MM-dd HH:mm") >
          format(new Date(), "yyyy-MM-dd HH:mm"),
        {
          message: "a data de final deve ser maior que a data atual.",
        }
      )
      .transform((data) => format(data, "yyyy-MM-dd HH:mm")),
    banner: z.any().optional(),
    availability: z
      .object({
        days: z
          .string({
            required_error: "campo obrigatório",
          })
          .array().nonempty({ message: "você deve selecionar os dias" }),
      })
  })
  .refine(
    (data) =>
      format(new Date(data.end_date), "yyyy-MM-dd HH:mm") >=
      format(new Date(data.start_date), "yyyy-MM-dd HH:mm"),
    {
      message: "a data final não pode ser mais antiga que a de inicio.",
      path: ["end_date"],
    }
  );

export function EditPromotionModal({ isOpen, closeModal, promotion, afterSave }: EditPromotionModalProps) {
  type PromotionForm = z.infer<typeof promotionSchema>;


  function transformStringTagsToArray(tags: string): string[] {
    return tags.split(",");
  }

  const methods = useForm<PromotionForm>(
    {
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: zodResolver(promotionSchema),
      defaultValues: {
        name: promotion.name,
        start_date: promotion.start_datetime,
        end_date: promotion.end_datetime,
        description: promotion.description ?? "",
        promotion_limit_by_customer: promotion.promotion_limit_by_customer,
        role: promotion.role,
        tags: transformStringTagsToArray(promotion.tags),
        type: promotion.type,
        discount_amount: promotion.discount_amount,
        status: promotion.status,
        type: promotion.type,
        availability: promotion.availability || {
          days: [],
        },
      },
    }
  );

  const { reset, handleSubmit } = methods;
  const [openEditPromotionModalConfirm, setOpenEditPromotionModalConfirm] =
    useState(false);
  const [loading, setLoading] =
    useState(false);

  function handleCloseAndResetForm() {
    closeModal();
    reset();
  }

  function handleCloseEditPromotionModalConfirm() {
    setOpenEditPromotionModalConfirm(false);
  }

  function handleOpenEditPromotionModalConfirm() {
    setOpenEditPromotionModalConfirm(true);
  }

  async function savePromotion(data: PromotionForm) {
    setLoading(true);

    try {
      if (promotion?.id) {
        const payload = {
          ...promotion,
          name: data.name,
          start_datetime: data.start_date,
          end_datetime: data.end_date,
          promotion_limit_by_customer: data.promotion_limit_by_customer,
          role: data.role,
          availability: Object(data.availability),
          tags: String(data.tags),
          type: data.type,
          discount_amount: data.discount_amount,
          description: data.description,
        };

        await updatePromotion(promotion.id, payload);

        data?.banner?.file &&
            (await updateBanner(promotion.id, data.banner.file));

        errorHandling(
          null,
          "atualizações/alterações realizadas com sucesso.",
          "success"
        );
      }
    } catch (error: any) {
      errorHandling(error, "Erro ao salvar promoção");
    } finally {
      setLoading(false);
      afterSave();
      reset();
      closeModal();
      handleCloseAndResetForm();
    }
  }

  const isReadOnly =
    promotion.status === "actived" || promotion.status === "paused";

  function onSubmit(data: PromotionForm) {
    isReadOnly ? savePromotion(data) : handleOpenEditPromotionModalConfirm()
  }

  return (
    <>
      <CustomDialog isOpen={isOpen} title="Editar promoção" onClose={closeModal}>
        <FormProvider {...methods}>
          <form
            className="w-full flex flex-col"
            onSubmit={handleSubmit(onSubmit)}
          >
            <PromotionForm promotion={promotion} />
            <section className="grid sm:grid-cols-2 gap-4 mt-8 mb-5">
              <Button
                className="sm:w-1/2"
                onClick={handleCloseAndResetForm}
                variant="outline"
                color="neon"
                type="button"
                full
              >
                Cancelar
              </Button>
              <Button
                className="sm:w-1/2"
                full
                variant="solid"
                type="submit"
                color="neon"
              >
                Atualizar
              </Button>
            </section>
          </form>
        </FormProvider>
      </CustomDialog>
      <EditPromotionConfirmationModal isOpen={openEditPromotionModalConfirm} close={handleCloseEditPromotionModalConfirm} onConfirm={handleSubmit(savePromotion)} loading={loading} />
    </>
  )
}