import { api } from './config';

export const updatePartner = async (payload: any) => {
  try {
    const response = await api.put('/v1/partners', payload);

    if (response.status === 200 && response.data) {
      const { full_name, cellphone, email } = response.data.partner;

      return { full_name, cellphone, email };
    }
    return false;
  } catch (error) {
    console.error('Erro ao atualizar parceiro:', error);
    return false;
  }
};
