import { Button, Icon } from 'oialbert-ui';
import { useHistory } from 'react-router';
import OnboardingImage from '../../../assets/illustrations/onboarding-balance.png';

const Balance = () => {
  const history = useHistory();

  return (
    <section className="flex flex-col items-center justify-between text-center px-6 mt-6 space-y-8">
      <section className="space-y-2">
        <p>boa!</p>
        <p>configurações feitas, tudo certo!</p>
      </section>
      <section className="space-y-2">
        <p>
          hora de potencializar ainda mais os resultados e distribuir cashback
          para todo mundo.
        </p>
        <p>vamos juntos!</p>
      </section>
      <img src={OnboardingImage} alt="Onboarding" />
      <section>
        <Button
          full
          variant="solid"
          color="neon"
          onClick={() => history.push('/')}
        >
          voltar para a tela inicial
        </Button>
        {false && (
          <Button
            full
            variant="light"
            color="gray"
            onClick={() => history.push('/')}
          >
            <section className="text-center flex items-center justify-center text-back font-light mx-auto">
              quero fazer isso depois{' '}
              <Icon.MdEast className="text-neon-900 text-2xl ml-2" />
            </section>
          </Button>
        )}
      </section>
    </section>
  );
};

export default Balance;
