import { Button } from 'oialbert-ui';
import { useHistory } from 'react-router';
import { useCategoryContext } from '../../../contexts/CategoryContext';
import { CategoryListProps } from '../types';
import Item from './MenuItem';

const MenuList = ({
  categories,
  onButtonClick,
  onClick,
  onDelete,
}: CategoryListProps) => {
  const history = useHistory();
  const { setCategory } = useCategoryContext();

  return (
    <>
      <Button full onClick={onButtonClick} color="neon" variant="outline">
        <span className="text-sm">Cadastrar Categoria</span>
      </Button>
      <ul className="space-y-4 mt-4">
        {categories.map((category) => (
          <Item
            category={category}
            onClick={(e) => onClick(e, category)}
            onButtonClick={() => {
              setCategory(category);
              history.push(`/categories/${category.id}/products`);
            }}
            onDelete={() => onDelete(category)}
            key={`${category.id}-${category.title}`}
          />
        ))}
      </ul>
    </>
  );
};

export default MenuList;
