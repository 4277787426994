import { useEffect, useState } from 'react';
import { IonRefresher, IonRefresherContent, useIonAlert } from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import { useHistory } from 'react-router';
import { Card, Avatar, Button, Pagination, Icon } from 'oialbert-ui';
import { Loading } from '../../components/Loading';
import EmptyState from '../../components/EmptyState';
import { useUsers } from '../../hooks/useUsers';
import { UserTypesEnum } from '../../enums/userTypes';
import { LayoutBase } from '../../components/LayoutBase';

const Users = () => {
  const [present] = useIonAlert();
  const history = useHistory();
  const [reloading, setReloading] = useState<boolean>(false);

  const { getUsers, loadingUsers, onDeleteUser, users, page, total, setPage } =
    useUsers();

  async function onRefresh(event: CustomEvent<RefresherEventDetail>) {
    try {
      await getUsers();
    } finally {
      setReloading(false);
      event.detail.complete();
    }
  }

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <LayoutBase title="Usuários">
      <IonRefresher
        slot="fixed"
        onIonRefresh={async (e) => {
          await onRefresh(e);
          setReloading(true);
        }}
        disabled={loadingUsers}
      >
        <IonRefresherContent />
      </IonRefresher>
      {loadingUsers && !reloading ? (
        <Loading />
      ) : (
        <>
          {users.length ? (
            <section className="space-y-4 mt-4">
              <section className="px-5">
                <Button
                  onClick={() => history.push('/users/add')}
                  variant="light"
                  color="neon"
                  full
                  normal
                >
                  Cadastrar novo usuário
                </Button>
              </section>
              <section className="px-5">
                <section className="space-y-4">
                  {users.map((user, idx) => (
                    <Card key={`user_${idx}`} className="rounded py-2 px-4">
                      <section className="flex items-center justify-between">
                        <Avatar
                          variant="circle"
                          size="default"
                          src={user?.photo?.url ?? ''}
                        />
                        <section className="flex-1 flex flex-col justify-start mx-4 space-y-1">
                          <h4 className="text-sm flex-1 text-gray-500">
                            {user.full_name}
                          </h4>
                          <span className="w-10 text-xs bg-neon-900 px-2 py-0.5 text-white rounded text-center table">
                            {
                              UserTypesEnum[
                                user.user_types as keyof typeof UserTypesEnum
                              ]
                            }
                          </span>
                        </section>
                        <section className="flex items-center space-x-4">
                          <button
                            className="text-neon-900 rounded"
                            type="button"
                            onClick={() => history.push(`/users/${user.id}`)}
                          >
                            <Icon.MdEdit size={18} />
                          </button>
                          <button
                            className="text-neon-900 rounded"
                            type="button"
                            onClick={() =>
                              present({
                                header: 'deletar usuário',
                                message:
                                  'deseja realmente deletar este usuário?',
                                buttons: [
                                  'Não',
                                  {
                                    text: 'Sim',
                                    handler: () =>
                                      onDeleteUser(user.user_id, () =>
                                        getUsers()
                                      ),
                                  },
                                ],
                                onDidDismiss: () => {},
                              })
                            }
                          >
                            <Icon.MdDelete size={18} />
                          </button>
                        </section>
                      </section>
                    </Card>
                  ))}
                </section>
              </section>
              {total > 10 && (
                <section className="flex justify-center mt-4">
                  <Pagination
                    onPrev={() => setPage(page - 1)}
                    onNext={() => setPage(page + 1)}
                    onPage={(_page) => setPage(_page)}
                    page={page}
                    total={total}
                    perPage={20}
                    variant='secondary'
                  />
                </section>
              )}
            </section>
          ) : (
            !loadingUsers && (
              <EmptyState
                text="nenhum usuário cadastrado"
                image="box"
                ctaAction={() => history.push('/users/add')}
                ctaText="Cadastrar novo usuário"
              />
            )
          )}
        </>
      )}
    </LayoutBase>
  );
};

export default Users;
