import { Modal } from 'oialbert-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import useAuth from '../../../hooks/useAuth';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

type StepProps = {
  handleSubmit: UseFormHandleSubmit<any>;
  onSubmit: (_data: any) => void;
  setOpenModal: (_value: boolean) => void;
  setCashbackUsed: (_value: number) => void;
  setIsUsedCashback: (_value: boolean) => void;
  usedDiscountInCents?: number;
  totalOrderInCents?: number;
  minPurchaseValue?: number;
  isUsedCashback: boolean;
  openModal: boolean;
  loading?: boolean;
};

const StepModal = ({
  handleSubmit,
  onSubmit,
  setOpenModal,
  setCashbackUsed,
  setIsUsedCashback,
  usedDiscountInCents = 0,
  totalOrderInCents = 0,
  minPurchaseValue = 0,
  isUsedCashback,
  openModal,
  loading,
}: StepProps) => {
  const [step, toStep] = useState<number>(0);
  const { company }: any = useAuth();

  const onClose = useCallback(() => {
    setOpenModal(false);
    if (step === 1) toStep(step - 1);
  }, [setOpenModal, step]);

  const minMultiplier = useMemo(() => {
    const multiplier = Number(company.min_purchase_multiplier);
    const total = 100 / multiplier;
    return total;
  }, [company]);

  const IsThreeBiggerCashback = useMemo(
    () => (totalOrderInCents >= minPurchaseValue ? true : false),
    [totalOrderInCents, minPurchaseValue]
  );

  const discount = useMemo(
    () =>
      !IsThreeBiggerCashback
        ? (totalOrderInCents * minMultiplier) / 100
        : usedDiscountInCents,
    [
      IsThreeBiggerCashback,
      minMultiplier,
      totalOrderInCents,
      usedDiscountInCents,
    ]
  );

  const totalOrder = useMemo(
    () => (discount >= totalOrderInCents ? 0 : totalOrderInCents - discount),
    [totalOrderInCents, discount]
  );

  useEffect(() => {
    setCashbackUsed(discount);
  }, [discount, setCashbackUsed, totalOrder]);

  const StepsCashback = useMemo(
    () => [
      {
        key: 'StepOne',
        component: (
          <StepOne
            isUsedCashback={isUsedCashback}
            IsThreeBiggerCashback={IsThreeBiggerCashback}
            setIsUsedCashback={setIsUsedCashback}
            closeModal={onClose}
            onNext={() => toStep(step + 1)}
          />
        ),
      },
      {
        key: 'StepTwo',
        component: (
          <StepTwo
            isUsedCashback={isUsedCashback}
            closeModal={onClose}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            loading={loading}
            onNext={() => toStep(step + 1)}
          />
        ),
      },
    ],
    [
      IsThreeBiggerCashback,
      handleSubmit,
      isUsedCashback,
      loading,
      onClose,
      onSubmit,
      setIsUsedCashback,
      step,
    ]
  );

  return (
    <Modal open={openModal} onClose={onClose}>
      <section>{StepsCashback[step].component}</section>
    </Modal>
  );
};
export default StepModal;
