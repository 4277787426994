import { createRef } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'oialbert-ui';

import Form from './components/Form';

import { useCompany } from '../../hooks/useCompany';
import type { CompanyDetailsData } from '../../types/companies';
import { LayoutBase } from '../../components/LayoutBase';

const CreateCompany = () => {
  const history = useHistory();
  const { createCompany, loadingCreateCompany } = useCompany();

  const formRef: any = createRef();

  const onSubmitForm = () => {
    formRef?.current?.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  return (
    <LayoutBase title="Adicionar empresa">
      <Form
        formRef={formRef}
        onSubmit={(c: CompanyDetailsData) =>
          createCompany(
            {
              ...c,
              document_number: c.document_number.replace(/[^a-zA-Z0-9]/g, ''),
              phone: c.phone?.replace(/[^a-zA-Z0-9]/g, ''),
              ...c.address,
              country: 'BR',
              has_delivery: c.has_delivery !== '1',
              payment_types: c.payment_types
                ? Object.keys(c.payment_types)
                    .map((key: any) => c?.payment_types?.[key] && key)
                    .filter((payment) => payment)
                : [],
            },
            () => {
              history.push('/select-company');
            }
          )
        }
        loading={loadingCreateCompany}
      />

      <section className="flex flex-col px-5 space-y-4 mt-8 mb-5">
        <Button
          full
          onClick={() => onSubmitForm()}
          disabled={loadingCreateCompany}
          variant="solid"
          color="neon"
        >
          {loadingCreateCompany ? 'Cadastrando...' : 'Cadastrar'}
        </Button>
        <Button
          onClick={() => history.goBack()}
          variant="outline"
          color="neon"
          full
        >
          Cancelar
        </Button>
      </section>
    </LayoutBase>
  );
};

export default CreateCompany;
