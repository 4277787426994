import { useCallback, useEffect } from "react";
import { Button } from "oialbert-ui";
import * as z from "zod";
import OptionFeature from "./OptionFeature";
import useForm from "../../../hooks/useForm";
import { OptionsSchema } from "../schema";
import type { Characteristic, Option } from "../types";

type FormProps = Pick<Characteristic, "options">;

type CreateOptionProps = {
  onClose: () => void;
  index: number;
  option?: Option;
  onSave: (item: FormProps) => void;
};

const CreateOption = ({ onClose, option, onSave }: CreateOptionProps) => {
  const {
    register,
    handleSubmit,
    control,
    trigger,
    reset,
    formState: { errors },
  } = useForm<Characteristic>({
    mode: "onChange",
    defaultValues: {
      options: [
        option ?? {
          title: "",
          min: 0,
          max: 0,
          additional_price_in_cents: 0,
        },
      ],
    },
    schema: z.object({
      options: OptionsSchema,
    }),
  });

  const saveOption = useCallback(
    (data: FormProps) => {
      reset({
        options: [
          {
            title: "",
            min: 0,
            max: 0,
            additional_price_in_cents: 0,
          },
        ],
      });
      onSave(data);
      onClose();
    },
    [onClose, onSave, reset]
  );

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <section className="flex flex-col space-between h-full">
      <div className="text-center text-xl mb-4">adicionar opção</div>
      <div className="px-2 py-1 h-full">
        <form id="saveOption" onSubmit={handleSubmit(saveOption)}>
          <OptionFeature
            index={0}
            register={register}
            errors={errors}
            control={control}
          />

          <footer className="mt-4">
            {(errors.options as any)?.message?.toString()}
          </footer>
        </form>
      </div>

      <div>
        <Button form="saveOption" full normal variant="solid" color="neon">
          <span className="text-sm font-bold">salvar opção</span>
        </Button>
        <button
          className="text-neon-900 w-full text-center p-2 mt-2 text-base space-x-2"
          onClick={() => onClose()}
        >
          voltar para características
        </button>
      </div>
    </section>
  );
};

export default CreateOption;
