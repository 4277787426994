import { ExtractProps } from '../../types';
import { Loading } from '../../../../components/Loading';
import EmptyState from '../../../../components/EmptyState';
import { Pagination } from 'oialbert-ui';

export const Extract = ({
  data,
  loading,
  pagination: { total, page, setPage },
}: ExtractProps) => (
  <>
    <section className="mt-4 divide-y divide-crema-500">
      {loading ? (
        <Loading />
      ) : data.length ? (
        data
      ) : (
        <EmptyState text="nenhuma transação encontrada" image="box" />
      )}
    </section>
    {total > 20 && (
      <section className="flex justify-center mt-4">
        <Pagination
          onPrev={() => setPage(page - 1)}
          onNext={() => setPage(page + 1)}
          onPage={(_page) => setPage(_page)}
          page={page}
          total={total}
          perPage={20}
          variant='secondary'
        />
      </section>
    )}
  </>
);
