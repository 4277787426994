import { useCallback, useEffect } from 'react';
import { Loading } from '../../../../components/Loading';
import { useCompany } from '../../../../hooks/useCompany';
import errorHandling from '../../../../utils/error_handling';
import Days from './Days';
import { Hours } from '../../../../types/companies';

interface ListDaysProps {
  companyId: string | any;
}
const ListDays = ({ companyId }: ListDaysProps) => {
  const { updateHours, getCompany, company, loadingCompany } = useCompany();

  const handleGetCompany = useCallback(async () => {
    await getCompany(companyId);
  }, [companyId, getCompany]);

  useEffect(() => {
    handleGetCompany();
  }, [handleGetCompany]);

  const monday: Hours[] | undefined = company?.business_hours?.monday.map(
    (item: Hours) => item
  );

  const tuesday: Hours[] | undefined = company?.business_hours?.tuesday.map(
    (item: Hours) => item
  );
  const wednesday: Hours[] | undefined = company?.business_hours?.wednesday.map(
    (item: Hours) => item
  );
  const thursday: Hours[] | undefined = company?.business_hours?.thursday.map(
    (item: Hours) => item
  );
  const friday: Hours[] | undefined = company?.business_hours?.friday.map(
    (item: Hours) => item
  );
  const saturday: Hours[] | undefined = company?.business_hours?.saturday.map(
    (item: Hours) => item
  );
  const sunday: Hours[] | undefined = company?.business_hours?.sunday.map(
    (item: Hours) => item
  );

  const handleGetDay = useCallback(
    async (day: string) => {
      const business_hours = {
        business_hours: {
          ...company?.business_hours,
          [day]: [],
        },
      };
      try {
        await updateHours(business_hours, company?.id);
      } catch (error) {
        errorHandling(error, 'erro ao criar horário', 'crema');
      }
    },
    [company, updateHours]
  );

  if (loadingCompany) {
    return <Loading />;
  }

  return (
    <>
      <Days day={monday} nameday="monday" handleGetDay={handleGetDay} />
      <Days day={tuesday} nameday="tuesday" handleGetDay={handleGetDay} />
      <Days day={wednesday} nameday="wednesday" handleGetDay={handleGetDay} />
      <Days day={thursday} nameday="thursday" handleGetDay={handleGetDay} />
      <Days day={friday} nameday="friday" handleGetDay={handleGetDay} />
      <Days day={saturday} nameday="saturday" handleGetDay={handleGetDay} />
      <Days day={sunday} nameday="sunday" handleGetDay={handleGetDay} />
    </>
  );
};

export default ListDays;
