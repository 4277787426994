import { Button, Icon, Pagination } from 'oialbert-ui';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import EmptyState from '../../components/EmptyState';
import { LayoutBase } from '../../components/LayoutBase';
import { Loading } from '../../components/Loading';
import { useCategoryContext } from '../../contexts/CategoryContext';
import { useProductContext } from '../../contexts/ProductContext';
import { Product } from '../CreateItem/types';
import RemoveItemModal from './components/RemoveItemModal';
import { useProducts } from './hooks';

type ItemProps = {
  onDelete: (event: ButtonEvent) => void;
  onClick: () => void;
  product: Product;
};

type RenderProductsProps = {
  products: Product[];
  loading: boolean;
  showModalRemove: (event: ButtonEvent) => void;
  onItemClick: (product: Product) => void;
  onCreate: () => void;
  pagination: {
    total: number;
    page: number;
    setPage: (page: number) => void;
  };
};

type ButtonEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;

const Item = ({ onDelete, onClick, product }: ItemProps) => {
  const { setProduct } = useProductContext();

  return (
    <li
      onClick={onClick}
      className="border flex items-center justify-between p-4 border-crema-500 h-14 w-full rounded-md"
    >
      <p className="text-base font-bold text-gray-500">{product.title}</p>

      <button
        onClick={(e) => {
          setProduct(product);
          onDelete(e);
        }}
        className="text-neon-900 flex items-center border border-transparent px-2 py-0.5 rounded hover:bg-neon-900 hover:text-white hover:border-neon-900"
      >
        <Icon.MdDeleteOutline className="mr-2" />
        <span className="text-sm">Excluir</span>
      </button>
    </li>
  );
};

const RenderProducts = ({
  loading,
  products,
  showModalRemove,
  onItemClick,
  onCreate,
  pagination: { page, setPage, total },
}: RenderProductsProps) => {
  if (loading) {
    return (
      <div className="h-full flex items-center">
        <Loading />
      </div>
    );
  }

  if (!loading && products.length === 0) {
    return (
      <EmptyState
        image="box"
        ctaAction={onCreate}
        ctaText="cadastrar produto"
        alt="nenhum produto encontrado"
        text="nenhum produto encontrado, que tal adicionar um novo produto :)"
      />
    );
  }

  return (
    <section className="mt-5 px-5 pb-8">
      <Button onClick={onCreate} full color="neon" variant="outline">
        <span className="text-sm font-bold">cadastrar produto</span>
      </Button>
      <ul className="space-y-4 mt-4">
        {products.map((product) => (
          <Item
            product={product}
            onClick={() => onItemClick(product)}
            onDelete={showModalRemove}
            key={product.id}
          />
        ))}
      </ul>
      {total > 20 && (
        <section className="flex justify-center mt-4">
          <Pagination
            onPrev={() => setPage(page - 1)}
            onNext={() => setPage(page + 1)}
            onPage={(_page) => setPage(_page)}
            page={page}
            total={total}
            perPage={20}
            variant='secondary'
          />
        </section>
      )}
    </section>
  );
};

const Items = () => {
  const history = useHistory();
  const { setProduct, selectedProduct } = useProductContext();
  const { getProducts, loading, products, page, setPage, total } =
    useProducts();
  const { id }: { id: string } = useParams();
  const { categoryTitle } = useCategoryContext();

  const [removeModalVisible, showRemoveModalVisible] = useState<boolean>(false);

  const editProduct = useCallback(
    (product: Product) => {
      setProduct(product);
      history.push(`/products/${product.id}/edit`, { categoryId: id });
    },
    [history, setProduct, id]
  );

  const showModalRemove = useCallback((event: ButtonEvent) => {
    event?.stopPropagation();
    showRemoveModalVisible(true);
  }, []);

  useEffect(() => {
    getProducts(id);
  }, [getProducts, id]);

  return (
    <LayoutBase title={`produtos${categoryTitle}`}>
      <RenderProducts
        products={products}
        loading={loading}
        showModalRemove={showModalRemove}
        onCreate={() => history.push('/products/create', { categoryId: id })}
        onItemClick={editProduct}
        pagination={{ page, setPage, total }}
      />
      <RemoveItemModal
        onClose={() => showRemoveModalVisible(false)}
        open={removeModalVisible}
        productId={selectedProduct?.id ?? null}
        onRemove={() => getProducts(id)}
      />
    </LayoutBase>
  );
};

export default Items;
