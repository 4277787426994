import { useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import classNames from 'classnames';

import './description.css';
import Quill from 'quill';

export const Description = ({
  value,
  onChange,
  placeholder = 'faça uma descrição.',
  error,
  isReadOnly,
}) => {
  const quillRef = useRef(null);

  const modules = {
    toolbar: isReadOnly
      ? false
      : [['bold', 'link'], [{ color: ['#646464', 'rgba(255, 0, 90, 1)'] }]],
  };

  useEffect(() => {
    if (quillRef.current) {
      const editor = (quillRef.current as unknown as { getEditor: () => Quill }).getEditor();
      editor.enable(!isReadOnly);
    }
  }, [isReadOnly]);

  return (
    <>
      <ReactQuill
        ref={quillRef}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        modules={modules}
        formats={['bold', 'link', 'color']}
        theme="snow"
        readOnly={isReadOnly}
        className={classNames(
          'description shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border border-gray-300 rounded-b-md text-gray-900',
          isReadOnly ? 'bg-gray-200' : ''
        )}
      />
      {error && (
        <p className="mt-2 text-sm text-neon-900 w-full lowercase">* {error}</p>
      )}
    </>
  );
};