import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { useEffect } from "react";

export const Description = ({
  value,
  onChange,
  error,
}: {
  value: string;
  onChange: (_value: string) => void;
  error?: string;
}) => {
  const theme = "snow";
  const placeholder = "faça uma descrição sobre a empresa.";
  const formats = ["bold", "link", "color"];
  const modules = {
    toolbar: [
      ["bold", "link"],
      [{ color: ["#646464", "rgba(255, 0, 90, 1)"] }],
    ],
  };

  const { quill, quillRef } = useQuill({
    theme,
    modules,
    formats,
    placeholder,
  });

  useEffect(() => {
    if (quill) {
      quill.setText(value ?? "");
      quill.clipboard.dangerouslyPasteHTML(value ?? "");
      quill.blur();
      quill.on("text-change", () => onChange(quill.root.innerHTML));
    }
  }, [quill]);

  return (
    <>
      <section
        ref={quillRef}
        className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border border-gray-300 rounded-b-md"
      />
      {error && (
        <p className={`mt-2 text-sm text-neon-900 w-full lowercase`}>
          * {error}
        </p>
      )}
    </>
  );
};
