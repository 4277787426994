import { useEffect, useState } from 'react';
import { IonPage, IonHeader, IonContent, useIonLoading } from '@ionic/react';
import { useHistory } from 'react-router';
import { Header } from 'oialbert-ui';
import Form from '../CreateItem/components/Form';
import { useProductContext } from '../../contexts/ProductContext';

const CreateItem = () => {
  const [present, dismiss] = useIonLoading();
  const [fullLoading, setFullLoading] = useState<boolean>(false);

  const { selectedProduct } = useProductContext();
  const history = useHistory();

  useEffect(() => {
    if (fullLoading) {
      present({
        message: 'aguarde...',
        keyboardClose: false,
        backdropDismiss: false,
      });
    } else {
      dismiss();
    }
  }, [dismiss, fullLoading, present]);

  return (
    <IonPage>
      <IonHeader className="ion-no-border bg-white">
        <section className="mt-10 px-5 pb-5">
          <Header onClick={() => history.goBack()} title="Editar Produto" />
        </section>
      </IonHeader>
      <IonContent>
        {selectedProduct && (
          <Form product={selectedProduct} onDuplicate={setFullLoading} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default CreateItem;
