import { IconBs } from 'oialbert-ui';
import { useHistory } from 'react-router';
import { translateWeekDays } from '../../../../utils/weekDays';
import { format } from 'date-fns';
import { Hours } from '../../../../types/companies';

const Days = ({
  day,
  nameday,
  handleGetDay,
}: {
  day: Hours[] | undefined;
  nameday: string;
  handleGetDay: (day: any) => void;
}) => {
  const { push } = useHistory();

  function formatFullTime(dateOrTime: string) {
    if (dateOrTime.includes('T')) {
      return format(new Date(dateOrTime), 'HH:mm');
    } else {
      const parts = dateOrTime.split(':');
      if (parts.length === 3) {
        return `${parts[0]}:${parts[1]}`;
      }
      return dateOrTime;
    }
  }
  function handleNavigateToEdit() {
    push(`/opening-hours/edit/${nameday}`);
  }

  return (
    <div className="flex  mb-2">
      <div className="flex items-center gap-x-4 w-1/4">
        <div className="bg-white shadow-md rounded h-10 w-10 flex items-center justify-center">
          <IconBs.BsClock className="h-5 w-5 text-neon-900" />
        </div>
        <span className="text-gray-500 truncate">
          {translateWeekDays(nameday)}:
        </span>
      </div>

      <div className="flex items-center w-full">
        <div>
          {day ? (
            <div className="flex flex-row items-center px-2 font-semibold text-gray-600">
              {day.map((item: any, i) => {
                const last = day.length - 1;

                return (
                  <div className="flex flex-col items-center" key={`key.${i}`}>
                    <span className="pr-1 flex flex-col text-gray-600">
                      {formatFullTime(item.open)} - {formatFullTime(item.close)}{' '}
                      {i !== last && ' / '}
                    </span>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>

        <div className="ml-auto">
          <div className="flex">
            <button
              className="w-8 h-8 rounded bg-neon-700 text-white font-bold flex items-center justify-center mr-2"
              onClick={handleNavigateToEdit}
            >
              <IconBs.BsPencil className="w-4 h-4" />{' '}
            </button>
            {day?.length !== 0 ? (
              <button
                className="w-8 h-8 rounded bg-neon-700 text-white font-bold flex items-center justify-center"
                onClick={() => handleGetDay(nameday)}
              >
                <IconBs.BsTrash className="w-4 h-4" />{' '}
              </button>
            ) : (
              <span className="pr-1 flex flex-col bg-neon-500 rounded text-white font-bold px-2 justify-center">
                fechado
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Days;
