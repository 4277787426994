import { Share } from "@capacitor/share";
import { RefresherEventDetail } from "@ionic/core";
import { IonRefresher, IonRefresherContent } from "@ionic/react";
import { Badge, Card, Icon } from "oialbert-ui";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import useAuth from "../../hooks/useAuth";
import useWallet from "../../hooks/useWallet";

import CompanyHeader from "../../components/CompanyHeader";
import ModalTutorial from "./components/ModalTutorial/ModalTutorial";

import { MenuProps } from "./types";

import { LayoutBase } from "../../components/LayoutBase";
import { guides } from "../../services/guides";
import { getData, storeData } from "../../utils/storage";
import { GuidesProps } from "../Guides/types";
import InvoicingInfo from "./DashInfo/InvoicingInfo";

const menus: MenuProps[] = [
  {
    name: "Categorias",
    to: "/menus",
    icon: <Icon.MdAddShoppingCart className="w-6 h-6 mb-2 text-neon-900" />,
    active: true,
    visible: false,
  },
  {
    name: "Cadastrar produtos",
    to: "/products/create",
    icon: <Icon.MdSell className="w-6 h-6 mb-2 text-neon-900" />,
    active: true,
    visible: false,
  },
  {
    name: "Ofertas",
    to: "/offers",
    icon: <Icon.MdOutlineShoppingBag className="w-6 h-6 mb-2 text-neon-900" />,
    active: true,
    visible: false,
  },
  {
    name: "Financeiro",
    to: "/financial",
    icon: <Icon.MdLocalAtm className="w-6 h-6 mb-2 text-neon-900" />,
    active: false,
    visible: false,
  },
  {
    name: "Pedidos",
    to: "/financial-order",
    icon: <Icon.MdOutlineShoppingCart className="w-6 h-6 mb-2 text-neon-900" />,
    active: true,
    visible: true,
  },
  {
    name: "Check-in",
    to: "/check-in",
    icon: <Icon.MdTaskAlt className="w-6 h-6 mb-2 text-neon-900" />,
    active: true,
    visible: true,
  },
  {
    name: "Promoções",
    to: "/promotions",
    icon: <Icon.MdOutlineShoppingBag className="w-6 h-6 mb-2 text-neon-900" />,
    active: true,
    visible: true,
  },
  {
    name: "Cashbacks expirados",
    to: "/cashbacks/expired",
    icon: <Icon.MdAttachMoney className="w-6 h-6 mb-2 text-neon-900" />,
    active: true,
    visible: true,
  },
  {
    name: "Usuários",
    to: "/users",
    icon: <Icon.MdOutlineGroup className="w-6 h-6 mb-2 text-neon-900" />,
    active: true,
    visible: true,
  },
];

const TUTORIAL_KEY = "@AlbertPartners::tutorial";

const Home = () => {
  const { company, user } = useAuth();
  const { getWallets, wallets } = useWallet();

  const [showTutorial, setShowTutorial] = useState<boolean>(false);
  const [invoiceInfo, setInvoiceInfo] = useState<boolean>(false);
  const [getGuides, setGetGuides] = useState<GuidesProps[] | null>(null);

  const isSubsidiary = company?.network_type === "subsidiary";
  const isNormalUser =
    company?.network_type === "head_office" && !company?.has_network;

  const parsedMenus = menus.map((menu) => {
    if (isSubsidiary) {
      return {
        ...menu,
        visible:
          menu.name === "Promoções" ||
          menu.name === "Cashbacks expirados" ||
          menu.name === "Usuários" ||
          menu.name === "Check-in"
            ? true
            : menu.visible,
      };
    }

    if (!isSubsidiary && !isNormalUser) {
      return {
        ...menu,
        visible:
          menu.name === "Promoções" ||
          menu.name === "Cashbacks expirados" ||
          menu.name === "Usuários" ||
          menu.name === "Check-in"
            ? false
            : menu.visible,
      };
    }

    return menu;
  });

  const onRefresh = useCallback(
    async (event: CustomEvent<RefresherEventDetail>) => {
      if (company?.id) {
        getWallets(company.id);
        event.detail.complete();
      }
    },
    [getWallets, company]
  );

  const handleCloseModal = useCallback(() => {
    storeData(TUTORIAL_KEY, JSON.stringify({ showModal: false }));
    setShowTutorial(false);
  }, []);

  const handleShare = useCallback(async () => {
    await Share.share({
      url: `https://associados.oialbert.com.br/detalhe-companhia/${company?.id}`,
      dialogTitle: "compartilhar catálogo",
    });
  }, [company]);

  useEffect(() => {
    let isMounted = true;
    getData(TUTORIAL_KEY).then((data) => {
      if (isMounted) {
        const value = data?.value && JSON.parse(data.value);
        setShowTutorial(value?.showModal ?? false);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (company?.id) {
      getWallets(company.id);
    }
  }, [getWallets, company]);

  useEffect(() => {
    guides().then((data) => {
      setGetGuides(data?.posts);
    });
  }, []);

  return (
    <LayoutBase
      title="Home"
      header={
        <section className="mt-5">
          <CompanyHeader />
        </section>
      }
    >
      <section className="w-100">
        <IonRefresher
          slot="fixed"
          onIonRefresh={(e) => {
            onRefresh(e);
          }}
        >
          <IonRefresherContent />
        </IonRefresher>
        {(company?.login_item_invoice?.status === "pending" ||
          company?.login_item_invoice?.status === "failed") && (
          <section className="mt-5 px-5">
            <div className="bg-red-100 lg:max-w-7xl rounded-md py-4 px-4 text-sm">
              Um pagamento precisa de sua atenção.{" "}
              <Link
                to={{
                  pathname: "/plan/payment",
                  state: { company, origin: "payment_after_home" },
                }}
                className="underline font-bold hover:text-gray-700"
              >
                Toque aqui para resolver.
              </Link>
            </div>
          </section>
        )}
        <section className="mt-5 px-5">
          {company?.report_item_invoice?.url && (
            <iframe
              allowFullScreen
              src={`${company?.report_item_invoice?.url}?usr=${user?.partner.document_number}`}
              style={{
                aspectRatio: "6.2 / 3",
                width: "100%",
              }}
            />
          )}
        </section>

        <section className="mt-4 px-5">
          {Number(company?.cashback_default_in_percent) <= 0 && (
            <p className="my-3 py-3 px-3 text-center text-xs bg-neon-700 text-white rounded shadow">
              para habilitar o check-in e oferecer o benefício de cashback aos
              seus clientes, é preciso configurá-lo. <br />
              <Link
                to="/cashback"
                className="text-sm font-semibold hover:underline"
              >
                configurar cashback
              </Link>
            </p>
          )}
          {wallets &&
            Number(
              wallets.wallets?.total_balance_in_cents +
                wallets?.total_credits_available_in_cents
            ) <= 0 && (
              <p className="my-3 py-3 px-3 text-center text-xs bg-neon-700 text-white rounded shadow">
                você não tem saldo e nem crédito, por favor, entre em contato
                conosco para que possamos liberar o acesso.
              </p>
            )}
          <h3 className="text-sm font-bold mb-4">ações</h3>
          <section className="grid grid-cols-2 sm:grid-cols-6 gap-4 mb-8">
            {parsedMenus
              .filter(({ visible }: any) => visible)
              .map((menu: any, idx) => (
                <Card
                  key={`menu_${idx}`}
                  className="flex flex-col items-center justify-center rounded"
                >
                  <Link
                    to={menu.active ? menu.to : "/"}
                    className="w-full flex flex-col items-center justify-center p-4"
                  >
                    <section
                      className={`flex flex-col items-center justify-center ${
                        !menu.active && "filter grayscale opacity-40"
                      }`}
                    >
                      {menu.icon}
                      <p className="text-xs text-gray-500 text-center mb-2">
                        {menu.name}
                      </p>
                    </section>
                    {menu.soon && <Badge size="small">em breve</Badge>}
                  </Link>
                </Card>
              ))}
          </section>
          {false && (
            <section className="mt-4">
              <Card className="flex rounded">
                <Link to="/orders" className="w-full flex p-4 text-black">
                  <section className="flex items-center">
                    <Icon.MdReceipt className="w-6 h-6 mr-2 text-neon-900" />
                    <p className="text-xs text-center">
                      gerenciar meus pedidos
                    </p>
                  </section>
                </Link>
              </Card>
            </section>
          )}
          {false && (
            <section className="flex items-center justify-center mt-4">
              <section className="bg-neon-900 w-full flex flex-col items-center justify-center rounded">
                <button
                  onClick={handleShare}
                  className="w-full flex flex-col items-center justify-center p-4"
                >
                  <section className="flex items-center justify-center space-x-2">
                    <Icon.MdShare className="w-6 h-6 text-white" />
                    <p className="text-base font-bold text-white text-center">
                      compartilhar catálogo
                    </p>
                  </section>
                </button>
              </section>
            </section>
          )}
        </section>
        <div className="m-4">
          <h3 className="pt-2 text-sm font-bold ">mais sobre o albert</h3>
          <Swiper
            spaceBetween={15}
            slidesPerView={window.innerWidth > 900 ? 3.5 : 1.5}
          >
            {getGuides?.map((guide: GuidesProps) => (
              <SwiperSlide key={guide.id}>
                <>
                  {" "}
                  <div className="pt-5">
                    <div className="flex flex-col items-center">
                      <img
                        className="h-36 w-full object-cover rounded-t-lg"
                        src={`${guide?.feature_image}?w=100&q=15`}
                        alt="Imagem"
                      />

                      <div className="p-2 bg-white rounded-lg h-38 shadow-md mb-1 w-full ">
                        <h1
                          className="text-md font-bold px-1 truncate"
                          key={guide.id}
                        >
                          {guide.title}
                        </h1>
                        <p className="text-sm font-normal px-1 truncate">
                          {guide.excerpt}
                        </p>
                        <Link
                          to={`/guides/${guide.slug}`}
                          className="w-full flex pt-2 text-neon-900 px-1"
                        >
                          <section className="flex items-center">
                            <p className="text-md font-bold ">ver tutorial</p>
                            <Icon.MdNavigateNext className="w-6 h-6 mr-2 text-neon-900" />
                          </section>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <ModalTutorial isOpen={showTutorial} onClose={handleCloseModal} />
        <InvoicingInfo open={invoiceInfo} setOpen={setInvoiceInfo} />
      </section>
    </LayoutBase>
  );
};

export default Home;
