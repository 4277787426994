import { useParams } from 'react-router';

import { useEffect, useState } from 'react';
import { LayoutBase } from '../../components/LayoutBase';
import { guideId } from '../../services/guides';
import { GuidesProps } from './types';

const Guides = () => {
  const [getGuide, setGetGuide] = useState<GuidesProps | null>(null);
  const { slug }: any = useParams();
  useEffect(() => {
    guideId(slug).then((data) => {
      setGetGuide(data);
    });
  }, []);

  return (
    <LayoutBase title="tutorias e guias">
      {getGuide && (
        <div className="p-6 rounded-xl w-full shadow-lg shadow-gray-100 bg-white">
          <div className="w-full lg:max-w-4xl mx-auto pt-4">
            <h1
              className="py-12 text-xl font-bold px-1 truncate"
              key={getGuide?.id}
            >
              {getGuide?.title}
            </h1>
            <img
              className="h-42 w-full object-cover"
              src={`${getGuide?.feature_image}?w=100&q=15`}
              alt="Imagem"
            />

            <div
              className='mx-auto my-6 list-outside text-justify font-display tracking-normal font-normal text-gray-700 leading-tight html text-lg text-justify"'
              dangerouslySetInnerHTML={{ __html: getGuide?.html }}
            ></div>
          </div>
        </div>
      )}
    </LayoutBase>
  );
};
export default Guides;
