import { Modal, Button } from 'oialbert-ui';
import { ModalProps } from 'oialbert-ui/lib/components/Modal/types';
import { Loading } from '../../../components/Loading';

type Props = Pick<ModalProps, 'onClose' | 'open'>;
type PropsExtends = {
  onConfirm: () => void;
  loading?: boolean;
};

const RemoveItemModal = ({
  onConfirm,
  onClose,
  open,
  loading,
}: Props & PropsExtends) => (
  <Modal height={209} open={open} onClose={onClose}>
    {loading ? (
      <section className="flex w-full h-full items-center justify-center">
        <Loading />
      </section>
    ) : (
      <>
        <h3 className="text-base mb-4 text-center font-bold text-black mb-2">
          excluir Item
        </h3>

        <p className="text-black text-base w-56 mx-auto text-center">
          você tem certeza que quer excluir este item?
        </p>

        <div className="flex mt-6 items-center gap-4 max-w-sm mx-auto">
          <Button
            full
            normal
            size="small"
            variant="outline"
            color="neon"
            onClick={onConfirm}
          >
            Excluir
          </Button>
          <Button
            size="small"
            onClick={onClose}
            full
            normal
            variant="light"
            color="neon"
          >
            Voltar
          </Button>
        </div>
      </>
    )}
  </Modal>
);

export default RemoveItemModal;
