export const WEEKDAYS = {
  monday: 'segunda-feira',
  tuesday: 'terça-feira',
  wednesday: 'quarta-feira',
  thursday: 'quinta-feira',
  friday: 'sexta-feira',
  saturday: 'sábado',
  sunday: 'domingo',
};

export const DEFAULT_HOURS = {
  monday: [
    {
      open: '08:00',
      close: '12:00',
    },
    {
      open: '13:00',
      close: '20:00',
    },
  ],
  tuesday: [
    {
      open: '08:00',
      close: '12:00',
    },
    {
      open: '13:00',
      close: '20:00',
    },
  ],
  wednesday: [
    {
      open: '08:00',
      close: '12:00',
    },
    {
      open: '13:00',
      close: '20:00',
    },
  ],
  thursday: [
    {
      open: '08:00',
      close: '12:00',
    },
    {
      open: '13:00',
      close: '20:00',
    },
  ],
  friday: [
    {
      open: '08:00',
      close: '12:00',
    },
    {
      open: '13:00',
      close: '20:00',
    },
  ],
  saturday: [
    {
      open: '08:00',
      close: '12:00',
    },
    {
      open: '13:00',
      close: '20:00',
    },
  ],
  sunday: [
    {
      open: '09:00',
      close: '12:00',
    },
  ],
};
