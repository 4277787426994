import { api } from './config';

export const createPhoto = async (companyId: string, photo: File) => {
  const form = new FormData();
  form.append('photo', photo as File);

  const { status, data } = await api.post(
    `/v1/partners/companies-gallery/${companyId}`,
    form,
    {
      headers: {
        company_id: companyId,
      },
    }
  );

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const deletePhoto = async (companyId: string, photoId: string) => {
  const { status, data } = await api.delete(
    `/v1/partners/companies/${companyId}/companies-gallery/${photoId}`,
    {
      headers: {
        company_id: companyId,
      },
    }
  );

  if (status === 200 && data) {
    return data;
  }

  return false;
};
