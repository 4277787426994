import { Category } from '../pages/Menus/types';
import { api } from './config';

export const fetchProductCategories = async <T>(params = {}) => {
  const { status, data } = await api.get('/v1/partners/categories', { params });

  if (status === 200 && data) {
    return data as T;
  }

  return false;
};

export const createProductCategory = async <T>(
  category: Partial<Category>,
  companyId?: string
) => {
  const { status, data } = await api.post('/v1/partners/categories', {
    ...category,
    company_id: companyId,
  });

  if (status === 200 && data) {
    return data as T;
  }

  return false;
};

export const updateProductCategory = async <T>(
  id: string,
  category: Partial<Category>,
  companyId?: string
) => {
  const { status, data } = await api.put(`/v1/partners/categories/${id}`, {
    ...category,
    company_id: companyId,
  });

  if (status === 200 && data) {
    return data as T;
  }

  return false;
};

export const removeProductCategory = async <T>(id: string) => {
  const { status, data } = await api.delete(`/v1/partners/categories/${id}`);

  if (status === 200 && data) {
    return data as T;
  }

  return false;
};
