import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Icon, Toast } from "oialbert-ui";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import * as z from "zod";
import { LayoutBase } from "../../../components/LayoutBase";
import LoadingAnimation from "../../../components/LoadingAnimation";
import useAuth from "../../../hooks/useAuth";
import { useCompany } from "../../../hooks/useCompany";
import { WEEKDAYS } from "../constants";

type Weekday = keyof typeof WEEKDAYS;

const weekdays: Weekday[] = Object.keys(WEEKDAYS) as Weekday[];

const schema = z.object(
  weekdays.reduce((acc, day) => {
    acc[day] = z
      .array(
        z.object({
          open: z.string(),
          close: z.string(),
        })
      )
      .refine((data) => {
        if (data[0].open && !data[0].close) {
          Toast.crema(
            `${WEEKDAYS[day]}: preencha o horário de fechamento do primeiro turno`
          );
          return false;
        }

        if (!data[0].open && data[0].close) {
          Toast.crema(
            `${WEEKDAYS[day]}: preencha o horário de abertura do primeiro turno`
          );
          return false;
        }

        if (data[1].open && !data[1].close) {
          Toast.crema(
            `${WEEKDAYS[day]}: preencha o horário de fechamento do segundo turno`
          );
          return false;
        }

        if (!data[1].open && data[1].close) {
          Toast.crema(
            `${WEEKDAYS[day]}: preencha o horário de abertura do segundo turno`
          );
          return false;
        }

        return true;
      });

    return acc;
  }, {} as Record<Weekday, any>)
);

const BulkEdit = () => {
  const { company } = useAuth();

  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      ...company?.business_hours,
    },
  });

  const { updateHours } = useCompany();

  const onSubmit = useCallback(async (formData: z.infer<typeof schema>) => {
    formData = Object.keys(formData).reduce((acc, day) => {
      acc[day as Weekday] = formData[day as Weekday].filter(
        (item: any) => item.open !== "" && item.close !== ""
      );
      return acc;
    }, {} as Record<Weekday, any>);

    await updateHours(
      {
        business_hours: {
          ...formData,
        },
      },
      company?.id
    )
      .then(() => {
        history.push("/opening-hours");
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <LayoutBase title="Modificar horarios">
      <div className="max-w-3xl  flex-col mt-10">
        <h1 className="font-bold mb-8">editar horário de funcionamento</h1>
        <form
          className="flex w-full flex flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          {weekdays.map((day) => (
            <div className="flex flex-col">
              <p className="font-bold mb-4">{WEEKDAYS[day]}:</p>
              <div className="flex flex-row gap-4 items-center">
                <p className="mb-1">abertura</p>
                <input
                  className="h-12 border-2 border-gray-200 rounded-md w-full px-4 text-sm"
                  type="time"
                  placeholder="abertura"
                  {...register(`${day}.0.open`)}
                />
                <input
                  className="h-12 border-2 border-gray-200 rounded-md w-full px-4 text-sm"
                  type="time"
                  placeholder="fechamento"
                  {...register(`${day}.0.close`)}
                />
                <p className="mb-1">fechamento</p>
                <input
                  className="h-12 border-2 border-gray-200 rounded-md w-full px-4 text-sm"
                  type="time"
                  placeholder="abertura"
                  {...register(`${day}.1.open`)}
                />
                <input
                  className="h-12 border-2 border-gray-200 rounded-md w-full px-4 text-sm"
                  type="time"
                  placeholder="fechamento"
                  {...register(`${day}.1.close`)}
                />
              </div>
            </div>
          ))}
          <div className="flex flex-row justify-end gap-4 mt-6">
            <Button
              variant="solid"
              color="gray"
              size="small"
              type="button"
              onClick={() => history.push("/opening-hours")}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="solid"
              color="neon"
              size="small"
              disabled={isSubmitting}
            >
              {isSubmitting ? <LoadingAnimation /> : <Icon.MdSave />}
            </Button>
          </div>
        </form>
      </div>
    </LayoutBase>
  );
};

export default BulkEdit;
