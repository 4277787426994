import { Browser } from "@capacitor/browser";
import { Icon } from "oialbert-ui";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

import { useCallback } from "react";
import { LayoutBase } from "../../components/LayoutBase";
import { SettingsMenuProps } from "./types";

import PackageVersion from "../../../package.json";

const Settings = () => {
  const history = useHistory();

  const { user, signOut, company, setCompany } = useAuth();

  const onOpenBrowser = useCallback(async () => {
    await Browser.open({
      url: "https://www.oialbert.com.br/duvidas-parceiros",
    });
  }, []);

  const menus: SettingsMenuProps[] = [
    {
      icon: <Icon.MdPersonOutline className="w-7 h-7 text-neon-900" />,
      title: "Perfil",
      to: "/profile",
      active: false,
      permitted: false,
    },
    {
      icon: <Icon.MdStore className="w-7 h-7 text-neon-900" />,
      title: "Minha empresa",
      to: "/company-profile",
      active: true,
      permitted: user
        ? !["pdv", "delivery"].includes(user.partner.user_types)
        : true,
    },
    {
      icon: <Icon.MdPerson className="w-7 h-7 text-neon-900" />,
      title: "Meus Dados",
      to: "/profile",
      active: true,
      permitted: user
        ? !["pdv", "delivery"].includes(user.partner.user_types)
        : true,
    },
    {
      icon: <Icon.MdPhoto className="w-7 h-7 text-neon-900" />,
      title: "Galeria",
      to: "/gallery",
      active: true,
      permitted: user
        ? !["pdv", "delivery"].includes(user.partner.user_types)
        : true,
    },
    {
      icon: <Icon.MdLockClock className="w-7 h-7 text-neon-900" />,
      title: "Horários de funcionamento",
      to: "/opening-hours",
      active: true,
      permitted: user
        ? !["pdv", "delivery"].includes(user.partner.user_types)
        : true,
    },
    {
      icon: <Icon.MdPriceChange className="w-7 h-7 text-neon-900" />,
      title: "Ajustes de cashback",
      to: "/cashback",
      active: true,
      permitted: user
        ? !["pdv", "delivery"].includes(user.partner.user_types)
        : true,
    },
    {
      icon: <Icon.MdPeople className="w-7 h-7 text-neon-900" />,
      title: "Usuários",
      to: "/users",
      active: true,
      permitted: user
        ? !["pdv", "delivery"].includes(user.partner.user_types)
        : true,
    },
    {
      icon: <Icon.MdCreditCard className="w-7 h-7 text-neon-900" />,
      title: "Informações de pagamento",
      to: "/cards",
      active: true,
      permitted: user
        ? !["pdv", "delivery"].includes(user.partner.user_types) &&
          (!company?.has_network || !company?.head_office_uuid) &&
          user.partner !== null
        : true,
    },
    {
      icon: <Icon.MdToken className="w-7 h-7 text-neon-900" />,
      title: "Token para api pública",
      to: "/token",
      active: true,
      permitted: user
        ? !["pdv", "delivery"].includes(user.partner.user_types)
        : true,
    },
    {
      icon: <Icon.MdAccountBalance className="w-7 h-7 text-neon-900" />,
      title: "Faturas",
      to: "/invoices",
      active: true,
      permitted: user
        ? !["pdv", "delivery"].includes(user.partner.user_types) &&
          (!company?.has_network || !company?.head_office_uuid)
        : true,
    },
    {
      icon: <Icon.MdBusiness className="w-7 h-7 text-neon-900" />,
      title: "Trocar empresa",
      onClick: () => {
        setCompany(null);
        history.push("/select-company");
      },
      active: true,
      permitted: true,
    },
    {
      icon: <Icon.MdTextSnippet className="w-7 h-7 text-neon-900" />,
      title: "Termos",
      to: "/my-term",
      active: true,
      permitted: true,
    },
    {
      icon: <Icon.MdLogout className="w-7 h-7 text-neon-900" />,
      title: "Sair",
      onClick: () => signOut(),
      active: true,
      permitted: true,
    },
  ];

  return (
    <LayoutBase title="Configurações">
      <section className="mt-5 px-5">
        <h3 className="text-base font-bold">Configurações</h3>
      </section>
      <section className="mt-4 px-5">
        <section className="flex flex-col">
          <section className="flex flex-col divide-y divide-crema-500">
            {menus
              .filter((menu) => menu.permitted)
              .map((menu, idx) => (
                <Link
                  to={menu.active && menu.to && !menu.onClick ? menu.to : "/"}
                  onClick={menu.onClick}
                  key={`menu_${idx}`}
                  className={`flex items-center justify-between px-4 py-5 ${
                    !menu.active && "filter grayscale opacity-40"
                  }`}
                >
                  {menu.icon}
                  <h4 className="text-sm flex-1 ml-4 mr-2 text-gray-500">
                    {menu.title}
                  </h4>
                  <Icon.MdKeyboardArrowRight className="w-6 h-6 text-neon-900" />
                </Link>
              ))}
          </section>
          <section className="mt-6">
            <button
              type="button"
              onClick={() => onOpenBrowser()}
              className="bg-transparent border-0 flex items-center justify-center p-4 m-auto"
            >
              <Icon.MdHelpOutline className="w-6 h-6 text-neon-900 mr-2" />
              <span className="text-lg text-gray-500">Ajuda</span>
            </button>
          </section>
          <span className="text-sm text-center">
            v{PackageVersion?.version}
          </span>
        </section>
      </section>
    </LayoutBase>
  );
};

export default Settings;
