import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json"
  },
});

api.interceptors.request.use((config: any) => {
  const token = window.localStorage.getItem("token");
  const companyId = window.localStorage.getItem("company_id");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  
  if (companyId) {
    config.headers.company_id = companyId;
  }

  if (config.url === "/partners/companies") {
    delete config.headers?.common?.["company_id"];
  }
  return config;
});

export { api };
