import { useState, useCallback, createRef } from "react";
import { Radio, Input } from "oialbert-ui";
import useForm from "../../hooks/useForm";
import { useMaskHandler } from "../../hooks/useMaskHandler";
import * as z from "zod";

import { FormCreditCard } from "./components/FormCreditCard";
import { FormBillet } from "./components/FormBillet";
import { FormPix } from "./components/FormPix";
import { LayoutBase } from "../../components/LayoutBase";

const schema = z.object({
  amount: z
    .number({ required_error: "campo obrigatório" })
    .min(5000, "valor mínimo para depósito é de R$ 50,00"),
});

const Balance = () => {
  const formRef: any = createRef();

  const [paymentType, setPaymentType] = useState<string>("credit_card");

  const {
    control,
    register,
    watch,
    formState: { errors, isValid },
  } = useForm({
    schema,
    mode: "onChange",
  });

  const amountProps = useMaskHandler("amount", control, 0);

  const amount = useCallback(() => watch().amount, [watch])();

  return (
    <LayoutBase title="Adicionar Saldo">
      <section className="mt-4 px-5">
        <h3 className="font-base">Quanto você quer adicionar?</h3>

        <section className="my-2 pb-2 border-b border-crema-500">
          <h3 className="font-sm font-bold text-gray-500 mb-220,00">Valor</h3>
          <Input
            type="tel"
            placeholder="0,00"
            mask="MONEY"
            className="bg-white border-0 rounded-none shadow-none font-bold text-2xl text-gray-500 px-0 placeholder-gray-500::placeholder focus:border-0 focus-within:ring-0"
            moneyProps={{
              ...amountProps,
              prefix: "",
            }}
            error={errors.amount?.message?.toString()}
            {...register("amount")}
          />
        </section>
      </section>
      <section className="mt-4 px-5">
        <h3 className="text-base font-bold">Formas de depósito</h3>

        <section className="flex flex-col mt-3">
          <section className="flex flex-1 rounded py-2 items-center">
            <Radio
              label="Cartão de Crédito"
              id="creditCard"
              name="type"
              value="credit_card"
              titleClassName="font-base"
              defaultChecked={paymentType === "credit_card"}
              onChange={(e) => setPaymentType(e.target.value)}
            />
          </section>
          <section className="flex flex-1 rounded py-2 items-center">
            <Radio
              label="Boleto"
              id="billet"
              name="type"
              value="boleto"
              titleClassName="font-base"
              defaultChecked={paymentType === "boleto"}
              onChange={(e) => setPaymentType(e.target.value)}
            />
          </section>
          <section className="flex flex-1 rounded py-2 items-center">
            <Radio
              label="PIX"
              id="pix"
              name="type"
              value="pix"
              titleClassName="font-base"
              defaultChecked={paymentType === "pix"}
              onChange={(e) => setPaymentType(e.target.value)}
            />
          </section>
        </section>
      </section>
      <section className="mt-4 mb-5 px-5">
        {paymentType === "boleto" && (
          <FormBillet
            formRef={formRef}
            amount={amount / 100}
            paymentType={paymentType}
            isAmountValid={isValid}
          />
        )}

        {paymentType === "credit_card" && (
          <FormCreditCard
            formRef={formRef}
            amount={amount / 100}
            paymentType={paymentType}
            isAmountValid={isValid}
          />
        )}

        {paymentType === "pix" && (
          <FormPix
            formRef={formRef}
            amount={amount / 100}
            paymentType={paymentType}
            isAmountValid={isValid}
          />
        )}
      </section>
    </LayoutBase>
  );
};

export default Balance;
