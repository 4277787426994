import { createContext, ReactNode, useContext, useState } from 'react';
import { Product } from '../pages/CreateItem/schema';

type ProductContextProps = {
  setProduct: React.Dispatch<React.SetStateAction<Product>>;
  selectedProduct: Product;
};

type ProductContextProviderProps = {
  children: ReactNode;
};

const ProductContext = createContext<ProductContextProps>({
  setProduct: () => {},
  selectedProduct: null!,
});

export default function ProductContextProvider({
  children,
}: ProductContextProviderProps) {
  const [selectedProduct, setProduct] = useState<Product>(null!);

  return (
    <ProductContext.Provider value={{ setProduct, selectedProduct }}>
      {children}
    </ProductContext.Provider>
  );
}

export const useProductContext = () =>
  useContext<ProductContextProps>(ProductContext);
