import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import { RefresherEventDetail } from "@ionic/core";
import { IonRefresher, IonRefresherContent } from "@ionic/react";
import { Button, Icon } from "oialbert-ui";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import EmptyState from "../../components/EmptyState";
import { LayoutBase } from "../../components/LayoutBase";
import { Loading } from "../../components/Loading";
import { useOffers } from "../../hooks/useOffers";
import { deleteOffer } from "../../services/offers";
import errorHandling from "../../utils/error_handling";
import RemoveItemModal from "./components/RemoveItemModal";

const Item = ({
  offer,
  onDelete,
  onEdit,
}: {
  offer: any;
  onDelete: () => void;
  onEdit: () => void;
}) => (
  <li className="shadow-lg p-4 pb-3 w-full list-none">
    <section className="flex w-full">
      <section className="w-20">
        <img
          src={offer?.product?.photo?.url ?? offer?.photo?.url ?? null}
          className="w-full h-full rounded"
          alt={offer?.product?.title ?? "–"}
        />
      </section>

      <section className="ml-2 flex-grow">
        <p className="font-bold text-sm text-black mb-3">
          {offer?.product?.title ?? "–"}
        </p>
        <p className="w-28 text-sm text-black leading-tight">
          {offer?.product?.description ?? "–"}
        </p>
      </section>
      <section className="ml-2 text-right pr-2">
        <p className="font-bold text-gray-300 text-sm mb-5 uppercase">
          R$ {formatCurrency(offer.value_in_cents / 100)}
        </p>
        <p className="text-sm text-neon-900 leading-tight">
          {`cashback: ${Math.round(
            (offer.price_cashback_in_cents / offer.value_in_cents) * 100
          )}%`}
        </p>
      </section>
    </section>

    <section className="flex space-x-2 mt-4">
      <button
        onClick={onEdit}
        className="flex bg-crema-500 p-2 w-full rounded text-base items-center justify-center text-neon-900"
      >
        <Icon.MdEdit className="mr-1" />
        editar
      </button>
      <button
        onClick={onDelete}
        className="flex bg-crema-500 p-2 w-full rounded text-base items-center justify-center text-neon-900"
      >
        <Icon.MdDeleteOutline className="mr-1" />
        excluir
      </button>
    </section>
  </li>
);

const Offers = () => {
  const history = useHistory();

  const [selectedItem, setSelectedItem] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [removeModalVisible, showRemoveModalVisible] = useState<boolean>(false);
  const [reloading, setReloading] = useState<boolean>(false);

  const { getOffers, loadingOffers, offers } = useOffers();

  const onRefresh = useCallback(
    async (event: CustomEvent<RefresherEventDetail>) => {
      await getOffers();
      setReloading(false);
      event.detail.complete();
    },

    []
  );

  const onDelete = useCallback(async () => {
    setLoading(true);
    try {
      await deleteOffer(selectedItem);
      await getOffers();
    } catch (err) {
      errorHandling(null, "não foi possível deletar esta oferta", "crema");
    } finally {
      setLoading(false);
      showRemoveModalVisible(false);
    }
  }, [getOffers, selectedItem]);

  useEffect(() => {
    getOffers();
  }, [getOffers]);

  return (
    <>
      <LayoutBase title="Ofertas">
        <IonRefresher
          slot="fixed"
          onIonRefresh={(e) => {
            onRefresh(e);
            setReloading(true);
          }}
          disabled={loadingOffers}
        >
          <IonRefresherContent />
        </IonRefresher>
        {loadingOffers && !reloading ? (
          <Loading />
        ) : (
          <>
            {offers.length ? (
              <section className="space-y-4 mt-4">
                <section className="px-5">
                  <Button
                    full
                    onClick={() => history.push("/offers/create")}
                    variant="outline"
                    color="neon"
                  >
                    <span className="text-sm">Cadastrar nova oferta</span>
                  </Button>
                </section>
                <section className="px-5">
                  <section className="space-y-4">
                    {offers.map((offer: any, idx) => (
                      <Item
                        offer={offer}
                        onEdit={() => history.push(`/offers/${offer.id}/edit`)}
                        onDelete={() => {
                          setSelectedItem(offer.id);
                          showRemoveModalVisible(true);
                        }}
                        key={idx}
                      />
                    ))}
                  </section>
                </section>
              </section>
            ) : (
              !loadingOffers && (
                <EmptyState
                  text="nenhuma oferta cadastrada"
                  image="no_data"
                  ctaAction={() => history.push("/offers/create")}
                  ctaText="Cadastrar nova oferta"
                />
              )
            )}
          </>
        )}
      </LayoutBase>
      <RemoveItemModal
        loading={loading}
        onConfirm={() => onDelete()}
        onClose={() => {
          showRemoveModalVisible(false);
          setSelectedItem("");
        }}
        open={removeModalVisible}
      />
    </>
  );
};

export default Offers;
