import { IonRefresher, IonRefresherContent } from '@ionic/react';
import { Card, Icon } from 'oialbert-ui';
import { Link } from 'react-router-dom';

import CompanyHeader from '../../components/CompanyHeader';

import { LayoutBase } from '../../components/LayoutBase';
import { MenuProps } from '../Home/types';

const menus: MenuProps[] = [
  {
    name: 'Check-in',
    to: '/check-in',
    icon: <Icon.MdTaskAlt className="w-6 h-6 mb-2 text-neon-900" />,
    active: true,
    visible: true,
  },
  {
    name: 'Configurações',
    to: '/settings',
    icon: <Icon.MdSettings className="w-6 h-6 mb-2 text-neon-900" />,
    active: true,
    visible: true,
  },
];

const HomePDV = () => {
  return (
    <LayoutBase
      title="Home"
      header={
        <section className="mt-5">
          <CompanyHeader />
        </section>
      }
    >
      <IonRefresher slot="fixed">
        <IonRefresherContent />
      </IonRefresher>
      <section className="mt-5 px-5">
        <section className="mt-4">
          <Card className="flex rounded">
            <Link to="/financial-order" className="w-full flex p-4">
              <section className="flex items-center">
                <Icon.MdReceipt className="w-6 h-6 mr-2 text-neon-900" />
                <p className="text-xs text-center">gerenciar meus pedidos</p>
              </section>
            </Link>
          </Card>
        </section>
      </section>
      <section className="mt-4 px-5">
        <h3 className="text-sm font-bold mb-4">Ações</h3>
        <section className="grid grid-cols-6 gap-4">
          {menus
            .filter(({ visible }) => visible)
            .map((menu, idx) => (
              <Card
                key={`menu_${idx}`}
                className="flex flex-col items-center justify-center rounded p-4"
              >
                <Link
                  to={menu.active ? menu.to : '/'}
                  className={`flex flex-col items-center justify-center ${
                    !menu.active && 'filter grayscale opacity-40'
                  }`}
                >
                  {menu.icon}
                  <p className="text-xs text-gray-500 text-center">
                    {menu.name}
                  </p>
                </Link>
              </Card>
            ))}
        </section>
      </section>
    </LayoutBase>
  );
};

export default HomePDV;
