import { useState, useCallback, useEffect } from 'react';
import { LayoutBase } from '../../components/LayoutBase';
import { Loading } from '../../components/Loading';
import { listReports } from '../../services/reports';
import { MetaType } from '../../types/meta';
import { ReportType } from '../../types/report';
import errorHandling from '../../utils/error_handling';
import { ReportList } from './components/ReportList';

export function Reports() {
  const [reports, setReports] = useState<ReportType[]>([]);
  const [page] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setMeta] = useState<MetaType>();
  const [loading, setLoading] = useState(false);

  const getAllReport = useCallback(async () => {
    const { data, meta } = await listReports({ page });
    setReports(data);
    setMeta(meta);
  }, [page]);

  const tryGetAllReport = useCallback(async () => {
    try {
      setLoading(true);
      await getAllReport();
    } catch (error) {
      errorHandling(error, 'ocorreu um erro ao carregar os materiais de apoio!', 'coral');
    } finally {
      setLoading(false);
    }
  }, [getAllReport]);

  const renderContent = () => {
    if (reports.length === 0) {
      return (
        <div className="mt-8 flex flex-col w-full justify-center items-center p-6 text-xl">
          <h3 className="text-center text-lg text-gray-500">
            Não há materiais de apoio criados para sua empresa.
          </h3>
        </div>
      );
    }
    return reports.map((report) => (
      <ReportList key={report.id} report={report} />
    ));
  };

  useEffect(() => {
    tryGetAllReport();
  }, [tryGetAllReport]);

  return (
    <LayoutBase title="Reports">
      <section className="sm:flex lg:p-3 gap-3 min-h-full bg-gray-50">
        <section className="p-6 rounded-xl w-full shadow-lg shadow-gray-100 bg-white">
          <div className="hidden sm:flex flex-col">
            <h1 className="text-xl font-semibold">Material de apoio</h1>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 items-end gap-3 mt-6 xl:max-w-7xl">
          </div>
          {loading ? (
            <div className="flex justify-center items-center h-96">
              <div className="flex flex-col justify-center items-center shadow-lg border border-gray-50 p-4 rounded-lg gap-3">
                <Loading />
                <span className="text-gray-500 text-sm">carregando</span>
              </div>
            </div>
          ) : (
            <div>{renderContent()}</div>
          )}
        </section>
      </section>
    </LayoutBase>
  );
}
