import { Button } from 'oialbert-ui';

import EmptyBox from '../assets/illustrations/empty_box.svg';
import EmptyNoData from '../assets/illustrations/empty_no_data.svg';
import EmptyWoman from '../assets/illustrations/empty_woman.svg';

const emptyImages = {
  box: EmptyBox,
  no_data: EmptyNoData,
  image: EmptyWoman,
};

type Image = keyof typeof emptyImages;

export type EmptyStateProps = {
  ctaAction?: () => void;
  ctaText?: string;
  alt?: string;
  text: string;
  image: Image;
};

const EmptyState = ({
  ctaAction,
  ctaText,
  alt,
  text,
  image,
}: EmptyStateProps) => {
  const randomImage = emptyImages[image];

  return (
    <div className="flex flex-col justify-center items-center">
      <img width={170} src={randomImage} alt={alt ?? ''} />
      <p className="text-gray-500 text-center my-4">{text}</p>

      {ctaAction && (
        <Button onClick={ctaAction} full color="neon" variant="outline">
          <span className="text-sm font-bold">{ctaText}</span>
        </Button>
      )}
    </div>
  );
};

export default EmptyState;
