import { IonPage, IonContent, IonIcon } from "@ionic/react";
import { Input, Button, Toast, Header } from "oialbert-ui";

import LogoWhite from "../../assets/logo-white.svg";

import * as z from "zod";
import useForm from "../../hooks/useForm";
import { useCallback, useState } from "react";
import { updatePassword } from "../../services/auth";
import errorHandling from "../../utils/error_handling";
import { useHistory, useParams } from "react-router";

import EyeOpenIcon from "../../assets/icons/eyeOpen.svg";
import EyeClosedIcon from "../../assets/icons/eyeClosed.svg";

type ResetPasswordType = z.infer<typeof ResetPasswordSchema>;

export const ResetPasswordSchema = z
  .object({
    password: z.string().nonempty({ message: "preencha a nova senha" }),
    new_password: z.string().nonempty({ message: "confirme a nova senha" }),
  })
  .refine((data) => data.password === data.new_password, {
    message: "as senhas não conferem.",
    path: ["new_password"], // path of error
  });

const ResetPassword = () => {
  const history = useHistory();
  const { email, token }: { email: string; token: string } = useParams();

  const [shouldDisplayPassword, setShouldDisplayPassword] =
    useState<boolean>(false);
  const [shouldDisplayConfirmPassword, setShouldDisplayConfirmPassword] =
    useState<boolean>(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<ResetPasswordType>({
    defaultValues: { password: "", new_password: "" },
    schema: ResetPasswordSchema,
  });

  const onSubmit = useCallback(
    async (item: ResetPasswordType) => {
      try {
        await updatePassword({
          email,
          token,
          password: item.password,
        });

        reset();
        Toast.success("senha resetada com sucesso!");

        history.push("/login");
      } catch (error) {
        errorHandling(error, "erro ao resetar a senha.", "crema");
      }
    },
    [email, history, reset, token]
  );

  return (
    <IonPage>
      <IonContent>
        <section className="bg-gray-900 h-full flex flex-col justify-center items-center">
          <section className="flex flex-col mt-10 px-5 pb-5 space-y-5">
            <img src={LogoWhite} className="h-16" alt="Logo" />
            <Header onClick={() => history.push("/")} title="Trocar senha" />
          </section>
          <form
            className="sm:mx-auto sm:w-full sm:max-w-xl"
            onSubmit={handleSubmit(onSubmit)}
          >
            <section className="flex flex-col items-center justify-between">
              <section className="w-full flex-1 flex flex-col items-center justify-center px-5 space-y-5">
                <h1 className="text-base font-bold text-white">
                  crie sua nova senha
                </h1>
                <p className="w-full text-sm text-white">
                  digite sua nova senha
                </p>

                <section className="w-full flex flex-col space-y-4">
                  <div className="flex items-center bg-gray-800 rounded-md">
                    <Input
                      type={shouldDisplayPassword ? "text" : "password"}
                      {...register("password")}
                      error={errors.password?.message?.toString()}
                      mode="dark"
                      placeholder="Nova senha"
                    />
                    <div
                      className="text-white mx-3"
                      onClick={() =>
                        setShouldDisplayPassword((state) => !state)
                      }
                    >
                      <IonIcon
                        icon={
                          shouldDisplayPassword ? EyeClosedIcon : EyeOpenIcon
                        }
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className="flex items-center bg-gray-800 rounded-md">
                    <Input
                      type={shouldDisplayConfirmPassword ? "text" : "password"}
                      {...register("new_password")}
                      error={errors.new_password?.message?.toString()}
                      mode="dark"
                      placeholder="Confirme sua senha"
                    />
                    <div
                      className="text-white mx-3"
                      onClick={() =>
                        setShouldDisplayConfirmPassword((state) => !state)
                      }
                    >
                      <IonIcon
                        icon={
                          shouldDisplayConfirmPassword
                            ? EyeClosedIcon
                            : EyeOpenIcon
                        }
                      />
                    </div>
                  </div>
                </section>
                <section className="w-full">
                  <Button full variant="solid" color="neon">
                    confirmar
                  </Button>
                </section>
              </section>
            </section>
          </form>
        </section>
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;
