import { useCallback, useState } from 'react';
import errorHandling from '../utils/error_handling';

import { Toast } from 'oialbert-ui';
import type { CreateUserProps } from '../services/types/users';
import {
  createUser,
  deleteUser,
  editUser,
  getAllUsers,
  getUser,
} from '../services/users';
import { UserData } from '../types/users';

export const useUsers = () => {
  const [dataUsers, setDataUsers] = useState<UserData[]>([]);
  const [dataUser, setDataUser] = useState<UserData | null>(null);
  const [createdUser, setCreatedUser] = useState<UserData | null>(null);

  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);

  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [loadingUser, setLoadingUser] = useState<boolean>(false);
  const [loadingCreateUsers, setLoadingCreateUsers] = useState<boolean>(false);
  const [loadingDeleteUsers, setLoadingDeleteUsers] = useState<boolean>(false);
  const [loadingEditUsers, setLoadingEditUsers] = useState<boolean>(false);

  const getDataUsers = useCallback(async () => {
    setLoadingUsers(true);
    try {
      const response = await getAllUsers({
        page,
      });
      setDataUsers(response.users.data);
      setTotal(response.users.meta.total);
    } catch (err) {
      errorHandling(err, 'erro ao buscar usuários', 'crema');
    } finally {
      setLoadingUsers(false);
    }
  }, [page]);

  const getDataUser = useCallback(async (id: string) => {
    setLoadingUser(true);
    try {
      const { user } = await getUser(id);
      setDataUser(user);
    } catch (err) {
      errorHandling(err, 'erro ao buscar usuário', 'crema');
    } finally {
      setLoadingUser(false);
    }
  }, []);

  const onCreateUser = useCallback(async (payload: CreateUserProps) => {
    setLoadingCreateUsers(true);
    try {
      const { data, status }: any = await createUser(payload);
      setCreatedUser(data.user);

      if (status === 200) {
        Toast.success('usuário criado com sucesso', undefined, undefined);
        return;
      }

      if (status === 201) {
        Toast.success('usuário associado com sucesso', undefined, undefined);
        return;
      }
    } catch (err) {
      errorHandling(err, 'erro ao criar usuário', 'crema');
    } finally {
      setLoadingCreateUsers(false);
    }
  }, []);

  const onEditUser = useCallback(
    async (id: string, payload: CreateUserProps) => {
      setLoadingEditUsers(true);
      try {
        await editUser(id, payload);
        Toast.success('usuário atualizado', undefined, undefined);
      } catch (err) {
        errorHandling(err, 'erro ao atualizar usuário', 'crema');
      } finally {
        setLoadingEditUsers(false);
      }
    },
    []
  );

  const onDeleteUser = useCallback(
    async (id: string, callback?: () => void) => {
      setLoadingDeleteUsers(true);
      try {
        await deleteUser(id);
        Toast.success('usuário deletado com sucesso', undefined, undefined);
        callback && callback();
      } catch (err) {
        errorHandling(err, 'erro ao deletar usuário', 'crema');
      } finally {
        setLoadingDeleteUsers(false);
      }
    },
    []
  );

  return {
    getUsers: getDataUsers,
    onCreateUser,
    getUser: getDataUser,
    onDeleteUser,
    onEditUser,
    users: dataUsers,
    user: dataUser,
    createdUser,
    setCreatedUser,
    loadingUsers,
    loadingCreateUsers,
    loadingUser,
    loadingDeleteUsers,
    loadingEditUsers,
    page,
    setPage,
    total,
  };
};
