import { api } from "./config";

import type { CompanyDetailsData } from "../types/companies";
import { CompaniesProps } from "./types/companies";

export const getAllCompanies = async (
  filters: CompaniesProps = { limit: 10, page: 1 },
  companyId?: string
) => {
  const { status, data } = await api.get("/v1/partners/companies", {
    params: filters,
    headers: companyId
      ? {
          company_id: companyId,
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        }
      : {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
  });

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const getCompanyById = async (companyId: string) => {
  const { status, data } = await api.get(
    `/v1/partners/companies/${companyId}`,
    {
      headers: {
        company_id: companyId,
      },
    }
  );

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const createCompanies = async (payload: CompanyDetailsData) => {
  const { status, data } = await api.post("/v1/partners/companies", payload);

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const updateCompanies = async ({
  id,
  ...payload
}: CompanyDetailsData) => {
  const { status, data } = await api.put(
    `/v1/partners/companies/${id}`,
    payload,
    {
      headers: {
        company_id: id,
      },
    }
  );

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const updateOpeningHours = async ({ id, payload }: any) => {
  const { status, data } = await api.put(
    `/v1/partners/companies/${id}`,
    payload,
    {
      headers: {
        company_id: id,
      },
    }
  );

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const uploadFoto = async <T>(id: string, photo: File, payload: any) => {
  const form = new FormData();
  form.append("photo", photo as File);

  const { status, data } = await api.put(`/v1/partners/companies/${id}`, form);

  if (status === 200 && data) {
    return data as T;
  }

  return false;
};

export const uploadBanner = async <T>(
  id: string,
  banner: File,
  payload: any
) => {
  const form = new FormData();
  form.append("banner", banner as File);

  const { status, data } = await api.put(`/v1/partners/companies/${id}`, form);

  if (status === 200 && data) {
    return data as T;
  }

  return false;
};
