// @ts-nocheck
import React, { useState } from "react";
import { WithContext as ReactTags } from "react-tag-input";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

type TagType = {
  id: string;
  text: string;
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

interface TagProps {
  value: string[];
  onChange: any;
  error: string;
  isReadOnly?: boolean;
}

export const Tag = ({ value, onChange, error, isReadOnly }: TagProps) => {
  const previewTags = value
    ? value.map((val: string) => ({
        id: val,
        text: val.replace(/["{}]/g, ""),
      }))
    : [];

  const [tags, setTags] = useState<TagType[]>(previewTags);

  const handleDelete = (i: any) => {
    const newTags = tags.filter((tag, index) => index !== i);
    setTags(newTags);
    handleChange(newTags);
  };

  const handleAddition = (tag: TagType) => {
    const newTag = {
      id: tag.id,
      text: ` ${tag.text}`,
    };

    const newTags = [...tags, newTag];
    setTags(newTags);
    handleChange(newTags);
  };

  const handleDrag = (tag: any, currPos: any, newPos: any) => {
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setTags(newTags);
    handleChange(newTags);
  };

  const handleTagClick = (index: number) => {
    console.log("The tag at index " + index + " was clicked");
  };

  const handleChange = (tags: TagType[]) => {
    onChange(tags.map((tag) => tag.text));
  };

  return (
    <div className="w-full">
      <ReactTags
        tags={tags}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        handleTagClick={handleTagClick}
        delimiters={delimiters}
        inputFieldPosition="bottom"
        placeholder="digite as palavras chave"
        autocomplete
        readOnly={isReadOnly}
      />
      {error && (
        <p
          className={`mt-2 text-sm text-neon-900 w-full lowercase bg-white`}
          id={`${error}-error`}
        >
          * {error}
        </p>
      )}
    </div>
  );
};
