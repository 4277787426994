import { cnpj, cpf } from 'cpf-cnpj-validator';
import * as zod from 'zod';

export const schema = zod.object({
  charge_id: zod
    .string({ required_error: 'id da cobrança não informado' })
    .nonempty(),
  payment_method: zod.enum(['credit_card'] as const),
  customer_id: zod
    .string({ required_error: 'customer id não informado' })
    .optional()
    .nullable(),
  installments: zod.number(),
  customer: zod
    .object({
      gender: zod.enum(['male', 'female'] as const),
      name: zod
        .string()
        .regex(/^[A-Za-z\s]*$/, {
          message: 'Este campo deve conter somente letras sem acentuação',
        })
        .nonempty({ message: 'Campo obrigatório' })
        .trim(),
      type: zod.enum(['individual'] as const),
      email: zod
        .string({ required_error: 'campo obrigatório' })
        .trim()
        .nonempty('campo obrigatório'),
      code: zod.string({ required_error: 'id do parceiro não informado' }),
      document: zod
        .string({ required_error: 'campo obrigatório' })
        .trim()
        .nonempty('campo obrigatório')
        .refine((value) => {
          if (cpf.isValid(value) || cnpj.isValid(value)) {
            return true;
          }
          return false;
        }, 'documento inválido'),
      document_type: zod.enum(['CPF', 'CNPJ'] as const),
      birthdate: zod.string().trim().optional().nullable(),
      address: zod.object({
        country: zod.enum(['BR'] as const),
        state: zod
          .string({ required_error: 'customer state não informado' })
          .trim()
          .nonempty('campo obrigatório'),
        city: zod
          .string({ required_error: 'customer city não informado' })
          .trim()
          .nonempty('campo obrigatório'),
        zip_code: zod
          .string({ required_error: 'customer zip_code não informado' })
          .trim()
          .nonempty('campo obrigatório'),
        line_1: zod
          .string({ required_error: 'customer line_1 não informado' })
          .trim()
          .nonempty('campo obrigatório'),
        line_2: zod
          .string({ required_error: 'customer line_2 não informado' })
          .trim()
          .optional(),
      }),
      phones: zod.object({
        mobile_phone: zod.object({
          area_code: zod
            .string({ required_error: 'customer area_code não informado' })
            .trim(),
          country_code: zod
            .string({ required_error: 'customer country_code não informado' })
            .trim(),
          number: zod
            .string({ required_error: 'customer number não informado' })
            .trim(),
        }),
      }),
    })
    .optional(),
  token_card: zod.string().optional(),
  card: zod
    .object({
      number: zod
        .string({ required_error: 'campo obrigatório' })
        .trim()
        .min(13, 'deve ter entre 13 e 16 dígitos')
        .max(16),
      holder_name: zod
        .string({ required_error: 'campo obrigatório' })
        .regex(new RegExp('^[aA-zZ0-9çÇs ]+$'), 'Nome não pode acentuações')
        .nonempty('campo obrigatório'),
      holder_document: zod
        .string({ required_error: 'campo obrigatório' })
        .trim()
        .nonempty('campo obrigatório'),
      exp_month: zod
        .string({ required_error: 'campo obrigatório' })
        .trim()
        .min(2, 'deve ter 2 dígitos')
        .max(2)
        .refine((value) => {
          if (Number(value) >= 1 && Number(value) <= 12) {
            return true;
          }
          return false;
        }, 'O mês não pode ser maior que 12'),
      exp_year: zod
        .string({ required_error: 'campo obrigatório' })
        .trim()
        .min(2, 'deve ter 2 dígitos')
        .max(2)
        .refine((value) => {
          const actualYear = new Date().getFullYear().toString().substr(-2);
          if (Number(value) >= Number(actualYear)) {
            return true;
          }
          return false;
        }, 'O ano não pode ser menor que o ano atual'),
      cvv: zod
        .string({ required_error: 'campo obrigatório' })
        .trim()
        .min(3, 'deve ter entre 3 e 4 dígitos')
        .max(4),
      billing_address: zod.object({
        zip_code: zod
          .string({ required_error: 'campo obrigatório' })
          .trim()
          .min(8, 'deve ter 8 dígitos'),
        line_1: zod
          .string({ required_error: 'campo obrigatório' })
          .trim()
          .nonempty('campo obrigatório'),
        line_2: zod
          .string({ required_error: 'campo obrigatório' })
          .trim()
          .optional(),
        city: zod
          .string({ required_error: 'campo obrigatório' })
          .trim()
          .nonempty('campo obrigatório'),
        state: zod
          .string({ required_error: 'campo obrigatório' })
          .trim()
          .min(2, 'deve ter duas letras')
          .nonempty('campo obrigatório'),
        country: zod.enum(['BR'] as const),
      }),
    })
    .optional(),
});
