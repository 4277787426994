import { useIonAlert } from '@ionic/react';
import { differenceInBusinessDays } from 'date-fns';
import { Avatar, Badge, Icon } from 'oialbert-ui';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import useAuth from '../../../hooks/useAuth';
import { CompanyDetailsData, InvoiceStatus } from '../../../types/companies';
import { checkData } from '../../../utils/checks';

type CardCompanyProps = {
  company: CompanyDetailsData;
  setCompany: (_company: CompanyDetailsData) => void;
};

const statusCompany: { [key: string]: string } = {
  generated: 'cobrança gerada',
  viewed: 'cobrança visualizada',
  underpaid: 'pagamento parcial',
  overpaid: 'pagamento em excesso',
  paid: 'cobrança paga',
  voided: 'cobrança cancelada',
  processing: 'processando pagamento',
  pending: 'pagamento pendente',
  failed: 'pagamento não autorizado',
  expired: 'pagamento expirado',
};

export const CardCompany = ({ company, setCompany }: CardCompanyProps) => {
  const [presentAlert] = useIonAlert();
  const history = useHistory();
  const { user } = useAuth();
  const statusPayment =
    company.login_item_invoice?.status ?? company.company_status?.status;

  const isPendingData = checkData(company, [
    'name',
    'profile_type',
    'payment_types',
  ]);

  const isPendingPayment = (
    [
      'viewed',
      'underpaid',
      'generated',
      'failed',
      'pending',
      'expired',
    ] as InvoiceStatus[]
  ).includes(statusPayment);

  const access_permitted = useMemo(() => {
    if (!company) return null;

    const lastDatePermitted =
      company.last_access_date_permitted ??
      company?.company_status?.last_login_access_date_permitted;

    if (!lastDatePermitted) return null;

    const lastAccessDate = new Date(lastDatePermitted);

    return differenceInBusinessDays(lastAccessDate, new Date()) >= 0;
  }, [company]);

  return (
    <section className="sm:mx-auto sm:w-full sm:max-w-xl w-full flex justify-between bg-gray-500 bg-opacity-5 shadow-lg rounded">
      <button
        onClick={() => {
          // Verificar se o pagamento está em processamento ou se está carregando a company
          if (statusPayment === 'processing') {
            return;
          }

          // Verificar se o acesso é permitido e o status do pagamento é 'paid'
          if (
            (access_permitted || company?.last_access_date_permitted == null) &&
            statusPayment === 'paid'
          ) {
            if (
              !(user && ['pdv', 'delivery'].includes(user?.partner?.user_types))
            ) {
              if (isPendingData.length) {
                history.push(`/onboarding/${company.id}`);
              } else {
                setCompany(company);
              }
            }
            //if (!isPendingData.length)
            else {
              setCompany(company);
            }
          } else if (access_permitted === false) {
            // Se o acesso não é permitido, redirecionar para a página de pagamento do plano
            if (
              !(user && ['pdv', 'delivery'].includes(user?.partner?.user_types))
            ) {
              history.push('/plan/payment', { company });
            } else {
              presentAlert({
                header: 'Conta com pendências.',
                message:
                  'Consulte o administrador da conta para restabelecer o acesso.',
                buttons: ['OK'],
              });
            }
          } else if (statusPayment === undefined) {
            // Se o status do pagamento é indefinido, exibir um alerta de assinatura irregular
            presentAlert({
              header: 'assinatura irregular',
              message:
                'esta assinatura está pendente de atualização. Entre em contato com o suporte.',
              buttons: [
                { text: 'Entendi', role: 'confirm', handler: () => {} },
              ],
            });
          } else if (
            isPendingPayment &&
            access_permitted &&
            company?.last_access_date_permitted
          ) {
            if (
              !(user && ['pdv', 'delivery'].includes(user?.partner?.user_types))
            ) {
              // Se há pagamento pendente, mostrar um alerta caso o acesso for permitido
              const lastAccessDate = new Date(
                company.last_access_date_permitted
              );
              const message = `a assinatura possui pagamento pendente (Expira em ${lastAccessDate.toLocaleDateString()})`;
              const buttons = [
                {
                  text: 'pagar agora',
                  role: 'confirm',
                  handler: () => history.push('/plan/payment', { company }),
                },
                {
                  text: 'pagar depois',
                  role: 'confirm',
                  handler: () => {
                    setCompany(company);
                  },
                },
              ];

              presentAlert({ header: 'pagamento pendente', message, buttons });
            } else {
              setCompany(company);
            }
          } else if (isPendingPayment && access_permitted == null) {
            if (
              !(user && ['pdv', 'delivery'].includes(user?.partner?.user_types))
            ) {
              history.push('/plan/payment', { company });
            } else {
              presentAlert({
                header: 'Conta com pendências.',
                message:
                  'Consulte o administrador da conta para restabelecer o acesso.',
                buttons: ['OK'],
              });
            }
          } else if (
            isPendingData.length &&
            access_permitted == null &&
            statusPayment === 'paid'
          ) {
            setCompany(company);
          }
        }}
        className="w-full flex items-center justify-between px-4 py-3 border-0 bg-transparent"
      >
        <Avatar
          size="default"
          variant="circle"
          src={company.photo?.url ?? ''}
        />
        <p className="text-base font-bold flex-1 ml-4 mr-2 text-neon-900 flex flex-col">
          {company.name}
          {user &&
            !['pdv', 'delivery'].includes(user.partner.user_types) &&
            (statusPayment !== 'paid' && statusPayment !== undefined ? (
              <span>
                <Badge size="small">{statusCompany[statusPayment]}</Badge>
              </span>
            ) : statusPayment === undefined ? (
              <span>
                <Badge size="small">assinatura irregular</Badge>
              </span>
            ) : (
              isPendingData.length > 0 && (
                <span>
                  <Badge size="small">informações pendentes</Badge>
                </span>
              )
            ))}
        </p>
        {statusPayment !== 'processing' ? (
          <Icon.MdKeyboardArrowRight className="w-6 h-6 text-neon-900" />
        ) : null}
      </button>
    </section>
  );
};
