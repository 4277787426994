import { IonContent, IonHeader, IonPage, IonSpinner } from '@ionic/react';
import { Button, Header, Input, Toast } from 'oialbert-ui';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { api } from '../../services/config';
import { CompanyDetailsData } from '../../types/companies';
import { formatInCents } from '../../utils/money';
import { LabeledCheckbox } from './components/LabeledCheckbox';
import { CreditCardForm } from './forms/CreditCard';
import { PaymentPixForm } from './forms/PaymentPix';
import { PaymentSlipForm } from './forms/PaymentSlip';
import { CouponData, GetLastChargeData, PlanDetails } from './type';
import { CardSelect } from '../../types/card';

const PlanPayment: React.FC = () => {
  const paymentsLabels = [
    {
      value: 'pix',
      label: 'pix',
    },
    {
      value: 'boleto',
      label: 'boleto',
    },
    {
      value: 'credit_card',
      label: 'cartão de crédito',
    },
  ];

  function handleGetPaymentMethodLabel(type: string) {
    const paymentLabelObject = paymentsLabels.find(
      (payment) => payment.value === type
    );
    return paymentLabelObject?.label ?? '';
  }

  const history = useHistory();
  const { state } = useLocation<{
    company: CompanyDetailsData;
    origin?: string;
  }>();

  const [planDetails, setPlanDetails] = useState<PlanDetails>();
  const [discount, setDiscount] = useState<CouponData>();
  const [loadingDiscount, setLoadingDiscount] = useState<boolean>(false);
  const [validateDiscount, setValidateDiscount] = useState<boolean>(true);
  const invoice: any = state?.company?.login_item_invoice;
  const [getLastCharge, setGetLastCharge] = useState<GetLastChargeData>();
  const [cards, setCards] = useState<[]>([]);
  const [cardSelected, setCardSelected] = useState<CardSelect | undefined>(
    undefined
  );

  const { control, handleSubmit, setValue, watch } = useForm<{
    code: string;
    selectedPaymentMethod: string;
  }>();

  const [watchPaymentMethodSelected] = watch(['selectedPaymentMethod']);

  const handleValidateCoupon = useCallback(
    async ({ code }: { code: string }) => {
      if (!code) return;
      setLoadingDiscount(true);
      await api
        .get(`/v2/partners/gateway/${invoice.id}/coupon/${code}`)
        .then(({ data }) => {
          setValidateDiscount(true);
          setDiscount(data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setValidateDiscount(false);
            Toast.crema('Cupom não encontrado');
          } else if (error.response.data?.message) {
            setValidateDiscount(false);
            Toast.crema(error.response.data?.message);
          }
        })
        .finally(() => {
          setLoadingDiscount(false);
        });
    },
    [invoice?.id]
  );

  const handleRemoveCoupon = useCallback(() => {
    if (!discount) {
      return;
    }

    setDiscount(undefined);
    setValue('code', '');
  }, [discount, setValue]);

  useEffect(() => {
    (async () => {
      if (!state?.company) return;
      api.defaults.headers.common['company_id'] = state?.company?.id;
      const { data } = await api.get(
        `/v2/partners/gateway/invoices/${invoice.id}`
      );
      setGetLastCharge(data.charges?.pop());
      setPlanDetails(data?.product_copy);
    })();
    if (!invoice) {
      history.push('/select-company');
    }

    return () => {
      if (state?.origin === 'payment_after_home') {
        return;
      }
      delete api.defaults.headers?.common?.['company_id'];
    };
  }, [history, invoice, setValue, state?.company, state?.origin]);

  useEffect(() => {
    (async () => {
      if (!state?.company) return;
      api.defaults.headers.common['company_id'] = state?.company?.id;
      const { data } = await api.get(`/v2/partners/gateway/cards`);
      setCards(data);
    })();
  }, [state?.company]);

  useEffect(() => {
    setValue(
      'selectedPaymentMethod',
      getLastCharge?.payment_method && invoice?.charge_type === 'recurrence'
        ? getLastCharge?.payment_method
        : planDetails?.payment_methods[0]
    );
  }, [
    getLastCharge,
    invoice?.charge_type,
    planDetails?.payment_methods,
    setValue,
  ]);

  useEffect(() => {
    if (
      invoice?.charge_type === 'recurrence' &&
      getLastCharge?.coupon_copy?.code &&
      getLastCharge?.coupon_copy?.cycles - getLastCharge?.coupon_cycle <=
        getLastCharge?.coupon_copy?.cycles
    ) {
      (async () => {
        setLoadingDiscount(true);
        await api
          .get(
            `/v2/partners/gateway/${invoice.id}/coupon/${getLastCharge?.coupon_copy?.code}`
          )
          .then(({ data }) => {
            setDiscount(data);
          })
          .catch((error) => {
            if (error.response.status === 404) {
              Toast.crema('Cupom não encontrado');
            }
          })
          .finally(() => {
            setLoadingDiscount(false);
          });
      })();
      setValue('code', getLastCharge?.coupon_copy?.code);
    }
  }, [invoice, getLastCharge, setValue]);

  return (
    <IonPage>
      <IonHeader className="ion-no-border bg-white shadow-sm sm:mb-5">
        <section className="mt-10 px-5 pb-5 sm:mx-auto sm:w-4/5">
          <Header onClick={() => history.goBack()} title="pagamento do plano" />
        </section>
      </IonHeader>
      <IonContent>
        <section className="sm:flex sm:flex-row-reverse sm:mx-auto sm:w-4/5">
          <section className="sm:w-2/5">
            {planDetails ? (
              <section className=" px-4 gap-3 flex flex-col">
                <div className="flex flex-col sm:justify-between sm:items-start flex-rows flex-wrap  items-start">
                  <div className="flex flex-col gap-1 pb-6">
                    <h1 className="text-lg font-semibold text-gray-700">
                      {planDetails.name}
                    </h1>
                    <h3 className="text-sm font-light">
                      {planDetails.description}
                    </h3>
                  </div>
                  <ul className="flex flex-col  gap-3 w-full">
                    {invoice?.product_copy?.packages.map(
                      (packageItem: any, idxPackage: number) => (
                        <li
                          className="flex flex-col items-start w-full justify-between font-light text-sm border border-gray-100 rounded-lg p-3 shadow"
                          key={`key-package-${idxPackage}`}
                        >
                          <strong className="font-semibold">
                            {packageItem.name}
                          </strong>
                          {packageItem?.items?.map(
                            (item: any, idxItem: number) => (
                              <span
                                key={`key-package-items-${idxItem}`}
                                className="font-light text-sm"
                              >
                                {item.name}
                              </span>
                            )
                          )}
                        </li>
                      )
                    )}
                  </ul>
                  <h1 className="flex flex-col text-2xl mt-5">
                    <b>
                      <span style={{ textTransform: 'uppercase' }}>
                        {formatInCents(planDetails.price_in_cents)}
                      </span>{' '}
                      mensais
                    </b>
                  </h1>
                </div>

                {discount ? (
                  <>
                    <div>
                      <p>
                        <span className="text-xs bg-neon-900 text-white w-4 h-4 shrink-0 inline-flex items-center justify-center rounded-full mr-2">
                          &#10003;
                        </span>
                        cupom{' '}
                        <span style={{ textTransform: 'uppercase' }}>
                          {discount.coupon.code}
                        </span>{' '}
                        aplicado!
                      </p>
                      <p>
                        <span className="text-xs bg-neon-900 text-white w-4 h-4 shrink-0 inline-flex items-center justify-center rounded-full mr-2">
                          &#10003;
                        </span>
                        você tem um desconto de{' '}
                        <span style={{ textTransform: 'uppercase' }}>
                          {formatInCents(discount.discount_in_cents)}
                        </span>{' '}
                        {!discount.coupon?.cycles_value ? (
                          <>
                            para {discount.coupon.cycles} recorrência
                            {discount.coupon.cycles > 0 ? 's' : ''}
                          </>
                        ) : null}
                      </p>

                      {discount.coupon?.cycles_value ? (
                        <p>
                          <span className="text-xs bg-neon-900 text-white w-4 h-4 shrink-0 inline-flex items-center justify-center rounded-full mr-2">
                            &#10003;
                          </span>
                          Esse cupom é de {discount.coupon.cycles} recorrência
                          {discount.coupon.cycles > 0 ? 's' : ''}:
                          {discount.coupon?.cycles_value?.map((cycle) => {
                            return (
                              <li>
                                <strong>ciclo {cycle.cycle}: </strong>
                                <span style={{ textTransform: 'uppercase' }}>
                                  {cycle.discount_percentage}%
                                </span>{' '}
                              </li>
                            );
                          })}
                        </p>
                      ) : null}
                      {getLastCharge?.coupon_copy &&
                      getLastCharge?.coupon_copy?.code ===
                        discount.coupon.code &&
                      getLastCharge?.coupon_cycle >= 0 ? (
                        <p>
                          <span className="text-xs bg-neon-900 text-white w-4 h-4 shrink-0 inline-flex items-center justify-center rounded-full mr-2">
                            &#10003;
                          </span>
                          após realizar este pagamento,{' '}
                          {`você terá utilizado o cupom ${
                            getLastCharge?.coupon_cycle
                          } vez${
                            getLastCharge?.coupon_cycle > 1 ? 'es' : ''
                          }  `}
                          {discount.coupon.cycles -
                            getLastCharge?.coupon_cycle >
                          0
                            ? `e restar${
                                discount.coupon.cycles -
                                  getLastCharge?.coupon_cycle >
                                1
                                  ? 'ão'
                                  : 'á'
                              } ${
                                discount.coupon.cycles -
                                getLastCharge?.coupon_cycle
                              } `
                            : 'e não restará mais '}
                          recorrência
                          {discount.coupon.cycles -
                            getLastCharge?.coupon_cycle >
                          1
                            ? 's'
                            : ''}
                        </p>
                      ) : null}
                    </div>
                    <hr />
                    <div className="flex justify-between">
                      <b>valor final</b>
                      <span style={{ textTransform: 'uppercase' }}>
                        {formatInCents(
                          planDetails.price_in_cents -
                            (discount?.discount_in_cents ?? 0)
                        )}
                      </span>
                    </div>
                    <hr />
                  </>
                ) : null}

                {!getLastCharge?.coupon_copy?.code ? (
                  <>
                    <Controller
                      control={control}
                      name="code"
                      render={({ field }) => (
                        <Input
                          value={field.value}
                          placeholder="tem cupom? insira o código aqui"
                          onChange={(e) => {
                            setValidateDiscount(false);
                            return field.onChange(e.target.value.toUpperCase());
                          }}
                        />
                      )}
                    />

                    <Button
                      color="neon"
                      variant="solid"
                      onClick={handleSubmit(handleValidateCoupon)}
                    >
                      {loadingDiscount ? (
                        <IonSpinner color="text-white h-4" />
                      ) : (
                        'validar cupom'
                      )}
                    </Button>
                    {discount && (
                      <Button
                        color="gray"
                        variant="solid"
                        onClick={handleRemoveCoupon}
                      >
                        remover cupom
                      </Button>
                    )}
                  </>
                ) : null}
              </section>
            ) : (
              <div className="flex justify-center items-center my-4">
                <IonSpinner className="text-neon-900" />
              </div>
            )}
          </section>
          <section className="sm:w-3/5">
            <h1 className="text-lg font-semibold text-center my-3 flex px-4">
              escolha sua forma de pagamento
            </h1>
            <div className="flex px-4 gap-3">
              {planDetails?.payment_methods.map(
                (paymentMethod) =>
                  paymentMethod !== 'debit_card' && (
                    <LabeledCheckbox
                      key={paymentMethod}
                      checked={watchPaymentMethodSelected === paymentMethod}
                      name="selectedPaymentMethod"
                      label={handleGetPaymentMethodLabel(paymentMethod)}
                      onChange={() => {
                        setValue('selectedPaymentMethod', paymentMethod);
                      }}
                    />
                  )
              )}
            </div>
            {getLastCharge ? (
              watchPaymentMethodSelected === 'credit_card' &&
              planDetails?.payment_methods.includes('credit_card') ? (
                <CreditCardForm
                  cards={cards}
                  card={cardSelected}
                  onSelectedCard={setCardSelected}
                  charge={getLastCharge}
                  couponCode={
                    !getLastCharge?.coupon_copy?.code
                      ? discount?.coupon.code ?? null
                      : null
                  }
                  company={state?.company}
                  couponValidate={validateDiscount}
                />
              ) : watchPaymentMethodSelected === 'boleto' &&
                planDetails?.payment_methods.includes('boleto') ? (
                <PaymentSlipForm
                  charge={getLastCharge}
                  coupon={discount}
                  company={state?.company}
                  couponValidate={validateDiscount}
                />
              ) : (
                watchPaymentMethodSelected === 'pix' &&
                planDetails?.payment_methods.includes('pix') && (
                  <PaymentPixForm
                    charge={getLastCharge}
                    coupon={discount}
                    company={state?.company}
                    couponValidate={validateDiscount}
                  />
                )
              )
            ) : null}
          </section>
        </section>
      </IonContent>
    </IonPage>
  );
};

export default PlanPayment;
