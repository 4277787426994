import { IonPage, IonContent } from '@ionic/react';
import { Modal } from 'oialbert-ui';
import { formatInCents } from '../../../../utils/money';
import useAuth from '../../../../hooks/useAuth';

type ModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const FullCreditInfo = ({ open, setOpen }: ModalProps) => {
  const { company } = useAuth();
  const limitInCents = company?.debit_balance_limit_in_cents;

  function handleClosed() {
    setOpen(false);
  }

  return (
    <Modal open={open} onClose={handleClosed}>
      <IonPage>
        <IonContent>
          <div className="px-4 pt-10">
            <div className="box-border h-52 w-46 p-4 border-2 border-neon-900 rounded-md">
              <section className="pt-1 px-4">
                <h3 className="text-base font-bold">limite de crédito</h3>
              </section>
              <section className="pt-2 px-4">
                <p className="text-base font-normal">
                  o limite de crédito é o valor que a gente disponibiliza para
                  que você distribua cashback nas suas vendas. se achar
                  necessário reajustar esse valor, fale com a gente. 😉
                </p>
                <p className="pt-2 text-xs font-normal">
                  valor atual da carteira{' '}
                  <strong className="uppercase">{`${formatInCents(
                    limitInCents ? limitInCents : 0
                  )}`}</strong>
                </p>
              </section>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </Modal>
  );
};

export default FullCreditInfo;
