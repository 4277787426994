import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { UseAuthProps } from "../hooks/useAuth";
import { login, sendToken } from "../services/auth";
import { api } from "../services/config";
import errorHandling from "../utils/error_handling";
import { getData, removeData, storeData } from "../utils/storage";
import Intercom, { shutdown } from "@intercom/messenger-js-sdk";
import { AxiosError } from "axios";

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthContext = createContext<UseAuthProps>({} as UseAuthProps);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<UseAuthProps["user"]>(null);
  const [company, setCompany] = useState<UseAuthProps["company"]>(null);
  const [head, setHead] = useState<UseAuthProps["head"]>(null);
  const [token, setToken] = useState<string | null>(null);

  const [hasSignedOut, setHasSignedOut] = useState<boolean>(false); // Controle de signOut

  const getUser = useCallback(async () => {
    setLoading(true);
    try {
      const tokenStoraged = await getData("@AlbertPartners::token");
      const userStoraged = await getData("@AlbertPartners::user");
      const companyStoraged: any = await getData("@AlbertPartners::company");

      if (tokenStoraged.value && userStoraged.value) {
        api.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(
          tokenStoraged.value
        )}`;
        if (companyStoraged.value) {
          api.defaults.headers.common["company_id"] = companyStoraged.value.id;
          setCompany(JSON.parse(companyStoraged.value));
        }
        setUser(JSON.parse(userStoraged.value));
        setToken(JSON.parse(tokenStoraged.value));
      }
    } catch (err) {
      errorHandling(err, "Erro ao buscar usuário", "crema");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (user && company !== null) {
      storeData("@AlbertPartners::company", JSON.stringify(company));
      window.localStorage.setItem("company_id", company.id);
      api.defaults.headers.common["company_id"] = company.id;
    }
  }, [user, company]);

  const authToken = useCallback(
    async (document_number: any, admin_document_number: any) => {
      setLoading(true);
      try {
        await sendToken(document_number, admin_document_number);
        errorHandling(
          null,
          "token enviado, verifique sua caixa de mensagem",
          "success"
        );
      } catch (error: any) {
        const {
          response: { data, status },
        } = error;
        if (data && status) {
          errorHandling(null, data?.message, "coral");
        }
        return status;
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const signIn = useCallback(async (token: any, document_number: any) => {
    setLoading(true);
    try {
      const data = await login(token, document_number);

      if (!data.user) {
        errorHandling(null, "usuário não tem permissão", "crema");
        return;
      }

      if (data.token && data.user) {
        window.localStorage.setItem(
          "token",
          data.token.token.replaceAll('""', "")
        );
        api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.token.token.replaceAll('""', "")}`;
        setUser(data.user);
        setToken(data.token.token);
        storeData("@AlbertPartners::user", JSON.stringify(data.user));
        storeData(
          "@AlbertPartners::token",
          JSON.stringify(data.token.token.replaceAll('""', ""))
        );
        window.localStorage.setItem(
          "token",
          data.token.token.replaceAll('""', "")
        );
      }
      if (data?.user) {
        return 200;
      }
    } catch (error: any) {
      const {
        response: { status },
      } = error;
      errorHandling(
        null,
        "token expirado, por favor realize o login novamente.",
        "crema"
      );
      return status;
    } finally {
      setLoading(false);
    }
  }, []);

  const signOut = useCallback(() => {
    removeData("@AlbertPartners::token");
    removeData("@AlbertPartners::user");
    removeData("@AlbertPartners::company");
    removeData("@AlbertPartners::tutorial");
    setUser(null);
    setToken(null);
    setCompany(null);
    window.localStorage.removeItem("company_id");
    window.localStorage.removeItem("token");

    delete api.defaults.headers.common["company_id"];

    window.location.href = `${window.location.origin}/login`;
    setHasSignedOut(false);
  }, [history]);

  if (typeof company?.id !== "undefined") {
    Intercom({
      app_id: "m95sx3bt",
      user_id: user?.id,
      name: company?.name ?? "",
      email: user?.email,
      horizontal_padding: 40,
      vertical_padding: 20,
    });
  } else {
    shutdown();
  }

  useEffect(() => {
    const interceptor = api.interceptors.response.use(
      (response) => response,
      (err: AxiosError) => {
        if (
          (err?.config.url !==
            "/v2/partners/contracts/items?type=report_access" && 
            //(err?.config.url === "/v1/partners/companies" && typeof err?.response?.data?.errors?.[0].message !== "undefined") &&
            err?.response?.data?.errors?.[0].message !==
              "O termo de uso não foi aceito." &&
            err?.response?.data?.message !==
              "O termo de uso não foi aceito." &&
            err?.response?.status === 401) 
        ) {
          if (!hasSignedOut) {
            setHasSignedOut(true);
            signOut();
            errorHandling(
              null,
              "Sua sessão expirou, é necessário fazer login novamente",
              "crema"
            );
          }
        } else if (err?.response?.data?.message ===
          "O termo de uso não foi aceito."){
            window.location.href = `/terms`;
        }
        return Promise.reject(err);
      }
    );

    return () => {
      api.interceptors.response.eject(interceptor);
    };
  }, [signOut, hasSignedOut]);

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        setUser,
        getUser,
        updateUser: useCallback(async (user: UseAuthProps["user"]) => {
          setUser(user);
          storeData("@AlbertPartners::user", JSON.stringify(user));
        }, []),
        signIn,
        sendToken: authToken,
        signOut,
        token,
        company,
        head,
        setCompany,
        setHead,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
