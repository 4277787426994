import { Button, Icon, Pagination, Toast } from 'oialbert-ui';
import { useCallback, useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading';
import { formatShort } from '../../../utils/date';
import errorHandling from '../../../utils/error_handling';
import { formatInCents } from '../../../utils/money';
import { deleteOrder } from '../../../services/orders';
import { AxiosError } from 'axios';
import { listAllOrders } from '../../../services/partners/orders';
import { OrderData } from '../../../types/order';
import { Meta } from '../../Orders/types';
import { FiAlertTriangle } from 'react-icons/fi';
import { maskPhoneNumber } from '../../../utils/maskPhoneNumber';

const LIMIT = 10;

interface AssociatesProps {
  companySelectedId?: string;
}

export const Associates = ({ companySelectedId }: AssociatesProps) => {
  const [orders, setOrders] = useState<OrderData[]>([]);
  const [ordersMeta, setOrdersMeta] = useState<Meta>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(ordersMeta?.current_page ?? 1);

  const total: number = ordersMeta?.total ?? 0;

  async function deleteOrderById(orderId: string) {
    setIsLoading(true);
    if (orderId) {
      await tryDeleteOrderById(orderId);
    }
    await getOrders();
  }

  async function tryDeleteOrderById(orderId: string) {
    try {
      await deleteOrder({
        id: orderId,
        type: 'order',
        companyId: companySelectedId,
      });
      Toast.success(
        'A solicitação de cancelamento do pedido foi enviada para análise. Ela será atualizada em breve, e você será notificado via e-mail.'
      );
    } catch (err) {
      const error = err as AxiosError;

      if (error.response?.data?.message) {
        const { message } = error.response.data;
        Toast.crema(message);
      } else {
        Toast.crema('ocorreu um erro ao deletar cashback');
      }
    } finally {
      setIsLoading(false);
    }
  }

  const getOrders = useCallback(async () => {
    setIsLoading(true);
    try {
      const {
        data: { orders },
        status,
      } = await listAllOrders({
        page,
        limit: LIMIT,
        companyId: companySelectedId,
      });
      if (status === 200 && orders) {
        if (orders.data) setOrders(orders.data);
        if (orders.meta) setOrdersMeta(orders.meta);
      }
    } catch (error: any) {
      const {
        response: { status, data },
      } = error;
      if (status !== 404 && data) {
        errorHandling(null, data.message, 'coral');
      }
    } finally {
      setIsLoading(false);
    }
  }, [companySelectedId, page]);

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {!orders?.length ? (
            <section className="w-full flex justify-center py-4">
              <h3 className="text-center text-lg text-gray-500">
                nenhum pedido encontrado
              </h3>
            </section>
          ) : (
            <>
              {orders.map((order: OrderData, idx: number) => (
                <section key={`order_${idx}`} className="flex mt-6 px-6">
                  <Icon.MdOutlineShoppingCart className="w-6 h-6 text-gray-300" />
                  <section key={order.id} className="ml-2 flex-1">
                    <section className="w-full flex justify-between items-center">
                      <div>
                        <h4 className="flex text-base font-bold mb-3.5">
                          {`${order.customer?.full_name??maskPhoneNumber(order.customer?.cellphone)}`} •{' '}
                          <span className="font-bold uppercase ml-1">
                            {formatInCents(order.order_price_in_cents ?? 0)}
                          </span>
                        </h4>
                        <section className="mt-2">
                          {Number(order.used_cashback_in_cents) > 0 && (
                            <p className="text-sm text-gray-500 mt-2">
                              pagamento recebido:{' '}
                              <span className="font-bold text-neon-900 uppercase">
                                {formatInCents(
                                  Number(order.used_cashback_in_cents)
                                )}
                              </span>{' '}
                              <span className="text-neon-900 font-bold">
                                (por cashback)
                              </span>
                            </p>
                          )}
                          <p className="text-sm text-gray-500 mt-2">
                            cashback ofertado para rede:{' '}
                            <span className="font-bold text-neon-900 uppercase">
                              {formatInCents(
                                order?.order_cashback_in_cents * 0.1
                              )}
                            </span>
                          </p>
                          <p className="text-sm text-gray-500 mt-2">
                            cashback ofertado para associado:{' '}
                            <span className="font-bold text-neon-900 uppercase">
                              {formatInCents(
                                order?.order_cashback_in_cents * 0.9
                              )}
                            </span>
                          </p>

                          {order.creator?.company_partner?.full_name && (
                            <p className="text-sm text-gray-500 mt-2">
                              checkin realizado por:{' '}
                              <span className="font-light lowercase">
                                {order?.creator?.company_partner?.full_name}
                              </span>
                            </p>
                          )}
                          {order.creator?.partner?.full_name && (
                            <p className="text-sm text-gray-500 mt-2">
                              checkin realizado por:{' '}
                              <span>{order?.creator?.partner?.full_name}</span>
                            </p>
                          )}
                          {order.created_at && (
                            <p className="text-sm text-gray-500 uppercase mt-2">
                              <span className=" uppercase">
                                {formatShort(order.created_at, true)}
                              </span>
                            </p>
                          )}
                          {order.cancel_request_status === 'waiting' && (
                            <div className="flex text-gray-500 mt-2 gap-1">
                              <FiAlertTriangle className="mt-1" size={14} />
                              <div className="flex w-96">
                                <p className="text-sm">
                                  A solicitação de cancelamento do pedido foi
                                  enviada para análise. Ela será atualizada em
                                  breve, e você será notificado via e-mail.
                                </p>
                              </div>
                            </div>
                          )}
                          {order?.cancel_request_status === 'reproved' && (
                            <div className="mt-6 flex normal-case rounded-lg items-center text-red-500">
                              <FiAlertTriangle className="w-4 h-4 mr-2 " />
                              <span className="text-sm">
                                Sua solicitação foi recusada.
                              </span>
                            </div>
                          )}
                        </section>
                      </div>
                      <Button
                        variant="solid"
                        color="neon"
                        disabled={
                          order?.cancel_request_status !== null ||
                          order?.status === 'complete'
                        }
                        onClick={() => deleteOrderById(order.id)}
                      >
                        <span>cancelar cashback</span>
                      </Button>
                    </section>
                    <hr className="mt-4 h-1 bg-primary-100" />
                  </section>
                </section>
              ))}
              {total > LIMIT && (
                <section className="flex justify-center mt-4">
                  <Pagination
                    onPrev={() => setPage(page - 1)}
                    onNext={() => setPage(page + 1)}
                    onPage={setPage}
                    page={page}
                    total={total}
                    perPage={ordersMeta?.per_page ?? 100}
                    variant='secondary'
                  />
                </section>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
