import { zodResolver } from "@hookform/resolvers/zod";
import type { AxiosError } from "axios";
import cep from "cep-promise";
import { Button, CreditCard, Icon, Input, Toast } from "oialbert-ui";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import * as z from "zod";
import { LayoutBase } from "../../components/LayoutBase";
import { createCard } from "../../services/cards";
import errorHandling from "../../utils/error_handling";
import { CardParamsProps } from "./types";
import { cnpj, cpf } from "cpf-cnpj-validator";

const schema = z.object({
  holder_name: z
    .string()
    .regex(
      new RegExp("^[aA-zZ0-9çÇs ]+$"),
      "Esse campo não aceita acentuações e caracteres especiais"
    )
    .nonempty({ message: "Nome é obrigatório" }),
  holder_document: z
    .string()
    .nonempty({ message: "CPF é obrigatório" })
    .refine((value) => {
      if (cpf.isValid(value) || cnpj.isValid(value)) {
        return true;
      }
      return false;
    }, "documento inválido"),
  number: z.string().nonempty({ message: "Número do cartão é obrigatório" }),
  exp_year: z
    .string()
    .nonempty({ message: "Ano de expiração é obrigatório" })
    .length(2, { message: "Mês de expiração inválido" })
    .refine(
      (value) => {
        //should be greater than or equal to current year, year format is YY
        const year = Number(value);

        return year >= new Date().getFullYear() % 100;
      },
      { message: "Ano de expiração inválido" }
    ),
  exp_month: z
    .string()
    .nonempty({ message: "Mês de expiração é obrigatório" })
    .length(2, { message: "Mês de expiração inválido" })
    .refine(
      (value) => {
        const month = Number(value);
        return month >= 1 && month <= 12;
      },
      { message: "Mês de expiração inválido" }
    ),
  cvv: z.string().nonempty({ message: "CVV é obrigatório" }),
  billing_address: z.object({
    city: z.string().nonempty({ message: "Cidade é obrigatório" }),
    state: z.string().nonempty({ message: "Estado é obrigatório" }),
    zip_code: z.string().nonempty({ message: "CEP é obrigatório" }),
    country: z.string().nonempty({ message: "País é obrigatório" }).length(2),
    line_1: z.string().nonempty({ message: "Endereço é obrigatório" }),
    line_2: z.string().optional(),
  }),
});

export type FormValues = z.infer<typeof schema>;

const Card = () => {
  const { id }: CardParamsProps = useParams();

  const { goBack } = useHistory();

  const {
    formState: { errors, isSubmitting },
    watch,
    register,
    setValue,
    handleSubmit,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { holder_name, number, exp_month, exp_year, cvv } = watch();

  const [, setLoadingAddress] = useState(false);

  const searchZipcode = useCallback(
    async (zipCode: string) => {
      setLoadingAddress(true);
      try {
        const { city, state, street } = await cep(zipCode);

        setValue("billing_address.city", city);
        setValue("billing_address.state", state);
        setValue("billing_address.line_1", street);
      } catch (err) {
        errorHandling(null, "não foi possível encontrar o cep", "crema");
      } finally {
        setLoadingAddress(false);
      }
    },
    [setValue]
  );

  const onSubmit = async (data: FormValues) => {
    try {
      await createCard(data);

      Toast.success("cartão adicionado");
      goBack();
    } catch (err: any) {
      const error = err as AxiosError;

      if (error.response?.data?.message) {
        const { message } = error.response.data;

        Toast.crema(message);
      } else {
        Toast.crema("ocorreu um erro ao adicionar cartão");
      }
    }
  };

  return (
    <LayoutBase title="Adicionar Cartão">
      <form className="mt-4 px-5" onSubmit={handleSubmit(onSubmit)}>
        <section className="w-full flex items-center justify-center mb-8">
          <CreditCard
            width="360px"
            number={number || "0000 0000 0000 0000"}
            holder={holder_name || "Nome do titular"}
            validate={`${exp_month || "00"}/${exp_year || "00"}`}
            code={cvv || "0000"}
          />
        </section>
        <section className="flex flex-col mt-3 mb-4 space-y-4">
          <Input
            {...register("number")}
            type="text"
            placeholder="0000 0000 0000 0000"
            //mask="NUMBER_CARD"
            error={errors?.number?.message?.toString()}
          />
          <section className="flex items-center space-x-4">
            <Input
              {...register("holder_name")}
              placeholder="Nome do titular"
              error={errors?.holder_name?.message?.toString()}
            />
            <Input
              {...register("holder_document", {
                setValueAs: (value) => value.replace(/[_\.-]/g, ""),
              })}
              placeholder="CPF"
              mask="CPF"
              error={errors?.holder_document?.message?.toString()}
            />
          </section>
          <section className="flex items-center space-x-4">
            <Input
              {...register("exp_month")}
              type="text"
              placeholder="Mês (ex: 05)"
              minLength={2}
              maxLength={2}
              error={errors?.exp_month?.message?.toString()}
            />
            <Input
              {...register("exp_year")}
              type="text"
              placeholder="Ano (ex: 25)"
              minLength={2}
              maxLength={2}
              error={errors?.exp_year?.message?.toString()}
            />
            <Input
              {...register("cvv")}
              placeholder="CVV"
              type="text"
              minLength={3}
              maxLength={4}
              error={errors?.cvv?.message?.toString()}
            />
          </section>

          <p className="text-md text-gray-500 font-normal my-4 ">
            Endereço de cobrança
          </p>
          <Input
            {...register("billing_address.zip_code", {
              setValueAs: (value) => value.replace(/[_\.-]/g, ""),
            })}
            placeholder="CEP"
            mask="CEP"
            error={errors?.billing_address?.zip_code?.message?.toString()}
            onBlur={(e) => {
              const zipCode = e.target.value;
              const { length } = zipCode;

              if (length === 9) {
                searchZipcode(zipCode);
              }
            }}
          />
          <section className="flex items-center space-x-4">
            <Input
              {...register("billing_address.city")}
              className="w-1/12"
              placeholder="Cidade"
              error={errors?.billing_address?.city?.message?.toString()}
            />
            <Input
              {...register("billing_address.state")}
              placeholder="Estado (ex: SP)"
              error={errors?.billing_address?.state?.message?.toString()}
            />
            <Input
              {...register("billing_address.country")}
              placeholder="País (ex: BR)"
              minLength={2}
              maxLength={2}
              error={errors?.billing_address?.country?.message?.toString()}
            />
          </section>
          <Input
            {...register("billing_address.line_1")}
            placeholder="Endereço"
            error={errors?.billing_address?.line_1?.message?.toString()}
          />
          <Input
            {...register("billing_address.line_2")}
            placeholder="Complemento"
            error={errors?.billing_address?.line_2?.message?.toString()}
          />
        </section>
        {id !== "add" && (
          <section className="mt-8 mb-4">
            <section className="border-t border-crema-500 py-4">
              <button onClick={() => {}} className="flex items-center">
                <Icon.MdOutlineDelete className="w-6 h-6 text-neon-900" />
                <span className="text-base text-neon-900 ml-2">Excluir</span>
              </button>
            </section>
          </section>
        )}

        <Button
          type="submit"
          variant="solid"
          color="neon"
          full
          disabled={isSubmitting}
        >
          Salvar
        </Button>
      </form>
    </LayoutBase>
  );
};

export default Card;
