import { CartItemData } from '../../types';
import AlbertIcon from '../../../../assets/albert_icon.png';
import { formatInCents } from '../../../../utils/money';
import { IconBs } from 'oialbert-ui';
import { useCart } from '../../context';
import useAuth from '../../../../hooks/useAuth';
import { Loading } from '../../../../components/Loading';

export type ItemCartProps = {
  item: CartItemData;
};

const ItemCart = ({ item }: ItemCartProps) => {
  const { removeItem, cart, isFetchingActiveProducts } = useCart();
  const { company } = useAuth();

  const removeItemFromCart = () => {
    if (company?.id) {
      removeItem(item.package_id, cart.token, company?.id);
    }
  };

  return (
    <div className="relative rounded border border-slate-300 p-4">
      <div className="flex">
        <figure className="flex w-12 h-10 overflow-hidden">
          {item?.purchased_product?.feature_image == null ? (
            <img
              src={AlbertIcon}
              className="w-10 h-10 rounded-md"
              alt={`Foto do Produto ${item?.purchased_product?.name}`}
            />
          ) : (
            <img
              src={item?.purchased_product?.feature_image}
              className="w-12 h-10 rounded-md"
              alt={`Foto do Produto ${item?.purchased_product?.name}`}
            />
          )}
        </figure>
        <div className="flex flex-col justify-center">
          <span className="absolute top-3 right-3 flex items-center justify-center gap-x-1">
            {isFetchingActiveProducts ? (
              <Loading />
            ) : (
              <IconBs.BsTrash
                className="h-4 w-4 hover:text-neon-900 cursor-pointer"
                onClick={removeItemFromCart}
              />
            )}
          </span>
          <h3 className="font-bold">{item?.purchased_product?.name}</h3>
          <div className="text-sm">{item?.purchased_product?.description}</div>
        </div>
      </div>
      <div className="flex justify-end">
        <strong>
          {formatInCents(item?.purchased_product?.price_in_cents ?? 0)}
        </strong>
      </div>
    </div>
  );
};

export default ItemCart;
