import React, { FC } from 'react';
import { CardBrandEnum, CardSelect } from '../../../../types/card';
import { MasterCardIcon } from './MasterCardIcon';
import { VisaIcon } from './VisaIcon';

import BackgroundCreditCard from '../../../../assets/background-creditcard.png';

type CreditCardViewProps = {
  data: CardSelect;
  onSelect: (data: CardSelect) => void;
  isSelected: boolean;
};

const CreditCardView: FC<CreditCardViewProps> = ({
  data,
  onSelect,
  isSelected,
}) => {
  const number = `${data.first_six_digits.slice(0, 4)} **** **** ${
    data.last_four_digits
  }`;
  const expiry = `${
    data.exp_month < 10 ? '0' + data.exp_month : data.exp_month
  }/${data.exp_year.toString().slice(-2)}`;
  const name = data.holder_name;
  const brand = data.brand;

  const BRANDS = {
    [CardBrandEnum.Mastercard]: {
      icon: <MasterCardIcon />,
      background: 'bg-gray-800',
    },
    [CardBrandEnum.Visa]: {
      icon: <VisaIcon />,
      background: 'bg-red-700',
    },
    [CardBrandEnum.Amex]: undefined,
    [CardBrandEnum.JCB]: undefined,
    [CardBrandEnum.Elo]: undefined,
    [CardBrandEnum.Aura]: undefined,
    [CardBrandEnum.Hipercard]: undefined,
    [CardBrandEnum.Diners]: undefined,
    [CardBrandEnum.Discover]: undefined,
  };

  const brandSelected = brand ? BRANDS[brand] : undefined;

  return (
    <div
      onClick={() => onSelect(data)}
      className={`relative w-44 h-28 px-3 py-2 rounded-md cursor-pointer ${
        brandSelected?.background ?? 'bg-gray-800'
      } ${isSelected ? 'opacity-100' : 'opacity-50'}`}
    >
      <img
        src={BackgroundCreditCard}
        alt="Card background"
        className="absolute inset-0 w-full h-full object-cover rounded-md"
      />
      <div className="relative flex flex-col justify-between h-full z-10">
        <div className="flex justify-between items-center">
          <span className="text-white text-xs">Card</span>
          {brandSelected?.icon}
        </div>
        <div className="space-y-1">
          <span className="text-white text-sm font-medium">{number}</span>
          <div className="flex justify-between text-white text-[10px]">
            <span>{name}</span>
            <span>{expiry}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditCardView;
