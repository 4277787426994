import { Icon } from 'oialbert-ui';
import { formatInCents } from '../../../../utils/money';
import { formatShort } from '../../../../utils/date';

type ExtractOrderItemProps = {
  data: {
    code: number;
    albertComission: number;
    customerComission: any;
    customer_id: string | null;
    id: string;
    order_price_in_cents: number;
    company_id: string | null;
    company: {
      name: string;
      id: string;
    } | null;
    customer: {
      full_name: string;
      id: string;
      meta: any;
    } | null;
    created_at: string | null;
    updated_at: string | null;
    payment_type: string;
    order_cashback_in_cents: number;
    used_cashback_in_cents: number;
  };
};

export const ExtractOrderItem = ({ data }: ExtractOrderItemProps) => (
  <section className="flex py-4">
    <Icon.MdOutlineShoppingCart className="w-6 h-6 text-gray-300" />
    <section className="ml-2 flex-1">
      <section className="w-full flex justify-between">
        <h4 className="flex text-base font-bold mb-3.5">
          {`#${data.code}`} •{' '}
          <span className="font-bold uppercase ml-1">
            {formatInCents(data.order_price_in_cents ?? 0)}
          </span>
        </h4>
        {data.created_at && (
          <p className="text-xs text-gray-500 uppercase mb-3">
            {formatShort(data.created_at, false)}
          </p>
        )}
      </section>
      <p className="text-sm">
        pedido realizado por: <strong>{data?.customer?.full_name ?? ''}</strong>
      </p>
      {data.used_cashback_in_cents > 0 && (
        <p className="text-sm">
          pagamento recebido:{' '}
          <span className="font-bold uppercase">
            {formatInCents(data.used_cashback_in_cents)}
          </span>{' '}
          (por cashback)
        </p>
      )}
      <p className="text-sm">
        cashback ofertado para rede:{' '}
        <span className="font-bold text-neon-900 uppercase">
          {formatInCents(data?.order_cashback_in_cents * 0.1)}
        </span>
      </p>
      <p className="text-sm">
        cashback ofertado para associado:{' '}
        <span className="font-bold text-neon-900 uppercase">
          {formatInCents(data?.order_cashback_in_cents * 0.9)}
        </span>
      </p>
      {data.albertComission > 0 && (
        <p className="text-sm">
          taxa albert:{' '}
          <span className="font-bold uppercase">
            {formatInCents(data.albertComission)}
          </span>
        </p>
      )}
    </section>
  </section>
);
