export function translateWeekDays(str: string) {
  if (str === 'monday') {
    return 'segunda-feira';
  }
  if (str === 'tuesday') {
    return 'terça-feira';
  }
  if (str === 'wednesday') {
    return 'quarta-feira';
  }
  if (str === 'thursday') {
    return 'quinta-feira';
  }
  if (str === 'friday') {
    return 'sexta-feira';
  }
  if (str === 'saturday') {
    return 'sábado';
  }
  if (str === 'sunday') {
    return 'domingo';
  }
  return null;
}
