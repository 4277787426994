import { IonToggle } from '@ionic/react';
import { Button } from 'oialbert-ui';
import { useCallback, useEffect } from 'react';
import * as z from 'zod';
import useAuth from '../../../hooks/useAuth';
import { useCompany } from '../../../hooks/useCompany';
import useForm from '../../../hooks/useForm';
import errorHandling from '../../../utils/error_handling';

type PaymentType = {
  dataTemp: any;
  setDataTemp: (_payload: any) => void;
  onNext: () => void;
};

const payment_types = [
  {
    key: 'cash',
    label: 'dinheiro',
    checked: true,
  },
  {
    key: 'credit_card',
    label: 'cartão de crédito',
    checked: false,
  },
  {
    key: 'debit_card',
    label: 'cartão de débito',
    checked: false,
  },
  {
    key: 'pix',
    label: 'pix',
    checked: false,
  },
];

const schema = z.object({
  payment_types: z.object({
    cash: z.boolean().default(true),
    pix: z.boolean().optional(),
    credit_card: z.boolean().optional(),
    debit_card: z.boolean().optional(),
  }),
});

const PaymentTypes = ({ onNext, dataTemp, setDataTemp }: PaymentType) => {
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      payment_types: {
        cash: true,
      },
    },
    schema,
  });

  const field = watch();

  const { setCompany } = useAuth();
  const { updateCompany, company } = useCompany();

  const { document_number, ...restDataTemp } = dataTemp;

  const handleUpdate = useCallback(
    async (payloadData) => {
      try {
        await updateCompany(
          {
            id: restDataTemp?.id,
            ...company,
            ...restDataTemp,
            payment_types: Object.keys(payloadData.payment_types).filter(
              (key) => payloadData.payment_types[key]
            ),
            subcategories: restDataTemp.subcategories.map(
              ({ id: idSubcategory }: { id: string }) => idSubcategory
            ),
          },
          () => onNext()
        );
        setDataTemp({
          ...company,
          ...restDataTemp,
          document_number,
          payment_types: Object.keys(payloadData.payment_types).filter(
            (key) => payloadData.payment_types[key]
          ),
        });
        setCompany({
          ...company,
          ...restDataTemp,
          document_number,
          payment_types: Object.keys(payloadData.payment_types).filter(
            (key) => payloadData.payment_types[key]
          ),
        });
      } catch (err) {
        errorHandling(err, 'erro ao atualizar empresa', 'crema');
      }
    },
    [
      company,
      document_number,
      onNext,
      restDataTemp,
      setCompany,
      setDataTemp,
      updateCompany,
    ]
  );

  useEffect(() => {
    if (dataTemp?.payment_types?.length) {
      dataTemp.payment_types.map((payment: string) =>
        setValue(`payment_types[${payment}]`, true)
      );
    }
  }, [dataTemp, setValue]);

  return (
    <section className="sm:mx-auto sm:w-full sm:max-w-xl flex flex-col items-center justify-between text-center px-6 mt-6 space-y-12">
      <p>selecione as formas de pagamento aceitas nas sua loja</p>
      <section className="w-full flex flex-col items-center justify-center mx-auto mx-4">
        <section className="w-full">
          {payment_types.map((payment, idx) => (
            <section
              key={`payment_${idx}`}
              className="w-full flex items-center justify-between py-4 px-8 rounded"
            >
              <p className="text-base text-gray-500">{payment.label}</p>
              <IonToggle
                checked={field.payment_types[payment.key]}
                onIonChange={(e) =>
                  setValue(`payment_types[${payment.key}]`, e.detail.checked)
                }
              />
            </section>
          ))}
        </section>
      </section>
      <Button
        full
        type="button"
        disabled={!isValid}
        variant="solid"
        color="neon"
        onClick={handleSubmit(handleUpdate)}
      >
        próximo
      </Button>
    </section>
  );
};

export default PaymentTypes;
