import { Button } from 'oialbert-ui';
import { useHistory } from 'react-router';
import { LayoutBase } from '../../components/LayoutBase';
import useAuth from '../../hooks/useAuth';
import ListDays from './components/ListDays/ListDays';

const OpeningHours = () => {
  const { company } = useAuth();
  const history = useHistory();

  return (
    <LayoutBase title="horários de funcionamento">
      <section className="px-5 pt-5">
        <h1 className="font-bold mb-1">horário de funcionamento</h1>
        <p className="text-gray-500 mb-8">
          configure o horário de funcionamento do seu estabelecimento
        </p>
        <Button
          onClick={() => history.push('/opening-hours/edit')}
          variant="solid"
          color="neon"
          normal
          className="w-fit-content"
        >
          Editar tudo
        </Button>
      </section>
      <section className="px-5 pt-8 w-full flex flex-col gap-y-3">
        <ListDays companyId={company?.id} />
      </section>
    </LayoutBase>
  );
};

export default OpeningHours;
