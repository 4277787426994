import { api } from './config';

export const terms = async (user_type = 'partners') => {
  const { status, data } = await api.get(`/v1/${user_type}/term`);

  if (status === 200 && data) {
    return data;
  }

  return false;
};
