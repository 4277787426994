import { IonPage, IonContent } from '@ionic/react';
import { Modal } from 'oialbert-ui';
import { formatInCents } from '../../../../utils/money';
import useWallet from '../../../../hooks/useWallet';

type ModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const ValueUsedInfo = ({ open, setOpen }: ModalProps) => {
  const { wallets } = useWallet();
  const debitBalance = Number(wallets?.debit_balance_value_in_cents);
  function handleClosed() {
    setOpen(false);
  }
  return (
    <Modal open={open} onClose={handleClosed}>
      <IonPage>
        <IonContent>
          <div className="px-4 pt-10">
            <div className="box-border h-52 w-46 p-4 border-2 border-neon-900 rounded-md">
              <section className="pt-2 px-4">
                <h3 className="text-base font-bold">você já utilizou</h3>
              </section>
              <section className="pt-8 px-4">
                <p className="text-base font-normal">
                  acompanhe o quanto você já utilizou
                </p>
                <p className="pt-6 text-xs font-normal">
                  valor utilizado{' '}
                  <strong className="uppercase">{`${formatInCents(
                    wallets?.debit_balance_value_in_cents ? debitBalance : 0
                  )}`}</strong>
                </p>
              </section>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </Modal>
  );
};

export default ValueUsedInfo;
