import { useIonAlert } from "@ionic/react";
import {
  Button,
  Card,
  Checkbox,
  Icon,
  Input,
  TextArea,
  Toast,
} from "oialbert-ui";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useController } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import { useCameraGallery } from "../../../contexts/CameraGalleryContext";
import { useProductContext } from "../../../contexts/ProductContext";
import useAuth from "../../../hooks/useAuth";
import useForm from "../../../hooks/useForm";
import { useMaskHandler } from "../../../hooks/useMaskHandler";
import errorHandling from "../../../utils/error_handling";
import { formatRound } from "../../../utils/float";
import { useProducts } from "../../Items/hooks";
import { useProductCategories } from "../../Menus/hooks";
import { Category } from "../../Menus/types";
import { Characteristic, Product, ProductSchema } from "../schema";
import { ProductFormProps } from "../types";
import CharacteristicItem from "./CharacteristicItem";
import CreateCharacteristic from "./CreateCharacteristic";
import SelectMenuItem from "./SelectMenuItem";

const ProductForm = ({ product, onDuplicate }: ProductFormProps) => {
  const history = useHistory();
  const { state } = useLocation<{ categoryId: string }>();
  const [present] = useIonAlert();
  const { company } = useAuth();

  const { openActions, clearResource, resource } = useCameraGallery();
  const [menuItemVisible, showMenuItemModal] = useState<boolean>(false);
  const [preview, setPreview] = useState<string>(null!);
  const [characteristicModalVisible, showCharacteristicModal] =
    useState<boolean>(false);
  const [category, setCategory] = useState<Category>({} as Category);
  const [characteristic, setCharacteristic] = useState<
    Characteristic | undefined | null
  >(null);
  const [errorCashback, setErrorCashback] = useState<string | undefined>(
    undefined
  );

  const { categories, getProductCategories } = useProductCategories();

  const { createProduct, updateProduct, loadingCreate } = useProducts();
  const { setProduct } = useProductContext();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm<Product>({
    mode: "onChange",
    defaultValues: {
      title: product?.title ?? "",
      is_active: product?.is_active ?? true,
      description: product?.description ?? "",
      price_albert_in_cents: product?.price_albert_in_cents ?? 0,
      price_cashback_in_cents: product?.price_cashback_in_cents ?? 0,
      product_category_id: state?.categoryId || "",
      characteristics: product?.characteristics ?? [],
      photo: product?.photo?.url,
      order_limit: product?.order_limit ?? 0,
    },
    schema: ProductSchema,
  });

  const isActive = useController({ control, name: "is_active" });
  const characteristics = watch("characteristics");

  const [, setPercentage] = useState<number>(0);
  const priceCashback = watch("price_cashback_in_cents");
  const priceMoney = watch("price_albert_in_cents");

  const percentageInteger = useMemo(
    () =>
      Math.round(
        isNaN(priceCashback / priceMoney)
          ? 0
          : (priceCashback / priceMoney) * 100
      ),
    [priceCashback, priceMoney]
  );

  const handleChangePercentage = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const totalPrice = getValues("price_albert_in_cents");
      setPercentage(Number(e.target.value));
      setValue(
        "price_cashback_in_cents",
        totalPrice * (Number(e.target.value) / 100)
      );
    },
    [setValue, getValues]
  );

  const onSubmit = useCallback(
    async (data: Product) => {
      try {
        const payloadProduct = {
          ...data,
          ...(watch("photo") && {
            photo: watch("photo"),
          }),
          price_cashback_in_cents: formatRound(data.price_cashback_in_cents),
          product_category_id: state?.categoryId ?? category.id,
        };

        if (product?.id) {
          await updateProduct(product?.id, payloadProduct);
        } else {
          await createProduct(payloadProduct);
        }

        Toast.success("produto salvo");
        history.goBack();
        reset();
      } catch (err) {
        errorHandling(
          err,
          `erro ao ${product?.id ? "atualizar" : "salvar"} produto`,
          "crema"
        );
      }
    },
    [
      watch,
      category.id,
      createProduct,
      history,
      product?.id,
      reset,
      state?.categoryId,
      updateProduct,
    ]
  );

  const onHandleDuplicate = useCallback(async () => {
    onDuplicate && onDuplicate(true);

    try {
      const { id, ...propsProduct }: any = product;
      const createdProduct = await createProduct({
        ...propsProduct,
        price_albert_in_cents: product?.price_albert_in_cents ?? 0,
        title: `${propsProduct.title} cópia`,
      });

      if (createdProduct) {
        Toast.success("Produto duplicado");
        setProduct({
          ...createdProduct,
          characteristics,
        });
        history.push(`/products/${createdProduct?.id}/edit`);
      }
    } catch (error: any) {
      errorHandling(error, "Ocorreu um erro ao duplicar o produto", "crema");
    } finally {
      onDuplicate && onDuplicate(false);
    }
  }, [
    characteristics,
    createProduct,
    history,
    onDuplicate,
    product,
    setProduct,
  ]);

  const selectCategory = useCallback(
    (category: Category) => {
      setCategory(category);
      setValue("product_category_id", category.id, { shouldValidate: true });
    },
    [setValue]
  );

  const setImage = useCallback(
    async (resource) => {
      if (resource) {
        const image = await fetch(resource?.[0].webPath as string);
        const blob = await image.blob();
        setValue("photo", blob as any);
        setPreview(resource?.[0].webPath as string);
      } else {
        setValue("photo", null as any);
        setPreview("");
      }
    },
    [setValue]
  );

  const editCharacteristic = useCallback(
    (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      characteristic: Characteristic
    ) => {
      event.stopPropagation();
      showCharacteristicModal(true);
      setCharacteristic(characteristic);
    },
    []
  );

  const removeCharacteristic = useCallback(
    (characteristic: Characteristic) => {
      setValue(
        "characteristics",
        characteristics?.filter(
          (item) => item.title !== characteristic.title
        ) ?? []
      );
    },
    [characteristics, setValue]
  );

  const saveCharacteristic = useCallback(
    (item: Characteristic) => {
      const characteristics = getValues("characteristics");
      if (item?.id) {
        const index =
          characteristics?.findIndex((current) => current.id === item.id) ?? 0;

        if (characteristics?.length) characteristics[index] = item;

        setValue("characteristics", characteristics);
      } else {
        setValue(
          "characteristics",
          characteristics?.concat({ ...item, id: String(Date.now()) }) ?? []
        );
      }
    },
    [getValues, setValue]
  );

  const priceAlbertInCents = useMaskHandler<Product>(
    "price_albert_in_cents",
    control,
    product?.price_albert_in_cents
  );

  const priceCashbackInCents = useMaskHandler<Product>(
    "price_cashback_in_cents",
    control,
    product?.price_cashback_in_cents
  );

  useEffect(() => {
    getProductCategories();
    register("product_category_id");
    register("photo", { value: "" });
  }, [getProductCategories, register]);

  useEffect(() => {
    if (product && categories.length) {
      const categoryId = product.product_category_id;
      const currentCategory = categories.find((item) => item.id === categoryId);

      setCategory(currentCategory as Category);
    }
  }, [categories, product]);

  useEffect(() => {
    if (percentageInteger < Number(company?.cashback_default_in_percent)) {
      setErrorCashback(
        `a porcetagem deve ser maior que ${company?.cashback_default_in_percent}%`
      );
    } else {
      setErrorCashback(undefined);
    }
  }, [company?.cashback_default_in_percent, percentageInteger]);

  useEffect(() => {
    setImage(resource);
  }, [resource, setImage]);

  useEffect(() => {
    return () => {
      setImage(null);
      clearResource();
    };
  }, [clearResource, setImage]);

  return (
    <>
      <section className="sm:mx-auto sm:w-full sm:max-w-2xl mt-5 px-5 pb-8">
        <div className="overflow-x-auto overflow-y-none mt-4 py-4">
          <div className="flex space-x-4 h-28">
            <Card
              onClick={() => openActions()}
              className="px-4 py-7 rounded h-28 w-36 flex-none flex flex-col justify-center items-center shadow-lg"
            >
              {(preview && preview !== "") || product?.photo?.url ? (
                <img
                  src={preview !== "" ? preview : product?.photo?.url}
                  alt="Preview do produto"
                />
              ) : (
                <>
                  <Icon.MdAddCircleOutline className="text-neon-900 text-4xl" />
                  Adicionar foto
                </>
              )}
            </Card>
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className="mb-4"
            onClick={() => {
              showMenuItemModal(true);
            }}
          >
            {!state?.categoryId ? (
              <Input
                readOnly
                defaultValue={category?.title || ""}
                label="nome da categoria"
                value={category.title}
                placeholder="selecione uma categoria"
                {...register("product_category_id")}
                error={errors.product_category_id?.message?.toString()}
              />
            ) : null}
          </div>
          <div className="mb-4">
            <Input
              label="Nome do produto"
              placeholder="Nome do produto"
              {...register("title")}
              error={errors.title?.message?.toString()}
            />
          </div>
          <div className="mb-4">
            <TextArea
              placeholder="Descrição"
              {...register("description")}
              error={errors.description?.message?.toString()}
            />
          </div>

          <div className="mb-4">
            <Input
              label="Preço Albert"
              placeholder="Preço Albert"
              mask="MONEY"
              moneyProps={priceAlbertInCents}
              error={errors.price_albert_in_cents?.message?.toString()}
            />
          </div>
          <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 mb-4">
            <section className="flex gap-x-2">
              <Input
                label="Cashback"
                placeholder="Cashback"
                mask="MONEY"
                moneyProps={priceCashbackInCents}
                error={errors.price_cashback_in_cents?.message?.toString()}
                disabled={priceMoney === 0}
              />
              <Input
                label="porcentagem"
                placeholder="porcentagem"
                onChange={handleChangePercentage}
                value={percentageInteger}
                disabled={priceMoney === 0}
                error={priceMoney > 0 ? errorCashback : undefined}
              />
            </section>
            <Input
              label="Limite máximo de unidades por pedido"
              placeholder="Limite máximo por pedido"
              {...register("order_limit", { valueAsNumber: true })}
              error={errors.order_limit?.message?.toString()}
            />

            <div className="flex flex-col">
              <div className="mt-4">
                <Checkbox
                  label="Produto Ativo?"
                  id="is_active"
                  error={errors.is_active?.message?.toString()}
                  {...isActive.field}
                  defaultChecked={product?.is_active ?? true}
                />
              </div>
            </div>
          </div>

          <footer className="space-y-4">
            <Button
              onClick={() => showCharacteristicModal(true)}
              full
              normal
              variant="light"
              color="neon"
              type="button"
            >
              <span className="flex items-center justify-center">
                <Icon.MdAdd className="mr-1" />
                Adicionar características
              </span>
            </Button>

            {characteristics?.map((characteristic: Characteristic) => (
              <CharacteristicItem
                key={characteristic.id}
                characteristic={characteristic}
                editCharacteristic={editCharacteristic}
                removeCharacteristic={removeCharacteristic}
              />
            ))}

            <Button full disabled={loadingCreate} variant="solid" color="neon">
              {loadingCreate ? "aguarde..." : "salvar"}
            </Button>

            <Button
              full
              type="button"
              onClick={() =>
                present({
                  header: "duplicar produto",
                  message: `deseja realmente duplicar este produto?`,
                  buttons: [
                    "Não",
                    {
                      text: "Sim",
                      handler: () => onHandleDuplicate(),
                    },
                  ],
                  onDidDismiss: () => {},
                })
              }
              color="neon"
              variant="outline"
            >
              duplicar
            </Button>
          </footer>
        </form>
      </section>
      <SelectMenuItem
        onClose={() => showMenuItemModal(false)}
        open={menuItemVisible}
        categories={categories}
        onSelect={selectCategory}
        category={category}
      />
      <CreateCharacteristic
        open={characteristicModalVisible}
        onClose={() => showCharacteristicModal(false)}
        onSave={saveCharacteristic}
        characteristic={characteristic}
      />
    </>
  );
};

export default ProductForm;
