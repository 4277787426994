// @ts-nocheck
import { useEffect, useState, useCallback, Ref } from "react";
import { Input, TextArea, Radio, Checkbox, Card, Icon } from "oialbert-ui";
import { Controller, useForm } from "react-hook-form";

import type { CompanyDetailsData } from "../../../types/companies";

import cep from "cep-promise";

import Category from "../../CompanyProfile/components/Category";
import { useCameraGallery } from "../../../contexts/CameraGalleryContext";

type FormProps = {
  formRef: Ref<HTMLFormElement>;
  onSubmit: (_payload: CompanyDetailsData) => void;
  loading: boolean;
};

const Form = ({ formRef, onSubmit, loading }: FormProps) => {
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);
  const [categoryModalVisible, setCategoryModalVisible] =
    useState<boolean>(false);

  const [selectedSubcategories, setSelectedSubcategories] = useState<
    { title: string; id: string }[]
  >([]);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      cashback_default_in_percent: 2,
    },
  });

  const { openActions, resource } = useCameraGallery();
  const [preview, setPreview] = useState<string | null>(null);

  const field = watch();

  const searchZipcode = useCallback(async () => {
    setLoadingAddress(true);
    try {
      const dataZipcode = await cep(
        field?.address?.zip_code.replace(/[^a-zA-Z0-9]/g, "")
      );
      setValue("address.neighborhood", dataZipcode.neighborhood);
      setValue("address.city", dataZipcode.city);
      setValue("address.state", dataZipcode.state);
      setValue("address.street", dataZipcode.street);
    } catch (err) {
      console.log("error searchZipcode", err);
    } finally {
      setLoadingAddress(false);
    }
  }, [field?.address?.zip_code, setValue]);

  const setImage = useCallback(
    async (resource) => {
      if (resource) {
        const image = await fetch(resource?.[0].webPath as string);
        const blob = await image.blob();
        setValue("photo", blob);
        setPreview(resource?.[0].webPath as string);
      } else {
        setValue("photo", null);
      }
    },
    [setValue]
  );

  useEffect(() => {
    setImage(resource);

    return () => {
      setImage(null);
    };
  }, [resource, setImage]);

  return (
    <form
      ref={formRef}
      className="w-full flex flex-col mt-5 px-5 space-y-4"
      onSubmit={handleSubmit((data: CompanyDetailsData) =>
        onSubmit({
          ...data,
          subcategories: selectedSubcategories.map((sub: any) => sub.id),
        })
      )}
    >
      <div className="overflow-x-auto overflow-y-none shadow-lg rounded">
        <div className="flex space-x-4 h-32">
          <Card
            onClick={() => openActions()}
            className="h-32 w-full flex-none flex flex-col justify-center items-center shadow-none"
          >
            {preview ? (
              <img
                className="object-cover h-32 w-full"
                src={preview}
                alt="Preview da foto"
              />
            ) : (
              <>
                <Icon.MdAddCircleOutline className="text-neon-900 text-4xl" />
                Adicionar foto de capa
              </>
            )}
          </Card>
        </div>
      </div>

      <div className="flex flex-col gap-y-2">
        <Controller
          control={control}
          name="name"
          rules={{
            required: {
              value: true,
              message: "campo obrigarório",
            },
          }}
          render={({ field: { ref, value, ...props } }) => (
            <Input
              label="nome"
              placeholder="Nome da empresa"
              disabled={loading}
              value={value ?? ""}
              required
              error={errors.name?.message?.toString()}
              {...props}
            />
          )}
        />
      </div>

      <div className="flex flex-col gap-y-2">
        <Controller
          control={control}
          name="cashback_default_in_percent"
          rules={{
            required: {
              value: true,
              message: "campo obrigatório",
            },
            min: {
              value: 2,
              message: "valor mínimo de 2",
            },
          }}
          render={({ field: { ref, value, ...props } }) => (
            <Input
              label="cashback do associado (em %)"
              placeholder="Cashback do associado %"
              disabled={loading}
              value={value ?? ""}
              defaultValue={2}
              required
              error={errors?.cashback_default_in_percent?.message?.toString()}
              type="tel"
              {...props}
            />
          )}
        />
      </div>

      <div className="flex flex-col gap-y-2">
        <h3 className="text-base">descrição</h3>
        <Controller
          control={control}
          name="description"
          render={({ field: { ref, value, ...props } }) => (
            <TextArea
              placeholder="Descrição da empresa"
              disabled={loading}
              value={value ?? ""}
              {...props}
            />
          )}
        />
      </div>

      <div className="flex flex-col gap-y-2">
        <Controller
          control={control}
          name="document_number"
          rules={{
            required: {
              value: true,
              message: "campo obrigatório",
            },
          }}
          render={({ field: { ref, value, ...props } }) => (
            <Input
              type="tel"
              label="número do documento"
              placeholder="CNPJ"
              disabled={loading}
              value={value ?? ""}
              maxLength={14}
              mask="CNPJ"
              required
              error={errors.document_number?.message?.toString()}
              {...props}
            />
          )}
        />
      </div>

      <div className="flex flex-col gap-y-2">
        <Controller
          control={control}
          name="email"
          rules={{
            required: {
              value: true,
              message: "campo obrigatório",
            },
          }}
          render={({ field: { ref, value, ...props } }) => (
            <Input
              type="email"
              label="endereço de e-mail"
              placeholder="E-mail"
              disabled={loading}
              value={value ?? ""}
              autoComplete="off"
              required
              error={errors.email?.message?.toString()}
              {...props}
            />
          )}
        />
      </div>

      <div className="flex flex-col gap-y-2">
        <Controller
          control={control}
          name="phone"
          rules={{
            required: {
              value: true,
              message: "campo obrigatório",
            },
          }}
          render={({ field: { ref, value, ...props } }) => (
            <Input
              type="tel"
              label="número de telefone"
              placeholder="(DD) + Telefone"
              disabled={loading}
              value={value ?? ""}
              mask="CELLPHONE"
              required
              error={errors.phone?.message?.toString()}
              {...props}
            />
          )}
        />
      </div>

      <div className="flex flex-col gap-y-2">
        <Controller
          control={control}
          name="address.zip_code"
          rules={{
            required: {
              value: true,
              message: "campo obrigatório",
            },
          }}
          render={({ field: { ref, value, ...props } }) => (
            <Input
              type="tel"
              label="endereço completo"
              placeholder="CEP"
              disabled={loading}
              value={value ?? ""}
              mask="CEP"
              required
              error={errors.address?.zip_code?.message?.toString()}
              {...props}
              onBlur={(e) => {
                e.target.value.length === 9 && searchZipcode();
              }}
            />
          )}
        />
      </div>

      <Controller
        control={control}
        name="address.street"
        rules={{
          required: {
            value: true,
            message: "campo obrigatório",
          },
        }}
        render={({ field: { ref, value, ...props } }) => (
          <Input
            placeholder="Endereço da empresa"
            disabled={loadingAddress || loading}
            value={value ?? ""}
            required
            error={errors.address?.street?.message?.toString()}
            {...props}
          />
        )}
      />

      <section className="flex space-x-4 mb-4">
        <Controller
          control={control}
          name="address.street_number"
          render={({ field: { ref, value, ...props } }) => (
            <Input
              placeholder="Número"
              disabled={loadingAddress || loading}
              value={value ?? ""}
              {...props}
            />
          )}
        />
        <Controller
          control={control}
          name="address.neighborhood"
          rules={{
            required: {
              value: true,
              message: "obrigatório",
            },
          }}
          render={({ field: { ref, value, ...props } }) => (
            <Input
              placeholder="Bairro"
              disabled={loadingAddress || loading}
              value={value ?? ""}
              required
              error={errors.address?.neighborhood?.message?.toString()}
              {...props}
            />
          )}
        />
      </section>

      <section className="flex space-x-4 mb-4">
        <Controller
          control={control}
          name="address.city"
          rules={{
            required: {
              value: true,
              message: "obrigatório",
            },
          }}
          render={({ field: { ref, value, ...props } }) => (
            <Input
              placeholder="Cidade"
              disabled={loadingAddress || loading}
              value={value ?? ""}
              required
              error={errors.address?.city?.message?.toString()}
              {...props}
            />
          )}
        />

        <Controller
          control={control}
          name="address.state"
          rules={{
            required: {
              value: true,
              message: "obrigatório",
            },
          }}
          render={({ field: { ref, value, ...props } }) => (
            <Input
              placeholder="Estado"
              disabled={loadingAddress || loading}
              value={value ?? ""}
              required
              error={errors.address?.state?.message?.toString()}
              {...props}
            />
          )}
        />
      </section>

      <section className="flex flex-col">
        <h3 className="text-base font-bold">Formas de pagamentos aceitos</h3>

        <section className="flex flex-col items-center mt-3 space-y-4">
          <section className="w-full flex border border-gray-100 rounded py-2 px-4 items-center justify-center">
            <Controller
              control={control}
              name="payment_types[credit_card]"
              render={({ field: { ref, value, ...props } }) => (
                <Checkbox
                  label="Cartão Crédito"
                  id="paymentTypesCreditCard"
                  disabled={loading}
                  value="credit_card"
                  {...props}
                />
              )}
            />
          </section>
          <section className="w-full flex border border-gray-100 rounded py-2 px-4 items-center justify-center">
            <Controller
              control={control}
              name="payment_types[debit_card]"
              render={({ field: { ref, value, ...props } }) => (
                <Checkbox
                  label="Cartão Débito"
                  id="paymentTypesDebitCard"
                  disabled={loading}
                  value="debit_card"
                  {...props}
                />
              )}
            />
          </section>
        </section>

        <section className="flex flex-col items-center mt-3 space-y-4">
          <section className="w-full flex border border-gray-100 rounded py-2 px-4 items-center justify-center">
            <Controller
              control={control}
              name="payment_types[pix]"
              render={({ field: { ref, value, ...props } }) => (
                <Checkbox
                  label="PIX"
                  id="paymentTypesPix"
                  disabled={loading}
                  value="pix"
                  {...props}
                />
              )}
            />
          </section>
          <section className="w-full flex border border-gray-100 rounded py-2 px-4 items-center justify-center">
            <Controller
              control={control}
              name="payment_types[cash]"
              render={({ field: { ref, value, ...props } }) => (
                <Checkbox
                  label="Dinheiro"
                  id="paymentTypesCash"
                  disabled={loading}
                  value="cash"
                  {...props}
                />
              )}
            />
          </section>
        </section>
      </section>

      <section className="flex flex-col">
        <h3 className="text-base font-bold">
          A empresa aceita realizar a entrega de produtos?
        </h3>

        <section className="flex items-center mt-3 space-x-4">
          <section className="flex flex-1 border border-gray-100 rounded py-2 px-4 items-center justify-center">
            <Controller
              control={control}
              name="has_delivery"
              rules={{
                required: {
                  value: true,
                  message: "campo obrigatório",
                },
              }}
              render={({ field: { ref, value, ...props } }) => (
                <Radio
                  label="Sim"
                  id="hasDeliveryYes"
                  disabled={loading}
                  titleClassName="font-base font-bold"
                  value="0"
                  required
                  {...props}
                />
              )}
            />
          </section>
          <section className="flex flex-1 border border-gray-100 rounded py-2 px-4 items-center justify-center">
            <Controller
              control={control}
              name="has_delivery"
              rules={{
                required: {
                  value: true,
                  message: "campo obrigatório",
                },
              }}
              render={({ field: { ref, value, ...props } }) => (
                <Radio
                  label="Não"
                  id="hasDeliveryNo"
                  disabled={loading}
                  titleClassName="font-base font-bold"
                  value="1"
                  required
                  {...props}
                />
              )}
            />
          </section>
        </section>
        <div className="mt-2">
          {errors?.has_delivery?.message ? (
            <span className="text-neon-900">
              * {errors.has_delivery.message?.toString()}
            </span>
          ) : null}
        </div>
      </section>

      <section className="flex flex-col">
        <h3 className="text-base font-bold">
          Selecione o tipo de categoria da empresa
        </h3>

        <button
          type="button"
          className="form-input h-12 focus:ring-neon-500 focus:border-neon-500 block w-full sm:text-sm border-gray-500 rounded mt-4 flex items-center"
          onClick={() => setCategoryModalVisible(true)}
        >
          <span>
            {selectedSubcategories.length
              ? selectedSubcategories
                  .map((subcategory: any) => subcategory.title)
                  .join(", ")
              : "escolher categorias"}
          </span>
        </button>

        <Category
          onClose={() => setCategoryModalVisible(false)}
          open={categoryModalVisible}
          onSelect={(subcategories) =>
            subcategories.length && setSelectedSubcategories(subcategories)
          }
          values={[]}
        />
      </section>
    </form>
  );
};

export default Form;
