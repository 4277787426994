import { Pagination } from 'oialbert-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { EmptyStateProps } from '../../components/EmptyState';
import { LayoutBase } from '../../components/LayoutBase';
import ListView from '../../components/ListView';
import CreateMenu from './components/CreateMenu';
import MenuList from './components/MenuList';
import RemoveItemModal from './components/RemoveItemModal';
import { useProductCategories } from './hooks';
import { Category } from './types';

const Menus = () => {
  const emptyProps = useMemo<EmptyStateProps>(
    () => ({
      ctaAction: () => showCreateMenuModal(true),
      ctaText: 'cadastrar categoria',
      alt: 'nenhuma categoria encontrada',
      text: 'nenhuma categoria encontrada, que tal adicionar uma nova :)',
      image: 'image',
    }),
    []
  );
  const { getProductCategories, categories, loading, total, page, setPage } =
    useProductCategories();
  const [removeModalVisible, showRemoveModalVisible] = useState<boolean>(false);
  const [category, setCategory] = useState<Category | null>(null!);
  const [createMenuModalVisible, showCreateMenuModal] =
    useState<boolean>(false);

  const openDeleteModal = useCallback(
    (category: Category, e?: React.MouseEvent<HTMLButtonElement>) => {
      e?.stopPropagation();
      setCategory(category);
      showRemoveModalVisible(true);
    },
    []
  );

  const openEditModal = useCallback((category: Category) => {
    setCategory(category);
    showCreateMenuModal(true);
  }, []);

  const closeDeleteModal = useCallback(() => {
    setCategory(null);
    showRemoveModalVisible(false);
  }, []);

  useEffect(() => {
    getProductCategories();
  }, [getProductCategories]);

  return (
    <LayoutBase title="categorias">
      <ListView<Category>
        loading={loading}
        data={categories}
        empty={emptyProps}
      >
        {(categories) => (
          <MenuList
            categories={categories}
            onButtonClick={() => showCreateMenuModal(true)}
            onClick={(e, category) => {
              e?.stopPropagation();
              openEditModal(category);
            }}
            onDelete={(category) => openDeleteModal(category)}
          />
        )}
      </ListView>
      {total > 10 && (
        <section className="flex justify-center mt-4">
          <Pagination
            onPrev={() => setPage(page - 1)}
            onNext={() => setPage(page + 1)}
            onPage={(_page) => setPage(_page)}
            page={page}
            total={total}
            variant='secondary'
            perPage={20}
          />
        </section>
      )}
      <RemoveItemModal
        id={category?.id}
        onClose={closeDeleteModal}
        open={removeModalVisible}
        afterRemove={getProductCategories}
      />

      <CreateMenu
        category={category}
        onClose={() => showCreateMenuModal(false)}
        open={createMenuModalVisible}
        afterSave={getProductCategories}
      />
    </LayoutBase>
  );
};

export default Menus;
