import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Icon, Toast } from 'oialbert-ui';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import * as z from 'zod';
import { LayoutBase } from '../../../components/LayoutBase';
import LoadingAnimation from '../../../components/LoadingAnimation';
import useAuth from '../../../hooks/useAuth';
import { useCompany } from '../../../hooks/useCompany';
import { WEEKDAYS } from '../constants';
import { format, parseISO, isValid } from 'date-fns';

type Weekday = keyof typeof WEEKDAYS;

const Create = () => {
  const { company } = useAuth();
  const history = useHistory();
  const { day } = useParams<{ day: Weekday }>();

  const schema = useMemo(() => {
    return z.object({
      [day]: z
        .array(
          z.object({
            open: z.string().nullable(),
            close: z.string().nullable(),
          })
        )
        .refine((data) => {
          if (data[0].open && !data[0].close) {
            Toast.crema(
              `${WEEKDAYS[day]}: preencha o horário de fechamento do primeiro turno`
            );
            return false;
          }

          if (!data[0].open && data[0].close) {
            Toast.crema(
              `${WEEKDAYS[day]}: preencha o horário de abertura do primeiro turno`
            );
            return false;
          }

          if (data[1].open && !data[1].close) {
            Toast.crema(
              `${WEEKDAYS[day]}: preencha o horário de fechamento do segundo turno`
            );
            return false;
          }

          if (!data[1].open && data[1].close) {
            Toast.crema(
              `${WEEKDAYS[day]}: preencha o horário de abertura do segundo turno`
            );
            return false;
          }

          return true;
        }),
    });
  }, [day]);

  function extractTime(dateOrTime: string): string {
    let date: Date | null = null;

    try {
      date = parseISO(dateOrTime);
    } catch (error) {
      date = null;
    }

    if (date && isValid(date)) {
      return format(date, 'HH:mm:ss');
    } else {
      return dateOrTime;
    }
  }

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      [day]:
        company?.business_hours[day]?.map(
          (item: { open: string; close: string }) => ({
            open: item.open ? extractTime(item.open) : '',
            close: item.close ? extractTime(item.close) : '',
          })
        ) || [],
    },
  });

  const { updateHours } = useCompany();

  const onSubmit = useCallback(
    async (formData: z.infer<typeof schema>) => {
      formData = Object.keys(formData).reduce((acc, day) => {
        acc[day as Weekday] = formData[day as Weekday].filter(
          (item: any) => item.open !== '' && item.close !== ''
        );
        return acc;
      }, {} as Record<Weekday, any>);

      await updateHours(
        {
          business_hours: {
            ...company?.business_hours,
            [day]: formData[day],
          },
        },
        company?.id
      )
        .then((data) => {
          history.push('/opening-hours');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [company?.business_hours, company?.id, day, history, updateHours]
  );

  return (
    <LayoutBase title="Modificar horários">
      <div className="max-w-3xl  flex-col mt-10">
        <h1 className="font-bold mb-8">editar horário de funcionamento</h1>
        <div>
          <p className="font-bold mb-4">{WEEKDAYS[day]}:</p>
        </div>

        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center flex-row gap-4">
            <p className="mb-1">abertura</p>
            <input
              className="h-12 border-2 border-gray-200 rounded-md w-full px-4 text-sm"
              type="time"
              placeholder="abertura"
              {...register(`${day}.0.open`)}
            />
            <input
              className="h-12 border-2 border-gray-200 rounded-md w-full px-4 text-sm"
              type="time"
              placeholder="fechamento"
              {...register(`${day}.0.close`)}
            />
            <p className="mb-1">fechamento</p>
            <input
              className="h-12 border-2 border-gray-200 rounded-md w-full px-4 text-sm"
              type="time"
              placeholder="abertura"
              {...register(`${day}.1.open`)}
            />
            <input
              className="h-12 border-2 border-gray-200 rounded-md w-full px-4 text-sm"
              type="time"
              placeholder="fechamento"
              {...register(`${day}.1.close`)}
            />
          </div>
          <div className="flex flex-row justify-end gap-4 mt-4">
            <Button
              type="button"
              variant="solid"
              color="gray"
              size="small"
              onClick={() => history.push('/opening-hours')}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="solid"
              color="neon"
              size="small"
              disabled={isSubmitting}
            >
              {isSubmitting ? <LoadingAnimation /> : <Icon.MdSave />}
            </Button>
          </div>
        </form>
      </div>
    </LayoutBase>
  );
};

export default Create;
