import { FC } from 'react';
import { ComponentProps } from 'react';
import { FaPlusCircle } from 'react-icons/fa'; // Importando o ícone de React Icons

export type AddCreditCardProps = ComponentProps<'button'> & {
  isSelected: boolean;
};

export const AddCreditCard: FC<AddCreditCardProps> = ({
  isSelected,
  ...props
}) => {
  return (
    <button
      className={`relative w-44 h-28 px-3 py-2 rounded-md cursor-pointer flex flex-col justify-center items-center
                  transition-colors duration-300
                  ${
                    isSelected
                      ? 'border-2 border-neon-900 bg-gray-50 dark:bg-gray-900'
                      : 'border border-dashed border-gray-300 bg-gray-50 dark:border-gray-700 dark:bg-gray-900'
                  }`}
      {...props}
      type="button"
    >
      <FaPlusCircle
        className={`text-lg mb-1 ${
          isSelected ? 'text-neon-900' : 'text-gray-600 dark:text-gray-300'
        }`}
      />
      <span
        className={`text-sm ${
          isSelected ? 'text-neon-900' : 'text-gray-700 dark:text-gray-200'
        }`}
      >
        Add novo
      </span>
    </button>
  );
};

export default AddCreditCard;
