import { Product } from '../pages/CreateItem/schema';
import { api } from './config';

// type ProductKeys = keyof Product;
// type Keys = Exclude<ProductKeys, 'photo' | 'characteristics'>;

export const fetchProducts = async <T>(id: string, params = {}) => {
  const { status, data } = await api.get(
    `/v1/partners/categories/${id}/products`,
    {
      params,
    }
  );

  if (status === 200 && data) {
    return data as T;
  }

  return false;
};

export const createProduct = async <T>(
  product: Product,
  companyId?: string
) => {
  const { status, data } = await api.post('/v1/partners/products', {
    ...product,
    company_id: companyId,
  });

  if (status === 200 && data) {
    return data as T;
  }

  return false;
};

export const updateProduct = async <T>(id: string, product: Product) => {
  const { status, data } = await api.put(`/v1/partners/products/${id}`, {
    ...product,
  });

  if (status === 200 && data) {
    return data as T;
  }

  return false;
};

export const deleteProduct = async <T>(id: string) => {
  const { status, data } = await api.delete(`/v1/partners/products/${id}`);

  if (status === 200 && data) {
    return data as T;
  }

  return false;
};

export const uploadFoto = async <T>(
  id: string,
  photo: File,
  product: Product
) => {
  const form = new FormData();
  form.append('photo', photo as Blob);

  const { status, data } = await api.put(`/v1/partners/products/${id}`, form);

  if (status === 200 && data) {
    return data as T;
  }

  return false;
};
