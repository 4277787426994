import { FaCheck, FaTimes } from 'react-icons/fa';
import { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import { IonSpinner } from '@ionic/react';
import BannerPromotion from '../../../assets/illustrations/banner-promotion.png';
import { usePromotionContext } from '../../../contexts/PromotionContext';
import getCroppedImg from '../../../utils/cropImages';
export const BannerCompany = ({
  onChange,
  previewBanner,
  visible,
  setVisible,
  setValue,
}: {
  onChange: any;
  previewBanner: string | undefined;
  visible: boolean;
  setVisible: any;
  setValue: (_key: string, _value: any) => void;
}) => {
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const { applyPreview } = usePromotionContext();
  const [loading, setLoading] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onCrop = useCallback(() => {
    setLoading(true);
    getCroppedImg(previewBanner, croppedAreaPixels)
      .then((croppedImageUrl: any) => {
        setValue('banner', croppedImageUrl?.file);
        onChange(croppedImageUrl);
      })
      .finally(() => {
        setLoading(false);
        setVisible(false);
      });
  }, [croppedAreaPixels, onChange, previewBanner, setValue]);

  const handleCropChange = useCallback(
    (croppedArea) => {
      applyPreview({
        croppedArea,
      });
    },
    [previewBanner]
  );

  return visible ? (
    <div
      className="flex flex-col items-center justify-center"
      style={{ backgroundColor: 'red' }}
    >
      <Cropper
        image={previewBanner || BannerPromotion}
        crop={crop}
        zoom={zoom}
        aspect={32 / 12}
        onCropChange={setCrop}
        onZoomChange={setZoom}
        onCropAreaChange={handleCropChange}
        onCropComplete={onCropComplete}
        style={{
          containerStyle: { borderRadius: '5px' },
          cropAreaStyle: {
            borderRadius: '5px',
            color: 'rgba(225, 225, 225, 0.5)',
            border: '2px solid rgba(255, 255, 255, 1)',
          },
        }}
        // cropSize={{ width: 1355, height: 542 }}
      />
      <div className="mt-12 absolute bottom-2 ">
        <button
          type="button"
          onClick={onCrop}
          className="bg-neon-900 shadow-lg hover:text-gray-700 text-white font-bold py-2 px-4 rounded-full"
        >
          {loading ? (
            <div className="flex items-center gap-3">
              <IonSpinner className="h-4 w-4" />
              aplicando
            </div>
          ) : (
            <div className="flex items-center text-white">
              <FaCheck name="crop" />
            </div>
          )}
        </button>
        {!loading ? (
          <button
            type="button"
            onClick={() => {
              setVisible(false);
            }}
            className="bg-white shadow-lg hover:text-gray-700 text-black font-bold py-2 px-4 ml-2 rounded-full"
          >
            <div className="flex items-center gap-3 text-neon-900">
              <FaTimes name="cancel" />
            </div>
          </button>
        ) : null}
      </div>
    </div>
  ) : null;
};
