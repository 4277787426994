import { Dialog, Transition } from '@headlessui/react';
import { Button, Toast } from 'oialbert-ui';
import { Fragment, useEffect, useState } from 'react';

import { Loading } from '../../../components/Loading';
import { findInvoice } from '../../../services/invoices';
import { Charge2, Invoice } from '../../../types/invoice';
import { formatInCents } from '../../../utils/money';

type Props = {
  invoiceId?: string;
  chargeGateway?: any;
  isOpen: boolean;
  onClose: () => void;
};

function calculateExpirationDate(date: string) {
  const dateNow = new Date();
  const dateExpiration = new Date(date);
  const diff = dateExpiration.getTime() - dateNow.getTime();
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor(diff / (1000 * 60));
  if (days > 0) {
    return `${days} dias`;
  } else if (hours > 0) {
    return `${hours} horas`;
  } else if (minutes > 0) {
    return `${minutes} minutos`;
  }
}

const PaymentModal = ({ invoiceId, chargeGateway, isOpen, onClose }: Props) => {
  const [charge, setCharge] = useState<Charge2>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (chargeGateway) {
      setCharge(chargeGateway);
      setLoading(false);
    } else {
      if (!invoiceId) return;

      findInvoice(invoiceId)
        .then((response: Invoice) => {
          const lastChargeInvoice =
            response?.charges[response?.charges.length - 1];

          const lastChargeGateway =
            lastChargeInvoice?.order_gateway_copy?.charges[
              lastChargeInvoice?.order_gateway_copy?.charges.length - 1
            ];

          setCharge(lastChargeGateway);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [invoiceId, chargeGateway]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 text-center"
                >
                  Informações de pagamento
                </Dialog.Title>
                {loading && (
                  <div className="display-flex flex-column align-items-center w-full h-full">
                    <Loading />
                  </div>
                )}
                {charge && charge.payment_method === 'pix' && (
                  <div className="mt-4">
                    <p className="text-sm text-gray-500 text-center">
                      Valor a pagar
                    </p>
                    <p className="text-2xl font-semibold text-gray-900 text-center uppercase">
                      {formatInCents(charge.amount)}
                    </p>
                    <img
                      className="mt-4 w-1/2 mx-auto"
                      src={charge.last_transaction.qr_code_url}
                      alt="QR Code Pix"
                    />
                    <p className="text-sm text-gray-500 text-center mb-6">
                      Expira em{' '}
                      {calculateExpirationDate(
                        charge.last_transaction.expires_at
                      )}
                    </p>
                    <Button
                      variant="solid"
                      color="neon"
                      className="mt-4 w-full"
                      full
                      onClick={() => {
                        navigator.clipboard
                          .writeText(charge.last_transaction.qr_code)
                          .then(() => {
                            Toast.success('Código copiado com sucesso!');
                          });
                      }}
                    >
                      Copiar código
                    </Button>
                  </div>
                )}

                {charge && charge.payment_method === 'boleto' && (
                  <div className="mt-4">
                    <p className="text-sm text-gray-500 text-center">
                      Valor a pagar
                    </p>
                    <p className="text-2xl font-semibold text-gray-900 text-center uppercase mb-2">
                      {formatInCents(charge.amount)}
                    </p>
                    <p className="text-sm text-gray-500 text-center mb-6">
                      Instruções: {charge.last_transaction.instructions}
                    </p>

                    <Button
                      variant="solid"
                      color="neon"
                      style={{
                        marginBottom: 16,
                      }}
                      full
                      onClick={() => {
                        window.open(charge.last_transaction.pdf, '_blank');
                      }}
                    >
                      Visualizar boleto
                    </Button>
                    <Button
                      variant="outline"
                      color="neon"
                      className="mt-4 w-full"
                      full
                      onClick={() => {
                        navigator.clipboard
                          .writeText(charge.last_transaction.line)
                          .then(() => {
                            Toast.success('Código copiado com sucesso!');
                          });
                      }}
                    >
                      Copiar código
                    </Button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PaymentModal;
