import React from 'react';

type MasterCardIconProps = {
  width?: string;
  height?: string;
};

export const MasterCardIcon: React.FC<MasterCardIconProps> = ({
  width = '33',
  height = '21',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9681 18.784H12.293V2.69629H20.9683L20.9681 18.784Z"
        fill="#FF5F00"
      />
      <path
        d="M12.7712 10.7394C12.7712 7.53232 14.2805 4.67547 16.6307 2.83438C14.8517 1.43872 12.6514 0.681251 10.3859 0.684581C4.8051 0.684581 0.28125 5.1862 0.28125 10.7394C0.28125 16.2926 4.8051 20.7942 10.3859 20.7942C12.6514 20.7975 14.8518 20.0401 16.6309 18.6444C14.2808 16.8036 12.7712 13.9466 12.7712 10.7394Z"
        fill="#EB001B"
      />
      <path
        d="M32.9824 10.7394C32.9824 16.2926 28.4588 20.7942 22.8783 20.7942C20.6126 20.7975 18.4121 20.04 16.6328 18.6444C18.9836 16.8033 20.4927 13.9466 20.4927 10.7394C20.4927 7.53216 18.9836 4.67547 16.6328 2.83438C18.412 1.43876 20.6125 0.681309 22.8781 0.684581C28.4586 0.684581 32.9823 5.1862 32.9823 10.7394"
        fill="#F79E1B"
      />
    </svg>
  );
};
