import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import { Modal, Icon } from 'oialbert-ui';
import { ModalProps } from 'oialbert-ui/lib/components/Modal/types';
import { useEffect } from 'react';
import { Loading } from '../../../components/Loading';
import { Product } from '../../CreateItem/types';
import { useProducts } from '../../Items/hooks';
import { Category } from '../../Menus/types';

type Props = Pick<ModalProps, 'onClose' | 'open'> & {
  onSelect: (_product: Product) => void;
  category?: Category | null;
};

const Item = ({ data, onClick }: { data: Product; onClick: () => void }) => (
  <section
    className="flex w-full pb-3 border-b border-crema-500"
    onClick={onClick}
  >
    <section className="w-20">
      <img
        src={data?.photo?.url ?? ''}
        className="w-full h-full rounded"
        alt={data.title}
      />
    </section>
    <section className="ml-2 w-full">
      <p className="font-bold text-sm text-black mb-3">{data.title}</p>
      <section className="flex justify-between">
        <p className="w-28 text-sm text-black leading-tight">
          {data.description}
        </p>
        <p className="text-sm text-gray-500 leading-tight uppercase">
          {formatCurrency(data.price_albert_in_cents / 100)}
        </p>
      </section>
    </section>
  </section>
);

const SelectOfferItem = ({ onClose, open, category, onSelect }: Props) => {
  const { getProducts, loading, products } = useProducts();

  useEffect(() => {
    if (category && category?.id) {
      getProducts(category.id);
    }
  }, [getProducts, category]);

  return (
    <Modal height={209} open={open} onClose={onClose}>
      <div className="text-right">
        <button onClick={onClose}>
          <Icon.MdClose className="text-gray-300" />
        </button>
      </div>
      {loading ? (
        <div className="h-full flex items-center">
          <Loading />
        </div>
      ) : (
        <div className="space-y-2 overflow-y-auto h-96 pr-4">
          {products.map((product, idx) => (
            <Item data={product} key={idx} onClick={() => onSelect(product)} />
          ))}
        </div>
      )}
    </Modal>
  );
};

export default SelectOfferItem;
