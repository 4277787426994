import { useIonActionSheet } from '@ionic/react';
import { Icon } from 'oialbert-ui';
import { ItemProps } from '../types';

const Item = ({ onButtonClick, onClick, category, onDelete }: ItemProps) => {
  const [present] = useIonActionSheet();

  return (
    <li className="border flex items-center justify-between p-4 border-crema-500 h-14 w-full rounded-md">
      <section onClick={onClick} className="flex items-center space-x-2">
        <p className="text-base font-bold text-gray-500">{category.title}</p>
        <p className="text-sm font-bold text-neon-900 border border-neon-900 py-0.5 px-2 rounded">
          {category.is_active ? 'ativa' : 'inativa'}
        </p>
      </section>

      <section className="flex items-center space-x-2">
        <button
          type="button"
          onClick={() =>
            present({
              buttons: [
                {
                  text: 'ver produtos',
                  handler: onButtonClick,
                },
                {
                  text: 'excluir',
                  role: 'destructive',
                  handler: onDelete,
                },
                {
                  text: 'cancelar',
                  role: 'cancel',
                },
              ],
              header: 'ações',
            })
          }
          className="text-neon-900 flex items-center border border-transparent py-0.5 rounded hover:bg-neon-900 hover:text-white hover:border-neon-900"
        >
          <Icon.MdOutlineMoreVert size={24} />
        </button>
      </section>
    </li>
  );
};

export default Item;
