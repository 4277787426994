import { IonPage, IonContent } from '@ionic/react';
import { Modal } from 'oialbert-ui';
import { formatInCents } from '../../../../utils/money';
import useWallet from '../../../../hooks/useWallet';

type ModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const BalanceAvailableInfo = ({ open, setOpen }: ModalProps) => {
  const { wallets } = useWallet();
  const valueInCents = Number(wallets?.value_in_cents);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <IonPage>
        <IonContent>
          <div className="px-4 pt-10">
            <div className="box-border h-52 w-46 p-4 border-2 border-neon-900 rounded-md">
              <section className="pt-1 px-4">
                <h3 className="text-base font-bold">saldo disponível</h3>
              </section>
              <section className="pt-8 px-5">
                <p className="pt-2 text-base font-normal">
                  esse é o valor disponível na sua carteira virtual, ok!?
                </p>
                <p className="pt-6 text-xs">
                  valor atual da carteira{' '}
                  <strong className="uppercase">{`${formatInCents(
                    valueInCents ?? 0
                  )}`}</strong>
                </p>
              </section>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </Modal>
  );
};

export default BalanceAvailableInfo;
