import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router';
import { Button, Checkbox } from 'oialbert-ui';

import useAuth from '../../hooks/useAuth';

import { terms } from '../../services/terms';
import { updatePartner } from '../../services/partners';

import { Loading } from '../../components/Loading';
import { Text } from './components/Text';

import errorHandling from '../../utils/error_handling';
import { IonContent, IonPage } from '@ionic/react';

const Terms = () => {
  const history = useHistory();

  const { user, updateUser } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTerms, setLoadingTerms] = useState<boolean>(false);
  const [termsAccepted, toggleTermsAccepted] = useState<boolean>(false);

  const [dataTerms, setDataTerms] = useState<string | null>(null);

  const onUpdate = useCallback(async () => {
    setLoading(true);
    try {
      await updatePartner({ is_term_accepted: true });

      if (user && user?.partner) {
        updateUser({
          ...user,
          partner: {
            ...user.partner,
            is_term_accepted: true,
          },
        });

        history.push('/');
      }
    } catch (err) {
      errorHandling(err, 'Erro ao atualizar', 'crema');
    } finally {
      setLoading(false);
    }
  }, [history, updateUser, user]);

  const getTerms = useCallback(async () => {
    setLoadingTerms(true);
    try {
      const response = await terms();
      setDataTerms(response.terms);
    } catch (err) {
      errorHandling(err, 'Erro ao buscar termos', 'crema');
    } finally {
      setLoadingTerms(false);
    }
  }, []);

  useEffect(() => {
    getTerms();
  }, [getTerms]);

  return (
    <IonPage>
      <IonContent>
        {loadingTerms ? (
          <Loading />
        ) : (
          <section className="sm:mx-auto sm:w-full sm:max-w-xl divide-y divide-gray-100">
            <section className="mt-5 px-5">
              <p className="text-sm">
                Aceite os termos de aceite e condições para prosseguir com o
                cadastro.
              </p>
            </section>
            {dataTerms && (
              <>
                <section className="mt-5 px-5">
                  <Text content={dataTerms} />
                </section>
                <section className="mt-5 p-5">
                  <Checkbox
                    label="Li e concordo com os termos de condições de uso."
                    id="terms"
                    name="terms"
                    onClick={() => toggleTermsAccepted(!termsAccepted)}
                  />
                  <section className="mt-5">
                    <Button
                      full
                      disabled={!termsAccepted || loading}
                      variant="solid"
                      color="neon"
                      onClick={() => onUpdate()}
                    >
                      {loading ? 'Aguarde...' : 'Confirmar'}
                    </Button>
                  </section>
                </section>
              </>
            )}
          </section>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Terms;
