import { useState, useCallback } from 'react';
import { Toast } from 'oialbert-ui';
import useAuth from '../../hooks/useAuth';
import * as Api from '../../services/products';
import errorHandling from '../../utils/error_handling';

import type { Product, Response } from '../CreateItem/types';

export const useProducts = () => {
  const { company } = useAuth();
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);

  const getProducts = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        const response = await Api.fetchProducts<Response>(id, { page });
        setProducts(response ? response?.products?.data : []);
        setTotal(response ? response?.products?.meta.total : 0);
      } catch (error) {
        errorHandling(error, 'erro ao buscar produtos', 'crema');
      } finally {
        setLoading(false);
      }
    },
    [page]
  );

  const createProduct = async (data: Product) => {
    setLoadingCreate(true);
    try {
      const response = await Api.createProduct<{ product: Product }>(
        data,
        company?.id
      );

      if (response && response?.product && data.photo) {
        await Api.uploadFoto(
          response?.product.id as string,
          data.photo as any,
          data as Product
        );
      }

      return response && response?.product;
    } catch (error) {
      errorHandling(error, 'erro ao criar produto', 'crema');
    } finally {
      setLoadingCreate(false);
    }
  };

  const updateProduct = async (id: string, { photo, ...data }: Product) => {
    setLoadingCreate(true);

    try {
      const response = await Api.updateProduct(id, data as any);

      if (photo instanceof Blob) {
        await Api.uploadFoto(id, photo as any, data as any);
      }

      return response;
    } catch (error) {
      errorHandling(error, 'erro ao atualizar produto', 'crema');
    } finally {
      setLoadingCreate(false);
    }
  };

  const deleteProduct = async (id: string) => {
    setLoadingDelete(true);
    try {
      await Api.deleteProduct(id);
      Toast.success('produto deletado com sucesso!');
    } catch (error) {
      errorHandling(error, 'ocorreu um erro ao deletar o produto', 'crema');
    } finally {
      setLoadingDelete(false);
    }
  };

  return {
    createProduct,
    updateProduct,
    getProducts,
    deleteProduct,
    products,
    loading,
    loadingCreate,
    loadingDelete,
    page,
    setPage,
    total,
  };
};
