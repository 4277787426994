import { formatInCents } from '../../../../utils/money';
import type { ReceiptLocationState } from '../..';

const Withdrawal = ({
  data: {
    value_in_cents,
    account_document_number,
    account_number,
    account_agency,
  },
}: {
  data: ReceiptLocationState;
}) => (
  <section className="mt-5 px-5">
    <h3 className="text-base font-bold">Recebedor</h3>
    <section className="flex items-center justify-between mt-3 mb-4">
      <ul className="m-0 space-y-4 text-sm">
        <li>Valor</li>
        <li>CPF</li>
        <li>Agência</li>
        <li>Conta</li>
      </ul>
      <ul className="m-0 ml-4 flex-1 space-y-4 text-sm text-right font-bold">
        <li className="uppercase">{formatInCents(value_in_cents ?? 0)}</li>
        <li>{account_document_number}</li>
        <li>{account_agency}</li>
        <li>{account_number}</li>
      </ul>
    </section>
  </section>
);

export default Withdrawal;
