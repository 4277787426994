import { Meta } from '../../types/invoice';
import { PreOrderData } from '../../types/pre-order';
import { api } from '../config';

type ListAllPreOrdersParams = {
  page?: number;
  limit?: number;
  companyId?: string;
};

interface PreOrdersData {
  meta: Meta;
  data: PreOrderData[];
}

export const listAllPreOrders = async ({
  page = 1,
  limit = 10,
  companyId,
}: ListAllPreOrdersParams) => {
  const config: any = {
    params: {
      page,
      limit,
    },
  };

  if (companyId) {
    config.headers = {
      company_id: companyId,
    };
  }

  const { data, status } = await api.get<{
    meta: Meta;
    preorders: PreOrdersData;
  }>(`/v1/partners/preorders`, config);

  return { data, status };
};
