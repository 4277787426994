/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useCallback, useState, useRef } from "react";
import { IonRefresher, IonRefresherContent, IonModal } from "@ionic/react";
import { RefresherEventDetail } from "@ionic/core";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Card, Icon, Tab, Button } from "oialbert-ui";
import useWallet from "../../hooks/useWallet";
import { Loading } from "../../components/Loading";

import { BalanceItem } from "./components/BalanceItem";
import { Extract } from "./components/Extract";
import { ExtractItem } from "./components/ExtractItem";
import { ExtractOrderItem } from "./components/ExtractOrderItem";
import { ExtractDepositItem } from "./components/ExtractDepositItem";
import { DebitBalanceItem } from "./components/DebitBalanceItem";
import useAuth from "../../hooks/useAuth";
import { LayoutBase } from "../../components/LayoutBase";

const Financial = () => {
  const modal = useRef<HTMLIonModalElement>(null);

  const history = useHistory();

  const [reloading, setReloading] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<number>(0);

  const { company } = useAuth();
  const {
    getWallets,
    loading,
    wallets,
    getTransactions,
    loadingTransactions,
    transactions,
    getTransactionsOrders,
    loadingTransactionsOrders,
    transactionsOrders,
    getDeposits,
    balanceCharge,
    loadingDeposits,
    deposits,
    pageDeposits,
    totalDeposits,
    setPageDeposits,
    pageTransactions,
    totalTransactions,
    setPageTransactions,
    pageTransactionsOrders,
    totalTransactionsOrders,
    setPageTransactionsOrders,
  } = useWallet();

  const onRefresh = useCallback(
    async (event: CustomEvent<RefresherEventDetail>) => {
      await getWallets();
      await getTransactions();
      await getTransactionsOrders();
      await getDeposits();
      setReloading(false);
      event.detail.complete();
    },

    []
  );

  useEffect(() => {
    getWallets();
  }, [getWallets]);

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  useEffect(() => {
    getTransactionsOrders();
  }, [getTransactionsOrders]);

  useEffect(() => {
    getDeposits();
  }, [getDeposits]);

  const limitInCents = company?.debit_balance_limit_in_cents;

  return (
    <LayoutBase title="Financeiro">
      <IonRefresher
        slot="fixed"
        onIonRefresh={(e) => {
          onRefresh(e);
          setReloading(true);
        }}
        disabled={loading}
      >
        <IonRefresherContent />
      </IonRefresher>
      {loading && !reloading ? (
        <Loading />
      ) : (
        <section>
          <section className="mt-4 px-5 flex flex-col gap-y-4">
            {false && <BalanceItem />}
            <div className="flex flex-col">
              <DebitBalanceItem />
              <div className="w-full mt-2">
                {balanceCharge.map((balance: any) =>
                  balance.charge?.charges[0]?.last_transaction ? (
                    <Button
                      onClick={() => history.push("/financial/ticket-list")}
                      full
                      color={"neon"}
                      variant={"solid"}
                    >
                      pagamentos
                    </Button>
                  ) : null
                )}
              </div>
            </div>
          </section>

          <section className="mt-5 px-5">
            <section className="grid grid-cols-2 gap-4">
              <Card className="rounded">
                <Link
                  to="/balance"
                  className="w-full flex flex-col items-center justify-center p-4"
                >
                  <Icon.MdAdd className="w-6 h-6 mb-2 text-neon-900" />
                  <p className="text-xs text-gray-500 text-center">
                    Adicionar saldo
                  </p>
                </Link>
              </Card>
              <Card className="rounded">
                <Link
                  to="/withdraw"
                  className="w-full flex flex-col items-center justify-center p-4"
                >
                  <Icon.MdOutlinePaid className="w-6 h-6 mb-2 text-neon-900" />
                  <p className="text-xs text-gray-500 text-center">Sacar</p>
                </Link>
              </Card>
            </section>
          </section>

          <section className="mt-8">
            <section className="flex items-center justify-between px-5 mb-4">
              <h3 className="text-base font-bold">Extrato</h3>
            </section>

            <Tab
              tabs={[
                {
                  title: "pedidos",
                  index: 0,
                },
                {
                  title: "depósitos",
                  index: 1,
                },
                {
                  title: "transferências",
                  index: 2,
                },
              ]}
              onSelect={(tab) => setCurrentTab(tab.index)}
            />

            <section className="mt-4 mb-5 px-5">
              {currentTab === 0 && (
                <Extract
                  loading={loadingTransactionsOrders}
                  data={transactionsOrders.map((item, idx) => (
                    <ExtractOrderItem data={item as any} key={`item_${idx}`} />
                  ))}
                  pagination={{
                    page: pageTransactionsOrders,
                    total: totalTransactionsOrders,
                    setPage: setPageTransactionsOrders,
                  }}
                />
              )}
              {currentTab === 1 && (
                <Extract
                  loading={loadingDeposits}
                  data={deposits.map((item, idx) => (
                    <ExtractDepositItem data={item} key={`item_${idx}`} />
                  ))}
                  pagination={{
                    page: pageDeposits,
                    total: totalDeposits,
                    setPage: setPageDeposits,
                  }}
                />
              )}
              {currentTab === 2 && (
                <Extract
                  loading={loadingTransactions}
                  data={transactions.map((item, idx) => (
                    <ExtractItem data={item} key={`item_${idx}`} />
                  ))}
                  pagination={{
                    page: pageTransactions,
                    total: totalTransactions,
                    setPage: setPageTransactions,
                  }}
                />
              )}
            </section>

            <IonModal
              id="example-modal"
              ref={modal}
              trigger="resume-transaction-modal"
            >
              <section className="w-full h-full p-6">
                <section className="bg-white w-full h-full py-6 px-3 rounded-3xl">
                  <p>oi</p>
                </section>
              </section>
            </IonModal>
          </section>
        </section>
      )}
    </LayoutBase>
  );
};

export default Financial;
