import { Input } from 'oialbert-ui';
import { ChangeEvent, useCallback } from 'react';

type TokenFormProps = {
  setTokenCode: (_value: string) => void;
  handleAuthenticated: (_token: any) => void;
};

const TokenForm = ({ setTokenCode, handleAuthenticated }: TokenFormProps) => {
  const handleChangeEvent = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const { value } = e.target;

      if (value) {
        setTokenCode(value);

        if (value.length === 5) {
          handleAuthenticated(value);
        }
      }
    },
    [handleAuthenticated, setTokenCode]
  );

  return (
    <>
      <section className="w-full mb-10">
        <div className="flex flex-row justify-center gap-x-6">
          <div className="w-full">
            <Input
              inputMode="numeric"
              maxLength={5}
              max={5}
              autoComplete="off"
              autoCorrect="off"
              onChange={handleChangeEvent}
              placeholder="xxxxx"
              className="text-3xl text-center"
              mode="dark"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default TokenForm;
