import { Button, Input, Toast } from "oialbert-ui";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { LayoutBase } from "../../components/LayoutBase";
import useAuth from "../../hooks/useAuth";
import { updatePartner } from "../../services/partners";
import errorHandling from "../../utils/error_handling";

const Profile = () => {
  const { user, updateUser } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      full_name: user?.partner?.full_name || "",
      email: user?.partner?.email || "",
      cellphone: user?.partner?.cellphone || "",
    },
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async (formData: {
    full_name: any;
    email: any;
    cellphone: any;
  }) => {
    if (!formData.full_name || !formData.email || !formData.cellphone) {
      errorHandling("Todos os campos são obrigatórios.", "error");
      return;
    }

    setLoading(true);

    try {
      const response: any = await updatePartner(formData);

      if (response) {
        user &&
          updateUser({
            ...user,
            partner: {
              ...user.partner,
              ...response,
            },
          });
        Toast.success("Perfil atualizado com sucesso!");
      } else {
        errorHandling(
          "Erro ao atualizar perfil. Verifique os dados e tente novamente.",
          "error"
        );
      }
    } catch (error) {
      console.log(error);
      errorHandling(error, "Erro ao salvar o perfil do parceiro", "crema");
    } finally {
      setLoading(false);
    }
  };

  return (
    <LayoutBase title="Perfil">
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className="mt-4 px-5 pb-5 py-32">
          <h2 className="text-xl font-semibold text-gray-800 mb-4 text-center">
            Atualizar Perfil
          </h2>
          <section className="flex flex-col mt-3 mb-5 space-y-4">
            <Controller
              control={control}
              name="full_name"
              rules={{ required: "Nome completo é obrigatório." }}
              render={({ field }) => (
                <Input
                  label="Nome completo"
                  placeholder="Digite seu nome completo"
                  disabled={loading}
                  required
                  error={errors.full_name?.message?.toString()}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="email"
              rules={{ required: "E-mail é obrigatório." }}
              render={({ field: { value, ...props } }) => (
                <Input
                  label="E-mail"
                  type="email"
                  placeholder="Digite seu e-mail"
                  disabled={true}
                  required
                  error={errors.email?.message?.toString()}
                  value={value}
                  {...props}
                />
              )}
            />
            <Controller
              control={control}
              name="cellphone"
              rules={{ required: "Celular é obrigatório." }}
              render={({ field }) => (
                <Input
                  label="Celular"
                  type="tel"
                  placeholder="(DD) + telefone"
                  mask="CELLPHONE"
                  disabled={loading}
                  required
                  error={errors.cellphone?.message?.toString()}
                  {...field}
                />
              )}
            />
          </section>
          <Button
            type="submit"
            variant="solid"
            color="neon"
            full
            disabled={loading}
          >
            {loading ? "Salvando..." : "Salvar"}
          </Button>
        </section>
      </form>
    </LayoutBase>
  );
};

export default Profile;
