import { IonContent, IonHeader, IonPage, IonSpinner } from '@ionic/react';
import { Header } from 'oialbert-ui';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { api } from '../../services/config';
import { CompanyDetailsData } from '../../types/companies';
import { formatInCents } from '../../utils/money';
import { LabeledCheckbox } from './components/LabeledCheckbox';
import { CreditCardForm } from './forms/CreditCard';
import { PaymentPixForm } from './forms/PaymentPix';
import { PaymentSlipForm } from './forms/PaymentSlip';

type CheckoutItems = {
  id: string;
  name: string;
  price_in_cents: number;
};

type ChekoutDetails = {
  message: string;
  name: string;
  description: string;
  type: string;
  price_in_cents: number;
  payment_methods: string[];
  installments: number;
  interval_count: number;
  interval: string;
  statement_descriptor: string;
  shippable: false;
  created_at: Date;
  updated_at: Date;
  id: string;
  items: CheckoutItems[];
};

type CouponData = {
  id: string;
  coupon: {
    id: string;
    code: string;
    discount_percentage: number;
    cycles: number;
  };
  price_in_cents: number;
  discount_in_cents: number;
};

const ExtraServicesCheckout: React.FC = () => {
  const history = useHistory();
  const { token }: { token: string } = useParams();
  const { state } = useLocation<{ company: CompanyDetailsData }>();

  const [waitingPayment, setWaitingPayment] = useState(false);
  const [checkoutDetails, setCheckoutDetails] = useState<ChekoutDetails>();
  const [discount] = useState<CouponData>();
  const [selectedForm, setSelectedForm] = useState<
    'credit_card' | 'boleto' | 'pix'
  >('boleto');
  const [invoice] = useState<any>(null);
  const [getLastCharge] = useState<any>(invoice?.charges?.pop());

  useEffect(() => {
    (async () => {
      if (!token) return;
      const { data } = await api.post(
        `/v2/partners/services-extras/carts/checkout?token=${token}`
      );

      if (!data || data.items?.length === 0) {
        history.push('/extra-services');
      }

      setCheckoutDetails(data);
    })();
  }, [history, token]);

  useEffect(() => {
    (async () => {
      if (!token || !checkoutDetails) return;
    })();
  }, [state, selectedForm, checkoutDetails, token]);

  return (
    <IonPage>
      <IonHeader className="ion-no-border bg-white shadow-sm sm:mb-5">
        <section className="mt-10 px-5 pb-5 sm:mx-auto sm:w-4/5">
          <Header
            onClick={() => history.push('/extra-services')}
            title="pagamento de serviços extras"
          />
        </section>
      </IonHeader>

      <IonContent>
        <section className="sm:flex sm:flex-row-reverse sm:mx-auto sm:w-4/5">
          <section className="sm:w-2/5">
            {checkoutDetails ? (
              <section className=" px-4 gap-3 flex flex-col">
                <div className="flex flex-col sm:justify-between sm:items-start flex-rows flex-wrap  items-start">
                  <div className="flex flex-col gap-1 pb-6">
                    <h1 className="text-lg font-semibold text-gray-700">
                      Carrinho
                    </h1>
                  </div>
                  <ul className="flex flex-col  gap-3 w-full">
                    {checkoutDetails.items?.map(
                      (packageItem: any, idxPackage: number) => (
                        <li
                          className="flex flex-col items-start w-full justify-between font-light text-sm border border-gray-100 rounded-lg p-3 shadow"
                          key={`key-package-${idxPackage}`}
                        >
                          <div className="flex items-start justify-between w-full">
                            <span className="font-light">
                              {packageItem.name}
                            </span>
                            <strong className="font-semibold text-sm uppercase">
                              {formatInCents(packageItem.price_in_cents)}
                            </strong>
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                  <h1 className="flex flex-col text-2xl mt-5">
                    <b>
                      <span style={{ textTransform: 'uppercase' }}>
                        {formatInCents(checkoutDetails.price_in_cents)}
                      </span>{' '}
                    </b>
                  </h1>
                </div>
              </section>
            ) : (
              <div className="flex justify-center items-center my-4">
                <IonSpinner className="text-neon-900" />
              </div>
            )}
          </section>

          <section className="sm:w-3/5">
            <h1 className="text-lg font-semibold text-center my-3 flex px-4">
              escolha sua forma de pagamento
            </h1>
            <div className="flex px-4 gap-3">
              {checkoutDetails?.payment_methods.includes('credit_card') ? (
                <LabeledCheckbox
                  checked={selectedForm === 'credit_card'}
                  name="payment_type"
                  label="cartão de crédito"
                  onChange={() => {
                    setSelectedForm('credit_card');
                  }}
                  disabled={waitingPayment}
                />
              ) : null}
              {checkoutDetails?.payment_methods.includes('boleto') ? (
                <LabeledCheckbox
                  checked={selectedForm === 'boleto'}
                  name="payment_type"
                  label="boleto"
                  onChange={() => {
                    setSelectedForm('boleto');
                  }}
                  disabled={waitingPayment && selectedForm !== 'boleto'}
                />
              ) : null}
              {checkoutDetails?.payment_methods.includes('pix') ? (
                <LabeledCheckbox
                  checked={selectedForm === 'pix'}
                  name="payment_type"
                  label="pix"
                  onChange={() => {
                    setSelectedForm('pix');
                  }}
                  disabled={waitingPayment && selectedForm !== 'pix'}
                />
              ) : null}
            </div>

            {checkoutDetails ? (
              selectedForm === 'credit_card' ? (
                <CreditCardForm
                  charge={getLastCharge}
                  couponCode={discount?.coupon.id ?? null}
                  company={state?.company}
                  token={token}
                />
              ) : selectedForm === 'boleto' ? (
                <>
                  <PaymentSlipForm
                    charge={getLastCharge}
                    couponId={discount?.coupon.id ?? null}
                    company={state?.company}
                    token={token}
                    setWaitingPayment={setWaitingPayment}
                    checkoutId={checkoutDetails.id}
                  />{' '}
                </>
              ) : (
                selectedForm === 'pix' && (
                  <PaymentPixForm
                    charge={getLastCharge}
                    couponId={discount?.coupon.id ?? null}
                    company={state?.company}
                    token={token}
                    setWaitingPayment={setWaitingPayment}
                    checkoutId={checkoutDetails.id}
                  />
                )
              )
            ) : null}
          </section>
        </section>
      </IonContent>
    </IonPage>
  );
};

export default ExtraServicesCheckout;
