import { Button, Toast } from 'oialbert-ui';
import { Ref, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import useAuth from '../../../../hooks/useAuth';
import useForm from '../../../../hooks/useForm';
import { createSubscriptionOrder } from '../../../../services/financial/subscription';
import errorHandling from '../../../../utils/error_handling';
import { Coupon } from '../Coupon';

type FormProps = {
  formRef: Ref<HTMLFormElement>;
  amount: number;
  paymentType: string;
  couponCode: string;
  isAmountValid: boolean;
  data: any;
  onCallback?: (data: any) => void;
  setDiscount: (value: number) => void;
  setCoupon: (value: string) => void;
};

export const FormPix = ({
  formRef,
  amount,
  paymentType,
  couponCode,
  isAmountValid,
  data: dataUser,
  setDiscount,
  setCoupon,
  onCallback,
}: FormProps) => {
  const history = useHistory();
  const { user } = useAuth();

  const {
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });

  const [loading, setLoading] = useState<boolean>(false);

  const {
    address: { street_number, street, zipcode, neighborhood, ...restAddress },
  } = dataUser;

  const onCreateOrder = useCallback(
    async (data) => {
      setLoading(true);
      try {
        const { charges } = await createSubscriptionOrder({
          type: 'subscription',
          items: [
            {
              code: dataUser.subscription.id,
              amount,
              description: 'assinatura partner',
              quantity: 1,
            },
          ],
          customer: {
            code: user?.id ?? '',
            name: dataUser.name,
            email: dataUser.email,
            document: dataUser?.document_number?.replace?.(/[^a-zA-Z0-9]/g, ''),
            type: 'individual',
            phones: {
              mobile_phone: {
                country_code: '55',
                area_code: dataUser.phone.slice(0, 2),
                number: dataUser.phone.slice(2, 11),
              },
            },
            address: {
              ...restAddress,
              line_1: `${street}, ${
                street_number ?? dataUser?.address?.street_number ?? 'S/N'
              }`,
              line_2: neighborhood,
              zip_code:
                dataUser?.address?.zip_code?.replace?.(/[^a-zA-Z0-9]/g, '') ??
                dataUser?.address?.zipcode?.replace?.(/[^a-zA-Z0-9]/g, ''),
              country: 'BR',
            },
          },
          payments: [
            {
              payment_method: 'pix',
              amount,
              pix: { expires_in: 86400 },
            },
          ],
          metadata: {
            id: dataUser.subscription.id,
            price_in_cents: dataUser.subscription.price_in_cents,
            user_id: user?.id ?? '',
            user_type: 'partner',
            payment_type: paymentType,
            license_id: dataUser.subscription.license_id,
            location_id: dataUser.subscription.license_copy?.location_id,
            type: 'subscription',
          },
          ...(couponCode !== '' && {
            coupon_code: couponCode?.toUpperCase()?.replace(/\s/g, ''),
          }),
        });

        if (charges?.success === false) {
          Toast.crema('não foi possível criar a chave pix');
        } else {
          if (charges) {
            if (amount === 0) {
              Toast.success('sua adesão foi realizada');
              history.push('/select-company');
            } else {
              onCallback && onCallback(charges);
            }
          } else {
            Toast.success('sua adesão foi realizada');
            history.push('/select-company');
          }
        }
      } catch (err: any) {
        const statusCode = err?.response?.status;

        if (statusCode && statusCode === 403) {
          history.push('/select-company');
        }
        errorHandling(err, 'não foi possível criar a chave pix', 'crema');
      } finally {
        setLoading(false);
      }
    },
    [
      dataUser.subscription.id,
      dataUser.subscription.price_in_cents,
      dataUser.subscription.license_id,
      dataUser.subscription.license_copy?.location_id,
      dataUser.name,
      dataUser.email,
      dataUser?.document_number,
      dataUser.phone,
      dataUser?.address?.street_number,
      dataUser?.address?.zip_code,
      dataUser?.address?.zipcode,
      amount,
      user?.id,
      restAddress,
      street,
      street_number,
      neighborhood,
      paymentType,
      couponCode,
      history,
      onCallback,
    ]
  );

  const validateForm = useMemo(
    () => isAmountValid && isValid,
    [isAmountValid, isValid]
  );

  return (
    <form ref={formRef}>
      <section className="mt-4 mb-5">
        <h3 className="text-base font-bold">cupom de desconto</h3>
        <Coupon
          subscriptionId={dataUser.subscription.id}
          onApply={setDiscount}
          onCoupon={setCoupon}
        />
      </section>
      <Button
        full
        onClick={handleSubmit(onCreateOrder)}
        variant="solid"
        color="neon"
        disabled={loading || !validateForm}
      >
        {loading ? 'aguarde...' : 'realizar pagamento'}
      </Button>
    </form>
  );
};
