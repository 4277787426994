import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { useIonActionSheet } from '@ionic/react';
import { Icon } from 'oialbert-ui';
import { useCallback, useEffect, useState } from 'react';

type CoverType = {
  current: any;
  setValue: (_key: string, _value: any) => void;
  setCurrentBanner: (banner: string) => void;
  setBannerCropVisible: (visible: boolean) => void;
};

export const Cover = ({
  current,
  setValue,
  setCurrentBanner,
  setBannerCropVisible,
}: CoverType) => {
  const [present] = useIonActionSheet();
  const [preview, setPreview] = useState<string | null>(current ?? null);

  useEffect(() => {
    if (current?.file) {
      setPreview(current?.url);
    }
  }, [current]);

  const setImage = useCallback(
    async (resource) => {
      if (resource) {
        const image = await fetch(resource?.[0].webPath as string);
        const blob = await image.blob();

        setValue('banner', blob);
        setCurrentBanner(resource?.[0].webPath as string);
        setBannerCropVisible(true);
      } else {
        setCurrentBanner(null!);
      }
    },
    [setCurrentBanner, setValue]
  );

  const takePicture = useCallback(
    (source) => {
      Camera.getPhoto({
        quality: 100,
        //allowEditing: true,
        resultType: CameraResultType.Uri,
        source,
        width: 1080,
        height: 288,
      })
        .then((item) => setImage([item]))
        .catch(console.error);
    },
    [setImage]
  );

  const openActions = useCallback(() => {
    present({
      buttons: [
        {
          text: 'câmera',
          handler: () => takePicture(CameraSource.Camera),
        },
        {
          text: 'galeria',
          handler: () => takePicture(CameraSource.Photos),
        },
        {
          text: 'cancelar',
          role: 'cancel',
        },
      ],
      header: 'ações',
    });
  }, [present, takePicture]);

  return (
    <section
      onClick={openActions}
      className="w-full h-full flex items-center justify-center rounded-3xl cursor-pointer space-x-2"
    >
      {preview ? (
        <img
          className="object-cover hover:object-scale-down h-full w-full rounded-3xl"
          src={preview}
          alt="Preview da capa"
        />
      ) : (
        <>
          <Icon.MdCameraAlt className="text-gray-500 text-3xl" />
          <span className="text-gray-500">alterar</span>
        </>
      )}
    </section>
  );
};
