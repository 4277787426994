export const copyToClipboard = async (str: string) => {
  const el = document.createElement('textarea');

  el.value = str;
  el.setAttribute('readonly', '');

  document.body.appendChild(el);

  el.select();

  try {
    const successful = document.execCommand('copy');

    if (successful) {
      document.body.removeChild(el);
      return true;
    }
  } catch (err) {
    return false;
  }

  return false;
};
