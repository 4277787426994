import { RefresherEventDetail } from "@ionic/core";
import { IonRefresher, IonRefresherContent } from "@ionic/react";
import { Button, Card, Icon } from "oialbert-ui";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useOrders } from "../Orders/hooks";

import CompanyHeader from "../../components/CompanyHeader";
import Orders from "../Home/components/Orders";

import { LayoutBase } from "../../components/LayoutBase";
import { MenuProps } from "../Home/types";

const menus: MenuProps[] = [
  {
    name: "Configurações",
    to: "/settings",
    icon: <Icon.MdSettings className="w-6 h-6 mb-2 text-neon-900" />,
    active: true,
    visible: true,
  },
];

const ORDER_SHOW = 2;

const HomeDelivery = () => {
  const [reloading, setReloading] = useState<boolean>(false);
  const { fetchingOrders, openedOrders, getOpenedOrders } = useOrders();

  const [end, setEnd] = useState(ORDER_SHOW);
  const ordersToShow = openedOrders.slice(0, end);

  const onRefresh = useCallback(
    async (event: CustomEvent<RefresherEventDetail>) => {
      await getOpenedOrders({ limit: 4 });
      setReloading(false);
      event.detail.complete();
    },

    []
  );

  useEffect(() => {
    getOpenedOrders({ limit: 4 });
  }, [getOpenedOrders]);

  return (
    <LayoutBase
      title="Home"
      header={
        <section className="mt-5">
          <CompanyHeader />
        </section>
      }
    >
      <section className="w-100">
        <IonRefresher
          slot="fixed"
          onIonRefresh={(e) => {
            onRefresh(e);
            setReloading(true);
          }}
        >
          <IonRefresherContent />
        </IonRefresher>
        <section className="mt-5 px-5">
          <section className="flex items-center justify-between mb-4">
            <h3 className="text-sm font-bold">pedidos em andamento</h3>
            <Link
              to="/financial-order"
              className="text-neon-900 text-sm font-bold"
            >
              ver todos os pedidos
            </Link>
          </section>

          <section className="space-y-4">
            <Orders
              loading={fetchingOrders}
              reloading={reloading}
              data={ordersToShow}
            />
          </section>

          {openedOrders.length > ORDER_SHOW && (
            <section className="mt-2">
              <Button
                full
                normal
                color="neon"
                variant="light"
                onClick={() => setEnd(ORDER_SHOW + 2)}
              >
                Ver mais
              </Button>
            </section>
          )}

          <section className="mt-4">
            <Card className="flex rounded">
              <Link to="/financial-order" className="w-full flex p-4">
                <section className="flex items-center">
                  <Icon.MdReceipt className="w-6 h-6 mr-2 text-neon-900" />
                  <p className="text-xs text-center">gerenciar meus pedidos</p>
                </section>
              </Link>
            </Card>
          </section>
        </section>
        <section className="mt-4 px-5">
          <h3 className="text-sm font-bold mb-4">Ações</h3>
          <section className="grid grid-cols-3 gap-4">
            {menus
              .filter(({ visible }) => visible)
              .map((menu, idx) => (
                <Card
                  key={`menu_${idx}`}
                  className="flex flex-col items-center justify-center rounded p-4"
                >
                  <Link
                    to={menu.active ? menu.to : "/"}
                    className={`flex flex-col items-center justify-center ${
                      !menu.active && "filter grayscale opacity-40"
                    }`}
                  >
                    {menu.icon}
                    <p className="text-xs text-gray-500 text-center">
                      {menu.name}
                    </p>
                  </Link>
                </Card>
              ))}
          </section>
        </section>
      </section>
    </LayoutBase>
  );
};

export default HomeDelivery;
