export function maskPhoneNumber(phone: string): string {
  const cleaned = phone.replace(/\D/g, '');

  if (cleaned.length < 10) {
    throw new Error('O número de telefone deve ter 10 ou 11 dígitos.');
  }

  if (cleaned.length === 10) {
    const ddd = cleaned.slice(0, 2);
    const prefix = cleaned.slice(2, 6);
    const suffix = cleaned.slice(6);
    return `(${ddd}) ${prefix}-${suffix}`;
  }

  const ddd = cleaned.slice(0, 2);
  const prefix = cleaned.slice(2, 7);
  const suffix = cleaned.slice(7);

  return `(${ddd}) ${prefix}-${suffix}`;
}
