import axios from 'axios';
import { CreditCardBillingForm } from '../../pages/PlanPayment/type';
import { api } from '../config';

export async function PUTPayment(id: string, payload: CreditCardBillingForm) {
  try {
    const { data } = await api.put(
      `v2/partners/gateway/charges/${id}/payment`,
      payload
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data.message);
    } else {
      throw new Error(
        'Não foi possível efetuar o pagamento, verifique os dados e tente novamente'
      );
    }
  }
}
