// @ts-nocheck
import { zodResolver } from "@hookform/resolvers/zod";
import { IonImg, IonSpinner, useIonAlert } from "@ionic/react";
import { Button } from "oialbert-ui";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { PUTPayment } from "../../../../services/financial/payment";
import { CompanyDetailsData } from "../../../../types/companies";
import { schema } from "./schema";
import { api } from "../../../../services/config";
import { useHistory } from "react-router";
import { delay } from "q";

type Props = {
  company: CompanyDetailsData;
  couponId: string | null;
  charge: any;
  token: string | null;
  checkoutId?: string | null;
  setWaitingPayment: any;
};

type Pix = {
  qr_code_url: any;
  qr_code: any;
};

export const PaymentPixForm: React.FC<Props> = ({
  company,
  couponId,
  token,
  setWaitingPayment,
  checkoutId,
}: any) => {
  let [pix, setPix] = useState<Pix | null>(null);
  const [presentAlert] = useIonAlert();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const copyToClipboard = useCallback(async () => {
    if (pix) {
      await navigator.clipboard.writeText(pix.qr_code);
      alert("Texto copiado para a área de transferência!" + pix.qr_code);
    }
  }, [pix]);

  const { handleSubmit } = useForm<PaymentPixBillingForm>({
    resolver: zodResolver(schema),
    defaultValues: {
      payment_method: "pix",
    },
  });
  const handleFormSubmit = useCallback(
    async (formData: CreditCardBillingForm) => {
      setLoading(true);

      const pagamento: PaymentForm = {
        checkout_id: checkoutId,
        payment_method: "pix",
        installments: 1,
      };

      const { data } = await api.post(
        `/v2/partners/services-extras/payment`,
        pagamento
      );

      await delay(5000);

      const { data: invoice } = await api.get(
        `/v2/partners/gateway/invoices/${data.id}`
      );

      let charge = invoice.charges.pop();

      await PUTPayment(charge.id, formData)
        .then((data: any) => {
          setPix(data.order_gateway_copy.charges[0].last_transaction);
          api.post(
            `/v2/partners/services-extras/carts/checkout/finished?token=${token}`
          );
          setWaitingPayment(true);
        })
        .catch((err) => {
          console.log(err);
          presentAlert(
            "não foi possível efetuar o pagamento, verifique os dados enviados"
          );
        })
        .finally(() => setLoading(false));
    },
    [presentAlert, token]
  );

  return loading ? (
    <section className="flex flex-col bg-white w-full h-full items-center justify-center">
      <IonSpinner className="text-neon-900" />
    </section>
  ) : !pix ? (
    <form
      className="flex flex-col gap-6 p-4"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Button variant="solid" color="neon">
        gerar pix
      </Button>
    </form>
  ) : (
    <div className="flex flex-col gap-6 p-4 py-12">
      <div className="flex flex-col items-center gap-2">
        <h1 className="text-2xl font-semibold">Pix gerado com sucesso!</h1>
        <p className="text-sm font-light">
          utilize o QR Code ou copie o código para realizar o pagamento
        </p>
      </div>
      <div className="flex flex-col gap-3 items-center">
        <IonImg alt="" src={pix.qr_code_url} />
        <span className="text-xs font-semibold text-center">{pix.qr_code}</span>
      </div>
      <div className="flex gap-3 w-full ">
        <button
          className="bg-neon-900 w-full text-white py-4 px-6 rounded-lg text-center text-sm"
          onClick={copyToClipboard}
        >
          copiar código do pix
        </button>
        <button
          className="bg-neon-900 w-full text-white py-4 px-6 rounded-lg text-center text-sm"
          onClick={() => {
            history.push("/invoices");
          }}
        >
          visualizar pagamentos
        </button>
      </div>
    </div>
  );
};
