import { Invoice, Meta } from '../types/invoice';
import { api } from './config';

type ListInvoiceParams = {
  page?: number;
  limit?: number;
  order?: string;
};

export const listInvoices = async ({
  page = 1,
  limit = 10,
  order,
}: ListInvoiceParams) => {
  const { data } = await api.get<{
    meta: Meta;
    data: Invoice[];
  }>(`/v2/partners/gateway/invoices`, {
    params: {
      page,
      limit,
      order,
    },
  });

  return data;
};

export const findInvoice = async (id: string): Promise<Invoice> => {
  const { data } = await api.get<Invoice>(
    `/v2/partners/gateway/invoices/${id}`
  );
  return data;
};

export const updateInvoice = async <T>(id: string, params = {}) => {
  const { status, data } = await api.put<Invoice>(
    `/v2/partners/gateway/invoices/${id}`,
    {
      coupon_code: params,
    }
  );
  if (status === 200 && data) {
    return data as T;
  }

  return false;
};
