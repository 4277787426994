import { IonImg } from '@ionic/react';
import classNames from 'classnames';
import { useState } from 'react';
import NotFoundImage from '../../../assets/illustrations/not-found-image.jpg';
import { Loading } from '../../../components/Loading';

interface PromotionCardProps {
  promotion: any;
  select: (promotion: any) => void;
  selected: boolean;
}

const PromotionCard: React.FC<PromotionCardProps> = ({
  promotion,
  select,
  selected,
}) => {
  const [retryCount, setRetryCount] = useState(0);
  const [loadRetry, setLoadRetry] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const handleImageError = () => {
    setLoadRetry(false);

    if (retryCount < 3) {
      setTimeout(() => {
        setRetryCount(retryCount + 1);
        setLoadRetry(true);
      }, 1000);
    } else {
      setLoadRetry(true);
      setLoading(false);
      setError(true);
    }
  };

  return (
    promotion && (
      <div
        className={`relative flex flex-col justify-between shadow-gray-50 shadow-sm border ${
          selected ? 'border-neon-900' : 'border-gray-100'
        } hover:border-neon-900 cursor-pointer rounded-lg p-4 bg-white`}
        onClick={() => select(selected ? null : promotion)}
      >
        <div
          className={`absolute z-10 top-2 left-2 w-7 h-7 rounded-full border-2 ${
            selected
              ? 'border-neon-900 bg-neon-900'
              : 'border-neon-900 bg-white'
          } flex items-center justify-center`}
        >
          {selected && <div className="w-2 h-2 bg-white rounded-full"></div>}
        </div>

        <div
          className={classNames(
            promotion.status === 'deleted' && 'opacity-50',
            'my-2 relative'
          )}
        >
          <div
            className={`object-cover w-full h-32 border border-gray-100 rounded ${
              !loading ? 'invisible absolute' : ''
            }`}
          >
            <Loading />
          </div>
          {loadRetry ? (
            <IonImg
              src={error ? NotFoundImage : promotion.banner || NotFoundImage}
              className={`object-cover w-full h-32 border border-gray-100 rounded ${
                loading ? 'invisible absolute' : ''
              }`}
              onIonError={handleImageError}
              onIonImgDidLoad={() => {
                setLoading(false);
              }}
            />
          ) : null}
        </div>
        <div>
          <h1 className="font-semibold text-sm text-gray-700 hover:text-neon-900 cursor-pointer line-clamp-1 truncate">
            {promotion.name}
          </h1>
          <p
            className="text-xs text-gray-500 truncate"
            dangerouslySetInnerHTML={{ __html: promotion.description }}
          />
        </div>
      </div>
    )
  );
};

export default PromotionCard;
