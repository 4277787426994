// @ts-nocheck
import { useIonAlert } from "@ionic/react";
import { Button, Checkbox, Icon, Input, Select } from "oialbert-ui";
import { useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import useForm from "../../hooks/useForm";
import { useUsers } from "../../hooks/useUsers";

import { Controller } from "react-hook-form";
import { LayoutBase } from "../../components/LayoutBase";
import { removeAllNumberAndSpecialCharacters } from "../../utils/texts";
import type { UserParamsProps } from "./types";
import { userCreateSchema, userUpdateSchema } from "../../schemas/UserSchema";

const User = () => {
  const [present] = useIonAlert();
  const history = useHistory();
  const { id }: UserParamsProps = useParams();

  const {
    onCreateUser,
    onEditUser,
    loadingCreateUsers,
    createdUser,
    setCreatedUser,
    getUser,
    loadingUser,
    user,
    onDeleteUser,
    loadingDeleteUsers,
    loadingEditUsers,
  } = useUsers();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    schema: id === "add" ? userCreateSchema : userUpdateSchema,
    mode: "onChange",
  });

  const onDelete = useCallback(async () => {
    await onDeleteUser(user?.user_id ?? "");
    history.goBack();
  }, [history, onDeleteUser, user?.user_id]);

  const onSave = useCallback(
    ({ email, ...payload }) => {
      if (id === "add") {
        onCreateUser({
          ...payload,
          email,
          cellphone: payload.cellphone.replace(/[^0-9]/g, ""),
          document_number: payload.document_number.replace(/[_.-]/g, ""),
          is_able_to_update_checkin_cashback:
            payload.is_able_to_update_checkin_cashback,
        }).then(() => {
          history.push("/users");
        });
      } else {
        onEditUser(id, {
          ...payload,
          email,
          cellphone: payload.cellphone.replace(/[^0-9]/g, ""),
          document_number: payload.document_number.replace(/[_.-]/g, ""),
          is_able_to_update_checkin_cashback:
            payload.is_able_to_update_checkin_cashback,
        }).then(() => {
          history.push("/users");
        });
      }
    },
    [history, id, onCreateUser, onEditUser]
  );

  useEffect(() => {
    if (createdUser !== null) {
      history.push("/users");
      setCreatedUser(null);
      reset();
    }
  }, [createdUser, history, reset, setCreatedUser]);

  useEffect(() => {
    if (id !== "add") getUser(id);
  }, [id, getUser]);

  useEffect(() => {
    reset(user ?? {});
  }, [reset, user]);

  return (
    <LayoutBase title="Usuários">
      <form>
        <section className="flex flex-col mt-5 px-5 space-y-4">
          <Controller
            control={control}
            name="full_name"
            rules={{
              required: true,
            }}
            render={({ field: { value, onChange, ...props } }) => (
              <Input
                required
                disabled={
                  loadingCreateUsers ||
                  loadingUser ||
                  loadingDeleteUsers ||
                  loadingEditUsers
                }
                placeholder="nome completo"
                value={value ?? ""}
                error={errors.full_name?.message?.toString()}
                onChange={(e) =>
                  onChange(removeAllNumberAndSpecialCharacters(e.target.value))
                }
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            rules={{
              required: true,
            }}
            render={({ field: { value, ...props } }) => (
              <Input
                required
                disabled={
                  loadingCreateUsers ||
                  loadingUser ||
                  loadingDeleteUsers ||
                  loadingEditUsers
                }
                type="email"
                placeholder="E-mail"
                value={value ?? ""}
                error={errors.email?.message?.toString()}
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            name="cellphone"
            rules={{
              required: true,
            }}
            render={({ field: { value, ...props } }) => (
              <Input
                required
                disabled={
                  loadingCreateUsers ||
                  loadingUser ||
                  loadingDeleteUsers ||
                  loadingEditUsers
                }
                type="tel"
                placeholder="Telefone"
                mask="CELLPHONE"
                value={value ?? ""}
                error={errors.cellphone?.message?.toString()}
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            name="document_number"
            rules={{
              required: true,
            }}
            render={({ field: { value, ...props } }) => (
              <Input
                required
                disabled={
                  loadingCreateUsers ||
                  loadingUser ||
                  loadingDeleteUsers ||
                  loadingEditUsers
                }
                type="tel"
                placeholder="CPF"
                mask="CPF"
                value={value ?? ""}
                error={errors.document_number?.message?.toString()}
                {...props}
              />
            )}
          />
          <Select
            required
            disabled={
              loadingCreateUsers ||
              loadingUser ||
              loadingDeleteUsers ||
              loadingEditUsers
            }
            label="Tipo de usuário"
            options={[
              {
                label: "Vendedor",
                value: "pdv",
              },
              {
                label: "Administrador",
                value: "admin",
              },
            ]}
            error={errors.user_types?.message?.toString()}
            {...register("user_types")}
          />
          <div className="flex flex-col">
            <div className="mt-2 ml-1">
              <Controller
                control={control}
                name="is_able_to_update_checkin_cashback"
                render={({ field: { ...props } }) => (
                  <Checkbox
                    id="is_able_to_update_checkin_cashback"
                    label="Permissão para checkin avançado"
                    {...register("is_able_to_update_checkin_cashback")}
                    className="w-auto teste"
                    {...props}
                  />
                )}
              />
            </div>
          </div>
        </section>
      </form>
      {id !== "add" && user && (
        <section className="mt-8 px-5">
          <section className="border-t border-crema-500 py-4">
            <button
              className="flex items-center"
              onClick={() => {
                present({
                  header: "deletar usuário",
                  message: "deseja realmente deletar este usuário?",
                  buttons: [
                    "Não",
                    {
                      text: "Sim",
                      handler: () => onDelete(),
                    },
                  ],
                  onDidDismiss: () => {},
                });
              }}
            >
              <Icon.MdOutlineDelete className="w-6 h-6 text-neon-900" />
              <span className="text-base text-neon-900 ml-2">
                {loadingDeleteUsers ? "aguarde..." : "excluir"}
              </span>
            </button>
          </section>
        </section>
      )}
      <section className="grid sm:grid-cols-2 gap-4 px-5 mt-8 mb-5">
        <Button
          className="sm:w-1/2"
          full
          variant="solid"
          color="neon"
          disabled={
            loadingCreateUsers ||
            loadingUser ||
            !isValid ||
            loadingDeleteUsers ||
            loadingEditUsers
          }
          onClick={handleSubmit(onSave)}
        >
          {loadingCreateUsers || loadingUser
            ? "Aguarde..."
            : id !== "add"
            ? "Atualizar"
            : "Cadastrar"}
        </Button>
        <Button
          className="sm:w-1/2"
          onClick={() => history.goBack()}
          variant="outline"
          color="neon"
          full
        >
          Cancelar
        </Button>
      </section>
    </LayoutBase>
  );
};

export default User;
