import { Button, Modal, Toast } from 'oialbert-ui';
import { useCallback, useEffect, useState } from 'react';
import { copyToClipboard } from '../../utils/copy';
import { LayoutBase } from '../../components/LayoutBase';
import { tokenApi } from '../../services/tokenApi';
import TableTokenApi from './components/TableTokenApi';
import errorHandling from '../../utils/error_handling';

const TokenApi = () => {
  const [token, setToken] = useState<{ token: string } | null>(null);
  const [loading, setLoading] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleCopyToken = async () => {
    if (token?.token) {
      await copyToClipboard(token?.token);
      Toast.success('Token copiado');
      setReloadPage(true);
    }
  };

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const getToken = await tokenApi();
      setToken(getToken);
    } catch (err) {
      errorHandling(
        err,
        'A empresa não ter permissão para acessar esta api, por favor entrar em contato com o time de suporte.',
        'crema'
      );
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (reloadPage) {
      window.location.reload();
    }
  }, [reloadPage]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const confirmGenerateToken = async () => {
    await handleSubmit();
    closeModal();
  };

  return (
    <>
      <LayoutBase title="Token Api Pública">
        <section className="pt-10 px-2">
          <div>
            <h1 className="text-xl font-semibold">Token para API Pública</h1>
            <span className="text-md text-gray-500">
              Gere seu token para ter disponibilidade com a API pública
            </span>
            <div className="pt-10">
              <Button
                onClick={openModal}
                variant="solid"
                color="neon"
                normal
                disabled={loading}
              >
                {loading ? 'Gerando Token...' : 'Gerar Token'}
              </Button>
            </div>
            <TableTokenApi />
            <section className="pt-10 px-0">
              {token && (
                <div className="bg-gray-50 shadow sm:rounded-lg border-2 w-76 mr-14">
                  <div className="px-2 py-5 sm:p-6">
                    <div className="w-42 mr-6">{token.token}</div>
                    <div className="mt-4">
                      <Button
                        onClick={handleCopyToken}
                        variant="solid"
                        color="neon"
                        normal
                        disabled={loading}
                      >
                        {loading ? 'Copiando Token...' : 'Copiar Token'}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </section>
          </div>
        </section>
      </LayoutBase>
      <Modal open={showModal} onClose={closeModal} height={210}>
        <section className="text-black text-center mb-10 space-y-4">
          <h3 className="text-base font-bold">
            Deseja realmente gerar um novo token?
          </h3>
        </section>
        <section className="flex items-center">
          <section className="flex-1">
            <Button
              full
              normal
              variant="outline"
              color="neon"
              onClick={confirmGenerateToken}
            >
              Sim
            </Button>
          </section>
          <section className="flex-1">
            <Button
              full
              normal
              variant="light"
              color="neon"
              onClick={closeModal}
            >
              Cancelar
            </Button>
          </section>
        </section>
      </Modal>
    </>
  );
};

export default TokenApi;
