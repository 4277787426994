import * as z from 'zod';

export type Product = z.infer<typeof ProductSchema>;
export type Characteristic = z.infer<typeof CharacteristicSchema>;

export const OptionSchema = z
  .object({
    title: z.string().nonempty({ message: 'campo obrigatório' }),
    min: z
      .number({
        required_error: 'campo obrigatório',
        invalid_type_error: 'campo obrigatório',
      })
      .min(1, 'o valor mínimo deve maior que 0')
      .nonnegative('não pode ser negativo')
      .default(1),
    max: z
      .number({
        required_error: 'campo obrigatório',
        invalid_type_error: 'campo obrigatório',
      })
      .nonnegative('não pode ser negativo')
      .default(0),
    additional_price_in_cents: z.number().default(0),
  })
  .refine(
    (data) => {
      if (data.max < data.min) {
        return false;
      }

      return true;
    },
    {
      message: 'o valor máximo não pode ser menor que o mínino',
      path: ['max'],
    }
  );

export const OptionsSchema = z
  .array(OptionSchema)
  .nonempty('adicione pelo menos uma opção');

export const CharacteristicSchema = z
  .object({
    id: z.string().optional(),
    title: z.string().nonempty({ message: 'preencha o nome do item' }),
    description: z
      .string()
      .nonempty({ message: 'preencha a descrição do item' }),
    is_required: z.boolean({
      required_error: 'selecione se a característica é obrigatória ou não.',
      invalid_type_error: 'característica obrigatória deve ser um boolean',
    }),
    min: z
      .number({
        required_error: 'campo obrigatório',
        invalid_type_error: 'campo obrigatório',
      })
      .min(1, 'o valor mínimo deve maior que 0')
      .nonnegative('não pode ser negativo')
      .default(1),
    max: z
      .number({
        required_error: 'campo obrigatório',
        invalid_type_error: 'campo obrigatório',
      })
      .nonnegative('não pode ser negativo')
      .default(0),
    options: OptionsSchema,
  })
  .refine(
    (data) => {
      if (data.max < data.min) {
        return false;
      }

      return true;
    },
    {
      message: 'o valor máximo não pode ser menor que o mínino',
      path: ['max'],
    }
  );

export const ProductSchema = z
  .object({
    id: z.string().optional(),
    title: z.string().nonempty({ message: 'campo obrigatório' }),
    photo: z.any().optional().default(''),
    is_active: z.any(),
    description: z.string().optional().default(''),
    order_limit: z
      .number({
        required_error: 'campo obrigatório',
        invalid_type_error: 'campo obrigatório',
      })
      .min(1, { message: 'limite mínimo é 1' })
      .optional()
      .default(1),
    price_albert_in_cents: z
      .number({ required_error: 'campo obrigatório' })
      .min(1, 'valor mínimo é R$ 1,00'),
    price_cashback_in_cents: z
      .number({ required_error: 'campo obrigatório' })
      .min(1, 'valor mínimo é R$ 1,00')
      .max(10000000, 'valor máximo é R$ 100.000,00'),
    product_category_id: z.string().nonempty({ message: 'campo obrigatório' }),
    characteristics: z.array(CharacteristicSchema).optional().nullable(),
  })
  .refine(
    (data) => {
      if (data.price_cashback_in_cents >= data.price_albert_in_cents) {
        return false;
      }

      return true;
    },
    {
      path: ['price_cashback_in_cents'],
      message: 'cashback não pode ser maior ou igual ao preço',
    }
  )
  .refine(
    (data) => {
      if (data.price_cashback_in_cents / data.price_albert_in_cents < 2 / 100) {
        return false;
      }

      return true;
    },
    {
      path: ['price_cashback_in_cents'],
      message: 'valor mínimo de cashback é de 2% do valor do produto',
    }
  )
  .refine(
    (data) => {
      if (
        data.price_cashback_in_cents / data.price_albert_in_cents >
        70 / 100
      ) {
        return false;
      }

      return true;
    },
    {
      path: ['price_cashback_in_cents'],
      message: 'valor maxímo de cashback é de 70% do valor do produto',
    }
  );
