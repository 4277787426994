import { useCallback, useMemo, useState, Ref } from 'react';
import { useHistory } from 'react-router';
import useForm from '../../../../hooks/useForm';
import useAuth from '../../../../hooks/useAuth';

import { Button } from 'oialbert-ui';

import { createDepositOrder } from '../../../../services/financial/subscription';
import errorHandling from '../../../../utils/error_handling';

type FormProps = {
  formRef: Ref<HTMLFormElement>;
  amount: number;
  paymentType: string;
  isAmountValid: boolean;
};

export const FormPix = ({
  formRef,
  amount,
  paymentType,
  isAmountValid,
}: FormProps) => {
  const history = useHistory();
  const { user, company } = useAuth();

  const {
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });

  const [loading, setLoading] = useState<boolean>(false);

  const onCreateOrder = useCallback(async () => {
    setLoading(true);
    try {
      const response = await createDepositOrder({
        type: 'deposit',
        items: [
          {
            amount,
            description: 'Deposito',
            quantity: 1,
          },
        ],
        customer: {
          code: user?.id ?? '',
          name: user?.partner?.full_name ?? '',
          email: user?.partner?.email ?? '',
          document:
            user?.partner?.document_number?.replace(/[^a-zA-Z0-9]/g, '') ?? '',
          type: 'individual',
          phones: {
            mobile_phone: {
              country_code: '55',
              area_code:
                user?.partner?.cellphone?.slice(0, 2) ??
                company?.phone?.slice(0, 2) ??
                '',
              number:
                user?.partner?.cellphone?.slice(2, 11) ??
                company?.phone?.slice(2, 11) ??
                '',
            },
          },
        },
        payments: [
          {
            payment_method: 'pix',
            pix: { expires_in: 86400 },
            amount,
          },
        ],
        metadata: {
          type: 'deposit',
          user_id: user?.id ?? '',
          user_type: 'partner',
          company_id: company?.id ?? '',
        },
      });

      history.push({
        pathname: '/receipt',
        state: {
          value_in_cents: amount,
          payment_type: paymentType,
          id: response.deposit.id,
          context: 'deposit',
          order: response,
        },
      });
    } catch (err) {
      errorHandling(err, 'não foi possível processar o pix', 'crema');
    } finally {
      setLoading(false);
    }
  }, [amount, user, company, history, paymentType]);

  const validateForm = useMemo(
    () => isAmountValid && isValid,
    [isAmountValid, isValid]
  );

  return (
    <form ref={formRef}>
      <section className="mt-4">
        <Button
          full
          onClick={handleSubmit(onCreateOrder)}
          variant="solid"
          color="neon"
          disabled={loading || !validateForm}
        >
          {loading ? 'Aguarde...' : 'Pagar com Pix'}
        </Button>
      </section>
    </form>
  );
};
