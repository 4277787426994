import { Options } from '../../options';
import { CashbackGratuityAdjustments } from '../enums/cashback-gratuity-adjustments';

const labels = new Map<CashbackGratuityAdjustments, string>([
  [3, '33.33%'],
  [5, '20%'],
  [10, '10%'],
]);

const getLabel = (type: CashbackGratuityAdjustments): string => labels.get(type)!;

const getOption = (type: CashbackGratuityAdjustments): Options<CashbackGratuityAdjustments> => ({
  label: getLabel(type),
  value: type,
});

const options: Options<CashbackGratuityAdjustments>[] = Array.from(labels).map(
  ([value, label]) => ({
    value,
    label,
  }),
);

export const cashbackGratuityAdjustments = {
  getLabel,
  getOption,
  options,
};
