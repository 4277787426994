/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty-pattern */
/* eslint-disable no-restricted-globals */
import { IonContent, IonPage } from "@ionic/react";
import { useCallback, useMemo, useState } from "react";

import LogoWhite from "../../../assets/logo-white.svg";
import PatternLogo from "../../../assets/pattern-logo.svg";

import classNames from "classnames";
import { Button } from "oialbert-ui";
import useAuth from "../../../hooks/useAuth";
import TokenForm from "../components/TokenForm";

import { useHistory, useParams } from "react-router";

type ParamsProps = {
  document: string;
};

const Authenticator = () => {
  const { sendToken, signIn, loading } = useAuth();
  const history = useHistory();
  const { document }: ParamsProps = useParams();
  const countDownNumber = useMemo(() => 30, []);
  const [tokenCode, setTokenCode] = useState<string>("");
  const [countTime, setCountTime] = useState<number>(countDownNumber);
  const [_, setSignInStatusCode] = useState<number | null>(null);

  const handleAuthenticated = useCallback(
    async (token: any) => {
      const status: any = await signIn(token, document);
      setSignInStatusCode(status);
      if (status !== 403) {
        history.push("/select-company");
      }
    },
    [document, signIn, history]
  );

  const handleGenerateNewToken = useCallback(() => {
    sendToken(document);
    let interval = setInterval(() => {
      setCountTime((state) => {
        if (state === 0) {
          clearInterval(interval);
          return state;
        }
        return state - 1;
      });
    }, 1000);
  }, [document, sendToken]);

  const handleAgainNewToken = useCallback(() => {
    if (countTime === 0) {
      setCountTime(countDownNumber);
      handleGenerateNewToken();
    } else {
      handleGenerateNewToken();
    }
  }, [handleGenerateNewToken, countTime, countDownNumber]);

  return (
    <>
      <IonPage>
        <IonContent>
          <section className="bg-gray-900 h-full flex flex-col items-center justify-between relative z-0">
            <img
              src={PatternLogo}
              className="h-60 absolute top-0 right-0 pointer-events-none"
              alt="Pattern Logo"
              style={{ zIndex: -1 }}
            />

            <section className="sm:mx-auto sm:w-full sm:max-w-xl flex-1 flex flex-col items-center justify-center px-5 space-y-5">
              <img src={LogoWhite} className="h-20" alt="Logo" />

              <p className="text-white text-sm text-center">
                por favor, insira o token que acabamos de lhe enviar para o
                endereço de e-mail ou celular cadastrado.
              </p>

              <section className="w-full pb-5">
                <TokenForm
                  setTokenCode={setTokenCode}
                  handleAuthenticated={handleAuthenticated}
                />
                <section className="w-full mt-5">
                  <Button
                    type="button"
                    full
                    variant="solid"
                    color="neon"
                    onClick={() => handleAuthenticated(tokenCode)}
                    disabled={loading && tokenCode?.length !== 5}
                    tabIndex={0}
                  >
                    {loading ? "aguarde..." : "validar"}
                  </Button>
                </section>
              </section>

              <section className="w-full">
                <p className="text-white text-sm text-center mb-5">
                  caso você não tenha recebido o token em seu e-mail, clique
                  aqui para solicitar o envio novamente.
                </p>

                <section className="flex flex-row ">
                  <button
                    disabled={countTime !== 30 && countTime !== 0}
                    onClick={handleAgainNewToken}
                    className={classNames(
                      " w-full flex justify-center mb-5",
                      countTime !== 30 && countTime !== 0
                        ? "text-gray-500"
                        : "text-neon-900"
                    )}
                  >
                    solicitar novo token
                  </button>
                  {/*  */}
                  <button
                    disabled={countTime !== 30 && countTime !== 0}
                    onClick={() => {
                      window.location.href = `${window.location.origin}/login`;
                    }}
                    className=" w-full flex justify-center mb-5 text-neon-900"
                  >
                    voltar
                  </button>
                </section>
                {countTime !== 30 && countTime !== 0 && (
                  <p className="text-white text-center">
                    você poderá solicitar outro código em,{" "}
                    {countTime === 0 ? 30 : countTime} segundos.
                  </p>
                )}
              </section>
            </section>
          </section>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Authenticator;
