import { IonPage, IonContent } from "@ionic/react";
import { useHistory } from "react-router";
import { Input, Button, Header, Toast } from "oialbert-ui";

import LogoWhite from "../../assets/logo-white.svg";

import * as z from "zod";
import useForm from "../../hooks/useForm";
import { useCallback } from "react";
import { sendLinkPassword } from "../../services/auth";
import errorHandling from "../../utils/error_handling";

type RecoverPassword = z.infer<typeof RecoverPasswordSchema>;

export const RecoverPasswordSchema = z.object({
  email: z
    .string({
      required_error: "campo obrigatório",
    })
    .email("e-mail inválido"),
});

const RecoveryPassword = () => {
  const history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<RecoverPassword>({
    defaultValues: { email: "" },
    schema: RecoverPasswordSchema,
  });

  const onSubmit = useCallback(
    async (item: RecoverPassword) => {
      try {
        await sendLinkPassword(item);
        reset();
        Toast.success("e-mail de recuperação enviado com sucesso!");
      } catch (error) {
        errorHandling(error, "erro ao enviar e-mail de recuperação", "crema");
      }
    },
    [reset]
  );

  return (
    <IonPage>
      <IonContent>
        <section className="bg-gray-900 h-full flex flex-col justify-center items-center">
          <section className="flex flex-col mt-10 px-5 pb-5 space-y-5 sm:w-96">
            <img src={LogoWhite} className="h-16" alt="Logo" />
            <Header onClick={() => history.goBack()} title="trocar senha" />
          </section>
          <form
            className="sm:mx-auto sm:w-full sm:max-w-xl"
            onSubmit={handleSubmit(onSubmit)}
          >
            <section className="h-full flex flex-col items-center justify-between">
              <section className="w-full flex-1 flex flex-col items-center px-5 space-y-5">
                <h1 className="text-base text-center font-bold text-white">
                  esqueceu sua senha?
                </h1>
                <p className="w-full text-sm text-white">
                  digite seu endereço de e-mail da sua conta abaixo:
                </p>

                <section className="w-full flex flex-col space-y-4">
                  <Input
                    type="email"
                    {...register("email")}
                    error={errors.email?.message?.toString()}
                    placeholder="E-mail"
                    mode="dark"
                  />
                </section>
                <section className="w-full">
                  <section className="mb-4">
                    <p className="text-sm text-white">
                      você receberá uma e-mail com link para a criação de uma
                      nova senha.
                    </p>
                  </section>
                  <Button full variant="solid" color="neon">
                    enviar Link
                  </Button>
                </section>
              </section>
            </section>
          </form>
        </section>
      </IonContent>
    </IonPage>
  );
};

export default RecoveryPassword;
