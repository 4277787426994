import { useCallback, useEffect, useState } from "react";
import { Button, Input, Icon, TextArea, Radio, Modal } from "oialbert-ui";
import useForm from "../../../hooks/useForm";
import { useFieldArray } from "react-hook-form";
import { CharacteristicSchema } from "../schema";
import type { Characteristic } from "../types";
import CreateOption from "./CreateOption";
import OptionItem from "./OptionItem";

type CreateCharacteristicProps = {
  open: boolean;
  onClose: () => void;
  onSave: (item: Characteristic) => void;
  characteristic?: Characteristic | null;
};

const CreateCharacteristic = ({
  open,
  onClose,
  onSave,
  characteristic,
}: CreateCharacteristicProps) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    watch,
    trigger,
    reset,
    formState: { errors },
  } = useForm<Characteristic>({
    mode: "all",
    defaultValues: {
      title: "",
      description: "",
      is_required: false,
      max: 0,
      min: 0,
      options: [],
    },
    schema: CharacteristicSchema,
  });

  const options = watch("options");
  const [showOption, setOption] = useState(false);
  const [optionIndex, setOptionIndex] = useState(-1);

  const { append, remove, update } = useFieldArray({
    control,
    name: "options",
  });

  const saveCharacteristic = useCallback(
    (data: Characteristic) => {
      reset();
      onSave(data);
      onClose();
    },
    [onClose, onSave, reset]
  );

  useEffect(() => {
    register("is_required");
    trigger();
  }, [register, trigger]);

  useEffect(() => {
    if (characteristic) {
      reset({
        ...characteristic,
      });
    }
  }, [characteristic, reset]);

  return (
    <Modal full open={open} onClose={onClose}>
      {showOption ? (
        <CreateOption
          index={optionIndex}
          onSave={(e) => {
            if (optionIndex !== -1) {
              update(optionIndex, e.options["0"]);
              setOptionIndex(-1);
            } else {
              append(e.options["0"]);
            }
          }}
          option={options.find((_, index) => index === optionIndex)}
          onClose={() => setOption(false)}
        />
      ) : (
        <section className="flex flex-col space-between h-full">
          <button
            onClick={() => onClose()}
            className="ml-auto mr-2 mb-1 text-lg"
          >
            <Icon.MdClose className="w-4 h-4 text-neon-900" />
          </button>
          <div className="px-2 py-1 h-full">
            <form
              id="saveCharacteristic"
              onSubmit={handleSubmit(saveCharacteristic)}
            >
              <div className="mb-4">
                <Input
                  {...register("title")}
                  placeholder="Título da característica"
                  error={errors.title?.message?.toString()}
                />
              </div>
              <div className="mb-4">
                <TextArea
                  {...register("description")}
                  placeholder="Descrição"
                  error={errors.description?.message?.toString()}
                />
              </div>

              <p className="text-base text-black font-bold mb-4">
                Característica obrigatória
              </p>

              <div>
                <div className="flex space-x-4 mb-2">
                  <div className="border border-gray-100 rounded w-full p-4 h-8 flex items-center justify-center">
                    <Radio
                      name="is_required"
                      label="Sim"
                      id="yes"
                      value={"yes"}
                      onClick={() => setValue("is_required", true)}
                      defaultChecked={getValues("is_required")}
                    />
                  </div>
                  <div className="border border-gray-100 rounded w-full p-4 h-8 flex items-center justify-center">
                    <Radio
                      name="is_required"
                      label="Não"
                      id="no"
                      value={"no"}
                      onClick={() => setValue("is_required", false)}
                      defaultChecked={!getValues("is_required")}
                    />
                  </div>
                </div>

                <p className="text-neon-900">
                  {errors.is_required?.message?.toString()}
                </p>
              </div>

              <div className="flex space-x-4 mb-2">
                <Input
                  type="number"
                  label="Qtd miníma"
                  placeholder="Qtd miníma"
                  {...register("min", { valueAsNumber: true })}
                  error={errors.min?.message?.toString()}
                />
                <Input
                  type="number"
                  label="Qtd máxima"
                  placeholder="Qtd máxima"
                  {...register("max", { valueAsNumber: true })}
                  error={errors.max?.message?.toString()}
                />
              </div>

              <span className="mb-2 block text-sm font-medium">Opções</span>
              <section className="space-y-2 border border-dashed border-crema-500 p-2 mb-2 rounded max-h-28 overflow-y-scroll">
                {options.length === 0 ? (
                  <p className="text-coral-900 text-center text-sm">
                    {(errors.options as any)?.message?.toString()}
                  </p>
                ) : (
                  options?.map((option, index) => (
                    <OptionItem
                      key={index}
                      option={option}
                      editOption={(e) => {
                        e.stopPropagation();
                        setOption(true);
                        setOptionIndex(index);
                      }}
                      removeOption={(e) => {
                        e?.stopPropagation();
                        remove(index);
                      }}
                    />
                  ))
                )}
              </section>
            </form>
          </div>

          <div className="space-y-2">
            <Button
              form="saveCharacteristic"
              full
              normal
              variant="solid"
              color="neon"
            >
              <span className="text-sm font-bold">
                SALVAR NOVA CARACTERÍSTICA
              </span>
            </Button>

            <Button
              type="button"
              onClick={() => setOption(true)}
              full
              normal
              variant="light"
              color="neon"
              size="small"
            >
              <span className="flex items-center justify-center">
                <Icon.MdAdd className="mr-1" />
                Adicionar opção
              </span>
            </Button>
          </div>
        </section>
      )}
    </Modal>
  );
};

export default CreateCharacteristic;
