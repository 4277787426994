import { Button, Icon, Pagination } from 'oialbert-ui';
import { formatInCents } from '../../../utils/money';
import { useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading';
import useAuth from '../../../hooks/useAuth';
import AddCartModal from './AddCartModal';
import { useCart } from '../context';
import { Product } from '../../../types/extra-services/product';

const LIMIT = 10;

export const ActiveProduct = () => {
  const { company } = useAuth();
  const [productsMeta] = useState<any>(null);
  const [page, setPage] = useState<number>(productsMeta?.current_page || 1);
  const [isOpenAddCartModal, setIsOpenAddCartModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [isOpenItemDetails, setIsOpenItemDetails] = useState(false);
  const [selectedItemDetails, setSelectedItemDetails] = useState(null);
  const { getCurrentActiveProducts, products, isFetchingActiveProducts } =
    useCart();

  const total: number = productsMeta?.total ?? 0;
  const openAddCartModal = (product: any) => {
    setSelectedProduct(product);
    setIsOpenAddCartModal(true);
  };

  const closeAddCartModal = () => {
    setSelectedProduct(null);
    setIsOpenAddCartModal(false);
  };

  const toggleItemDetails = (item: any, identifier: any) => {
    setSelectedItemDetails(
      selectedItemDetails === identifier ? null : identifier
    );
    setIsOpenItemDetails(!selectedItemDetails);
  };

  useEffect(() => {
    (async () => {
      if (company?.id) {
        getCurrentActiveProducts(company?.id);
      }
    })();
  }, [company?.id]);

  return (
    <>
      {isFetchingActiveProducts ? (
        <Loading />
      ) : (
        <>
          {!products?.length ? (
            <section className="w-full flex justify-center py-4">
              <h3 className="text-center text-lg text-gray-500">
                nenhum produto ativo encontrado
              </h3>
            </section>
          ) : (
            <>
              {products.map((product: Product) => (
                <section
                  key={`products_${product.id}`}
                  className={`flex flex-col justify-between shadow-gray-50 shadow-sm border-2 rounded-lg ${
                    product.on_cart
                      ? 'border-neon-900 bg-neon-900'
                      : 'border-gray-100 bg-gray-100'
                  }`}
                  style={{
                    minHeight: '24rem',
                  }}
                >
                  <AddCartModal
                    isOpen={
                      isOpenAddCartModal && product.id === selectedProduct
                    }
                    selectedProducts={product.id}
                    closeModal={closeAddCartModal}
                    afterSave={() => {
                      if (company?.id) {
                        getCurrentActiveProducts(company.id);
                      }
                    }}
                  />

                  <section className="w-full pb-2">
                    <h1
                      className={`text-xl ${
                        product.on_cart && 'text-white'
                      } font-bold mt-6 mb-1 text-center flex items-center justify-center h-16`}
                    >
                      {`${product.name}`}
                    </h1>
                    <div
                      className={`text-sm text-center mb-6 ${
                        product.on_cart ? 'text-white' : 'text-gray-400'
                      } lowercase break-all flex items-center justify-center h-10`}
                    >
                      {`${product.description}`}
                    </div>
                    {product.on_cart ? (
                      <p className="text-center text-xs px-2 pt-2 pb-1.5 mb-4 items-center">
                        <span
                          className={`w-full font-bold text-white opacity-50 uppercase`}
                        >
                          adicionado
                        </span>
                      </p>
                    ) : (
                      <p className="text-center text-xl px-2 mb-4 items-center">
                        <span
                          className={`w-full font-bold text-neon-900 uppercase`}
                        >
                          {formatInCents(product.price_in_cents)}
                        </span>
                      </p>
                    )}
                  </section>

                  <section className="w-full bg-white h-64">
                    {product.items.map((item: any, idx: number) => (
                      <>
                        <section
                          className="flex cursor-pointer justify-between p-3 text-gray-600 hover:text-neon-900 focus:text-neon-900 "
                          key={`itens_${idx}`}
                          onClick={() =>
                            toggleItemDetails(item, `${item.id}-${product.id}`)
                          }
                        >
                          <Icon.MdCheck className="flex text-xs w-4 h-4 mr-2 text-neon-900" />
                          <div className="flex text-xs w-full">{item.name}</div>
                          {isOpenItemDetails &&
                          `${item.id}-${product.id}` === selectedItemDetails ? (
                            <Icon.MdOutlineKeyboardArrowUp className="flex text-sm w-4 h-4 text-neon-900" />
                          ) : (
                            <Icon.MdOutlineKeyboardArrowDown className="flex text-sm w-4 h-4 text-neon-900" />
                          )}
                        </section>
                        <div
                          className={`w-full p-4 text-xs text-gray-600 bg-gray-100 ${
                            isOpenItemDetails &&
                            `${item.id}-${product.id}` === selectedItemDetails
                              ? ''
                              : 'hidden'
                          }`}
                        >
                          {item.description}
                        </div>
                      </>
                    ))}
                  </section>

                  {product.on_cart ? (
                    <div
                      className="rounded-b text-center p-3 bg-gray-100 items-center"
                      style={{
                        borderTopLeftRadius: '0',
                        borderTopRightRadius: '0',
                      }}
                    >
                      <Icon.MdCheck className="flex text-sm w-full h-6 text-neon-900" />
                    </div>
                  ) : (
                    <Button
                      type="button"
                      onClick={() => openAddCartModal(product.id)}
                      normal
                      variant="solid"
                      color="neon"
                      className="rounded-b"
                      style={{
                        borderTopLeftRadius: '0',
                        borderTopRightRadius: '0',
                      }}
                    >
                      contratar
                    </Button>
                  )}
                </section>
              ))}
              {total > LIMIT && (
                <section className="flex justify-center mt-4">
                  <Pagination
                    onPrev={() => setPage(page - 1)}
                    onNext={() => setPage(page + 1)}
                    onPage={setPage}
                    page={page}
                    total={total}
                    perPage={productsMeta.per_page}
                    variant='secondary'
                  />
                </section>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
