import { useContext } from 'react';
import { WalletContext } from '../contexts/WalletContext';

import type { Wallet } from '../types/wallets';
import type { Transaction } from '../types/transactions';
import type { Deposit } from '../types/deposits';
import type { Withdrawals } from '../types/withdrawals';
import type { BankAccount } from '../types/bankAccounts';
import type { Bank } from '../types/banks';

import type { CreateWithdrawalsProps } from '../services/types/withdrawals';

export type UseWalletProps = {
  getWallets: (_value?: string) => void;
  getTransactions: () => void;
  getTransactionsOrders: () => void;
  getDeposits: () => void;
  getBalanceCharges: () => void;
  getWithdraws: () => void;
  onCreateWithdraw: (_payload: CreateWithdrawalsProps) => void;
  createdWithdrawal: boolean;
  getBankAccounts: () => void;
  getBanks: () => void;
  onCreateBank: (_payload: BankAccount['bank_account']) => void;
  transactions: Transaction[];
  transactionsOrders: Transaction[];
  deposits: Deposit[];
  balanceCharge: Deposit[];
  wallets: Wallet | null | any;
  withdrawals: Withdrawals[];
  bankAccounts: BankAccount[];
  banks: Bank[];
  loading: boolean;
  loadingTransactions: boolean;
  loadingTransactionsOrders: boolean;
  loadingDeposits: boolean;
  loadingBalanceCharges: boolean;
  loadingWithdrawals: boolean;
  loadingBankAccounts: boolean;
  loadingBanks: boolean;
  loadingCreateBanks: boolean;
  loadingCreateWithdrawal: boolean;
  pageDeposits: number;
  totalDeposits: number;
  setPageBalanceCharges: (_page: number) => void;
  pageBalanceCharges: number;
  totalBalanceCharge: number;
  setPageDeposits: (_page: number) => void;
  pageTransactions: number;
  totalTransactions: number;
  setPageTransactions: (_page: number) => void;
  pageWithdraws: number;
  totalWithdraws: number;
  setPageWithdraws: (_page: number) => void;
  pageTransactionsOrders: number;
  totalTransactionsOrders: number;
  setPageTransactionsOrders: (_page: number) => void;
};

const useWallet = (): UseWalletProps => {
  const context = useContext(WalletContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export default useWallet;
