import { Icon } from 'oialbert-ui';
import { formatInCents } from '../../../../utils/money';
import { formatShort } from '../../../../utils/date';
import { Withdrawals } from '../../../../types/withdrawals';

type ExtractItemProps = {
  data: Withdrawals;
};

export const ExtractItem = ({ data }: ExtractItemProps) => (
  <section className="flex py-4 m-5">
    <Icon.MdOutlineArrowCircleUp className="w-6 h-6 text-neon-900" />
    <section className="ml-2 flex-1">
      <h4 className="text-base font-bold mb-3.5">pedido de saque</h4>
      <p className="text-sm">{data.bank.bank_account.account_name}</p>
      <p className="text-sm">
        valor:{' '}
        <span className="font-bold uppercase">
          {formatInCents(data.value_in_cents)}
        </span>
      </p>
    </section>
    <section className="ml-2 text-right">
      <span className="flex items-center text-sm text-gray-500 mb-4 justify-end lowercase">
        {data.status === 'paid' && (
          <>
            <Icon.MdCheckCircleOutline className="w-4 h-4 mr-2 text-success-500" />
            pago
          </>
        )}
        {data.status === 'pending' && (
          <>
            <Icon.MdTimelapse className="w-4 h-4 mr-2 text-warning-500" />
            pendente
          </>
        )}
        {data.status === 'processing' && (
          <>
            <Icon.MdTimelapse className="w-4 h-4 mr-2 text-warning-500" />
            processando
          </>
        )}
        {data.status === 'refused' && (
          <>
            <Icon.MdRemoveCircle className="w-4 h-4 mr-2 text-neon-900" />
            recusado
          </>
        )}
      </span>
      {data.created_at && (
        <p className="text-xs text-gray-500 uppercase">
          {formatShort(data.created_at, false)}
        </p>
      )}
    </section>
  </section>
);
