import { IonContent, IonHeader, IonPage, IonSpinner } from '@ionic/react';
import { useParams } from 'react-router';
import useAuth from '../../hooks/useAuth';
import OnboardingImage from '../../assets/illustrations/onboarding.png';
import { Button, Header } from 'oialbert-ui';
import { useEffect, useMemo, useState } from 'react';
import Profile from './components/Profile';
import PaymentTypes from './components/PaymentTypes';
import Delivery from './components/Delivery';
import Balance from './components/Balance';
import { useCompany } from '../../hooks/useCompany';

const Onboarding = () => {
  const { companyId }: { companyId: string } = useParams();
  const { user } = useAuth();

  const [companyOnboarding, setCompanyOnboarding] = useState<any>({});

  const [startOnboarding, setStartOnboarding] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);

  const { company, loadingCompany, getCompany } = useCompany();
  const Steps = useMemo(
    () => [
      {
        key: 'profile',
        title: 'preencha com os dados da sua loja',
        component: (
          <Profile
            data={company}
            dataTemp={companyOnboarding}
            setDataTemp={setCompanyOnboarding}
            onNext={() => setStep(step + 1)}
          />
        ),
      },
      {
        key: 'payment_types',
        title: 'formas de pagamento',
        component: (
          <PaymentTypes
            dataTemp={companyOnboarding}
            setDataTemp={setCompanyOnboarding}
            onNext={() => setStep(step + 1)}
          />
        ),
      },
      {
        key: 'has_delivery',
        title: 'delivery',
        component: (
          <Delivery
            dataTemp={companyOnboarding}
            setDataTemp={setCompanyOnboarding}
            onNext={() => setStep(step + 1)}
          />
        ),
      },
      {
        key: 'balance',
        title: 'parabéns!',
        component: <Balance />,
      },
    ],
    [company, companyOnboarding, step]
  );

  useEffect(() => {
    startOnboarding && getCompany(companyId);
  }, [companyId, getCompany, startOnboarding]);

  useEffect(() => {
    return () => {
      setStartOnboarding(false);
    };
  }, []);

  return (
    <IonPage>
      {startOnboarding && (
        <IonHeader className="ion-no-border bg-white">
          <section className="mt-10 px-5 pb-5">
            <Header
              onClick={() =>
                step === 0 ? setStartOnboarding(false) : setStep(step - 1)
              }
              title={Steps[step].title}
            />
          </section>
        </IonHeader>
      )}
      <IonContent>
        {startOnboarding ? (
          <>
            {loadingCompany ? (
              <section className="flex flex-col bg-white w-full h-full items-center justify-center">
                <IonSpinner className="text-neon-900" />
              </section>
            ) : (
              Steps[step].component
            )}
          </>
        ) : (
          <section className="w-full h-full flex flex-col items-center justify-center space-y-6">
            <h1 className="text-lg">bem–vindo ao albert</h1>
            <h2 className="text-lg">
              olá,{' '}
              <span className="text-neon-900">{user?.partner.full_name}</span>
            </h2>
            <img src={OnboardingImage} className="h-28" alt="Onboarding" />
            <section className="flex flex-col items-center space-y-6">
              <section className="w-64">
                <p className="text-lg text-center">
                  estamos completos agora que <strong>você chegou</strong> 🙂
                </p>
              </section>
              <section className="w-80">
                <p className="text-lg text-center">
                  antes de mais nada, é necessário cadastrar os dados da sua
                  empresa. é simples e não demora nada, vamos lá?!
                </p>
              </section>
            </section>
            <section className="flex items-center justify-center">
              <Button
                color="neon"
                variant="solid"
                onClick={() => setStartOnboarding(true)}
              >
                começar
              </Button>
            </section>
          </section>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Onboarding;
