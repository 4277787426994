import { api } from './config';

export const guides = async () => {
  const { status, data } = await api.get('/v1/partners/guides');

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const guideId = async (id: any): Promise<any> => {
  const { status, data } = await api.get(`/v1/partners/guides/${id}`);

  if (status === 200 && data) {
    return data;
  }

  return false;
};
