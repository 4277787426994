import { IonImg, useIonAlert, useIonLoading } from '@ionic/react';
import { Icon, Toast } from 'oialbert-ui';
import useAuth from '../../hooks/useAuth';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCameraGallery } from '../../contexts/CameraGalleryContext';
import { createPhoto, deletePhoto } from '../../services/gallery';
import errorHandling from '../../utils/error_handling';
import type { CompanyDetailsData } from '../../types/companies';
import { LayoutBase } from '../../components/LayoutBase';

const Gallery = () => {
  const [present] = useIonAlert();
  const [presentLoading, dismissLoading] = useIonLoading();
  const { company, setCompany } = useAuth();
  const { openActions, clearResource, resource } = useCameraGallery();

  const [photo, setPhoto] = useState<any>(null);

  const gallery: Array<CompanyDetailsData['company_gallery'] | any> = useMemo(
    () => company?.company_gallery ?? [],
    [company?.company_gallery]
  );

  const onUpload = useCallback(async () => {
    if (!company) return;

    presentLoading({
      message: 'carregando imagem...',
      keyboardClose: false,
      backdropDismiss: false,
    });

    try {
      const photoData = await createPhoto(company.id, photo);
      Toast.success('imagem adicionada');
      setTimeout(() => {
        setCompany({
          ...company,
          company_gallery: [...company?.company_gallery, photoData?.photo[0]],
        });

        dismissLoading();
      }, 2000);
    } catch (error) {
      errorHandling(error, 'ocorreu um erro ao adicionar imagem', 'crema');
    }
  }, [company, dismissLoading, photo, presentLoading, setCompany]);

  const onDelete = useCallback(
    async (photoId: string) => {
      if (!company) return;

      try {
        await deletePhoto(company.id, photoId);
        Toast.success('imagem excluída');
        setCompany({
          ...company,
          company_gallery: company?.company_gallery.filter(
            ({ id }) => id !== photoId
          ),
        });
      } catch (error) {
        errorHandling(error, 'ocorreu um erro ao excluir imagem', 'crema');
      }
    },
    [company, setCompany]
  );

  useEffect(() => {
    const loadImage = async () => {
      if (resource) {
        const image = await fetch(resource?.[0].webPath as string);
        const blob = await image.blob();
        setPhoto(blob as any);
      } else {
        clearResource();
      }
    };

    loadImage();
  }, [resource, clearResource, gallery]);

  useEffect(() => {
    photo && onUpload();

    return () => {
      clearResource();
      setPhoto(null);
    };
  }, [clearResource, photo, onUpload]);

  return (
    <LayoutBase title="Galeria">
      <section className="mt-5 px-5">
        <h3 className="text-sm font-bold">
          esta galeria aparecerá dentro da sua loja
        </h3>
        <p className="text-xs text-gray-500 font-normal">limite de 12 fotos</p>
      </section>
      <section className="my-5 px-5 grid grid-cols-4 gap-2.5">
        {gallery.length < 12 && (
          <button
            type="button"
            className="flex flex-col items-center justify-center bg-white shadow-lg border border-gray-100 border-opacity-50 w-full h-40 rounded-xl text-gray-900 font-bold"
            onClick={() => openActions()}
          >
            <section className="flex flex-col items-center justify-center bg-neon-900 text-white p-2 rounded-full mb-2">
              <Icon.MdAdd />
            </section>
            adicionar
          </button>
        )}

        {!gallery?.length
          ? [0, 1, 2, 3, 5].map((photo) => (
              <div
                key={`photo_${photo}`}
                className="flex flex-col items-center justify-center bg-white shadow-lg border border-gray-100 border-opacity-50 w-full h-40 rounded-xl text-gray-900 font-bold opacity-50"
              />
            ))
          : gallery.map((photo) => (
              <section
                key={`photo_${photo}`}
                className="shadow-lg w-full h-40 rounded-xl overflow-hidden relative"
              >
                <section
                  className="absolute top-2 right-2 cursor-pointer z-10"
                  onClick={() =>
                    present({
                      header: 'excluir',
                      message: 'deseja realmente excluir esta imagem?',
                      buttons: [
                        'Não',
                        {
                          text: 'Sim',
                          handler: () => onDelete(photo.id),
                        },
                      ],
                    })
                  }
                >
                  <Icon.MdOutlineDelete className="text-white text-1xl bg-gray-800 bg-opacity-20 rounded-lg p-2 w-8 h-8" />
                </section>
                <IonImg
                  src={photo.url}
                  alt="imagem"
                  className="w-full h-40 object-cover"
                />
              </section>
            ))}
      </section>
    </LayoutBase>
  );
};

export default Gallery;
