import { useCallback, useState } from 'react';

import modalIllustration from '../../../../assets/illustrations/modalfrontimage.png';

import { Button } from 'oialbert-ui';

const ModalTutorial: React.FC<ModalTutorialProps> = ({ isOpen, onClose }) => {
  const [, setDisplaySlide] = useState<boolean>(false);
  const [shouldFinishModal] = useState<boolean>(false);

  // const handleFinishTutorial = useCallback(() => {
  //   setDisplaySlide(false);
  //   setShouldFinishModal(true);
  //   onClose();
  // }, [onClose]);

  const handleOpenSlide = useCallback(() => {
    setDisplaySlide(true);
  }, []);

  return isOpen && !shouldFinishModal ? (
    <div className="fixed top-0 left-0 z-20 bg-black bg-opacity-50 w-screen h-screen flex flex-col justify-center text-center">
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 px-7 bg-white rounded-md py-2">
        <div
          className="mx-auto rounded-full"
          style={{
            width: 125,
            height: 125,
          }}
        >
          <img
            src={modalIllustration}
            alt="tutorial"
            className="w-full h-full object-contain"
          />
        </div>

        <p className="my-3">
          acesse o <span className="text-neon-900">tutorial albert </span> para
          não ficar nenhuma dúvida durante suas compras :)
        </p>

        <div className="flex flex-col">
          <Button
            className="w-full flex items-center justify-center"
            variant="light"
            color="neon"
            onClick={handleOpenSlide}
            style={{
              height: 50,
            }}
          >
            <p className="text-neon font-normal">Ir para Tutorial</p>
          </Button>

          <Button
            className="w-full flex items-center justify-center"
            variant="light"
            color="crema"
            onClick={onClose}
            style={{
              height: 50,
            }}
          >
            <p className="text-black font-normal">cancelar</p>
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};
export default ModalTutorial;
