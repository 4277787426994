import { useState, useCallback } from 'react';
import { Toast } from 'oialbert-ui';
import { createOffer, getOffer, offers, updateOffer } from '../services/offers';
import errorHandling from '../utils/error_handling';

export const useOffers = () => {
  const [dataOffers, setDataOffers] = useState<[]>([]);
  const [dataOffer, setDataOffer] = useState<{
    id: string;
    photo: any;
    product: any;
    start_date: string;
    end_date: string;
    value_in_cents: number;
    price_cashback_in_cents: number;
  }>({} as any);

  const [loadingOffers, setLoadingOffers] = useState<boolean>(false);
  const [loadingOffer, setLoadingOffer] = useState<boolean>(false);
  const [loadingCreateOffer, setLoadingCreateOffer] = useState<boolean>(false);
  const [loadingUpdateOffer, setLoadingUpdateOffer] = useState<boolean>(false);

  const getDataOffers = useCallback(async () => {
    setLoadingOffers(true);
    try {
      const response = await offers();
      setDataOffers(response);
    } catch (err) {
      errorHandling(err, 'erro ao buscar ofertas', 'crema');
    } finally {
      setLoadingOffers(false);
    }
  }, []);

  const getDataOffer = useCallback(async (id) => {
    setLoadingOffer(true);
    try {
      const response = await getOffer(id);
      setDataOffer(response);
    } catch (err) {
      errorHandling(err, 'erro ao buscar oferta', 'crema');
    } finally {
      setLoadingOffer(false);
    }
  }, []);

  const create = useCallback(async (payload) => {
    setLoadingCreateOffer(true);
    try {
      await createOffer(payload);
      Toast.success('oferta criada');
    } catch (err) {
      errorHandling(err, 'erro ao criar oferta', 'crema');
    } finally {
      setLoadingCreateOffer(false);
    }
  }, []);

  const update = useCallback(async (id, payload) => {
    setLoadingUpdateOffer(true);
    try {
      await updateOffer(id, payload);
      Toast.success('oferta editada');
    } catch (err) {
      errorHandling(err, 'erro ao editar oferta', 'crema');
    } finally {
      setLoadingUpdateOffer(false);
    }
  }, []);

  return {
    getOffers: getDataOffers,
    offers: dataOffers,
    loadingOffers,
    getOffer: getDataOffer,
    offer: dataOffer,
    loadingOffer,
    create,
    loadingCreateOffer,
    update,
    loadingUpdateOffer,
  };
};
