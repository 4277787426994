import { useHistory } from 'react-router';
import { Location } from 'history';
import { Header } from 'oialbert-ui';

import { BalanceItem } from '../Financial/components/BalanceItem';

import Withdrawal from './components/Withdrawal';
import Deposit from './components/Deposit';
import { LayoutBase } from '../../components/LayoutBase';

export type ReceiptLocationState = {
  value_in_cents: number;
  account_document_number?: string;
  account_number?: string;
  account_agency?: string;
  payment_type?: string;
  id?: string;
  context: string;
  order: any;
};

interface ReceiptProps {
  location: Location<ReceiptLocationState>;
}

const ComponentsContext: any = {
  withdrawal: Withdrawal,
  deposit: Deposit,
};

const Receipt = ({ location }: ReceiptProps) => {
  const history = useHistory();

  const {
    value_in_cents,
    account_document_number,
    account_number,
    account_agency,
    payment_type,
    id,
    context,
    order,
  }: ReceiptLocationState = location.state;

  const Component = ComponentsContext[context];

  return (
    <LayoutBase
      title="Comprovante"
      header={
        <section className="mt-10 px-5 pb-5">
          <Header
            onClick={() =>
              context === 'deposit'
                ? history.push('/financial')
                : history.goBack()
            }
            title="Comprovante"
          />
        </section>
      }
    >
      <section className="mt-4 px-5">
        <BalanceItem />
      </section>

      <Component
        data={{
          value_in_cents,
          account_document_number,
          account_agency,
          account_number,
          payment_type,
          id,
          order,
        }}
      />
    </LayoutBase>
  );
};

export default Receipt;
