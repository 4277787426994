import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Input, Select } from 'oialbert-ui';

import useAuth, { UseAuthProps } from '../../hooks/useAuth';
import { CompanyDetailsData } from '../../types/companies';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCompany } from '../../hooks/useCompany';
import errorHandling from '../../utils/error_handling';
import { LayoutBase } from '../../components/LayoutBase';
import { api } from '../../services/config';
import { Panel } from '../CreatePromotion/components/Panel';
import { cashbackGratuityAdjustments } from '../../types/constants/options/cashback-gratuity-adjustments';

type AuthProps = {
  company: CompanyDetailsData | any;
  setCompany: (_company: UseAuthProps['company']) => void;
};

type Distribuition = {
  total_cashback_percent_offered_to_customer: number;
  total_cashback_percent_offered_to_network: number;
  total_cashback_percent_offered: number;
};

const Cashback = () => {
  const { company, setCompany }: AuthProps = useAuth();
  const { updateCompany, loadingUpdateCompany } = useCompany();
  const [distribuition, setDistribuition] = useState<Distribuition>({
    total_cashback_percent_offered_to_customer: 0,
    total_cashback_percent_offered_to_network: 0,
    total_cashback_percent_offered: 0,
  });

  const validationForm = useMemo(
    () =>
      z.object({
        cashback_default_in_percent: z
          .number({
            required_error: 'campo obrigatório',
            invalid_type_error: 'campo obrigatório',
          })
          .min(company.minCashback, {
            message: `limite mínimo é ${company.minCashback}%`,
          })
          .max(70, { message: 'limite máximo é 70%' })
          .optional()
          .default(2),
        min_purchase_multiplier: z.string().nonempty({ message: 'campo obrigatório' }),
      }),
    [company.minCashback],
  );

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: zodResolver(validationForm),
    defaultValues: {
      cashback_default_in_percent: company?.cashback_default_in_percent,
      min_purchase_multiplier: company?.min_purchase_multiplier,
    },
  });

  const onSubmitForm = useCallback(
    async (formData) => {
      const data = {
        id: company.id,
        cashback_default_in_percent: formData.cashback_default_in_percent,
        min_purchase_multiplier: formData.min_purchase_multiplier,
      };

      try {
        await updateCompany(data, (companyData: CompanyDetailsData) => {
          setCompany({
            ...company,
            ...companyData,
            ...data,
            ...formData,
          });
        });
      } catch (error) {
        errorHandling(error, 'erro ao salvar a porcentagem de cashback padrão', 'coral');
      }
    },
    [company, setCompany, updateCompany],
  );

  const percent = watch('cashback_default_in_percent') || 0;

  const getDistribuition = useCallback(async (cashback_percent: any) => {
    const { data } = await api.get<Distribuition>(`/v2/partners/cashback/compute/distribution`, {
      params: {
        order_cashback_percent: cashback_percent,
        order_in_cents: 0,
      },
    });
    setDistribuition(data);
  }, []);

  useEffect(() => {
    getDistribuition(percent);
  }, [getDistribuition, percent]);

  return (
    <LayoutBase title="ajustes de cashback">
      <section className="flex flex-col space-y-4 my-5">
        <form
          className="w-full flex flex-col mt-5 px-5 space-y-4"
          onSubmit={handleSubmit(onSubmitForm)}
        >
          <div>
            <Select
              required
              label="Ajustes de Cashback e Gratificação"
              placeholder="selecione um ajuste"
              options={cashbackGratuityAdjustments.options}
              error={errors.min_purchase_multiplier?.message?.toString()}
              {...register('min_purchase_multiplier')}
            />
          </div>
          <div className="mb-4">
            <Input
              label="cashback padrão (em %)"
              placeholder="Cashback padrão %"
              disabled={loadingUpdateCompany}
              inputMode="decimal"
              required
              type="number"
              step="any"
              error={errors.cashback_default_in_percent?.message?.toString()}
              {...register('cashback_default_in_percent', {
                valueAsNumber: true,
              })}
            />
          </div>
          <section className={'mt-4 mb-5'}>
            <h3 className="text-base font-bold text-neon-700 mb-2">cálculo do cashback</h3>

            <div className="h-px bg-crema-500 mt-4 mb-4"></div>

            <div className="flex mb-4">
              <p className="font-bold text-gray-700 text-sm flex-grow">cashback total:</p>

              <p className="text-sm font-bold text-gray-500  uppercase">
                {distribuition.total_cashback_percent_offered}%
              </p>
            </div>

            <div className="flex mb-4">
              <p className="font-bold text-gray-700 text-sm flex-grow">cashback da rede:</p>

              <p className="text-sm font-bold text-gray-500  uppercase">
                {distribuition.total_cashback_percent_offered_to_network}%
              </p>
            </div>

            <div className="flex mb-4">
              <p className="font-bold text-gray-700 text-sm flex-grow">cashback do associado:</p>

              <p className="text-sm font-bold text-gray-500  uppercase">
                {Math.round(
                  Number(distribuition.total_cashback_percent_offered_to_customer) * 100,
                ) / 100}
                %
              </p>
            </div>

            <div className="normal-case w-full shadow-lg p-4 rounded-lg bg-crema-500 text-yellow-900 items-center w-full mt-1 mb-4 px-4">
              <span className="normal-case text-sm text-yellow-900 font-bold">ATENÇÃO: </span>
              <span className="normal-case text-sm text-yellow-900">
                É possível que o sistema arredonde alguns valores, para cima ou para baixo, devido
                às casas decimais geradas pela definição da porcentagem.
              </span>
            </div>
          </section>
          <Button
            type="submit"
            full
            disabled={loadingUpdateCompany || !isValid}
            variant="solid"
            color="neon"
          >
            {loadingUpdateCompany ? 'Salvando...' : 'Salvar'}
          </Button>
        </form>
      </section>
    </LayoutBase>
  );
};

export default Cashback;
