import { useEffect, useMemo } from 'react';

import useAuth from '../../../hooks/useAuth';

import { Loading } from '../../../components/Loading';

import { useHistory } from 'react-router';
import { useQuery } from '../../../utils/useQuery';
import errorHandling from '../../../utils/error_handling';

type DataTokenProps = {
  token?: string;
  document?: string;
};

const TokenValidator = () => {
  const { signIn } = useAuth();
  const history = useHistory();
  const query = useQuery();

  const data: DataTokenProps = useMemo(() => {
    const token = query.get('token');
    const newToken = token?.slice(-5);

    const restLength = token!.length - newToken!.length;
    const document = token?.substr(0, restLength);
    return {
      token: newToken,
      document,
    };
  }, [query]);

  useEffect(() => {
    const fetchData = async () => {
      const token = data.token;
      const document = data.document;
      if (token && document) {
        const status: any = await signIn(token, document);
        if (status === 200) history.push('/select-company');
        if (status !== 200) history.push(`/authenticator/${document}`);
      }
      if (!token && !document) {
        errorHandling(
          null,
          'desculpe-nos, o link fornecido não corresponde ao esperado.',
          'crema'
        );
        history.push('/login');
      }
    };
    fetchData().catch();
  }, [data, history, signIn]);

  return <Loading />;
};

export default TokenValidator;
