import { Button, Card, Icon, Modal, Toast, IconBs } from "oialbert-ui";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";

import { LayoutBase } from "../../components/LayoutBase";
import { deleteCard, listCards } from "../../services/cards";
import type { Card as CardType } from "../../types/card";
import { Loading } from "../../components/Loading";
import useAuth from "../../hooks/useAuth";
import { api } from "../../services/config";
import { Invoice } from "../../types/invoice";
import { useCompany } from "../../hooks/useCompany";

const Cards = () => {
  const history = useHistory();
  const { company } = useAuth();
  const { getCompany } = useCompany();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [cards, setCards] = useState<CardType[]>();
  const [card, setCard] = useState<CardType>();
  const [invoice, setInvoice] = useState<Invoice>();

  const getCards = useCallback(async () => {
    try {
      setLoading(true);

      let companyData = company;

      if (company && !company?.login_item_invoice?.id) {
        const response = await getCompany(company.id);
        companyData = response?.company;
      }

      if (
        companyData &&
        (companyData?.login_item_invoice?.id ||
          companyData?.company?.login_item_invoice?.id)
      ) {
        const { data } = await api.get<Invoice>(
          `/v2/partners/gateway/invoices/${
            companyData?.login_item_invoice.id ||
            companyData?.company?.login_item_invoice.id
          }`
        );

        setInvoice(data);

        if (data.payment_method === "credit_card") {
          const response = await listCards();
          setCards(response);
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);

  function handleDeleteCard(id: string) {
    deleteCard(id)
      .then(() => {
        Toast.success("Cartão excluído com sucesso");
        getCards();
      })
      .catch((err) => {
        Toast.crema(
          "",
          err.response?.data?.message || "Erro ao excluir cartão"
        );
      })
      .finally(() => {
        setCard(undefined);
        setOpen(false);
      });
  }

  useEffect(() => {
    getCards();
  }, [company]);

  return (
    <>
      <LayoutBase title="Informações de pagamento">
        <section className="mt-5 px-5">
          <section className="space-y-4">
            {loading && (
              <div className="flex items-center">
                <Loading text="carregando..." transparent />
              </div>
            )}

            {invoice?.payment_method === "credit_card" ? (
              <>
                {cards?.map((card) => (
                  <Card key={card.id} className="rounded py-2 px-4">
                    <section className="flex items-center justify-between py-4">
                      <h4 className="text-sm flex-1 ml-4 mr-2">
                        {`**** ${card.last_four_digits}`}
                      </h4>
                      <section className="flex items-center space-x-4">
                        <button
                          onClick={() => history.push("/card/add")}
                          className="flex items-center"
                        >
                          <IconBs.BsCreditCardFill className="w-4 h-4 text-neon-900" />
                          <span className="text-xs text-neon-900 ml-2">
                            alterar
                          </span>
                        </button>
                        <button
                          onClick={() => {
                            setCard(card);
                            setOpen(true);
                          }}
                          className="flex items-center"
                        >
                          <Icon.MdOutlineDelete className="w-4 h-4 text-neon-900" />
                          <span className="text-xs text-neon-900 ml-2">
                            excluir
                          </span>
                        </button>
                      </section>
                    </section>
                  </Card>
                ))}
              </>
            ) : (
              <>
                <Card className="rounded py-2 px-4">
                  <section className="flex items-center justify-between py-4">
                    <h4 className="text-sm flex-1 ml-4 mr-2">
                      {invoice?.payment_method}
                    </h4>
                    <section className="flex items-center space-x-4"></section>
                  </section>
                </Card>
              </>
            )}
          </section>
        </section>
        <section className="mt-4 px-5">
          <Button
            onClick={() =>
              history.push("/card/paymentMethod/add", { ...invoice })
            }
            variant="solid"
            color="neon"
            full
            disabled={loading ? true : false}
          >
            Adicionar forma de pagamento
          </Button>
        </section>
      </LayoutBase>
      <Modal open={open} onClose={() => setOpen(false)} height={210}>
        <section className="text-black text-center mb-10 space-y-4">
          <h3 className="text-base font-bold">Excluir cartão</h3>
          <p className="w-9/12 text-base mx-auto">
            Você está prestes a excluir o cartão terminado em{" "}
            <span className="font-bold">{card?.last_four_digits}</span>
          </p>
        </section>
        <section className="flex items-center">
          <section className="flex-1">
            <Button
              full
              normal
              variant="outline"
              color="neon"
              onClick={() => {
                if (card?.id) {
                  handleDeleteCard(card.id);
                }
              }}
            >
              Excluir
            </Button>
          </section>
          <section className="flex-1">
            <Button
              full
              normal
              variant="light"
              color="neon"
              onClick={() => setOpen(false)}
            >
              Cancelar
            </Button>
          </section>
        </section>
      </Modal>
    </>
  );
};

export default Cards;
