import { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import { getCompanyById as companyService } from '../../../services/companies';
import { format } from 'date-fns';
import { copyToClipboard } from '../../../utils/copy';
import { Button, Toast } from 'oialbert-ui';

export default function TableTokenApi() {
  const { company } = useAuth();
  const [currentCompany, setCurrentCompany] = useState(company);

  const formatDate = (dateString: string | number | Date) => {
    try {
      const date = new Date(dateString);
      return format(date, 'dd/MM/yyyy HH:mm:ss');
    } catch (error) {
      console.error('Erro ao formatar data:', error);
      return 'Data inválida';
    }
  };

  const handleCopyToken = async () => {
    if (currentCompany?.company?.publicApiToken?.token) {
      await copyToClipboard(currentCompany?.company?.publicApiToken?.token);
      Toast.success('Token copiado');
    }
  };

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const fetchedCompany = company && (await companyService(company?.id));
        setCurrentCompany(fetchedCompany);
      } catch (error) {
        console.error('Erro ao buscar a empresa:', error);
      }
    };

    fetchCompany();
  }, [company]);

  if (!currentCompany?.company?.publicApiToken?.token) {
    return null;
  }
  return (
    <div className="px-0">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 pt-10">
            último token gerado
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            acompanhe seu último token gerado
          </p>
        </div>
      </div>
      <div className="mt-8 flow-root ">
        <section className="">
          {currentCompany && (
            <div className="bg-gray-50 shadow sm:rounded-lg border-2 w-76 mr-14 divide-y-2">
              <h1 className="px-6 pt-2 text-md font-semibold">token</h1>
              <div className="px-2 pt-1 pl-6 ">
                <div className="w-42 mr-6">
                  {currentCompany?.company?.publicApiToken?.token}
                </div>
                <div className="pt-4">
                  <span className="text-md font-bold text-gray-800 ">data</span>
                  <div>
                    <p className="text-md font-md uppercase">
                      {formatDate(
                        currentCompany?.company?.publicApiToken?.created_at
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="mt-4">
            <Button
              onClick={handleCopyToken}
              variant="solid"
              color="neon"
              normal
            >
              Copiar token
            </Button>
          </div>
        </section>
      </div>
    </div>
  );
}
