import { Tab } from 'oialbert-ui';
import { useState } from 'react';
import { LayoutBase } from '../../components/LayoutBase';
import { Associates } from './components/Associates';
import { PreRegistration } from './components/PreRegistration';

const FinancialOrders = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);

  const tabs = [
    { title: 'Associados', component: <Associates /> },
    { title: 'Pré-cadastro', component: <PreRegistration /> },
  ];

  return (
    <LayoutBase title="Pedidos">
      <Tab
        tabs={tabs.map((tab, index) => ({ title: tab.title, index }))}
        onSelect={({ index }) => setCurrentTab(index)}
      />
      {tabs[currentTab].component}
    </LayoutBase>
  );
};

export default FinancialOrders;
