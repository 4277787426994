import { format } from 'date-fns';
import { Button, Icon, Input, Toast } from 'oialbert-ui';
import * as Api from '../../services/invoices';

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaCartPlus, FaFileInvoiceDollar } from 'react-icons/fa6';
import { MdCreditCard, MdPix } from 'react-icons/md';
import { PiBarcode } from 'react-icons/pi';
import { RiCoupon2Line } from 'react-icons/ri';
import { TbCalendarDollar } from 'react-icons/tb';
import { LayoutBase } from '../../components/LayoutBase';
import { listInvoices } from '../../services/invoices';
import { Invoice } from '../../types/invoice';
import errorHandling from '../../utils/error_handling';
import { formatInCents } from '../../utils/money';
import { InvoiceDetailModal } from './components/InvoiceDetailModal';
import PaymentModal from './components/PaymentModal';

const PAYMENT_METHOD = {
  credit_card: 'Crédito',
  boleto: 'Boleto',
  pix: 'Pix',
};

const STATUS = {
  pending: 'Pendente',
  paid: 'Pago',
  authorized_pending_capture: 'Autorizado pendente de captura',
  not_authorized: 'Não autorizado',
  captured: 'Capturado',
  partial_capture: 'Captura parcial',
  waiting_capture: 'Aguardando captura',
  refunded: 'Estornado',
  voided: 'Cancelado',
  partial_refunded: 'Estorno parcial',
  partial_void: 'Cancelamento parcial',
  error_on_voiding: 'Erro no cancelamento',
  error_on_refunding: 'Erro no estorno',
  waiting_cancellation: 'Aguardando cancelamento',
  with_error: 'Com erro',
  failed: 'Falha',
  waiting_payment: 'Aguardando pagamento',
  generated: 'Gerado',
  viewed: 'Visualizado',
  underpaid: 'Pagamento a menos',
  overpaid: 'Pagamento a mais',
  processing: 'Boleto ainda está em etapa de criação',
};

function createChargeArray(invoices: Invoice[]) {
  let extraServicesCharges: any[] = [];
  let chargesGroup = invoices.flatMap((invoice) => {
    const charge = invoice?.charges[invoice?.charges.length - 1];
    const isExtraService = invoice?.product_copy?.name.startsWith('cart_');

    if (!charge) return;

    const chargeObject = {
      invoice_id: !isExtraService ? invoice.id : charge.invoice_id,
      charge_id: !isExtraService ? null : charge.id,
      status: !isExtraService ? invoice.status : charge.status,
      product_copy: invoice.product_copy,
      price_in_cents: invoice.product_copy.price_in_cents,
      price_in_cents_paid: !isExtraService ? null : charge.price_in_cents_paid,
      charge_date: charge.charge_date,
      last_payment_date: !isExtraService ? null : charge.last_payment_date,
      order_gateway_copy: !isExtraService ? null : charge.order_gateway_copy,
      enotas_id: !isExtraService ? null : charge.enotas_id,
      enotas_pdf: !isExtraService ? null : charge.enotas_pdf,
      payment_period: !isExtraService ? null : invoice.payment_period,
      payment_method: !isExtraService ? null : invoice.payment_method,
      charge_type: invoice.charge_type,
      coupon_copy: !isExtraService ? null : invoice.coupon_copy,
      created_at: !isExtraService ? invoice.created_at : charge.created_at,
      updated_at: !isExtraService ? invoice.updated_at : charge.updated_at,
      charges: !isExtraService ? invoice.charges : null,
      next_charge_date: isExtraService ? null : invoice.next_charge_date,
    };

    if (isExtraService) {
      extraServicesCharges.push(chargeObject);
    } else {
      return chargeObject;
    }
  });

  if (extraServicesCharges.length > 0) {
    let extraServiceGroup = { ...extraServicesCharges[0] };

    extraServiceGroup.id = '';
    extraServiceGroup.charge_id = null;
    extraServiceGroup.status = '';
    extraServiceGroup.price_in_cents = null;
    extraServiceGroup.price_in_cents_paid = null;
    extraServiceGroup.charge_date = null;
    extraServiceGroup.last_payment_date = null;
    extraServiceGroup.order_gateway_copy = null;
    extraServiceGroup.enotas_id = null;
    extraServiceGroup.enotas_pdf = null;
    extraServiceGroup.payment_period = null;
    extraServiceGroup.payment_method = null;
    extraServiceGroup.charge_type = null;
    extraServiceGroup.coupon_copy = null;
    extraServiceGroup.created_at = null;
    extraServiceGroup.updated_at = null;
    extraServiceGroup.next_charge_date = null;

    extraServiceGroup.charges = extraServicesCharges;

    chargesGroup.push(extraServiceGroup);
  }

  return chargesGroup;
}

const Invoices = () => {
  const [invoices, setInvoices] = useState<Invoice[]>();
  const [loading, setLoading] = useState(true);

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [invoiceId, setInvoiceId] = useState<string>();
  const [chargeGateway, setChargeGateway] = useState();
  const [isOpenItemDetails, setIsOpenItemDetails] = useState(false);
  const [viewButtonAddCoupon, setViewButtonAddCoupon] = useState(false);

  const [selectedItemDetails, setSelectedItemDetails] = useState(null);
  const [isOpenInvoiceDetail, setIsOpenInvoiceDetail] =
    useState<boolean>(false);

  const { register, watch, reset } = useForm<any>({
    mode: 'onChange',
  });

  const [watchCoupon] = watch(['coupon']);

  const updateCoupon = async (params: any, invoiceId: string) => {
    try {
      const data = await Api.updateInvoice(invoiceId, params);

      if (data) {
        await getInvoices();
        Toast.success('Esse cupom será aplicado no próximo pagamento');
        setViewButtonAddCoupon(false);
        reset({});
        return data;
      }
    } catch (error) {
      if (error instanceof Error) {
        errorHandling(error, error.message, 'crema');
      } else {
        errorHandling(error, 'Não foi possível aplicar o cupom', 'crema');
      }
    }
  };

  function handleOpenPaymentModal(id: string, charge: any) {
    setIsPaymentModalOpen(true);
    setChargeGateway(charge);
    setInvoiceId(id);
  }

  const toggleItemDetails = (item: any, identifier: any) => {
    setSelectedItemDetails(
      selectedItemDetails === identifier ? null : identifier
    );
    setIsOpenItemDetails(!selectedItemDetails);
  };

  function handleClosePaymentModal() {
    setInvoiceId(undefined);
    setChargeGateway(undefined);
    setIsPaymentModalOpen(false);
  }

  async function getInvoices() {
    await listInvoices({
      page: 1,
      limit: 100,
      order: 'created_at,asc',
    })
      .then((response) => {
        setInvoices(response.data);
      })
      .then(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getInvoices();
  }, []);

  return (
    <>
      <LayoutBase title="Informações de pagamento">
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 max-w-7xl gap-1 md:p-6">
          {loading && (
            <tr>
              <td
                colSpan={6}
                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center"
              >
                Carregando...{' '}
              </td>
            </tr>
          )}

          {invoices?.length === 0 && (
            <tr>
              <td
                colSpan={6}
                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center"
              >
                Nenhuma fatura encontrada{' '}
              </td>
            </tr>
          )}

          {!loading &&
            invoices &&
            invoices.length > 0 &&
            createChargeArray(invoices).map((item) => {
              if (!item) {
                return;
              }

              const charges =
                item?.order_gateway_copy?.charges[
                  item?.order_gateway_copy?.charges.length - 1
                ];

              const payment_method =
                item?.payment_method ??
                (charges?.payment_method as keyof typeof PAYMENT_METHOD);

              return (
                <section
                  key={item.invoice_id}
                  className={`mb-2 justify-between shadow-gray-50 shadow-sm border-1 rounded-r-lg bg-gray-100`}
                  style={{
                    minHeight: '8rem',
                  }}
                >
                  <div className="flex">
                    <section
                      className={`flex-1 border-l-8
                      ${
                        item?.product_copy?.name.startsWith('cart_')
                          ? 'border-neon-900 p-7'
                          : ''
                      }
                      ${item?.status === 'paid' ? 'border-green-500' : ''}
                      ${
                        item?.status === 'pending' || item?.status === 'expired'
                          ? 'border-orange-500'
                          : ''
                      }`}
                    >
                      {item?.payment_method ||
                      item?.product_copy?.name.startsWith('cart_') ? (
                        <>
                          {!item?.product_copy?.name.startsWith('cart_') ? (
                            <h1
                              className={`text-xl text-gray-400 font-bold mb-1 text-center flex items-center justify-center h-10 w-32`}
                            >
                              {
                                PAYMENT_METHOD[
                                  payment_method as keyof typeof PAYMENT_METHOD
                                ]
                              }
                            </h1>
                          ) : null}

                          <div
                            className={`text-sm text-center mb-2 mx-auto
                                ${
                                  item?.product_copy?.name.startsWith('cart_')
                                    ? 'text-neon-900'
                                    : ''
                                }
                                ${
                                  item?.status === 'paid'
                                    ? 'text-green-500'
                                    : ''
                                }
                                ${
                                  item?.status === 'pending' ||
                                  item?.status === 'expired'
                                    ? 'text-orange-500'
                                    : ''
                                }
                             lowercase break-all flex items-center justify-center h-16 w-16 rounded-full bg-white`}
                          >
                            {item?.product_copy?.name.startsWith('cart_') ? (
                              <FaCartPlus className="flex text-xs w-8 h-8" />
                            ) : null}
                            {payment_method === 'credit_card' ? (
                              <MdCreditCard className="flex text-xs w-8 h-8" />
                            ) : (
                              ''
                            )}
                            {payment_method === 'pix' ? (
                              <MdPix className="flex text-xs w-8 h-8" />
                            ) : (
                              ''
                            )}
                            {payment_method === 'boleto' ? (
                              <PiBarcode className="flex text-xs w-8 h-8" />
                            ) : (
                              ''
                            )}
                          </div>

                          {item?.status ? (
                            <p className="text-center text-ls mb-1 items-center">
                              <span
                                className={`w-full font-bold ${
                                  item?.status === 'paid'
                                    ? 'text-green-500'
                                    : 'text-orange-500'
                                }`}
                              >
                                {STATUS[item?.status as keyof typeof STATUS]}
                              </span>
                            </p>
                          ) : null}
                        </>
                      ) : null}

                      {item.payment_period ? (
                        <p className="text-center text-xs mt-2 mb-2 items-center">
                          <span className={`w-full font-bold text-gray-400`}>
                            Venc.:
                          </span>
                          <span className={`w-full text-gray-400`}>
                            {format(
                              new Date(item.payment_period),
                              ' dd/MM/yyyy'
                            )}
                          </span>
                        </p>
                      ) : null}
                    </section>

                    <section className="w-full bg-white flex flex-col">
                      <h1
                        className={`h-full text-xl mx-3 mt-1 flex-grow flex font-bold float-left cursor-pointer`}
                      >
                        <span className="float-left mr-auto flex items-center">
                          <span className="mr-1.5">{`${
                            item?.product_copy?.name.startsWith('cart_')
                              ? 'Serviços extras'
                              : item?.product_copy?.name
                          }`}</span>

                          {item.charge_type === 'recurrence' ? (
                            <span className="inline-block text-gray-400 items-center">
                              <Icon.MdOutlineRefresh
                                className={`float-left text-sm w-5 h-5 mt-1.5`}
                              />
                              <span className="normal-case text-xs">
                                Recorrente
                              </span>
                            </span>
                          ) : null}
                        </span>

                        {!item?.product_copy?.name.startsWith('cart_') ? (
                          <span className="text-sm text-gray-400 ml-auto ml-2 mt-2 float-right font-normal">
                            {format(new Date(item.created_at), 'dd MMM')}
                          </span>
                        ) : null}
                      </h1>

                      {!item?.product_copy?.name.startsWith('cart_') ? (
                        <div
                          className={`text-sm mx-3 ${
                            item?.payment_method ? 'mb-2 h-8' : 'mb-1'
                          } flex-grow text-gray-400 lowercase break-all items-center`}
                        >
                          {item?.product_copy?.description}
                        </div>
                      ) : null}

                      <div
                        className={`m-3 ${
                          !item?.payment_method ? 'mt-0' : ''
                        } flex-grow flex items-center`}
                      >
                        {!item?.product_copy?.name.startsWith('cart_') ? (
                          <div className="mr-auto">
                            <span className="text-xs text-gray-400 lowercase">
                              Preço
                            </span>
                            <div className="border text-gray-400 border-gray-400 font-bold rounded-sm py-1 px-2">
                              {formatInCents(item?.price_in_cents)}
                            </div>
                          </div>
                        ) : null}
                        <div className="flex gap-2">
                          <div className="flex gap-2 items-center">
                            {item?.product_copy?.name?.startsWith(
                              'cart_'
                            ) ? null : (
                              <div className="ml-auto">
                                <span
                                  className="text-sm text-gray-400 lowercase"
                                  style={{
                                    height: '0.75rem',
                                    display: 'block',
                                  }}
                                >
                                  &nbsp;
                                </span>
                                {!viewButtonAddCoupon ? (
                                  <Button
                                    variant="solid"
                                    color="neon"
                                    normal
                                    full
                                    className="border text-gray-400 border-gray-100 bg-gray-100 font-bold rounded-sm"
                                    style={{ padding: '.25rem .50rem' }}
                                    onClick={() => setViewButtonAddCoupon(true)}
                                  >
                                    <RiCoupon2Line className="float-left text-sm w-6 h-6 mr-2 text-white" />
                                    <span className="uppercase text-xs">
                                      Adicionar cupom
                                    </span>
                                  </Button>
                                ) : (
                                  <div className="relative flex items-center space-x-2">
                                    <div className="relative flex-1">
                                      <Input
                                        className="text-gray-400 focus:ring-neon-900 rounded-sm pl-10 w-full"
                                        style={{
                                          width: '11.5rem',
                                          height: '2rem',
                                          border: 'none',
                                          background: '#f4f4f5',
                                          textTransform: 'uppercase',
                                        }}
                                        {...register('coupon', {
                                          onChange: (e) => {
                                            const uppercasedValue =
                                              e.target.value.toUpperCase();
                                            e.target.value = uppercasedValue;
                                            return e;
                                          },
                                        })}
                                        placeholder="Digite seu cupom"
                                      />
                                      <RiCoupon2Line className="absolute top-1/2 left-3 transform -translate-y-1/2 text-sm w-6 h-6 text-neon-900" />
                                    </div>
                                    <Button
                                      variant="solid"
                                      color="neon"
                                      normal
                                      disabled={!watchCoupon?.length}
                                      style={{ padding: '.25rem .50rem' }}
                                      onClick={() =>
                                        updateCoupon(
                                          watchCoupon,
                                          item.invoice_id
                                        )
                                      }
                                    >
                                      Aplicar
                                    </Button>
                                  </div>
                                )}
                              </div>
                            )}
                            {invoices[0]?.coupon_copy?.code && (
                              <div className="ml-auto mb-3">
                                <span className="text-xs text-gray-400 lowercase">
                                  último cupom utilizado
                                </span>
                                <div className="border text-green-400 border-green-400 bg-green-100 text-center justify-center font-bold rounded py-1 px-2 text-sm">
                                  {invoices[0].coupon_copy.code}
                                </div>
                              </div>
                            )}
                          </div>
                          {item.next_charge_date ? (
                            <div className="ml-auto mb-3">
                              <span className="text-xs text-gray-400 lowercase">
                                Próximo pagamento
                              </span>
                              <div className="border text-gray-400 border-gray-100 bg-gray-100 font-bold rounded-sm py-1 px-2">
                                <TbCalendarDollar
                                  className={`float-left text-sm w-6 h-6 mr-2 ${
                                    item?.status === 'paid'
                                      ? 'text-green-400'
                                      : 'text-orange-400'
                                  }`}
                                />
                                <span className="uppercase text-xs">
                                  {format(
                                    new Date(item.next_charge_date),
                                    'dd/MM/yyyy'
                                  )}
                                </span>
                              </div>
                            </div>
                          ) : null}
                        </div>

                        {item.coupon_copy?.code ? (
                          <div className="ml-auto">
                            <span className="text-sm text-gray-400 lowercase">
                              &nbsp;
                            </span>
                            <div className="border text-gray-400 border-gray-100 bg-gray-100 font-bold rounded-sm py-1 px-2">
                              <RiCoupon2Line
                                className={`float-left text-sm w-6 h-6 mr-2 ${
                                  item?.status === 'paid'
                                    ? 'text-green-400'
                                    : 'text-orange-400'
                                }`}
                              />
                              <span className="uppercase text-xs">
                                {item.coupon_copy?.code ?? ''}
                              </span>
                            </div>
                          </div>
                        ) : null}

                        {item?.price_in_cents_paid && (
                          <div className="ml-1">
                            <span className="text-xs text-gray-400 lowercase">
                              {item?.status === 'paid'
                                ? 'Valor pago'
                                : 'A pagar'}
                            </span>
                            <div
                              className={`border text-white font-bold rounded-sm py-1 px-2 ${
                                item?.status === 'paid'
                                  ? 'border-green-400 bg-green-500'
                                  : 'border-orange-400 bg-orange-500'
                              }`}
                            >
                              {formatInCents(item?.price_in_cents_paid)}
                            </div>
                          </div>
                        )}
                      </div>
                    </section>

                    {(item?.status === 'pending' ||
                      item?.status === 'expired') &&
                    item?.order_gateway_copy?.charges?.length ? (
                      <Button
                        type="button"
                        onClick={() => {
                          handleOpenPaymentModal(item.invoice_id, null);
                        }}
                        normal
                        variant="light"
                        color="gray"
                        className="rounded-b"
                        style={{
                          borderTopLeftRadius: '0',
                          borderBottomLeftRadius: '0',
                        }}
                      >
                        <Icon.MdRemoveRedEye className="flex text-xs w-6 h-6" />
                      </Button>
                    ) : item?.charges ? (
                      <Button
                        type="button"
                        onClick={() => {
                          toggleItemDetails(item, `${item.invoice_id}`);
                        }}
                        normal
                        variant="light"
                        color="gray"
                        className="rounded-b"
                        style={{
                          borderTopLeftRadius: '0',
                          borderBottomLeftRadius: '0',
                        }}
                      >
                        {`${item.invoice_id}` === selectedItemDetails ? (
                          <Icon.MdOutlineKeyboardArrowUp className="flex text-sm w-6 h-6" />
                        ) : (
                          <Icon.MdOutlineKeyboardArrowDown className="flex text-sm w-6 h-6" />
                        )}
                      </Button>
                    ) : null}
                  </div>

                  <section
                    className={`bg-gray-100 w-full p-4
                      ${
                        `${item.invoice_id}` === selectedItemDetails
                          ? ''
                          : 'hidden'
                      }`}
                  >
                    {item?.charges?.map((charge: any, idx: number) => {
                      return (
                        <section
                          key={charge.id}
                          className={`flex mb-4 bg-gray-100 justify-between shadow-gray-50 shadow-sm border-1 rounded-r-lg w-full`}
                        >
                          <section
                            className={`flex-1 border-l-8 ${
                              charge?.status === 'paid'
                                ? 'border-green-500'
                                : 'border-orange-500'
                            }`}
                          >
                            <h1
                              className={`text-xl text-gray-400 font-bold mb-1 text-center flex items-center justify-center h-10 w-32`}
                            >
                              {
                                PAYMENT_METHOD[
                                  (charge.payment_method ??
                                    payment_method) as keyof typeof PAYMENT_METHOD
                                ]
                              }
                            </h1>

                            <div
                              className={`text-sm text-center mb-2 mx-auto ${
                                charge?.status === 'paid'
                                  ? 'text-green-500'
                                  : 'text-orange-500'
                              }
                                lowercase break-all flex items-center justify-center h-16 w-16 rounded-full bg-white`}
                            >
                              {(charge?.payment_method ?? payment_method) ===
                              'credit_card' ? (
                                <MdCreditCard className="flex text-xs w-8 h-8" />
                              ) : (
                                ''
                              )}
                              {(charge.payment_method ?? payment_method) ===
                              'pix' ? (
                                <MdPix className="flex text-xs w-8 h-8" />
                              ) : (
                                ''
                              )}
                              {(charge.payment_method ?? payment_method) ===
                              'boleto' ? (
                                <PiBarcode className="flex text-xs w-8 h-8" />
                              ) : (
                                ''
                              )}
                            </div>

                            {charge?.status ? (
                              <p className="text-center text-ls mb-1 items-center">
                                <span
                                  className={`w-full font-bold ${
                                    charge?.status === 'paid'
                                      ? 'text-green-500'
                                      : 'text-orange-500'
                                  }`}
                                >
                                  {
                                    STATUS[
                                      charge?.status as keyof typeof STATUS
                                    ]
                                  }
                                </span>
                              </p>
                            ) : null}

                            {item?.payment_period &&
                            charge?.status !== 'paid' ? (
                              <p className="text-center text-xs mt-2 mb-2 items-center">
                                <span
                                  className={`w-full font-bold text-gray-400`}
                                >
                                  Venc.:
                                </span>
                                <span className={`w-full text-gray-400`}>
                                  {format(
                                    new Date(item?.payment_period),
                                    ' dd/MM/yyyy'
                                  )}
                                </span>
                              </p>
                            ) : null}
                          </section>

                          <section className="w-full bg-white flex flex-col">
                            <h1
                              className={`text-xl mx-3 mt-1 flex-grow flex font-bold float-left cursor-pointer`}
                              onClick={() => {
                                setInvoiceId(item.invoice_id);
                                if (
                                  item?.product_copy?.name?.startsWith('cart_')
                                ) {
                                  setIsOpenInvoiceDetail(true);
                                }
                              }}
                            >
                              <span className="float-left mr-auto">{`${
                                charge?.product_copy?.name?.startsWith('cart_')
                                  ? 'Serviços extras'
                                  : charge?.product_copy?.name
                              }`}</span>
                              <span className="text-sm text-gray-400 ml-auto ml-2 mt-2 float-right font-normal">
                                {charge?.status === 'paid'
                                  ? format(
                                      new Date(charge.last_payment_date),
                                      'dd MMM'
                                    )
                                  : format(
                                      new Date(charge.updated_at),
                                      'dd MMM'
                                    )}
                              </span>
                            </h1>
                            <div
                              className={`text-sm mx-3 mb-2 flex-grow text-gray-400 lowercase break-all items-center h-8`}
                            >
                              {`${
                                charge?.product_copy?.name.startsWith('cart_')
                                  ? 'Itens do carrinho'
                                  : charge?.product_copy?.description
                              }`}
                            </div>
                            <div className={`m-3 flex-grow flex items-center`}>
                              <div className="mr-auto hidden md:block">
                                <span className="text-xs text-gray-400 lowercase">
                                  Preço
                                </span>
                                <div className="border text-gray-400 border-gray-400 font-bold rounded-sm py-1 px-2">
                                  {formatInCents(charge?.price_in_cents)}
                                </div>
                              </div>

                              <div className="ml-auto">
                                {charge.coupon_copy?.code ? (
                                  <>
                                    <span className="text-sm text-gray-400 lowercase">
                                      &nbsp;
                                    </span>
                                    <div className="border text-gray-400 border-gray-100 bg-gray-100 font-bold rounded-sm py-1 px-2">
                                      <RiCoupon2Line
                                        className={`float-left text-sm w-6 h-6 mr-2 ${
                                          charge?.status === 'paid'
                                            ? 'text-green-400'
                                            : 'text-orange-400'
                                        }`}
                                      />
                                      <span className="uppercase text-xs">
                                        {charge.coupon_copy?.code ?? ''}
                                      </span>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                              <div className="ml-1">
                                <span className="text-xs text-gray-400 lowercase">
                                  {charge?.status === 'paid'
                                    ? 'Valor pago'
                                    : 'A pagar'}
                                </span>
                                <div
                                  className={`border text-white font-bold rounded-sm py-1 px-2 ${
                                    charge?.status === 'paid'
                                      ? 'border-green-400 bg-green-500'
                                      : 'border-orange-400 bg-orange-500'
                                  }`}
                                >
                                  {charge?.status === 'paid'
                                    ? formatInCents(charge.price_in_cents_paid)
                                    : formatInCents(charge.price_in_cents)}
                                </div>
                              </div>
                            </div>
                          </section>

                          {charge?.product_copy?.name?.startsWith('cart_') ? (
                            <Button
                              type="button"
                              onClick={() => {
                                setInvoiceId(item.invoice_id);
                                setIsOpenInvoiceDetail(true);
                              }}
                              normal
                              variant="light"
                              color="gray"
                              className="rounded-b"
                              style={{
                                borderTopLeftRadius: '0',
                                borderBottomLeftRadius: '0',
                              }}
                            >
                              <Icon.MdOutlineShoppingCart className="flex text-xs w-6 h-6" />
                            </Button>
                          ) : null}

                          {charge?.status === 'pending' &&
                          charge?.order_gateway_copy?.charges?.length ? (
                            <Button
                              type="button"
                              onClick={() => {
                                handleOpenPaymentModal(
                                  charge.invoice_id,
                                  charge?.order_gateway_copy?.charges[
                                    charge?.order_gateway_copy?.charges.length -
                                      1
                                  ]
                                );
                              }}
                              normal
                              variant="light"
                              color="gray"
                              className="rounded-b"
                              style={{
                                borderTopLeftRadius: '0',
                                borderBottomLeftRadius: '0',
                              }}
                            >
                              <Icon.MdRemoveRedEye className="flex text-xs w-6 h-6" />
                            </Button>
                          ) : charge?.enotas_pdf ? (
                            <Button
                              type="button"
                              onClick={() => {
                                window.location.href = charge.enotas_pdf;
                              }}
                              normal
                              variant="light"
                              color="gray"
                              className="rounded-b uppercase font-bold"
                              style={{
                                borderTopLeftRadius: '0',
                                borderBottomLeftRadius: '0',
                              }}
                            >
                              <FaFileInvoiceDollar className="flex text-xs w-6 h-6 mx-auto" />
                              <span className="mt-1 text-xs text-gray-400 uppercase">
                                NF
                              </span>
                            </Button>
                          ) : null}
                        </section>
                      );
                    })}
                    {/* Fim de charges */}
                  </section>
                </section>
              );
            })}
        </div>
      </LayoutBase>
      <PaymentModal
        isOpen={isPaymentModalOpen}
        onClose={handleClosePaymentModal}
        invoiceId={invoiceId}
        chargeGateway={chargeGateway}
      />
      <InvoiceDetailModal
        invoiceId={invoiceId}
        isOpen={isOpenInvoiceDetail}
        onClose={() => setIsOpenInvoiceDetail(false)}
      />
    </>
  );
};

export default Invoices;
