// @ts-nocheck
import { useEffect, useMemo } from "react";
import { useIonAlert } from "@ionic/react";
import { useHistory } from "react-router";
import { Input, Select, Button } from "oialbert-ui";
import { formatInCents } from "../../utils/money";
import useWallet from "../../hooks/useWallet";
import useForm from "../../hooks/useForm";
import { useMaskHandler } from "../../hooks/useMaskHandler";
import * as z from "zod";

import { Extract } from "../Financial/components/Extract";
import { BalanceItem } from "../Financial/components/BalanceItem";
import { ExtractItem } from "./components/ExtractItem";
import { LayoutBase } from "../../components/LayoutBase";

const Withdraw = () => {
  const history = useHistory();
  const [present] = useIonAlert();

  const {
    getWithdraws,
    loadingWithdrawals,
    withdrawals,
    wallets,
    getBankAccounts,
    loadingBankAccounts,
    bankAccounts,
    onCreateWithdraw,
    loadingCreateWithdrawal,
    createdWithdrawal,
    pageWithdraws,
    totalWithdraws,
    setPageWithdraws,
  } = useWallet();

  const valueInCents = Number(wallets?.value_in_cents);

  const walletValue = valueInCents ?? 0;
  const disabledWithdraw = walletValue < 5000;

  const schema = useMemo(
    () =>
      z.object({
        value_in_cents: z
          .number({ required_error: "campo obrigatório" })
          .min(5000, "valor mínimo para saque é de R$ 50,00")
          .max(
            walletValue,
            `valor máximo para saque é de ${formatInCents(walletValue)}`
          ),
        bank_id: z.string().nonempty({ message: "campo obrigatório" }),
      }),
    [walletValue]
  );

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitSuccessful, isValid },
  } = useForm({
    schema,
    mode: "onChange",
  });

  const valueInCentsProps = useMaskHandler("value_in_cents", control, 0);

  const field = watch();

  useEffect(() => {
    getWithdraws();
  }, [getWithdraws]);

  useEffect(() => {
    getBankAccounts();
  }, [getBankAccounts]);

  useEffect(() => {
    const bankInfo = bankAccounts.find(
      (bank) => bank.default && bank.is_active && bank.status === "approved"
    );

    if (bankInfo) setValue("bank_id", bankInfo.id);
  }, [bankAccounts, setValue, field.bank_id, loadingBankAccounts]);

  useEffect(() => {
    if (isSubmitSuccessful && !loadingCreateWithdrawal && createdWithdrawal) {
      const bankInfo = bankAccounts.find((bank) => bank.id === field.bank_id);

      history.push({
        pathname: "/receipt",
        state: {
          value_in_cents: field.value_in_cents / 100,
          account_document_number:
            bankInfo?.bank_account.account_document_number,
          account_number: bankInfo?.bank_account.account_number,
          account_agency: bankInfo?.bank_account.account_agency,
          context: "withdrawal",
        },
      });
    }
  }, [
    history,
    isSubmitSuccessful,
    field,
    bankAccounts,
    loadingCreateWithdrawal,
    createdWithdrawal,
  ]);

  return (
    <LayoutBase title="Sacar">
      <section className="mt-4 px-5">
        <BalanceItem />
      </section>

      <section className="mt-4 px-5">
        <h3 className="font-base">quanto você quer sacar?</h3>

        <section className="my-2 pb-2 border-b border-crema-500">
          <h3 className="font-sm font-bold text-gray-500 mb-220,00">valor</h3>
          <Input
            type="tel"
            placeholder="0,00"
            mask="MONEY"
            className="bg-white border-0 rounded-none shadow-none font-bold text-2xl text-gray-500 px-0 placeholder-gray-500::placeholder focus:border-0 focus-within:ring-0"
            disabled={disabledWithdraw}
            moneyProps={{
              ...valueInCentsProps,
              prefix: "",
            }}
            error={errors.value_in_cents?.message?.toString()}
            {...register("value_in_cents")}
          />
        </section>
      </section>
      <section className="mt-4 px-5">
        <section className="flex flex-col mt-3 mb-4 space-y-4">
          <Select
            disabled={loadingBankAccounts || disabledWithdraw}
            label={loadingBankAccounts ? "Buscando..." : "Selecione a conta"}
            options={bankAccounts
              .filter((account) => account.status === "approved")
              .map((bank) => ({
                value: bank.id,
                label: `${bank.bank_account.account_number} - ${bank.bank_account.account_agency} | ${bank.bank_account.account_name}`,
                disabled: !bank.is_active && bank.status !== "approved",
              }))}
            error={errors.bank_id?.message?.toString()}
            {...register("bank_id")}
          />
        </section>

        <Button
          full
          disabled={
            !isValid ||
            loadingBankAccounts ||
            disabledWithdraw ||
            loadingCreateWithdrawal
          }
          variant="outline"
          color="neon"
          onClick={() =>
            present({
              header: "confirmar solicitação",
              message: `você confirma o saque de ${formatInCents(
                field.value_in_cents / 100
              )}?`,
              buttons: [
                "Não",
                { text: "Sim", handler: handleSubmit(onCreateWithdraw) },
              ],
              onDidDismiss: () => {},
            })
          }
        >
          {loadingCreateWithdrawal ? "Aguarde..." : "Sacar"}
        </Button>
      </section>

      <Extract
        loading={loadingWithdrawals}
        data={withdrawals.map((item, idx) => (
          <ExtractItem data={item} key={`item_${idx}`} />
        ))}
        pagination={{
          page: pageWithdraws,
          total: totalWithdraws,
          setPage: setPageWithdraws,
        }}
      />
    </LayoutBase>
  );
};

export default Withdraw;
