import { Button, Icon, Pagination, Toast } from 'oialbert-ui';
import { useCallback, useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading';
import { formatShort } from '../../../utils/date';
import errorHandling from '../../../utils/error_handling';
import { formatInCents } from '../../../utils/money';
import { AxiosError } from 'axios';
import { deleteOrder } from '../../../services/orders';
import { listAllPreOrders } from '../../../services/partners/preorders';
import { Meta } from '../../Orders/types';
import { PreOrderData } from '../../../types/pre-order';
import { maskPhoneNumber } from '../../../utils/maskPhoneNumber';
import { FiAlertTriangle } from 'react-icons/fi';

const LIMIT = 10;

interface PreRegistrationProps {
  companySelectedId?: string;
}

export const PreRegistration = ({
  companySelectedId,
}: PreRegistrationProps) => {
  const [orders, setOrders] = useState<PreOrderData[]>([]);
  const [ordersMeta, setOrdersMeta] = useState<Meta>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(ordersMeta?.current_page ?? 1);

  const total: number = ordersMeta?.total ?? 0;

  const getOrders = useCallback(async () => {
    setIsLoading(true);
    try {
      const {
        data: { preorders },
        status,
      } = await listAllPreOrders({
        page,
        limit: LIMIT,
        companyId: companySelectedId,
      });

      if (status === 200 && preorders) {
        if (preorders.data) setOrders(preorders.data);
        if (preorders.meta) setOrdersMeta(preorders.meta);
      }
    } catch (error: any) {
      const {
        response: { status, data },
      } = error;
      if (status !== 404 && data) {
        errorHandling(null, data.message, 'coral');
      }
    } finally {
      setIsLoading(false);
    }
  }, [page, companySelectedId]);

  async function deleteOrderById(orderId: string) {
    setIsLoading(true);
    if (orderId) {
      await tryDeleteOrderById(orderId);
    }
    await getOrders();
  }

  async function tryDeleteOrderById(orderId: string) {
    try {
      await deleteOrder({
        id: orderId,
        type: 'preorder',
        companyId: companySelectedId,
      });
      Toast.success(
        'A solicitação de cancelamento do pedido foi enviada para análise. Ela será atualizada em breve, e você será notificado via e-mail.'
      );
    } catch (err) {
      const error = err as AxiosError;

      if (error.response?.data?.message) {
        const { message } = error.response.data;
        Toast.crema(message);
      } else {
        Toast.crema('ocorreu um erro ao deletar cashback');
      }
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getOrders();
  }, [getOrders, companySelectedId]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {!orders?.length ? (
            <section className="w-full flex justify-center py-4">
              <h3 className="text-center text-lg text-gray-500">
                nenhum pedido encontrado
              </h3>
            </section>
          ) : (
            <>
              {orders.map((preOrder: PreOrderData, idx: number) => {
                const orderValue = preOrder?.order_value_in_cents ?? 0;
                const cashbackValue = preOrder?.cashback_in_percent ?? 0;

                return (
                  <section key={idx} className="flex mt-6 px-6">
                    <Icon.MdOutlineShoppingCart className="w-6 h-6 text-gray-300" />
                    <section key={preOrder.id} className="ml-2 flex-1 ">
                      <section className="w-full flex justify-between items-center">
                        <div>
                          <h4 className="flex text-base font-bold mb-3.5">
                            {maskPhoneNumber(preOrder?.cellphone)} •{' '}
                            <span className="font-bold uppercase ml-1">
                              {formatInCents(orderValue)}
                            </span>
                          </h4>
                          <section className="-mt-2">
                            <p className="text-sm text-gray-500">
                              cashback ofertado:{' '}
                              <span className="font-bold text-neon-900 uppercase">
                                {formatInCents(
                                  (orderValue * Number(cashbackValue)) / 100
                                )}
                              </span>
                            </p>
                            {preOrder.creator?.company_partner?.full_name && (
                              <p className="text-sm text-gray-500 mt-2">
                                checkin realizado por:{' '}
                                <span>
                                  {
                                    preOrder?.creator?.company_partner
                                      ?.full_name
                                  }
                                </span>
                              </p>
                            )}
                            {preOrder.creator?.partner?.full_name && (
                              <p className="text-sm text-gray-500 mt-2">
                                checkin realizado por:{' '}
                                <span>
                                  {preOrder?.creator?.partner?.full_name}
                                </span>
                              </p>
                            )}
                            {preOrder.created_at && (
                              <p className="text-sm text-gray-500 uppercase mt-2">
                                <span className="uppercase">
                                  {formatShort(preOrder.created_at, true)}
                                </span>
                              </p>
                            )}
                            {preOrder.cancel_request_status === 'waiting' && (
                              <div className="flex text-gray-500 mt-2 gap-1">
                                <FiAlertTriangle className="mt-1" size={14} />
                                <div className="flex w-96">
                                  <p className="text-sm">
                                    A solicitação de cancelamento do pedido foi
                                    enviada para análise. Ela será atualizada em
                                    breve, e você será notificado via e-mail.
                                  </p>
                                </div>
                              </div>
                            )}
                            {preOrder?.cancel_request_status === 'reproved' && (
                              <div className="mt-6 flex normal-case rounded-lg items-center text-red-500">
                                <FiAlertTriangle className="w-4 h-4 mr-2 " />
                                <span className="text-sm">
                                  Sua solicitação foi recusada.
                                </span>
                              </div>
                            )}
                          </section>
                        </div>
                        <Button
                          variant="solid"
                          color="neon"
                          disabled={
                            preOrder?.cancel_request_status !== null ||
                            preOrder?.status === 'complete'
                          }
                          onClick={() => deleteOrderById(preOrder.id)}
                        >
                          <span>cancelar cashback</span>
                        </Button>
                      </section>
                      <hr className="mt-4 h-1 bg-primary-100" />
                    </section>
                  </section>
                );
              })}
              {total > LIMIT && (
                <section className="flex justify-center mt-4">
                  <Pagination
                    onPrev={() => setPage(page - 1)}
                    onNext={() => setPage(page + 1)}
                    onPage={setPage}
                    page={page}
                    total={total}
                    variant='secondary'
                    perPage={ordersMeta?.per_page ?? 100}
                  />
                </section>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
