import { Modal, Button } from 'oialbert-ui';
import { ModalProps } from 'oialbert-ui/lib/components/Modal/types';
import { useProducts } from '../hooks';

type Props = Pick<ModalProps, 'onClose' | 'open'>;

const RemoveItemModal = ({
  onClose,
  open,
  productId,
  onRemove,
}: Props & {
  productId: string | null;
  onRemove: () => void;
}) => {
  const { deleteProduct, loadingDelete } = useProducts();

  const onRemoveProduct = async () => {
    productId && (await deleteProduct(productId));
    onClose();
    onRemove();
  };

  return (
    <Modal height={209} open={open} onClose={onClose}>
      <h3 className="text-base mb-4 text-center font-bold text-black mb-2">
        excluir Item
      </h3>

      <p className="text-black text-base w-56 mx-auto text-center">
        você tem certeza que quer excluir este produto?
      </p>

      <div className="flex mt-6 items-center gap-4 max-w-sm mx-auto">
        <Button
          full
          normal
          size="small"
          onClick={() => onRemoveProduct()}
          variant="outline"
          color="neon"
          disabled={loadingDelete}
        >
          <span className="font-bold">
            {loadingDelete ? 'aguarde...' : 'excluir'}
          </span>
        </Button>
        <Button
          full
          normal
          size="small"
          onClick={onClose}
          variant="light"
          color="neon"
          disabled={loadingDelete}
        >
          <span className="font-bold">fechar</span>
        </Button>
      </div>
    </Modal>
  );
};

export default RemoveItemModal;
