import { useCallback } from 'react';
import { Modal, Button } from 'oialbert-ui';
import { ModalProps } from 'oialbert-ui/lib/components/Modal/types';
import { useProductCategories } from '../hooks';

type Props = Pick<ModalProps, 'onClose' | 'open'> & {
  id?: string;
  afterRemove: () => void;
};

function RemoveItemModal({ onClose, open, id, afterRemove }: Props) {
  const { removeProductCategory } = useProductCategories();

  const deleteCategory = useCallback(async () => {
    if (id) {
      await removeProductCategory(id);

      onClose();
      afterRemove();
    }
  }, [afterRemove, id, onClose, removeProductCategory]);

  return (
    <Modal height={209} open={open} onClose={onClose}>
      <h3 className="text-base mb-4 text-center font-bold text-black mb-2">
        excluir Item
      </h3>

      <p className="text-black text-base w-60 mx-auto text-center">
        <b>atenção!</b> se excluir esta categoria todos os itens cadastrados
        nela serão perdidos.
      </p>

      <div className="flex mt-6 items-center gap-4 max-w-sm mx-auto">
        <Button
          onClick={deleteCategory}
          full
          normal
          size="small"
          variant="outline"
          color="neon"
        >
          <span className="font-bold">excluir</span>
        </Button>
        <Button
          size="small"
          onClick={onClose}
          full
          normal
          variant="light"
          color="neon"
        >
          <span className="font-bold">voltar</span>
        </Button>
      </div>
    </Modal>
  );
}

export default RemoveItemModal;
