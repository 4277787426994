import { Button } from 'oialbert-ui';
import React from 'react';
import type { Option } from '../types';

type OptionItemProps = {
  option: Option;
  editOption: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    Option: Option
  ) => void;
  removeOption: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
    Option: Option
  ) => void;
};

export default function OptionItem({
  option,
  editOption,
  removeOption,
}: OptionItemProps) {
  return (
    <div
      className="flex justify-between border flex items-center justify-between pl-4 py-1.5 border-gray-100 w-full rounded-md"
      key={option.title}
      onClick={(e) => editOption(e, option)}
    >
      {option.title}

      <Button
        onClick={(e) => removeOption(e, option)}
        normal
        color="neon"
        size="small"
        variant="light"
      >
        <span className="text-sm">Excluir</span>
      </Button>
    </div>
  );
}
