// @ts-nocheck
import { zodResolver } from "@hookform/resolvers/zod";
import { IonImg, IonSpinner, useIonAlert } from "@ionic/react";
import { Button } from "oialbert-ui";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { PUTPayment } from "../../../../services/financial/payment";
import { CompanyDetailsData } from "../../../../types/companies";
import { schema } from "./schema";
import {
  CouponData,
  CreditCardBillingForm,
  PaymentPixBillingForm,
} from "../../type";

type Props = {
  company: CompanyDetailsData;
  coupon?: CouponData;
  charge: any;
  couponValidate: boolean;
};

export const PaymentPixForm: React.FC<Props> = ({
  charge,
  company,
  coupon,
  couponValidate,
}: Props) => {
  let [pix, setPix] = useState<any>(null);
  const [presentAlert] = useIonAlert();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const copyToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(pix.qr_code);
    alert(
      "Texto copiado para a área de transferência!" + (pix ? pix.qr_code : "")
    );
  }, [pix]);

  const { handleSubmit } = useForm<PaymentPixBillingForm>({
    resolver: zodResolver(schema),
    defaultValues: {
      payment_method: "pix",
    },
  });

  const handleFormSubmit = useCallback(
    async (formData: CreditCardBillingForm) => {
      const sendPayment = async () => {
        setLoading(true);
        if (!charge?.coupon_copy?.code) {
          formData.coupon_code = coupon?.coupon.code;
        }

        await PUTPayment(charge.id, formData)
          .then((data: any) => {
            if (
              Number(coupon?.coupon.discount_percentage) === 100 ||
              (Array.isArray(coupon?.coupon?.cycles_value) &&
                coupon?.coupon?.cycles_value &&
                coupon?.coupon?.cycles_value?.length > 0 &&
                Number(
                  coupon?.coupon.cycles_value[coupon?.charge_cycle - 1]
                    .discount_percentage
                ) === 100)
            ) {
              history.push("/plan/payment/success", {
                origin: "plan-form-coupon",
              });

              return;
            }
            if (
              Array.isArray(data.order_gateway_copy.charges) &&
              data.order_gateway_copy.charges.length > 0
            ) {
              setPix(data.order_gateway_copy.charges[0].last_transaction);
            } else {
              setPix(data.order_gateway_copy);
            }
          })
          .catch((error) => {
            if (error instanceof Error && error.message) {
              presentAlert(error.message);
            } else {
              presentAlert(
                "Houve um problema no processamento do pagamento; tente novamente ou entre em contato com nosso suporte técnico."
              );
            }
          })
          .finally(() => setLoading(false));
      };

      if (!couponValidate) {
        const message = `O cupom não foi validado, deseja continuar?`;
        const buttons = [
          {
            text: "Sim",
            role: "confirm",
            handler: async () => {
              await sendPayment();
            },
          },
          {
            text: "Não",
            role: "cancel",
          },
        ];

        presentAlert({ header: "Cupom", message, buttons });
      } else {
        await sendPayment();
      }
    },
    [
      couponValidate,
      charge?.coupon_copy?.code,
      charge.id,
      coupon?.coupon.code,
      coupon?.coupon.discount_percentage,
      coupon?.coupon.cycles_value,
      coupon?.charge_cycle,
      history,
      presentAlert,
    ]
  );

  useEffect(() => {
    if (
      charge?.payment_method === "pix" &&
      Array.isArray(charge?.order_gateway_copy?.charges) &&
      charge?.order_gateway_copy?.charges.length > 0
    ) {
      setPix(charge?.order_gateway_copy?.charges[0]?.last_transaction);
    } else {
      setPix(charge.order_gateway_copy);
    }
  }, []);

  return loading ? (
    <section className="flex flex-col bg-white w-full h-full items-center justify-center">
      <IonSpinner className="text-neon-900" />
    </section>
  ) : (
    <>
      {pix ? (
        <div className="flex flex-col gap-6 p-4 py-12">
          <div className="flex flex-col items-center gap-2">
            <h1 className="text-2xl font-semibold">Pix gerado com sucesso!</h1>
            <p className="text-sm font-light">
              utilize o QR Code ou copie o código para realizar o pagamento
            </p>
          </div>
          <div className="flex flex-col gap-3 items-center">
            <IonImg alt="" src={pix.qr_code_url} />
            <span className="text-xs font-semibold text-center">
              {pix.qr_code}
            </span>
          </div>
          <div className="flex gap-3 w-full ">
            <button
              className="bg-neon-900 w-full text-white py-4 px-6 rounded-lg text-center text-sm"
              onClick={copyToClipboard}
            >
              copiar código do pix
            </button>
          </div>
        </div>
      ) : null}
      <form
        className="flex flex-col gap-6 p-4"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Button variant="solid" color="neon">
          gerar {pix ? "novo " : ""}pix
        </Button>
      </form>
    </>
  );
};
