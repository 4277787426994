import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Loading } from '../../../components/Loading';
import { formatShort } from '../../../utils/date';
import { Badge, Card, Icon } from 'oialbert-ui';
import RenderResume from '../../Orders/components/RenderResume';
import DetailsOrder from '../../Orders/components/DetailsOrder';

import type { OrdersProps } from '../types';

const Orders = ({ loading, reloading, data }: OrdersProps) => {
  const history = useHistory();

  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [toggleModal, setToggleModal] = useState<boolean>(false);

  return (
    <>
      {loading && !reloading ? (
        <Loading />
      ) : !loading && data.length === 0 ? (
        <Badge size="default" full>
          nenhum pedido encontrado.
        </Badge>
      ) : (
        <>
          {data.map((order) => (
            <Card key={order.id} className="rounded p-4 space-y-2">
              <section
                onClick={() => history.push(`/orders/${order.id}`)}
                className="flex items-center justify-between"
              >
                <h4 className="font-bold">{order.customer.full_name}</h4>
                <span className="flex lowercase text-xs text-gray-500 items-center">
                  <Icon.MdAccessTime size={16} className="mr-1" />
                  {formatShort(order.updated_at)}
                </span>
              </section>
              <section className="flex flex-col space-y-1">
                <RenderResume
                  resume={order?.resume}
                  onShowDetails={() => {
                    setSelectedOrder(order);
                    setToggleModal(true);
                  }}
                />
              </section>
            </Card>
          ))}
          <DetailsOrder
            selectedOrder={selectedOrder}
            open={toggleModal}
            setToggleModal={setToggleModal}
            setSelectedOrder={setSelectedOrder}
          />
        </>
      )}
    </>
  );
};

export default Orders;
