import { api } from './config';

export const categories = async () => {
  const { status, data } = await api.get('/v1/categories');

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const subcategories = async () => {
  const { status, data } = await api.get('/v1/subcategories');

  if (status === 200 && data) {
    return data;
  }

  return false;
};
