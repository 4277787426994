import { Avatar, Badge, Icon } from 'oialbert-ui';
import { Link, useHistory } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const CompanyHeader = () => {
  const history = useHistory();
  const { user, company } = useAuth();

  return (
    <section className="bg-white">
      <section
        className="mt-5 px-5 flex justify-between items-center"
        onClick={() => history.push('/settings')}
      >
        <Avatar
          variant="circle"
          size="default"
          title={user?.partner?.full_name ?? ''}
          src={company?.photo?.url ?? user?.partner?.photo?.url ?? ''}
          description={<Badge size="small">{company?.name ?? ''}</Badge>}
        />
        {false && (
          <Link to="/notifications">
            <Icon.MdOutlineNotifications className="text-neon-900 text-2xl" />
          </Link>
        )}
      </section>
    </section>
  );
};

export default CompanyHeader;
