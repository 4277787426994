import { IonContent, IonHeader, IonPage } from "@ionic/react";
import { Button, Header, Input, Radio, Toast } from "oialbert-ui";
import { createRef, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Location } from "history";
import useForm from "../../hooks/useForm";
import { useMaskHandler } from "../../hooks/useMaskHandler";
import * as z from "zod";
import { FormPix } from "./components/FormPix";
import { FormBillet } from "./components/FormBillet";
import { FormCreditCard } from "./components/FormCreditCard";
import { copyToClipboard } from "../../utils/copy";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";

interface OnboardingPaymentProps {
  location: Location<any>;
}

const OnboardingPayment = ({ location }: OnboardingPaymentProps) => {
  const history = useHistory();
  const formRef: any = createRef();

  const [discount, setDiscount] = useState<number>(0);
  const [coupon, setCoupon] = useState<string>("");

  const [paymentType, setPaymentType] = useState<string>("credit_card");
  const [receiptState, setReceiptState] = useState<boolean>(false);

  const [receipt, setReceipt] = useState<any>(null);

  const amount = useMemo(
    () =>
      discount > 0
        ? location.state.subscription.price_in_cents - discount
        : location.state.subscription.price_in_cents,
    [discount, location]
  );

  const schema = z.object({
    amount:
      amount === 0
        ? z.number().optional()
        : z.number({ required_error: "campo obrigatório" }),
  });

  const {
    control,
    register,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    schema,
    mode: "onChange",
  });

  const amountProps = useMaskHandler("amount", control, 0);

  useEffect(() => {
    setValue("amount", amount * 100);
  }, [amount, setValue]);

  useEffect(() => {
    return () => {
      setReceiptState(false);
      setValue("amount", 0);
      setDiscount(0);
      setCoupon("");
    };
  }, [setValue]);

  return (
    <IonPage>
      <IonHeader className="ion-no-border bg-white">
        <section className="mt-5 px-5 pb-5">
          <Header onClick={() => history.goBack()} title="voltar" />
        </section>
      </IonHeader>
      <IonContent>
        {receiptState ? (
          <>
            <section className="mt-4 px-5 space-y-8">
              <section className="space-y-2">
                <h1 className="font-bold text-lg">
                  prontinho! seu código pix foi gerado.
                </h1>
                <h2 className="text-sm">
                  agora é só realizar o pagamento e esperar a confirmação por
                  e-mail.
                </h2>
              </section>

              <p className="w-full text-center bg-gray-100 bg-opacity-50 rounded p-4 select-all">
                {receipt?.qr_code ?? ""}
              </p>

              <Button
                full
                color="neon"
                variant="solid"
                onClick={async () => {
                  await copyToClipboard(receipt?.qr_code ?? "");
                  Toast.success("código copiado");
                }}
              >
                copiar código pix
              </Button>
            </section>
          </>
        ) : (
          <>
            <section className="mt-4 px-5">
              <section className="my-2 pb-2 border-b border-crema-500">
                <h3 className="font-sm font-bold text-gray-500 mb-220,00">
                  valor
                </h3>
                <Input
                  disabled
                  type="tel"
                  placeholder="0,00"
                  mask="MONEY"
                  className="bg-white border-0 rounded-none shadow-none font-bold text-2xl text-gray-500 px-0 placeholder-gray-500::placeholder focus:border-0 focus-within:ring-0"
                  moneyProps={{
                    ...amountProps,
                    prefix: "",
                  }}
                  error={errors.amount?.message?.toString()}
                  {...register("amount")}
                />
              </section>
              {discount > 0 && (
                <p className="font-sm">
                  desconto de{" "}
                  <strong className="uppercase text-neon-900">
                    R$ {formatCurrency(discount / 100)}
                  </strong>
                </p>
              )}
            </section>
            <section className="mt-4 px-5">
              <h3 className="text-base font-bold">
                selecione a forma de pagamento
              </h3>

              <section className="flex mt-5 -mx-2">
                <section className="flex flex-1 rounded py-2 items-center justify-center border border-gray-100 mx-2">
                  <Radio
                    label="cartão de crédito"
                    id="creditCard"
                    name="type"
                    value="credit_card"
                    titleClassName="font-base"
                    defaultChecked={paymentType === "credit_card"}
                    onChange={(e) => setPaymentType(e.target.value)}
                  />
                </section>
                <section className="flex flex-1 rounded py-2 items-center justify-center border border-gray-100 mx-2">
                  <Radio
                    label="pix"
                    id="pix"
                    name="type"
                    value="pix"
                    titleClassName="font-base"
                    defaultChecked={paymentType === "pix"}
                    onChange={(e) => setPaymentType(e.target.value)}
                  />
                </section>
                {false && (
                  <section className="flex flex-1 rounded py-2 items-center justify-center border border-gray-100 mx-2">
                    <Radio
                      label="boleto"
                      id="billet"
                      name="type"
                      value="boleto"
                      titleClassName="font-base"
                      defaultChecked={paymentType === "boleto"}
                      onChange={(e) => setPaymentType(e.target.value)}
                    />
                  </section>
                )}
              </section>
            </section>
            <section className="mt-4 px-5 mb-5">
              {paymentType === "pix" && (
                <FormPix
                  couponCode={coupon}
                  formRef={formRef}
                  amount={amount}
                  paymentType={paymentType}
                  isAmountValid={isValid}
                  data={location.state}
                  setDiscount={setDiscount}
                  setCoupon={setCoupon}
                  onCallback={(callback) => {
                    if (callback.success) {
                      setReceipt(callback);
                      setReceiptState(true);
                    }
                  }}
                />
              )}

              {false && (
                <FormBillet
                  couponCode={coupon}
                  formRef={formRef}
                  amount={amount}
                  paymentType={paymentType}
                  isAmountValid={isValid}
                  data={location.state}
                />
              )}

              {paymentType === "credit_card" && (
                <FormCreditCard
                  couponCode={coupon}
                  formRef={formRef}
                  amount={amount}
                  paymentType={paymentType}
                  isAmountValid={isValid}
                  data={location.state}
                  setDiscount={setDiscount}
                  setCoupon={setCoupon}
                />
              )}
            </section>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default OnboardingPayment;
