import { api } from '../config';
import type {
  CreateOrderProps,
  CreateTokenCardProps,
} from '../types/subscriptions';

export const createTokenCard = async (payload: CreateTokenCardProps) => {
  const { status, data } = await api.post(
    '/v1/payments/pagarme/tokencard',
    payload
  );

  if (status === 200 && data) {
    return data;
  }

  return false;
};
// assinatura
export const createSubscriptionOrder = async (payload: CreateOrderProps) => {
  const { status, data } = await api.post(
    '/v1/payments/pagarme/subscription',
    payload
  );

  if (status === 200 && data) {
    return data;
  }

  return false;
};
// deposito
export const createDepositOrder = async (payload: CreateOrderProps) => {
  const { status, data } = await api.post(
    '/v1/payments/pagarme/deposit',
    payload
  );

  if (status === 200 && data) {
    return data;
  }

  return false;
};
// pagamento de crédito
export const createCreditOrder = async (payload: CreateOrderProps) => {
  const { status, data } = await api.post(
    '/v1/payments/pagarme/charge',
    payload
  );

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const getCoupon = async (subscriptionId: string, coupon: string) => {
  const { status, data } = await api.get(
    `/v1/subscriptions/${subscriptionId}/coupons/${coupon}`
  );

  if (status === 200 && data) {
    return data;
  }

  return false;
};
