import { CompanyDetailsData } from '../types/companies';

export const checkData = (
  company: CompanyDetailsData,
  uncompletedSteps: string[]
): Array<string> => {
  const companyData: { [key: string]: any } = company;

  Object.keys(companyData).map((value: string) => {
    const stepIndex = uncompletedSteps.indexOf(value);
    if (stepIndex >= 0 && companyData[value]) {
      uncompletedSteps.splice(stepIndex, 1);
    }

    return value;
  });

  return uncompletedSteps;
};
