import { MoneyProps } from 'oialbert-ui/lib/components/Input/types';
import { Control, Path, useController } from 'react-hook-form';

type DefaultValue = number | undefined;
type NumberValue = string | number | null | undefined;

const centsToValue = (number: NumberValue, returnValue: any = 0) => {
  if (!number) {
    return returnValue;
  }

  return Number(number) / 100;
};

export function useMaskHandler<T = any>(
  name: Path<T>,
  control: Control<any>,
  defaultValue: DefaultValue = null!
): Partial<MoneyProps<T>> {
  const controller = useController({ name, control });
  const value = controller.field.value as NumberValue;

  const config = {
    value: centsToValue(value),
    onValueChange: ({ floatValue }) => {
      const valueInCents = floatValue && floatValue * 100;
      controller.field.onChange(valueInCents);
    },
    defaultValue: centsToValue(defaultValue, null) ?? centsToValue(value, null),
  } as MoneyProps<any>;

  return config;
}
