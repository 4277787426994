import { Icon } from 'oialbert-ui';
import { ReactNode, useEffect, useRef } from 'react';

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Drawer = ({ isOpen, onClose, children }: DrawerProps) => {
  const toggleDrawer = () => {
    onClose();
  };

  const drawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  return (
    <div>
      {isOpen ? (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 z-50">
          <div
            ref={drawerRef}
            className="absolute top-0 right-0 h-full w-2/6 bg-white p-4"
          >
            <Icon.MdClose
              onClick={toggleDrawer}
              className="w-4 h-4 mb-2 text-neon-900 absolute right-4 top-6 cursor-pointer"
            />
            {children}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Drawer;
