// @ts-nocheck
import { Card, Checkbox, Icon, Input, Radio, Select, Toast } from "oialbert-ui";
import { Ref, useCallback, useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import * as z from "zod";
import useForm from "../../../hooks/useForm";

import type { CompanyDetailsData } from "../../../types/companies";

import cep from "cep-promise";

import { useCameraGallery } from "../../../contexts/CameraGalleryContext";
import useAuth from "../../../hooks/useAuth";
import { profile_types } from "../../../utils/texts";
import { isValidPhoneNumber } from "../../../utils/validate-phone-number";
import { BannerCompany } from "./BannerCompany";
import Category from "./Category";
import { Cover } from "./Cover";
import { Description } from "./Description";

type FormProps = {
  formRef: Ref<HTMLFormElement>;
  onSubmit: (_payload: CompanyDetailsData) => void;
  loading: boolean;
  defaultValues: any;
};

const CompanySchema = z.object({
  profile_type: z
    .string({
      required_error: "campo obrigatório",
    })
    .nonempty({ message: "campo obrigatório" }),
  name: z
    .string({
      required_error: "campo obrigatório",
    })
    .nonempty({ message: "campo obrigatório" }),
  description: z.string().optional().default(""),
  corporate_name: z
    .string({
      required_error: "campo obrigatório",
    })
    .nonempty({ message: "campo obrigatório" }),
  email: z
    .string({
      required_error: "campo obrigatório",
    })
    .nonempty({ message: "campo obrigatório" })
    .email("e-mail inválido"),
  phone: z
    .string()
    .nonempty({ message: "campo obrigatório" })
    .refine((value) => isValidPhoneNumber(value), {
      message: "número inválido",
    }),
  whatsapp: z.string().optional(),
  address: z.object({
    zip_code: z
      .string({
        required_error: "campo obrigatório",
      })
      .nonempty({ message: "campo obrigatório" }),
    street: z
      .string({
        required_error: "campo obrigatório",
      })
      .nonempty({ message: "campo obrigatório" }),
    street_number: z
      .string({
        required_error: "campo obrigatório",
      })
      .regex(/^(\s*|\d+)$/)
      .optional()
      .default(""),
    neighborhood: z
      .string({
        required_error: "campo obrigatório",
      })
      .nonempty({ message: "campo obrigatório" }),
    city: z
      .string({
        required_error: "campo obrigatório",
      })
      .nonempty({ message: "campo obrigatório" }),
    state: z
      .string({
        required_error: "campo obrigatório",
      })
      .nonempty({ message: "campo obrigatório" }),
    complement: z.string().optional(),
  }),
  payment_types: z
    .object({
      cash: z.boolean().optional(),
      pix: z.boolean().optional(),
      credit_card: z.boolean().optional(),
      debit_card: z.boolean().optional(),
    })
    .refine((value) => {
      const paymentTypes = Object.keys(value).filter(
        (key) => value[key as keyof typeof value]
      );
      if (paymentTypes.length === 0) {
        Toast.crema("selecione ao menos uma forma de pagamento");
        return false;
      }

      return true;
    }),
  banner: z.any().optional(),
  photo: z.any().optional(),
  has_delivery: z.string().optional().default(""),
});

const Form = ({ formRef, onSubmit, loading, defaultValues }: FormProps) => {
  const { company } = useAuth();

  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);
  const [categoryModalVisible, setCategoryModalVisible] =
    useState<boolean>(false);
  const [selectedSubcategories, setSelectedSubcategories] = useState<
    { title: string; id: string }[]
  >(defaultValues?.subcategories ?? []);
  const [previewBanner, setPreviewBanner] = useState<string | undefined>();
  const [bannerCropVisible, setBannerCropVisible] = useState(true);

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    setFocus,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
      ...defaultValues.address,
      description: defaultValues.description,
      payment_types: defaultValues?.payment_types ?? { cash: true },
      has_delivery: defaultValues?.has_delivery?.toString(),
      banner: company?.banner?.url ?? defaultValues?.banner?.url,
      photo: company?.photo?.url ?? defaultValues?.photo?.url,
      whatsapp: company?.whatsapp ?? defaultValues?.whatsapp,
      address: {
        ...defaultValues.address,
        street_number: String(defaultValues.address.street_number),
      },
      profile_type: defaultValues?.profile_type ?? "",
    },
    schema: CompanySchema,
  });

  const { openActions, resource, clearResource } = useCameraGallery();
  const [preview, setPreview] = useState<string | null>(null);

  const field = watch();

  const searchZipCode = useCallback(async () => {
    setLoadingAddress(true);
    try {
      const dataZipCode = await cep(
        field?.address?.zip_code?.replace(/[^a-zA-Z0-9]/g, "")
      );
      setValue("address.neighborhood", dataZipCode.neighborhood);
      setValue("address.city", dataZipCode.city);
      setValue("address.state", dataZipCode.state);
      setValue("address.street", dataZipCode.street);
    } catch (err) {
      console.log("error searchZipcode", err);
    } finally {
      setLoadingAddress(false);
    }
  }, [field?.address?.zip_code, setValue]);

  const setImage = useCallback(
    async (resource) => {
      if (resource) {
        const image = await fetch(resource?.[0].webPath as string);
        const blob = await image.blob();
        setValue("photo", blob);
        setPreview(resource?.[0].webPath as string);
      } else {
        setValue("photo", null);
        setPreview(null);
      }
    },
    [setValue]
  );

  const zipCode: string | null = useMemo(
    () => watch("address.zip_code"),
    [watch("address.zip_code")]
  );
  const zipCodeRegex = zipCode?.replace(/[_\\.-]/g, "");
  const zipCodeDisabled = useMemo(
    () => zipCodeRegex?.length === 8 && true,
    [zipCode]
  );

  useEffect(() => {
    register("photo", { value: "" });
    register("banner", { value: "" });
    register("description", { value: "" });

    return () => {
      setImage(null);
      clearResource();
    };
  }, [clearResource, register, setImage]);

  useEffect(() => {
    if (resource) setImage(resource);
  }, [resource, setImage]);

  useEffect(() => {
    const firstError = (
      Object.keys(errors) as Array<keyof typeof errors>
    ).reduce<keyof typeof errors | null>((field, a) => {
      const fieldKey = field as keyof typeof errors;

      if (["address", "description"].includes((fieldKey || a) as string)) {
        return null;
      }

      return !!errors[fieldKey] ? fieldKey : a;
    }, null) as string;

    if (firstError !== null && firstError !== "payment_types")
      setFocus(firstError);
  }, [errors, setFocus]);

  return (
    <form
      ref={formRef}
      className="w-full flex flex-col mt-5 px-5 space-y-4"
      onSubmit={handleSubmit((data: CompanyDetailsData) => {
        onSubmit({
          ...data,
          subcategories: selectedSubcategories as any,
          description: field?.description,
        });
      })}
    >
      <section className="relative bg-white rounded-3xl h-72 mb-8 shadow-lg">
        <Cover
          current={field.banner}
          setValue={setValue}
          setCurrentBanner={setPreviewBanner}
          setBannerCropVisible={setBannerCropVisible}
        />
        <section className="absolute cursor-pointer -bottom-8 left-6 w-24 overflow-x-auto overflow-y-none shadow-lg rounded-full z-2">
          <section className="flex space-x-4 h-24">
            <Card
              onClick={() => openActions()}
              className="h-24 w-full flex-none flex flex-col justify-center items-center shadow-none"
            >
              {preview ?? defaultValues?.photo ? (
                <img
                  className="object-cover h-24 w-full"
                  src={preview ?? defaultValues.photo.url}
                  alt="Preview da foto"
                />
              ) : (
                <>
                  <Icon.MdCameraAlt className="text-gray-500 text-3xl" />
                  <span className="text-gray-500">alterar</span>
                </>
              )}
            </Card>
          </section>
        </section>
      </section>
      {previewBanner && bannerCropVisible && (
        <div className="h-48 relative mt-5">
          <Controller
            control={control}
            name="banner"
            render={({ field: { ref, onChange, ...props } }) => (
              <BannerCompany
                setValue={setValue}
                onChange={onChange}
                previewBanner={previewBanner}
                visible={bannerCropVisible}
                setVisible={setBannerCropVisible}
                {...props}
              />
            )}
          />
        </div>
      )}
      <Controller
        control={control}
        name="name"
        rules={{ required: true }}
        render={({ field: { value, onChange, ...props } }) => (
          <Input
            label="nome"
            placeholder="Nome da empresa"
            disabled={loading}
            value={value ?? ""}
            required
            error={errors.name?.message?.toString()}
            onChange={(e) => onChange(e.target.value)}
            {...props}
          />
        )}
      />
      <Controller
        control={control}
        name="corporate_name"
        rules={{ required: true }}
        render={({ field: { value, onChange, ...props } }) => (
          <Input
            label="razão social"
            placeholder="razão social da empresa"
            disabled={loading}
            value={value ?? ""}
            required
            error={errors.corporate_name?.message?.toString()}
            onChange={(e) => onChange(e.target.value)}
            {...props}
          />
        )}
      />
      <Select
        required
        label="perfil da loja"
        id="profile"
        error={errors.profile_type?.message?.toString()}
        placeholder="selecione"
        options={Object.keys(profile_types).map((p) => ({
          label: profile_types[p],
          value: p,
        }))}
        {...register("profile_type")}
      />
      <Description
        value={field?.description ?? ""}
        onChange={(description) => setValue("description", description)}
        error={errors.description?.message?.toString()}
      />
      <Controller
        control={control}
        name="email"
        rules={{ required: true }}
        render={({ field: { value, ...props } }) => (
          <Input
            type="email"
            label="endereço de e-mail"
            placeholder="E-mail"
            disabled={loading}
            value={value ?? ""}
            autoComplete="off"
            required
            error={errors.email?.message?.toString()}
            {...props}
          />
        )}
      />
      <Controller
        control={control}
        name="phone"
        rules={{ required: true }}
        render={({ field: { value, ...props } }) => (
          <Input
            type="tel"
            label="número de telefone"
            placeholder="(DD) + Telefone"
            disabled={loading}
            value={value ?? ""}
            mask="CELLPHONE"
            required
            error={errors.phone?.message?.toString()}
            {...props}
          />
        )}
      />
      <Controller
        control={control}
        name="whatsapp"
        rules={{ required: true }}
        render={({ field: { value, ...props } }) => (
          <Input
            type="tel"
            label="número de whatsapp"
            placeholder="(DD) + Whatsapp"
            disabled={loading}
            value={value ?? ""}
            mask="CELLPHONE"
            required
            error={errors.whatsapp?.message?.toString()}
            {...props}
          />
        )}
      />
      <div className="flex flex-col gap-y-2">
        <Controller
          control={control}
          name="address.zip_code"
          rules={{ required: true }}
          render={({ field: { value, ...props } }) => (
            <Input
              type="tel"
              label="endereço completo"
              placeholder="CEP"
              disabled={loading}
              value={value ?? ""}
              mask="CEP"
              required
              error={errors.address?.zip_code?.message?.toString()}
              {...props}
              onBlur={(e) => {
                e.target.value.length === 9 && searchZipCode();
              }}
            />
          )}
        />
      </div>
      <Controller
        control={control}
        name="address.street"
        rules={{ required: true }}
        render={({ field: { value, ...props } }) => (
          <Input
            placeholder="Endereço da empresa"
            disabled={loadingAddress || loading}
            value={value ?? ""}
            required
            error={errors.address?.street?.message?.toString()}
            {...props}
          />
        )}
      />
      <section className="flex space-x-4 mb-4">
        <Controller
          control={control}
          name="address.street_number"
          render={({ field: { value, ...props } }) => (
            <Input
              placeholder="Número"
              disabled={loadingAddress || loading}
              value={value ?? ""}
              error={errors.address?.street_number?.message?.toString()}
              {...props}
            />
          )}
        />
        <Controller
          control={control}
          name="address.neighborhood"
          rules={{ required: true }}
          render={({ field: { value, ...props } }) => (
            <Input
              placeholder="Bairro"
              disabled={loadingAddress || loading}
              value={value ?? ""}
              required
              error={errors.address?.neighborhood?.message?.toString()}
              {...props}
            />
          )}
        />
      </section>
      <section className="flex space-x-4 mb-4">
        <Controller
          control={control}
          name="address.city"
          rules={{ required: true }}
          render={({ field: { value, ...props } }) => (
            <Input
              placeholder="Cidade"
              disabled={loadingAddress || loading || zipCodeDisabled}
              value={value ?? ""}
              required
              error={errors.address?.city?.message?.toString()}
              {...props}
            />
          )}
        />
        <Controller
          control={control}
          name="address.state"
          rules={{ required: true }}
          render={({ field: { value, ...props } }) => (
            <Input
              placeholder="Estado"
              disabled={loadingAddress || loading || zipCodeDisabled}
              value={value ?? ""}
              required
              error={errors.address?.state?.message?.toString()}
              {...props}
            />
          )}
        />
      </section>
      <section className="flex space-x-4 mb-4">
        <Controller
          control={control}
          name="address.complement"
          rules={{ required: true }}
          render={({ field: { value, ...props } }) => (
            <Input
              placeholder="Complemento"
              disabled={loadingAddress}
              value={value ?? ""}
              required
              error={errors.address?.complement?.message?.toString()}
              {...props}
            />
          )}
        />
      </section>
      <section className="flex flex-col">
        <h3 className="text-base font-bold">formas de pagamentos aceitas</h3>
        <section className="flex flex-col items-center mt-3 space-y-4">
          <section className="w-full flex border border-gray-100 rounded py-2 px-4 items-center justify-center">
            <Controller
              control={control}
              name="payment_types[credit_card]"
              render={({ field: { value, ...props } }) => (
                <Checkbox
                  label="Cartão Crédito"
                  id="paymentTypesCreditCard"
                  disabled={loading}
                  value="credit_card"
                  defaultChecked={defaultValues?.payment_types?.credit_card}
                  {...props}
                />
              )}
            />
          </section>
          <section className="w-full flex border border-gray-100 rounded py-2 px-4 items-center justify-center">
            <Controller
              control={control}
              name="payment_types[debit_card]"
              render={({ field: { value, ...props } }) => (
                <Checkbox
                  label="Cartão Débito"
                  id="paymentTypesDebitCard"
                  disabled={loading}
                  value="debit_card"
                  defaultChecked={defaultValues?.payment_types?.debit_card}
                  {...props}
                />
              )}
            />
          </section>
        </section>
        <section className="flex flex-col items-center mt-3 space-y-4">
          <section className="w-full flex border border-gray-100 rounded py-2 px-4 items-center justify-center">
            <Controller
              control={control}
              name="payment_types[pix]"
              render={({ field: { value, ...props } }) => (
                <Checkbox
                  label="PIX"
                  id="paymentTypesPix"
                  disabled={loading}
                  value="pix"
                  defaultChecked={defaultValues?.payment_types?.pix}
                  {...props}
                />
              )}
            />
          </section>
          <section className="w-full flex border border-gray-100 rounded py-2 px-4 items-center justify-center">
            <Controller
              control={control}
              name="payment_types[cash]"
              render={({ field: { value, ...props } }) => (
                <Checkbox
                  label="Dinheiro"
                  id="paymentTypesCash"
                  value="cash"
                  disabled={loading}
                  defaultChecked={defaultValues?.payment_types?.cash}
                  {...props}
                />
              )}
            />
          </section>
        </section>
      </section>
      <section className="flex flex-col">
        <h3 className="text-base font-bold">
          A empresa aceita realizar a entrega de produtos?
        </h3>
        <Controller
          control={control}
          name="has_delivery"
          rules={{ required: true }}
          render={({ field: { ...props } }) => (
            <section className="flex items-center mt-3 space-x-4">
              <section className="flex flex-1 border border-gray-100 rounded py-2 px-4 items-center justify-center">
                <Radio
                  {...props}
                  required
                  label="Sim"
                  id="hasDeliveryYes"
                  disabled={loading}
                  titleClassName="font-base font-bold"
                  value="true"
                  defaultChecked={defaultValues.has_delivery}
                />
              </section>
              <section className="flex flex-1 border border-gray-100 rounded py-2 px-4 items-center justify-center">
                <Radio
                  {...props}
                  required
                  label="Não"
                  id="hasDeliveryNo"
                  disabled={loading}
                  titleClassName="font-base font-bold"
                  value="false"
                  defaultChecked={!defaultValues.has_delivery}
                />
              </section>
            </section>
          )}
        />
      </section>
      <section className="flex flex-col">
        <h3 className="text-base font-bold">
          Selecione o tipo de categoria da empresa
        </h3>
        <button
          type="button"
          className="form-input h-12 focus:ring-neon-500 focus:border-neon-500 block w-full sm:text-sm border-gray-500 rounded mt-4 flex items-center"
          onClick={() => setCategoryModalVisible(true)}
        >
          <span>
            {selectedSubcategories.length
              ? selectedSubcategories
                  ?.map((subcategory: any) => subcategory.title)
                  .join(", ")
              : "escolher categorias"}
          </span>
        </button>
        <Category
          onClose={() => setCategoryModalVisible(false)}
          open={categoryModalVisible}
          onSelect={(subcategories) =>
            subcategories.length && setSelectedSubcategories(subcategories)
          }
          values={selectedSubcategories}
        />

        {selectedSubcategories.length === 0 && (
          <p className={`mt-2 text-sm text-neon-900 w-full lowercase`}>
            * campo obrigatório
          </p>
        )}
      </section>
    </form>
  );
};

export default Form;
