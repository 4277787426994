import { Modal, Button, Input, Checkbox, Toast } from "oialbert-ui";
import { ModalProps } from "oialbert-ui/lib/components/Modal/types";
import { useCallback, useEffect } from "react";
import useForm from "../../../hooks/useForm";
import * as z from "zod";
import { useProductCategories } from "../hooks";
import { Category } from "../types";
import { updateProductCategory } from "../../../services/productCategories";
import { useController } from "react-hook-form";
import errorHandling from "../../../utils/error_handling";

type Props = Pick<ModalProps, "onClose" | "open"> & {
  category?: Category | null;
  afterSave: () => void;
};
type FormValues = z.infer<typeof schema>;

const schema = z.object({
  title: z.string().nonempty({ message: "Preencha o nome da categoria" }),
  is_active: z.any(),
});

function CreateMenu({ onClose, open, category, afterSave }: Props) {
  const { createProductCategory } = useProductCategories();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      title: "",
      is_active: true,
    },
    schema,
  });

  const isActive = useController({ control, name: "is_active" });

  useEffect(() => {
    reset();
    if (category) {
      setValue("title", category.title);
      setValue("is_active", category.is_active);
    }
  }, [category, reset, setValue]);

  const saveCategory = useCallback(
    async (data: FormValues) => {
      try {
        if (category?.id) {
          await updateProductCategory(category?.id, data);
        } else {
          await createProductCategory(data);
        }

        Toast.success("Categoria salva com sucesso!");

        reset();
        onClose();
        afterSave();
      } catch (error: any) {
        errorHandling(error, "Erro ao salvar categoria");
      }
    },
    [category]
  );

  return (
    <Modal height={160} open={open} onClose={onClose}>
      <form
        className="w-full flex flex-col space-y-4"
        onSubmit={handleSubmit(saveCategory)}
      >
        <div className="mb-4">
          <Input
            error={errors.title?.message?.toString()}
            placeholder="Nome da categoria"
            {...register("title")}
          />
        </div>

        <div className="mb-4">
          <Checkbox
            label="Ativa"
            id="is_active"
            error={errors.is_active?.message?.toString()}
            {...isActive.field}
            defaultChecked={getValues("is_active")}
          />
        </div>

        <div className="flex gap-4 max-w-sm mx-auto">
          <Button type="submit" full normal variant="outline" color="neon">
            <span className="font-bold">Salvar</span>
          </Button>
          <Button
            type="button"
            onClick={onClose}
            full
            normal
            variant="light"
            color="neon"
          >
            <span className="font-bold">Voltar</span>
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default CreateMenu;
