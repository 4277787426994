import { Modal, Button, Radio, Icon } from 'oialbert-ui';
import { ModalProps } from 'oialbert-ui/lib/components/Modal/types';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Category } from '../../Menus/types';

type Props = Pick<ModalProps, 'onClose' | 'open'> & {
  categories: Category[];
  category?: Category | null;
  onSelect: (item: Category) => void;
  showMenu?: boolean;
};

const SelectMenuItem = ({
  onClose,
  open,
  categories,
  category,
  onSelect,
  showMenu = true,
}: Props) => {
  const history = useHistory();

  const selectCategory = useCallback((category: Category) => {
    onSelect(category);
    onClose();
  }, []);

  return (
    <Modal height={209} open={open} onClose={onClose}>
      <div className="space-y-2 p-4 h-64 overflow-y-auto">
        {categories.map((item) => (
          <Radio
            defaultChecked={item.id === category?.id}
            key={item.id}
            onClick={() => selectCategory(item)}
            label={item.title}
            id={item.id}
            name="category"
          />
        ))}
      </div>

      {showMenu && (
        <div className="flex items-center justify-center mt-4">
          <Button
            onClick={() => history.push('/menus')}
            normal
            size="small"
            variant="light"
            color="neon"
          >
            <span className="flex items-center justify-center">
              <Icon.MdAdd className="mr-1" />
              Cadastrar Menu
            </span>
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default SelectMenuItem;
