import { IonToggle } from '@ionic/react';
import { Button } from 'oialbert-ui';
import { useCallback, useEffect } from 'react';
import OnboardingImage from '../../../assets/illustrations/onboarding-delivery.png';
import useAuth from '../../../hooks/useAuth';
import { useCompany } from '../../../hooks/useCompany';
import useForm from '../../../hooks/useForm';
import { CompanyDetailsData } from '../../../types/companies';
import errorHandling from '../../../utils/error_handling';

type DeliveryType = {
  dataTemp: any;
  setDataTemp: (_payload: any) => void;
  onNext: () => void;
};

const Delivery = ({ onNext, dataTemp, setDataTemp }: DeliveryType) => {
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { isValid },
  } = useForm<any>({
    mode: 'onChange',
  });

  const field = watch();

  const { setCompany } = useAuth();
  const { updateCompany, company } = useCompany();

  const { document_number, ...restDataTemp } = dataTemp;

  const handleUpdate = useCallback(
    async (payloadData) => {
      try {
        await updateCompany(
          {
            id: restDataTemp?.id,
            ...company,
            ...restDataTemp,
            subcategories: restDataTemp.subcategories.map(
              ({ id: idSubcategory }: { id: string }) => idSubcategory
            ),
            has_delivery: payloadData.has_delivery,
          },
          () => onNext()
        );
        setDataTemp({
          ...company,
          ...restDataTemp,
          document_number,
          has_delivery: payloadData.has_delivery,
        });
        setCompany({
          ...company,
          ...restDataTemp,
          document_number,
          has_delivery: payloadData.has_delivery,
        } as CompanyDetailsData);
      } catch (err) {
        errorHandling(err, 'erro ao atualizar empresa', 'crema');
      }
    },
    [
      company,
      document_number,
      onNext,
      restDataTemp,
      setCompany,
      setDataTemp,
      updateCompany,
    ]
  );

  useEffect(() => {
    register('has_delivery', { value: restDataTemp.has_delivery });
  }, [register, restDataTemp.has_delivery]);

  return (
    <section className="sm:mx-auto sm:w-full sm:max-w-xl flex flex-col items-center justify-between text-center px-6 mt-6 space-y-12">
      <p>seu estabelecimento aceita delivery?</p>
      <section className="w-full flex flex-col items-center justify-center mx-auto mx-4">
        <section className="w-full px-4">
          <section className="w-full flex items-center justify-between py-4 px-8 rounded">
            <p className="text-base text-gray-500">delivery</p>
            <IonToggle
              checked={field.has_delivery}
              onIonChange={(e) => setValue('has_delivery', e.detail.checked)}
            />
          </section>
        </section>
      </section>
      <img src={OnboardingImage} alt="Onboarding" />
      <Button
        full
        type="button"
        disabled={!isValid}
        variant="solid"
        color="neon"
        onClick={handleSubmit(handleUpdate)}
      >
        próximo
      </Button>
    </section>
  );
};

export default Delivery;
