import { Card, IconBs } from 'oialbert-ui';
import useWallet from '../../../../hooks/useWallet';
import { formatInCents } from '../../../../utils/money';
import { useState } from 'react';
import FullCreditInfo from '../../FinancialInfo/FullCreditInfo';
import ValueUsedInfo from '../../FinancialInfo/ValueUsedInfo';

export const DebitBalanceItem = () => {
  const { wallets } = useWallet();

  const debitBalance = Number(wallets?.total_credits_used_in_cents);
  const limitInCents = Number(wallets?.credit_value_in_cents);
  const valueInCents = Number(
    wallets?.total_credits_available_in_cents + wallets?.total_balance_in_cents
  );

  const [debitBalanceItem, setDebitBalanceItem] = useState<boolean>(false);
  const [valueUsedInfo, setValueUsedInfo] = useState<boolean>(false);

  return (
    <Card className="rounded-b">
      <div className=" w-full h-full px-3 py-6 rounded-t flex items-center flex-col gap-y-2 ">
        <div className="w-full flex items-center justify-between">
          <section className="flex items-center">
            <p className="text-md">crédito total</p>
            <button
              onClick={() => {
                setDebitBalanceItem(true);
              }}
            >
              <p className="px-3">
                <IconBs.BsInfoCircle className="w-3 h-3 text-grey-400 mr-1" />
              </p>
            </button>
          </section>
          <p className="text-lg font-bold uppercase">
            {formatInCents(limitInCents ? limitInCents : 0)}
          </p>
        </div>
        {/*  */}
        <div className="w-full flex items-center justify-between">
          <section className="flex items-center">
            <p className="text-md ">valor utilizado</p>
            <button
              onClick={() => {
                setValueUsedInfo(true);
              }}
            >
              <p className="px-3">
                <IconBs.BsInfoCircle className="w-3 h-3 text-grey-400 mr-1" />
              </p>
            </button>
          </section>
          <p className="text-lg font-bold uppercase">
            {formatInCents(debitBalance ? debitBalance : 0)}
          </p>
        </div>
      </div>
      <div className="w-full h-0.5 bg-crema-500"></div>
      {/*  */}
      <div className="w-full h-full rounded-b px-3 py-2 ">
        <div className="w-full flex items-center justify-between">
          <section className="flex items-center">
            <p className="text-md font-bold">disponível para uso</p>
          </section>
          <p className="text-lg font-bold text-neon-900 uppercase">
            {formatInCents(valueInCents ?? 0)}
          </p>
        </div>
      </div>
      <FullCreditInfo open={debitBalanceItem} setOpen={setDebitBalanceItem} />
      <ValueUsedInfo open={valueUsedInfo} setOpen={setValueUsedInfo} />
    </Card>
  );
};
