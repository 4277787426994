import { Button, Input, Toast } from 'oialbert-ui';
import { Fragment, useCallback, useState } from 'react';
import { getCoupon } from '../../../../services/financial/subscription';
import errorHandling from '../../../../utils/error_handling';

type CouponType = {
  subscriptionId: string;
  onApply: (_value: number) => void;
  onCoupon: (_value: string) => void;
};

export const Coupon = ({ subscriptionId, onApply, onCoupon }: CouponType) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [couponValid, setCouponValid] = useState<boolean>(false);
  const [coupon, setCoupon] = useState<string>('');

  const onSearchCode = useCallback(
    async (data) => {
      setLoading(true);
      try {
        const { discount } = await getCoupon(
          subscriptionId,
          data?.toUpperCase()?.replace(/\s/g, '')
        );
        onApply(discount);
        onCoupon(coupon);
        Toast.success('cupom aplicado com sucesso!');
        setCouponValid(true);
      } catch (err) {
        errorHandling(err, 'erro ao aplicar cupom de desconto', 'crema');
        setCouponValid(false);
      } finally {
        setLoading(false);
      }
    },
    [coupon, onApply, onCoupon, subscriptionId]
  );

  return (
    <section className="flex space-x-4 justify-between items-center mt-4">
      {!couponValid ? (
        <Fragment>
          <Input
            required
            placeholder="código do cupom"
            onChange={(e) => setCoupon(e.target.value)}
          />
          <Button
            disabled={coupon.length <= 0 || loading}
            variant="solid"
            color="neon"
            onClick={() => onSearchCode(coupon)}
          >
            {loading ? '...' : 'aplicar'}
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          <p>
            cupom aplicado:{' '}
            <strong className="font-bold">{coupon?.replace(/\s/g, '')}</strong>
          </p>
          <Button
            size="small"
            variant="light"
            color="neon"
            onClick={() => {
              setCouponValid(false);
              setCoupon('');
              onApply(0);
            }}
          >
            remover
          </Button>
        </Fragment>
      )}
    </section>
  );
};
