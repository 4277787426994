import { IonContent, IonPage } from '@ionic/react';

import { Button } from 'oialbert-ui';
import { Redirect, useHistory, useLocation } from 'react-router';
import Illustration from '../../assets/illustrations/confirmed_payment.svg';

function ruleOfThree(value: number, base = 812) {
  return (value * window.innerHeight) / base;
}

const PlanPaymentSuccess: React.FC = () => {
  const { state } = useLocation<{
    origin: string;
    method: string;
    company_id: number;
  }>();
  const history = useHistory();

  if (
    !state?.origin ||
    !['plan-form', 'plan-form-coupon'].includes(state.origin)
  ) {
    return <Redirect to="/" />;
  }

  return (
    <IonPage>
      <IonContent>
        <div className="flex flex-col py-3 justify-center h-full px-4 gap-20">
          <div className="flex justify-center">
            <img
              src={Illustration}
              height={ruleOfThree(300)}
              width={ruleOfThree(300)}
              alt="uma mulher olhando para uma tela de celular confirmando o sucesso de seu pagamento"
            />
          </div>
          {state.origin === 'plan-form-coupon' && (
            <p className="text-center">
              <span className="font-bold">Oi, que bom ter você por aqui!</span>
              <br />
              <br />
              <p className="mb=8">
                Hora de fazer o seu negócio decolar e potencializar ainda mais
                as suas vendas.
              </p>
              <p>O seu pagamento já está sendo processado. Vamos juntos!</p>
              <br />
              <p className="text-lg font-semibold">Bem-vindo ao albert!</p>
            </p>
          )}
          {state.origin === 'plan-form' && (
            <p className="text-center">
              <span className="font-bold">Oi, que bom ter você por aqui!</span>
              <br />
              <p className="mb=8">
                Hora de fazer o seu negócio decolar e potencializar ainda mais
                as suas vendas.
              </p>
              <p>O seu pagamento já está sendo processado. Vamos juntos!</p>
            </p>
          )}
          <Button
            color="neon"
            variant="solid"
            onClick={() => {
              if (state.method === 'boleto') {
                history.replace(`/onboarding/${state.company_id}`);
                return;
              }
              history.replace('/select-company');
            }}
          >
            {state.origin === 'plan-form-coupon' ? 'Acesse o app' : 'entendi'}
          </Button>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PlanPaymentSuccess;
