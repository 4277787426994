import { Dialog, Transition } from '@headlessui/react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Icon } from 'oialbert-ui';
import { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading';
import { findInvoice } from '../../../services/invoices';
import { Charge, Invoice } from '../../../types/invoice';
import { formatInCents } from '../../../utils/money';

type InvoiceDetailModalProps = {
  invoiceId?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const InvoiceDetailModal = memo(
  ({ invoiceId, isOpen, onClose }: InvoiceDetailModalProps) => {
    const [invoice, setInvoice] = useState<Invoice | undefined>(undefined);
    const [charge1, setCharge1] = useState<Charge | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const getInvoiceDetail = useCallback(async () => {
      if (!invoiceId) {
        setInvoice(undefined);
        setCharge1(undefined);
        return;
      }

      try {
        setLoading(true);
        const response = await findInvoice(invoiceId);
        const lastCharge = response.charges[response.charges.length - 1];

        setInvoice(response);
        setCharge1(lastCharge);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }, [invoiceId]);

    useEffect(() => {
      getInvoiceDetail();
    }, [invoiceId, getInvoiceDetail]);

    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          onClose={onClose}
          className="relative z-10 flex justify-center items-center h-screen"
        >
          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
            <Dialog.Title
              as="h3"
              className="text-xl font-medium leading-6 text-gray-900 text-center font-bold"
            >
              informações do carrinho
            </Dialog.Title>

            <button
              className="absolute top-6 right-6 bg-neon-900 p-1.5 rounded-md"
              onClick={onClose}
            >
              <Icon.MdClose color="white" />
            </button>

            {loading && (
              <div className="display-flex flex-column align-items-center w-full h-full m-4">
                <Loading />
              </div>
            )}

            {!loading && invoice && charge1 && (
              <>
                <div className="flex flex-col gap-1 mt-8">
                  <div className="flex justify-between">
                    <p className="text-gray-500">Produto</p>
                    <p className="font-bold">
                      {invoice?.product_copy?.name.startsWith('cart_')
                        ? 'Serviços extras'
                        : invoice?.product_copy?.name}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <p className="text-gray-500">data</p>
                    <p className="font-bold">
                      {charge1.status === 'paid'
                        ? format(
                            new Date(charge1.charge_date),
                            "dd 'de' MMMM 'de' yyyy",
                            { locale: ptBR }
                          )
                        : format(
                            new Date(charge1.created_at),
                            "dd 'de' MMMM 'de' yyyy",
                            { locale: ptBR }
                          )}
                    </p>
                  </div>
                </div>

                <hr className="border-1 border-dashed mt-3" />

                <div className="mt-4">
                  <div className="flex justify-between">
                    <p className="text-lg font-bold">Itens</p>
                    <p className="text-lg font-bold">Valores</p>
                  </div>
                  <div className="flex flex-col gap-1 mt-2">
                    {invoice.product_copy.packages.map((item) => (
                      <div className="flex justify-between">
                        <p className="text-gray-500">
                          {item?.name}
                          <br />
                          <span className="text-xs">{item.description}</span>
                        </p>
                        <p className="font-bold uppercase">
                          {formatInCents(item.price_in_cents)}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>

                <hr className="border-1 border-dashed mt-3" />

                <div className="flex flex-col gap-2 mt-6">
                  {charge1?.coupon_copy &&
                    !isNaN(
                      Number(charge1?.coupon_copy?.discount_percentage)
                    ) && (
                      <div className="flex justify-between">
                        <p className="font-bold">desconto</p>
                        <p className="font-bold uppercase text-red-500">
                          {formatInCents(
                            invoice?.price_in_cents *
                              (Number(
                                charge1?.coupon_copy?.discount_percentage
                              ) /
                                100)
                          )}
                        </p>
                      </div>
                    )}
                  <div className="flex justify-between">
                    <p className="font-bold">Valor pago</p>
                    <p className="text-xl font-bold uppercase">
                      {charge1.status === 'paid'
                        ? formatInCents(charge1.price_in_cents_paid)
                        : formatInCents(charge1.price_in_cents)}
                    </p>
                  </div>
                </div>
              </>
            )}
          </Dialog.Panel>
        </Dialog>
      </Transition>
    );
  }
);
