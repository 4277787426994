import { useState, useCallback } from 'react';
import errorHandling from '../utils/error_handling';

import { categories, subcategories } from '../services/categories';
import type { Categories } from '../types/categories';

export const useCategory = () => {
  const [dataCategories, setDataCategories] = useState<Categories[]>([]);
  const [dataSubcategories, setDataSubcategories] = useState<any>([]);

  const [loadingCategories, setLoadingCategories] = useState<boolean>(false);
  const [loadingSubcategories, setLoadingSubcategories] =
    useState<boolean>(false);

  const getDataCategories = useCallback(async () => {
    setLoadingCategories(true);
    try {
      const response = await categories();
      setDataCategories(response.categories);
    } catch (err) {
      errorHandling(err, 'erro ao buscar categorias', 'crema');
    } finally {
      setLoadingCategories(false);
    }
  }, []);

  const getDataSubcategories = useCallback(async () => {
    setLoadingSubcategories(true);
    try {
      const response = await subcategories();
      setDataSubcategories(response.subcategories);
    } catch (err) {
      errorHandling(err, 'erro ao buscar subcategorias', 'crema');
    } finally {
      setLoadingSubcategories(false);
    }
  }, []);

  return {
    getCategories: getDataCategories,
    categories: dataCategories,
    loadingCategories,
    getSubcategories: getDataSubcategories,
    subcategories: dataSubcategories,
    loadingSubcategories,
  };
};
