import { zodResolver } from "@hookform/resolvers/zod";
import { IonSpinner, useIonAlert } from "@ionic/react";
import cep from "cep-promise";
import { Button, Input } from "oialbert-ui";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import useAuth from "../../../../hooks/useAuth";
import { PUTPayment } from "../../../../services/financial/payment";
import { CompanyDetailsData } from "../../../../types/companies";
import errorHandling from "../../../../utils/error_handling";
import { LabeledCheckbox } from "../../components/LabeledCheckbox";
import { schema } from "./schema";
import { api } from "../../../../services/config";

type Props = {
  company: CompanyDetailsData;
  couponCode: string | null;
  charge: any;
  token: string | null;
};

export const CreditCardForm: React.FC<Props> = ({
  charge,
  company,
  couponCode,
  token,
}: any) => {
  const [loadingSend, setLoadingSend] = useState<boolean>(false);
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);

  const [presentAlert] = useIonAlert();
  const history = useHistory();
  const { user } = useAuth();
  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm<CreditCardBillingForm>({
    mode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: {
      coupon_code: couponCode ? couponCode : null,
      customer_id: user?.partner.pagarme_customer_id,
      payment_method: "credit_card",
      charge_id: charge.id || "",
      installments: 1,
      customer: {
        name: "",
        type: "individual",
        email: user?.email ?? "",
        birthdate: user?.partner?.birthdate ?? "1990-01-01",
        gender: "male",
        code: user?.partner.id,
        document_type:
          user?.partner.document_number.length === 11 ? "CPF" : "CNPJ",
        address: {
          city: user?.partner.address?.city ?? "",
          country: "BR",
          line_1: user?.partner.address?.street ?? "",
          line_2: "",
          state: user?.partner.address?.state ?? "",
          zip_code: user?.partner.address?.zip_code ?? "",
        },
        phones: {
          mobile_phone: {
            area_code: user?.partner.cellphone?.slice(0, 2),
            country_code: "55",
            number: user?.partner.cellphone?.slice(2),
          },
        },
      },
      card: {
        cvv: "",
        exp_month: "",
        exp_year: "",
        holder_document: user?.partner.document_number,
        holder_name: user?.partner.full_name ?? "",
        number: "",
        billing_address: {
          city: user?.partner.address?.city ?? "",
          country: "BR",
          line_1: user?.partner.address?.street ?? "",
          line_2: "",
          state: user?.partner.address?.state ?? "",
          zip_code: user?.partner.address?.zip_code ?? "",
        },
      },
    },
  });

  const fields = watch();

  const searchZipcode = useCallback(async () => {
    setLoadingAddress(true);
    try {
      const dataZipcode = await cep(fields.customer.address.zip_code);
      setValue(
        "customer.address.line_1",
        `${dataZipcode.street}, ${dataZipcode.neighborhood}`
      );
      setValue("customer.address.city", dataZipcode.city);
      setValue("customer.address.state", dataZipcode.state);
    } catch (err) {
      errorHandling(null, "não foi possível encontrar o cep", "crema");
    } finally {
      setLoadingAddress(false);
    }
  }, [fields?.customer.address, setValue]);

  const handleFormSubmit = useCallback(
    async (formData: CreditCardBillingForm) => {
      if (loadingSend) {
        return;
      }

      setLoadingSend(true);
      await PUTPayment(
        charge.id,
        couponCode ? { ...formData, coupon_code: couponCode } : formData
      )
        .then(async () => {
          api.post(
            `/v2/partners/services-extras/carts/checkout/finished?token=${token}`
          );
          history.push("/services-extras/checkout/success", {
            origin: "plan-form",
          });
        })
        .catch(() => {
          presentAlert(
            "Não foi possível efetuar o pagamento, verifique os dados e tente novamente"
          );
        })
        .finally(() => {
          setLoadingSend(false);
        });
    },
    [charge.id, couponCode, history, loadingSend, presentAlert]
  );

  return (
    <form
      className="flex flex-col gap-6 p-4"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Controller
        control={control}
        name="card.number"
        defaultValue=""
        render={({ field }) => (
          <Input
            {...field}
            mask="9999 9999 9999 9999"
            color="neon"
            placeholder="0000 0000 0000 0000"
            label="número do cartão"
            onChange={(e) =>
              field.onChange(e.target.value.replace(/[^\d]/g, ""))
            }
            error={errors.card?.number?.message?.toString()}
          />
        )}
      />

      <div className="flex flex-col gap-3">
        <p>validade do cartão</p>
        <div className="flex gap-3">
          <Controller
            control={control}
            name="card.exp_month"
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                mask="99"
                color="neon"
                placeholder="12"
                label="mês"
                onChange={(e) =>
                  field.onChange(e.target.value.replace(/[^\d]/g, ""))
                }
                error={errors.card?.exp_month?.message?.toString()}
              />
            )}
          />

          <Controller
            control={control}
            name="card.exp_year"
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                mask="99"
                color="neon"
                placeholder="23"
                label="ano"
                onChange={(e) =>
                  field.onChange(e.target.value.replace(/[^\d]/g, ""))
                }
                error={errors.card?.exp_year?.message?.toString()}
              />
            )}
          />
          <Controller
            control={control}
            name="card.cvv"
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                mask="9999"
                color="neon"
                placeholder="0000"
                label="cvv"
                onChange={(e) =>
                  field.onChange(e.target.value.replace(/[^\d]/g, ""))
                }
                error={errors.card?.cvv?.message?.toString()}
              />
            )}
          />
        </div>
      </div>
      <Controller
        control={control}
        name="customer.name"
        render={({ field: { onChange, ...rest } }) => (
          <Input
            color="neon"
            id="customer.name"
            onChange={(e) => {
              setValue("card.holder_name", e.target.value);
              onChange(e.target.value);
            }}
            error={errors?.customer?.name?.message?.toString()}
            label="Nome no cartão"
            placeholder="Nome no cartão"
            {...rest}
          />
        )}
      />

      {/* <Input
        color="neon"
        placeholder="Nome no cartão"
        label="Nome no cartão"
        {...register('customer.name', {
          onChange(event: any) {
            setValue('card.holder_name', event.target.value);
          },
        })}
        error={errors.card?.holder_name?.message?.toString()}
      /> */}

      <div className="flex flex-col gap-3">
        <p>Tipo de documento</p>
        <div className="flex gap-4">
          <LabeledCheckbox
            {...register("customer.document_type")}
            label="CPF"
            value="CPF"
          />
          <LabeledCheckbox
            {...register("customer.document_type")}
            label="CNPJ"
            value="CNPJ"
          />
        </div>
        <p className="text-neon-500">
          {errors.customer?.document_type?.message?.toString()}
        </p>
      </div>

      <Controller
        control={control}
        name="customer.document"
        defaultValue=""
        render={({ field }) => (
          <Input
            {...field}
            error={errors.card?.holder_document?.message?.toString()}
            onChange={(e) => {
              field.onChange(e.target.value.replace(/[^\d]/g, ""));
              setValue(
                "card.holder_document",
                e.target.value.replace(/[^\d]/g, "")
              );
            }}
            mask={
              watch("customer.document_type") === "CPF"
                ? "999.999.999-99"
                : "99.999.999/9999-99"
            }
            type="text"
            color="neon"
            placeholder={
              watch("customer.document_type") === "CPF"
                ? "000.000.000-00"
                : "00.000.000/0000-00"
            }
            label={`${watch("customer.document_type")} do titular`}
          />
        )}
      />

      <div className="flex flex-col gap-3">
        <p>endereço de faturamento</p>

        <div className="flex flex-col gap-6">
          <div className="flex gap-3">
            <Controller
              control={control}
              name="customer.address.zip_code"
              render={({ field }) => (
                <Input
                  {...field}
                  onChange={(e) => {
                    field.onChange(e.target.value.replace(/[^\d]/g, ""));
                    setValue(
                      "card.billing_address.zip_code",
                      e.target.value.replace(/[^\d]/g, "")
                    );
                  }}
                  onBlur={(e) => {
                    e.target.value.length >= 9 && searchZipcode();
                  }}
                  mask="99999-999"
                  type="text"
                  color="neon"
                  placeholder="00000-000"
                  label="cep"
                  disabled={loadingAddress}
                  error={errors.customer?.address?.zip_code?.message?.toString()}
                />
              )}
            />
          </div>
          <Input
            {...register("customer.address.line_1", {
              onChange(event) {
                setValue("card.billing_address.line_1", event.target.value);
              },
            })}
            error={errors.card?.billing_address?.line_1?.message?.toString()}
            color="neon"
            placeholder="nome da rua, número, bairro"
            label="endereço"
            disabled={loadingAddress}
          />
          <Input
            {...register("customer.address.line_2", {
              onChange(e) {
                setValue("card.billing_address.line_2", e.target.value);
              },
            })}
            error={errors.card?.billing_address?.line_2?.message?.toString()}
            type="text"
            color="neon"
            placeholder="casa, lado ímpar etc."
            label="complemento"
            disabled={loadingAddress}
          />
          <div className="flex gap-3">
            <Input
              {...register("customer.address.city", {
                onChange(e) {
                  setValue("card.billing_address.city", e.target.value);
                },
              })}
              error={errors.card?.billing_address?.city?.message?.toString()}
              type="text"
              color="neon"
              placeholder="ex.: São José dos Campos"
              label="cidade"
              disabled={loadingAddress}
            />
            <Controller
              control={control}
              name="customer.address.state"
              render={({ field }) => (
                <Input
                  mask="aa"
                  color="neon"
                  placeholder="SP"
                  label="estado"
                  {...field}
                  onChange={(e) => {
                    field.onChange(
                      e.target.value.replace("_", "").toUpperCase()
                    );
                    setValue(
                      "card.billing_address.state",
                      e.target.value.replace("_", "").toLocaleUpperCase()
                    );
                  }}
                  disabled={loadingAddress}
                  error={errors.card?.billing_address?.state?.message?.toString()}
                />
              )}
            />
          </div>
        </div>
      </div>

      <Button variant="solid" color="neon">
        {loadingSend ? (
          <IonSpinner color="text-white h-4" />
        ) : (
          "finalizar pagamento"
        )}
      </Button>
    </form>
  );
};
