import { IonContent, IonHeader, IonPage } from "@ionic/react";
import { Link } from "react-router-dom";
import LogoWhite from "../../assets/logo.jpg";
import MensagerSvg from "../../assets/illustrations/order-complete.svg";

const CheckInResume = () => {
  return (
    <IonPage>
      <IonHeader className="ion-no-border bg-white"></IonHeader>
      <IonContent>
        <section className="mt-5 px-5 bg-white  bg-opacity-10">
          <div className="max-w-2xl mx-auto  flex items-center justify-center min-h-screen mt-auto flex flex-col ">
            <div className="flex flex-col justify-center mb-2">
              <img src={LogoWhite} className="h-28" alt="Logo" />
            </div>
            <div className="mx-5 w-full bg-white items-center flex flex-col gap-6 py-6 border-0 border-opacity-40 shadow-lg shadow-gray-100 h-full px-2 py-3 rounded-lg z-10 mb-10">
              <h1 className="text-base text-center text-gray-500">
                o cashback foi enviado
                <br /> com <span className="text-success-500">sucesso!</span>
              </h1>
              <div className="flex  justify-center rounded-full bg-crema-500 bg-opacity-70 h-40 w-40">
                <img src={MensagerSvg} alt="" />
              </div>
              <p className="text-center text-sm text-gray-500">
                em breve seu cliente será ativado e retornará ao seu
                estabelecimento. <br />
                <span className="text-neon-700">
                  continue ofertando seu cashback
                </span>
              </p>
            </div>
            <footer className="flex flex-wrap justify-center min-w-full ">
              <Link
                to="/check-in"
                className="z-20 bg-neon-900 hover:bg-neon-800 py-3 px-2 w-full rounded-lg text-center text-white font-bold text-md "
              >
                realizar um novo check-in
              </Link>
            </footer>
          </div>
        </section>
      </IonContent>
    </IonPage>
  );
};

export default CheckInResume;
