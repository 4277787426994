import { useCallback, useMemo, useState, Ref } from "react";
import { useHistory } from "react-router";
// import { useHistory, useParams } from 'react-router';
import useForm from "../../../../hooks/useForm";
import useAuth from "../../../../hooks/useAuth";
import * as z from "zod";
import errorHandling from "../../../../utils/error_handling";

import { Input, Button, Select, Toast } from "oialbert-ui";

import {
  createSubscriptionOrder,
  createTokenCard,
} from "../../../../services/financial/subscription";

import { formatInCents } from "../../../../utils/money";
import { Coupon } from "../Coupon";

type FormProps = {
  formRef: Ref<HTMLFormElement>;
  amount: number;
  paymentType: string;
  couponCode: string;
  isAmountValid: boolean;
  data: any;
  setDiscount: (value: number) => void;
  setCoupon: (value: string) => void;
};

const schema = z
  .object({
    number: z
      .string()
      .regex(
        /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
        "número inválido"
      )
      .nonempty({ message: "campo obrigatório" }),
    holder_name: z
      .string()
      .regex(/^[a-zA-Z]{4,}(?: [a-zA-Z]+){0,3}$/, "nome inválido")
      .nonempty({ message: "campo obrigatório" }),
    exp: z.string().nonempty({ message: "campo obrigatório" }),
    cvv: z
      .string()
      .regex(/^[0-9]{3,}$/, "cvv inválido")
      .nonempty({ message: "campo obrigatório" })
      .min(3, "cvv inválido")
      .max(4),
    installments: z.string().nonempty({ message: "campo obrigatório" }),
  })
  .refine(
    (data) => {
      const today = new Date();
      const monthToday = today.getMonth() + 1;
      const yearToday = today.getFullYear().toString().substr(-2);

      const [expMonth, expYear] = data.exp.split("/");

      if (Number(expYear) < Number(yearToday)) {
        return false;
      } else if (
        Number(expMonth) > 12 ||
        (Number(expMonth) < monthToday && Number(expYear) <= Number(yearToday))
      ) {
        return false;
      }

      return true;
    },
    {
      path: ["exp"],
      message: "Data inválida",
    }
  );

export const FormCreditCard = ({
  formRef,
  amount,
  paymentType,
  couponCode,
  isAmountValid,
  data: dataUser,
  setDiscount,
  setCoupon,
}: FormProps) => {
  const history = useHistory();
  // const { companyId }: { companyId: string } = useParams();
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      number: "",
      holder_name: "",
      exp: "",
      cvv: "",
      installments: "1",
    },
    schema,
    mode: "onChange",
  });

  const [loading, setLoading] = useState<boolean>(false);

  const installmentsOptions = useMemo(
    () =>
      Array.from(Array(12).keys(), (n) => {
        const installment = n + 1;
        const amountInstallment = amount / installment;

        return {
          label:
            installment === 1
              ? `${formatInCents(amount)} à vista`
              : `${installment}x ${formatInCents(amountInstallment)} sem juros`,
          value: installment,
        };
      }),
    [amount]
  );

  const {
    address: {
      city,
      state,
      street_number,
      street,
      neighborhood,
      zip_code,
      ...restAddress
    },
  } = dataUser;

  const onCreateOrder = useCallback(
    async (data) => {
      setLoading(true);
      try {
        const [month, year] = data.exp.split("/");

        const { token_card } = await createTokenCard({
          exp_year: year,
          exp_month: month,
          cvv: data?.cvv?.replace(/\D/g, ""),
          number: data.number,
          holder_name: data.holder_name,
          holder_document: dataUser.document_number.replace(
            /[^a-zA-Z0-9]/g,
            ""
          ),
          billing_address: {
            ...restAddress,
            state,
            street,
            city,
            number: street_number,
            street_number,
            neighborhood,
            line_1: `${street_number}, ${street}, ${neighborhood}`,
            line_2: neighborhood,
            zip_code,
            country: "BR",
          },
        });

        const { charges } = await createSubscriptionOrder({
          type: "subscription",
          items: [
            {
              code: dataUser.subscription.id,
              amount,
              description: "assinatura partner",
              quantity: 1,
            },
          ],
          customer: {
            code: user?.id ?? "",
            name: dataUser.name,
            email: dataUser.email,
            document: dataUser?.document_number?.replace?.(/[^a-zA-Z0-9]/g, ""),
            type: "individual",
            phones: {
              mobile_phone: {
                country_code: "55",
                area_code: dataUser.phone.slice(0, 2),
                number: dataUser.phone.slice(2, 11),
              },
            },
            address: {
              ...restAddress,
              city,
              state,
              line_1: `${street}, ${
                street_number ?? dataUser?.address?.street_number ?? "S/N"
              }`,
              line_2: neighborhood,
              zip_code:
                zip_code?.replace?.(/[^a-zA-Z0-9]/g, "") ??
                dataUser?.address?.zip_code?.replace?.(/[^a-zA-Z0-9]/g, "") ??
                dataUser?.address?.zipcode?.replace?.(/[^a-zA-Z0-9]/g, ""),
              country: "BR",
            },
          },
          payments: [
            {
              payment_method: "credit_card",
              amount,
              credit_card: {
                installments: data?.installments ?? 1,
                card_token: token_card,
                card: {
                  billing_address: {
                    ...restAddress,
                    state,
                    street,
                    city,
                    number: street_number,
                    street_number,
                    neighborhood,
                    line_1: `${street_number}, ${street}, ${neighborhood}`,
                    line_2: neighborhood,
                    zip_code,
                    country: "BR",
                  },
                },
              },
            },
          ],
          metadata: {
            id: dataUser.subscription.id,
            price_in_cents: dataUser.subscription.price_in_cents,
            user_id: user?.id ?? "",
            user_type: "partner",
            payment_type: paymentType,
            license_id: dataUser.subscription.license_id,
            location_id: dataUser.subscription.license_copy?.location_id,
            type: "subscription",
          },
          ...(couponCode !== "" && {
            coupon_code: couponCode?.toUpperCase()?.replace(/\s/g, ""),
          }),
        });

        if (charges?.success === false) {
          Toast.crema("seu pagamento foi recusado");
        } else {
          Toast.success("sua adesão foi realizada");
          history.push("/select-company");
        }
      } catch (err: any) {
        const statusCode = err?.response?.status;

        if (statusCode && statusCode === 403) {
          history.push("/select-company");
        }

        errorHandling(err, "não foi possível processar seu pagamento", "crema");
      } finally {
        setLoading(false);
      }
    },
    [
      amount,
      dataUser,
      history,
      paymentType,
      restAddress,
      neighborhood,
      street,
      zip_code,
      couponCode,
      user?.id,
      city,
      state,
      street_number,
    ]
  );

  const validateForm = useMemo(
    () => isAmountValid && isValid,
    [isAmountValid, isValid]
  );

  return (
    <form ref={formRef}>
      <section className="flex flex-col mt-3 mb-4 space-y-4">
        <Input
          required
          label="número do cartão"
          type="tel"
          placeholder="0000 0000 0000 0000"
          mask="NUMBER_CARD"
          disabled={loading}
          error={errors.number?.message?.toString()}
          {...register("number", {
            setValueAs: (value) => value.replace(/\s/g, ""),
          })}
        />
        <Input
          required
          label="nome impresso no cartão"
          placeholder="Nome completo"
          disabled={loading}
          error={errors.holder_name?.message?.toString()}
          {...register("holder_name")}
        />
        <section className="flex items-start space-x-4">
          <Input
            required
            type="tel"
            label="validade"
            placeholder="MM/YY"
            mask="VALIDATE_CARD"
            disabled={loading}
            error={errors.exp?.message?.toString()}
            {...register("exp")}
          />
          <Input
            required
            type="tel"
            label="cvv"
            placeholder="0000"
            mask="CODE_CARD"
            disabled={loading}
            error={errors.cvv?.message?.toString()}
            {...register("cvv", {
              setValueAs: (value) => value?.replace(/\D/g, ""),
            })}
          />
        </section>
        <Select
          required
          label="parcelamento"
          options={installmentsOptions}
          style={{ textTransform: "none" }}
          disabled={loading}
          error={errors.installments?.message?.toString()}
          {...register("installments")}
        />
      </section>
      <section className="mt-4 mb-5">
        <h3 className="text-base font-bold">cupom de desconto</h3>
        <Coupon
          subscriptionId={dataUser.subscription.id}
          onApply={setDiscount}
          onCoupon={setCoupon}
        />
      </section>

      <section className="mt-4">
        <Button
          full
          onClick={handleSubmit(onCreateOrder)}
          variant="solid"
          color="neon"
          disabled={loading || !validateForm}
        >
          {loading ? "aguarde..." : "realizar pagamento"}
        </Button>
      </section>
    </form>
  );
};
