import { ComponentProps, ComponentType } from "react";
import { Route as RDRoute, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

type RouteProps = ComponentProps<typeof RDRoute> & {
  component: ComponentType<any>;
};

const URLPUBLIC = [
  "login",
  "authenticator",
  "select-company",
  "validate-token",
  "recovery-password",
  "pergunte-ao-albert",
  "reset-password",
  "ai",
  "admin"
];

export function Route({ component: Component, ...rest }: RouteProps) {
  const { user } = useAuth();
  const { pathname } = useLocation();

  return (
    <RDRoute
      {...rest}
      render={(props) => {
        const isPublic = URLPUBLIC.includes(pathname.split("/")[1]);
        const token = window.localStorage.getItem("token");

        if (token === null && !isPublic) {
          window.location.href = `${window.location.origin}/login`;
          return;
        }

        if (
          user?.partner?.is_term_accepted === false &&
          isPublic === false &&
          pathname === "/" &&
          token !== null
        ) {
          window.location.href = `${window.location.origin}/terms`;
          return;
        }
        return <Component {...props} />;
      }}
    />
  );
}
