import { Button } from 'oialbert-ui';
import React from 'react';
import type { Characteristic } from '../types';

type CharacteristicItemProps = {
  characteristic: Characteristic;
  editCharacteristic: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    characteristic: Characteristic
  ) => void;
  removeCharacteristic: (characteristic: Characteristic) => void;
};

export default function CharacteristicItem({
  characteristic,
  editCharacteristic,
  removeCharacteristic,
}: CharacteristicItemProps) {
  return (
    <div
      className="flex justify-between border flex items-center justify-between p-4 border-crema-500 h-14 w-full rounded-md"
      key={characteristic.title}
      onClick={(e) => editCharacteristic(e, characteristic)}
    >
      {characteristic.title}

      <Button
        onClick={() => removeCharacteristic(characteristic)}
        normal
        color="neon"
        size="small"
        variant="light"
      >
        <span className="text-sm">Excluir</span>
      </Button>
    </div>
  );
}
