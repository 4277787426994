import { useForm, Controller } from "react-hook-form";
import { useCallback, useState } from "react";
import CreditCardView from "./CreditCardView";
import { Button, Input } from "oialbert-ui";
import { CardSelect } from "../../../../types/card";
import AddCreditCard from "./AddCreditCard";
import { CompanyDetailsData } from "../../../../types/companies";
import { IonSpinner, useIonAlert } from "@ionic/react";
import { useHistory } from "react-router";
import useAuth from "../../../../hooks/useAuth";
import { zodResolver } from "@hookform/resolvers/zod";
import { schema } from "./schema";
import { PUTPayment } from "../../../../services/financial/payment";
import { LabeledCheckbox } from "../../components/LabeledCheckbox";
import errorHandling from "../../../../utils/error_handling";
import cep from "cep-promise";
import { CreditCardBillingForm } from "../../type";

type CreditCardFormProps = {
  cards?: CardSelect[];
  card?: CardSelect;
  onSelectedCard: (card: CardSelect | undefined) => void;
  company: CompanyDetailsData;
  couponCode: string | null;
  charge: any;
  couponValidate: boolean;
};

export const CreditCardForm: React.FC<CreditCardFormProps> = ({
  cards,
  card,
  onSelectedCard,
  charge,
  couponCode,
  couponValidate,
}) => {
  const [loadingSend, setLoadingSend] = useState<boolean>(false);
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);

  const [presentAlert] = useIonAlert();
  const history = useHistory();
  const { user } = useAuth();

  const [selectedCard, setSelectedCard] = useState<CardSelect | undefined>();

  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm<CreditCardBillingForm>({
    mode: "onChange",
    resolver: zodResolver(
      schema.refine(
        (data) =>
          (data.token_card && !data.card) || (!data.token_card && data.card),
        {
          message: "Preencha apenas um dos campos: token_card ou card",
          path: ["card"],
        }
      )
    ),
    defaultValues: {
      coupon_code: couponCode ? couponCode : null,
      customer_id: user?.partner.pagarme_customer_id,
      payment_method: "credit_card",
      charge_id: charge.id || "",
      installments: 1,
      token_card: undefined,
      customer: {
        name: "",
        type: "individual",
        email: user?.email ?? "",
        birthdate: user?.partner?.birthdate ?? "1990-01-01",
        gender: "male",
        code: user?.partner.id,
        document_type:
          user?.partner.document_number.length === 11 ? "CPF" : "CNPJ",
        address: {
          city: user?.partner.address?.city ?? "",
          country: "BR",
          line_1: user?.partner.address?.street ?? "",
          line_2: "",
          state: user?.partner.address?.state ?? "",
          zip_code: user?.partner.address?.zip_code ?? "",
        },
        phones: {
          mobile_phone: {
            area_code: user?.partner.cellphone?.slice(0, 2),
            country_code: "55",
            number: user?.partner.cellphone?.slice(2),
          },
        },
      },
      card: {
        cvv: "",
        exp_month: "",
        exp_year: "",
        holder_document: user?.partner.document_number,
        holder_name: user?.partner.full_name ?? "",
        number: "",
        billing_address: {
          city: user?.partner.address?.city ?? "",
          country: "BR",
          line_1: user?.partner.address?.street ?? "",
          line_2: "",
          state: user?.partner.address?.state ?? "",
          zip_code: user?.partner.address?.zip_code ?? "",
        },
      },
    },
  });

  const fields = watch();

  const searchZipcode = useCallback(async () => {
    setLoadingAddress(true);
    try {
      const dataZipcode = await cep(fields.customer?.address?.zip_code ?? "");
      setValue(
        "customer.address.line_1",
        `${dataZipcode.street || ""}${dataZipcode.neighborhood && ","} ${
          dataZipcode.neighborhood || ""
        }`
      );
      setValue("customer.address.city", dataZipcode.city);
      setValue("customer.address.state", dataZipcode.state);
      setValue(
        "card.billing_address.line_1",
        `${dataZipcode.street || ""}${dataZipcode.neighborhood && ","} ${
          dataZipcode.neighborhood || ""
        }`
      );
      setValue("card.billing_address.city", dataZipcode.city);
      setValue("card.billing_address.state", dataZipcode.state);
    } catch (err) {
      errorHandling(null, "não foi possível encontrar o cep", "crema");
    } finally {
      setLoadingAddress(false);
    }
  }, [fields?.customer?.address, setValue]);

  const handleFormSubmit = useCallback(
    async (formData: CreditCardBillingForm) => {
      if (loadingSend) {
        return;
      }

      const sendPayment = async () => {
        setLoadingSend(true);
        try {
          const adjustedFormData = selectedCard
            ? { ...formData, token_card: selectedCard.id, card: undefined }
            : formData;

          const data = await PUTPayment(
            charge.id,
            couponCode
              ? { ...adjustedFormData, coupon_code: couponCode }
              : adjustedFormData
          );

          if (!data.failed) {
            history.push("/plan/payment/success", {
              origin: "plan-form-coupon",
            });
          } else {
            //@ts-ignore
            const message: any = Object.values(data?.errors?.errors)[0][0];
            presentAlert(message);
          }
        } catch (error: any) {
          if (error instanceof Error && error.message) {
            presentAlert(error.message);
          } else {
            presentAlert(
              "Houve um problema no processamento do pagamento; tente novamente ou entre em contato com nosso suporte técnico."
            );
          }
        } finally {
          setLoadingSend(false);
        }
      };

      if (!couponValidate) {
        const message = `O cupom não foi validado, deseja continuar?`;
        const buttons = [
          {
            text: "Sim",
            role: "confirm",
            handler: async () => {
              await sendPayment();
            },
          },
          {
            text: "Não",
            role: "cancel",
          },
        ];

        presentAlert({ header: "Cupom", message, buttons });
      } else {
        await sendPayment();
      }
    },
    [charge.id, couponCode, history, loadingSend, presentAlert, couponValidate]
  );

  const handleSelectCard = (card?: CardSelect) => {
    setSelectedCard(card);
    onSelectedCard(card);

    setValue("token_card", card?.id || undefined);
    if (card?.id) {
      setValue("card", undefined);
      setValue("customer", undefined);
      /*setValue('card.number', `${card?.first_six_digits}******${card?.last_four_digits}`);
      setValue('card.exp_month', `${card?.exp_month}`);
      setValue('card.exp_year', `${card?.exp_year}`);
      setValue('card.holder_name', `${card?.holder_name}`);*/
    } else {
      setValue("customer", {
        name: "",
        type: "individual",
        email: user?.email ?? "",
        birthdate: user?.partner?.birthdate ?? "1990-01-01",
        gender: "male",
        code: user?.partner.id ?? "",
        document: user?.partner.document_number ?? "",
        document_type:
          user?.partner.document_number.length === 11 ? "CPF" : "CNPJ",
        address: {
          city: user?.partner.address?.city ?? "",
          country: "BR",
          line_1: user?.partner.address?.street ?? "",
          line_2: "",
          state: user?.partner.address?.state ?? "",
          zip_code: user?.partner.address?.zip_code ?? "",
        },
        phones: {
          mobile_phone: {
            area_code: user?.partner.cellphone?.slice(0, 2) ?? "",
            country_code: "55",
            number: user?.partner.cellphone?.slice(2) ?? "",
          },
        },
      });
      setValue("card", {
        cvv: "",
        exp_month: "",
        exp_year: "",
        holder_document: user?.partner.document_number ?? "",
        holder_name: user?.partner.full_name ?? "",
        number: "",
        billing_address: {
          city: user?.partner.address?.city ?? "",
          country: "BR",
          line_1: user?.partner.address?.street ?? "",
          line_2: "",
          state: user?.partner.address?.state ?? "",
          zip_code: user?.partner.address?.zip_code ?? "",
        },
      });
    }
  };

  return (
    <form
      className="flex flex-col gap-4 p-4 payment-form"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <div>Selecione um cartão ou adicione um novo:</div>

      <div className="credit-card-list flex gap-4 flex-wrap">
        <AddCreditCard
          isSelected={!selectedCard}
          onClick={() => handleSelectCard(undefined)}
        />
        {cards?.map((card) => (
          <CreditCardView
            key={card.id}
            data={card}
            onSelect={handleSelectCard}
            isSelected={selectedCard?.id === card.id}
          />
        ))}
      </div>

      {!selectedCard && (
        <div className="new-card-form mb-3 flex flex-col gap-6">
          <Controller
            control={control}
            name="card.number"
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                mask="9999 9999 9999 9999"
                color="neon"
                placeholder="0000 0000 0000 0000"
                label="número do cartão"
                onChange={(e) =>
                  field.onChange(e.target.value.replace(/[^\d]/g, ""))
                }
                error={errors.card?.number?.message?.toString()}
              />
            )}
          />

          <div className="flex flex-col gap-3">
            <p className="font-bold">validade do cartão</p>
            <div className="flex gap-3">
              <Controller
                control={control}
                name="card.exp_month"
                defaultValue=""
                render={({ field }) => (
                  <Input
                    {...field}
                    mask="99"
                    color="neon"
                    placeholder="12"
                    label="mês"
                    onChange={(e) =>
                      field.onChange(e.target.value.replace(/[^\d]/g, ""))
                    }
                    error={errors.card?.exp_month?.message?.toString()}
                  />
                )}
              />

              <Controller
                control={control}
                name="card.exp_year"
                defaultValue=""
                render={({ field }) => (
                  <Input
                    {...field}
                    mask="99"
                    color="neon"
                    placeholder="23"
                    label="ano"
                    onChange={(e) =>
                      field.onChange(e.target.value.replace(/[^\d]/g, ""))
                    }
                    error={errors.card?.exp_year?.message?.toString()}
                  />
                )}
              />

              <Controller
                control={control}
                name="card.cvv"
                defaultValue=""
                render={({ field }) => (
                  <Input
                    {...field}
                    mask="9999"
                    color="neon"
                    placeholder="0000"
                    label="cvv"
                    onChange={(e) =>
                      field.onChange(e.target.value.replace(/[^\d]/g, ""))
                    }
                    error={errors.card?.cvv?.message?.toString()}
                  />
                )}
              />
            </div>
          </div>

          <Controller
            control={control}
            name="customer.name"
            render={({ field: { onChange, ...rest } }) => (
              <Input
                color="neon"
                id="customer.name"
                onChange={(e) => {
                  setValue("card.holder_name", e.target.value);
                  onChange(e.target.value);
                }}
                error={errors?.customer?.name?.message?.toString()}
                label="Nome no cartão"
                placeholder="Nome no cartão"
                {...rest}
              />
            )}
          />

          {/* <Input
            color="neon"
            placeholder="Nome no cartão"
            label="Nome no cartão"
            {...register('customer.name', {
              onChange(event: any) {
                setValue('card.holder_name', event.target.value);
              },
            })}
            error={errors.card?.holder_name?.message?.toString()}
          /> */}

          <div className="flex flex-col gap-3">
            <p>Tipo de documento</p>
            <div className="flex gap-4">
              <LabeledCheckbox
                {...register("customer.document_type")}
                label="CPF"
                value="CPF"
              />
              <LabeledCheckbox
                {...register("customer.document_type")}
                label="CNPJ"
                value="CNPJ"
              />
            </div>
            <p className="text-neon-500">
              {errors.customer?.document_type?.message?.toString()}
            </p>
          </div>

          <Controller
            control={control}
            name="customer.document"
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                error={errors.customer?.document?.message?.toString()}
                onChange={(e) => {
                  field.onChange(e.target.value.replace(/[^\d]/g, ""));
                  setValue(
                    "customer.document",
                    e.target.value.replace(/[^\d]/g, "")
                  );
                }}
                mask={
                  watch("customer.document_type") === "CPF"
                    ? "999.999.999-99"
                    : "99.999.999/9999-99"
                }
                type="text"
                color="neon"
                placeholder={
                  watch("customer.document_type") === "CPF"
                    ? "000.000.000-00"
                    : "00.000.000/0000-00"
                }
                label={`${watch("customer.document_type")} do titular`}
              />
            )}
          />

          <div className="flex flex-col gap-3">
            <p className="font-bold">endereço de faturamento</p>
            <div className="flex flex-col gap-6">
              <div className="flex gap-3">
                <Controller
                  control={control}
                  name="customer.address.zip_code"
                  render={({ field }) => (
                    <Input
                      {...field}
                      onChange={(e) => {
                        const zipCode = e.target.value.replace(/[^\d]/g, "");
                        field.onChange(zipCode);
                        setValue("customer.address.zip_code", zipCode);
                        setValue("card.billing_address.zip_code", zipCode);
                      }}
                      onBlur={(e) => {
                        e.target.value.length >= 9 && searchZipcode();
                      }}
                      mask="99999-999"
                      type="text"
                      color="neon"
                      placeholder="00000-000"
                      label="cep"
                      disabled={loadingAddress}
                      error={errors.customer?.address?.zip_code?.message?.toString()}
                    />
                  )}
                />
              </div>
              <Controller
                control={control}
                name="customer.address.line_1"
                render={({ field }) => (
                  <Input
                    {...field}
                    onChange={(e) => {
                      const line1 = e.target.value;
                      field.onChange(line1);
                      setValue("customer.address.line_1", line1);
                      setValue("card.billing_address.line_1", line1);
                    }}
                    error={errors.card?.billing_address?.line_1?.message?.toString()}
                    color="neon"
                    placeholder="nome da rua, número, bairro"
                    label="endereço"
                    disabled={loadingAddress}
                  />
                )}
              />
              <Input
                {...register("customer.address.line_2", {
                  onChange(e) {
                    setValue("card.billing_address.line_2", e.target.value);
                  },
                })}
                error={errors.card?.billing_address?.line_2?.message?.toString()}
                type="text"
                color="neon"
                placeholder="casa, lado í­mpar etc."
                label="complemento"
                disabled={loadingAddress}
              />
              <div className="flex gap-3">
                <Input
                  {...register("customer.address.city", {
                    onChange(e) {
                      setValue("card.billing_address.city", e.target.value);
                    },
                  })}
                  error={errors.card?.billing_address?.city?.message?.toString()}
                  type="text"
                  color="neon"
                  placeholder="ex.: São José dos Campos"
                  label="cidade"
                  disabled={loadingAddress}
                />
                <Controller
                  control={control}
                  name="customer.address.state"
                  render={({ field }) => (
                    <Input
                      mask="aa"
                      color="neon"
                      placeholder="SP"
                      label="estado"
                      {...field}
                      onChange={(e) => {
                        const state = e.target.value
                          .replace("_", "")
                          .toUpperCase();
                        field.onChange(state);
                        setValue("customer.address.state", state);
                        setValue("card.billing_address.state", state);
                      }}
                      disabled={loadingAddress}
                      error={errors.card?.billing_address?.state?.message?.toString()}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/*<form onSubmit={handleSubmit(onSubmit)} className="payment-form">
      <div>Selecione um cartão ou adicione um novo:</div>

      <div className="credit-card-list my-3 flex gap-4 flex-wrap">
        <AddCreditCard isSelected={!selectedCard} onClick={() => handleSelectCard(undefined)}  />
        {cards?.map((card) => (
          <CreditCardView
            key={card.id}
            data={card}
            onSelect={handleSelectCard}
            isSelected={selectedCard?.id === card.id}
          />
        ))}
      </div>

      {!selectedCard && (
        <div className="new-card-form mb-3">
          <Controller
            control={control}
            name="card.number"
            render={({ field }) => (
              <Input
                {...field}
                label="Número do Cartão"
                mask="9999 9999 9999 9999"
                placeholder="0000 0000 0000 0000"
              />
            )}
          />
          <div className="flex space-x-4">
            <Controller
              control={control}
              name="card.exp_month"
              render={({ field }) => (
                <Input {...field} label="Mês" mask="99" placeholder="MM" />
              )}
            />
            <Controller
              control={control}
              name="card.exp_year"
              render={({ field }) => (
                <Input {...field} label="Ano" mask="9999" placeholder="AAAA" />
              )}
            />
          </div>
          <Controller
            control={control}
            name="card.cvv"
            render={({ field }) => (
              <Input {...field} label="CVV" mask="999" placeholder="000" />
            )}
          />
          <Controller
            control={control}
            name="card.holder_name"
            render={({ field }) => (
              <Input {...field} label="Nome no Cartão" placeholder="Nome completo" />
            )}
          />
        </div>
      )}
      */}

      <Button variant="solid" color="neon">
        {loadingSend ? (
          <IonSpinner color="text-white h-4" />
        ) : (
          "finalizar pagamento"
        )}
      </Button>
    </form>
  );
};

export default CreditCardForm;
