import React from 'react';
import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  useIonAlert,
} from '@ionic/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useMessages } from './ChatContext';
import LogoWhiteSimple from '../../assets/logo-white-simple.svg';
import LogoPergunteAlbert from '../../assets/chat.png';
import ChatIcon from '../../assets/message-circle.svg';
import './scrollbar.css';
import useAuth from '../../hooks/useAuth';
import {
  deleteChat,
  getCategories,
  getChats,
  getPrompts,
  getSegments,
  pinChat,
} from '../../services/chat';
import { Chat, Prompt } from '../../types/chat';
import { BsPinAngle } from 'react-icons/bs';
import { FiTrash2 } from 'react-icons/fi';
import { TbClock } from 'react-icons/tb';
import { MdArrowForwardIos } from 'react-icons/md';
import ArrowBackIcon from '../../assets/arrow-back.svg';
import { checkLimit } from '../../services/contracts';
import { LIMIT_TYPE } from '../Promotions/types';
import { FiCopy } from 'react-icons/fi';
import { Select } from 'oialbert-ui';

const PergunteAlbertAI: React.FC = () => {
  const { addMessage, addChat } = useMessages();
  const history = useHistory();
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const { company } = useAuth();
  const [prompts, setPrompts] = useState<Prompt[]>([]);

  const [fixedItems, setFixedItems] = useState<Chat[]>([]);
  const [todayItems, setTodayItems] = useState<Chat[]>([]);
  const [lastItems, setLastItems] = useState<Chat[]>([]);
  const [premium, setPremium] = useState<boolean>(false);
  const [free, setFree] = useState<boolean>(false);
  const [presentAlert] = useIonAlert();

  const [segments, setSegments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [loadingSegments, setLoadingSegments] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    presentAlert('Texto copiado!');
  };

  const handleCheckLimitChat = useCallback(async () => {
    try {
      if (!company) return;

      const responsePremium = await checkLimit(LIMIT_TYPE.ai_premium);
      const responseFree = await checkLimit(LIMIT_TYPE.ai_free);

      setPremium(responsePremium?.limit > 0);
      setFree(responseFree?.limit > 0);
    } catch (error) {}
  }, [company]);

  useEffect(() => {
    (async () => {
      await handleCheckLimitChat();
    })();
  }, [handleCheckLimitChat]);

  const handlePin = async (item: Chat) => {
    if (!company?.id) return;

    await pinChat(company?.id, item?.id);
    getFixed();
    setTodayItems(todayItems.filter((chat) => chat !== item));
    setLastItems(lastItems.filter((chat) => chat !== item));
  };

  const handleUnpin = async (item: Chat) => {
    if (!company?.id) return;

    await pinChat(company?.id, item?.id);
    getFixed();
    getToday();
    getLastDays();
  };

  const handleDelete = async (item: Chat) => {
    if (!company?.id) return;

    await deleteChat(company?.id, item?.id);
    setTodayItems(todayItems.filter((chat) => chat !== item));
    setLastItems(lastItems.filter((chat) => chat !== item));
    setFixedItems(fixedItems.filter((chat) => chat !== item));
  };

  const handleClick = async (item: Chat) => {
    if (!company?.id) return;

    addChat(item.id);
    history.push('/ai/chat');
  };

  const handleSegmentChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSegment(event.target.value);
    setSelectedCategory('');
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(event.target.value);
  };

  const sendMessage = async (id?: string, input?: string, response?: string) => {
    if (input) {
      const message = { content: input, response: response, prompt_id: id };
      addMessage(message);
    } else {
      addMessage(null);
    }
    history.push('/ai/chat');
  };

  const getPromptsList = useCallback(async () => {
    if (!company?.id) return;

    if (!selectedCategory) {
      setPrompts([]);
      return;
    }

    const promptsList = await getPrompts(company?.id, selectedSegment, selectedCategory);
    setPrompts(promptsList);
  }, [company, selectedCategory]);

  const getFixed = useCallback(async () => {
    if (!company?.id) return;

    const fixedList = await getChats(company?.id, { pined: true, limit: 100 });
    setFixedItems(fixedList);
  }, [company]);

  const getToday = useCallback(async () => {
    if (!company?.id) return;

    const todayList = await getChats(company?.id, {
      filter: 'today',
      limit: 100,
    });
    setTodayItems(todayList);
  }, [company]);

  const getLastDays = useCallback(async () => {
    if (!company?.id) return;

    const lastDaysList = await getChats(company?.id, {
      filter: 'last_7_days',
      limit: 100,
    });
    setLastItems(lastDaysList);
  }, [company]);

  useEffect(() => {
    const fetchSegments = async () => {
      setLoadingSegments(true);
      try {
        if (!company?.id) return;
        const data = await getSegments(company?.id);
        setSegments(data);
        if (data.length > 0) {
          setSelectedSegment(data[0].id);
        }
      } catch (error) {
        console.error('Erro ao carregar segmentos:', error);
      } finally {
        setLoadingSegments(false);
      }
    };

    fetchSegments();
  }, [company]);

  useEffect(() => {
    if (!selectedSegment) return;

    const fetchCategories = async () => {
      setLoadingCategories(true);
      try {
        if (!company?.id) return;
        const data = await getCategories(company?.id, selectedSegment);
        setCategories(data);
        if (data.length > 0) {
          setSelectedCategory(data[0].id);
        }
      } catch (error) {
        console.error('Erro ao carregar categorias:', error);
      } finally {
        setLoadingCategories(false);
      }
    };

    fetchCategories();
  }, [selectedSegment]);

  useEffect(() => {
    getPromptsList();
    getFixed();
    getToday();
    getLastDays();
  }, [company, getFixed, getLastDays, getPromptsList, getToday, selectedCategory]);

  return (
    <IonPage className="overflow-hidden">
      <IonContent>
        <div className="flex flex-col items-center min-h-screen bg-white normal-case overflow-hidden">
          <div className="w-full bg-white fixed z-10">
            <div className="absolute">
              <button className="mr-2 m-3 flex" onClick={() => history.push('/')}>
                <img src={ArrowBackIcon} alt="Voltar" className="w-6 h-6 m-1.5" />
              </button>
            </div>

            <div
              className="flex items-center justify-center bg-neon-900 text-white p-4"
              style={{ height: '62px' }}
            >
              <img src={LogoWhiteSimple} alt="Logo" />
            </div>
          </div>

          <div
            className="chat w-full bg-white flex flex-col overflow-y-auto"
            style={{ marginTop: '62px' }}
          >
            <div className="flex items-center justify-center text-center mt-8">
              <img src={LogoPergunteAlbert} width={130} height={137} alt="Chat Logo" />
            </div>

            <div className="text-center mb-4">
              <span className="bg-white text-md normal-case text-gray-800 font-bold block w-60 mx-auto">
                Escolha uma pergunta ou digite algo para começar
              </span>
            </div>

            {free && (
              <>
                <div className="flex justify-center mt-4 mb-8">
                  <div className="flex space-x-4">
                    <div className="flex items-center space-x-2">
                      <span className="text-gray-700 font-bold">Segmento:</span>
                      <Select
                        label=""
                        disabled={loadingSegments}
                        placeholder={loadingSegments ? 'Carregando...' : 'Selecione'}
                        options={segments.map((segment: any) => ({
                          value: segment.id,
                          label: segment.title,
                        }))}
                        value={selectedSegment}
                        onChange={(e) => handleSegmentChange(e)}
                      />
                    </div>

                    <div className="flex items-center space-x-2">
                      <span className="text-gray-700 font-bold">Categoria:</span>
                      <Select
                        label=""
                        disabled={loadingCategories || !selectedSegment}
                        placeholder={loadingCategories ? 'Carregando...' : 'Selecione'}
                        options={categories.map((category: any) => ({
                          value: category.id,
                          label: category.title,
                        }))}
                        value={selectedCategory}
                        onChange={(e) => handleCategoryChange(e)}
                      />
                    </div>
                  </div>
                </div>

                <div className="bg-white w-full max-w-xl px-4 mx-auto">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-0.5 pb-10">
                    {prompts.map((prompt, index) => (
                      <div
                        key={index}
                        onClick={() => sendMessage(prompt.id, prompt.question, prompt.response)}
                        className="shadow-lg cursor-pointer m-2 bg-neon-900 rounded-lg relative"
                        style={{
                          borderRadius: '15px',
                          boxShadow: 'rgba(0, 0, 0, 0.3) 0px 6px 20px',
                        }}
                      >
                        <div className="font-inter text-white text-sm font-semibold text-center p-2 m-2 mb-3 max-w-lg">
                          {prompt.question}
                        </div>

                        <div className="flex justify-end mb-1 text-xs absolute bottom-3 right-2 space-x-2">
                          <FiCopy
                            className="text-white cursor-pointer mr-4"
                            size={20}
                            onClick={(e) => {
                              e.stopPropagation();
                              copyToClipboard(prompt.question);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div ref={messagesEndRef} />
              </>
            )}
          </div>

          {fixedItems.length > 0 && (
            <div className="mb-4 w-full max-w-xl px-4 mx-auto">
              <h2 className="text-md font-semibold normal-case py-4 px-6">
                <BsPinAngle className="w-6 h-6 mr-2 inline-block" /> Fixadas
              </h2>
              <IonList
                key={`today-${company?.id}>`}
                className="shadow-lg cursor-pointer m-2 rounded-lg p-0"
                style={{
                  borderRadius: '15px',
                  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 6px 20px',
                }}
              >
                {fixedItems.map((item) =>
                  !item?.name ? (
                    <></>
                  ) : (
                    <IonItemSliding className="rounded-none border-none !m-none p-0">
                      <IonItem button onClick={() => handleClick(item)}>
                        <IonLabel class="p-5 relative">
                          {item.name}{' '}
                          <MdArrowForwardIos className="absolute h-5 w-5 right-0 top-5" />
                        </IonLabel>
                      </IonItem>

                      <IonItemOptions>
                        <IonItemOption color="success" onClick={() => handleUnpin(item)}>
                          <BsPinAngle className="w-8 h-8 mx-1 text-white" />
                        </IonItemOption>
                        <IonItemOption color="danger" onClick={() => handleDelete(item)}>
                          <FiTrash2 className="w-8 h-8 mx-1 text-white" />
                        </IonItemOption>
                      </IonItemOptions>
                    </IonItemSliding>
                  ),
                )}
              </IonList>
            </div>
          )}

          {todayItems.filter((chat) => chat.name).length > 0 && (
            <div className="mb-4 w-full max-w-xl px-4 mx-auto">
              <h2 className="text-md font-semibold normal-case py-4 px-6">
                <TbClock className="w-6 h-6 mr-2 inline-block" /> Hoje
              </h2>
              <IonList
                key={`today-${company?.id}>`}
                className="shadow-lg cursor-pointer m-2 rounded-lg p-0"
                style={{
                  borderRadius: '15px',
                  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 6px 20px',
                }}
              >
                {todayItems.map((item) =>
                  !item?.name ? (
                    <></>
                  ) : (
                    <IonItemSliding className="rounded-none border-none !m-none p-0">
                      <IonItem button onClick={() => handleClick(item)}>
                        <IonLabel class="p-5 relative">
                          {item.name}{' '}
                          <MdArrowForwardIos className="absolute h-5 w-5 right-0 top-5" />
                        </IonLabel>
                      </IonItem>

                      <IonItemOptions>
                        <IonItemOption color="success" onClick={() => handlePin(item)}>
                          <BsPinAngle className="w-8 h-8 mx-1 text-white" />
                        </IonItemOption>
                        <IonItemOption color="danger" onClick={() => handleDelete(item)}>
                          <FiTrash2 className="w-8 h-8 mx-1 text-white" />
                        </IonItemOption>
                      </IonItemOptions>
                    </IonItemSliding>
                  ),
                )}
              </IonList>
            </div>
          )}

          {lastItems.filter((chat) => chat.name).length > 0 && (
            <div className="mb-4 w-full max-w-xl px-4 mx-auto">
              <h2 className="text-md font-semibold normal-case py-4 px-6">
                <TbClock className="w-6 h-6 mr-2 inline-block" /> Últimos 7 dias
              </h2>
              <IonList
                key={`today-${company?.id}>`}
                className="shadow-lg cursor-pointer m-2 rounded-lg p-0"
                style={{
                  borderRadius: '15px',
                  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 6px 20px',
                }}
              >
                {lastItems.map((item) =>
                  !item?.name ? (
                    <></>
                  ) : (
                    <IonItemSliding className="rounded-none border-none !m-none p-0">
                      <IonItem button onClick={() => handleClick(item)}>
                        <IonLabel class="p-5 relative">
                          {item.name}{' '}
                          <MdArrowForwardIos className="absolute h-5 w-5 right-0 top-5" />
                        </IonLabel>
                      </IonItem>

                      <IonItemOptions>
                        <IonItemOption color="success" onClick={() => handlePin(item)}>
                          <BsPinAngle className="w-8 h-8 mx-1 text-white" />
                        </IonItemOption>
                        <IonItemOption color="danger" onClick={() => handleDelete(item)}>
                          <FiTrash2 className="w-8 h-8 mx-1 text-white" />
                        </IonItemOption>
                      </IonItemOptions>
                    </IonItemSliding>
                  ),
                )}
              </IonList>
            </div>
          )}

          {premium && (
            <div
              className="flex flex-col px-4 items-center w-full bg-white relative"
              style={{ height: '90px' }}
            >
              <button
                onClick={() => sendMessage()}
                className="normal-case absolute top-4 p-4 px-6 right-10 p-2 text-sm font-bold flex bg-neon-900 text-white rounded-full hover:bg-neon-900 disabled:opacity-50"
              >
                <img src={ChatIcon} className="mr-2" alt="Chat Icon" />{' '}
                <span className="normal-case mt-0.5">Novo chat</span>
              </button>
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PergunteAlbertAI;
