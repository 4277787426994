import { Icon } from 'oialbert-ui';

import { Resume } from '../types';

const COUNT_ORDER_DETAILS: number = 3;

type RenderResumeProps = {
  resume?: Resume[] | Resume;
  onShowDetails?: (payload: Resume[] | Resume) => void;
  showDetails?: boolean;
};

const RenderResume = ({
  resume,
  onShowDetails,
  showDetails = true,
}: RenderResumeProps) => {
  if (!resume) {
    return null;
  }

  if (Array.isArray(resume)) {
    return (
      <>
        {resume
          ?.slice(0, showDetails ? COUNT_ORDER_DETAILS : resume.length)
          .map((item, idx) => (
            <p className="text-xs text-gray-500" key={`item_${idx}`}>
              • {item.quantity && `${item.quantity}x`}{' '}
              {item?.product?.title || item.checkin.title}
            </p>
          ))}
        {showDetails && resume?.length > COUNT_ORDER_DETAILS && (
          <button
            type="button"
            className="flex items-center text-sm text-neon-900 p-2"
            onClick={() => onShowDetails && onShowDetails(resume)}
          >
            <Icon.MdKeyboardArrowDown size={24} className="mr-2" /> ver todos os
            itens do pedido
          </button>
        )}
      </>
    );
  }

  return <p className="text-xs text-gray-500">• {resume.product.name}</p>;
};

export default RenderResume;
