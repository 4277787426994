export const formatFloat = (str: any) => {
  str = Math.round(parseFloat(str));
  str = str.toFixed(2) * 100;
  return str;
};

export const formatRound = (str: any) => {
  str = Math.round(parseFloat(str));
  str = str.toFixed(2) * 100;
  str = str / 100;
  return str;
};
