import { IonImg } from '@ionic/react';
import classNames from 'classnames';
import { Icon, Toast } from 'oialbert-ui';
import { useCallback, useState } from 'react';
import { FiCheckCircle, FiHeart } from 'react-icons/fi';
import NotFoundImage from '../../../assets/illustrations/not-found-image.jpg';
import { usePromotionContext } from '../../../contexts/PromotionContext';
import { PromotionType } from '../types';
import Modal from './Modal';
import Status from './Status';
import { Loading } from '../../../components/Loading';
import { duplicatePromotion } from '../../../services/promotions';
import { EditPromotionModal } from './EditPromotionModal';

const PromotionCard = ({ promotion: dataPromotion, getAllPromotion }) => {
  const [promotion, setPromotion] = useState(dataPromotion);
  const {
    handlePausePromotion,
    handleRestaurePromotion,
    handleDeletePromotion,
  } = usePromotionContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);  
  const [retryCount, setRetryCount] = useState(0);
  const [loadRetry, setLoadRetry] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const postPausePromotion = useCallback(
    async (promotion: PromotionType) => {
      setPromotion({
        ...promotion,
        status: promotion.status === 'paused' ? 'actived' : 'paused',
      });
      await handlePausePromotion(promotion);
    },
    [handlePausePromotion]
  );

  const afterHandleRestorePromotion = useCallback(async () => {
    setLoading(false);
    await getAllPromotion();
  }, [getAllPromotion]);

  const postRestaurePromotion = useCallback(
    async (promotion: PromotionType) => {
      try {
        setLoading(true);
        await handleRestaurePromotion(promotion);
        setPromotion({
          ...promotion,
          status: 'pending',
        });
      } catch (error) {
        Toast.crema('ocorreu um erro ao reativar promoção');
      } finally {
        await afterHandleRestorePromotion();
      }
    },
    [afterHandleRestorePromotion, handleRestaurePromotion]
  );

  async function handleRestorePromotion(promotion: PromotionType) {
    await postRestaurePromotion(promotion);
  }

  const deletePromotion = useCallback(
    async (promotion) => {
      setIsOpen(false);
      const responseDeletion = await handleDeletePromotion(promotion);
      if (responseDeletion) {
        setPromotion({
          ...promotion,
          status: 'deleted',
        });
      }
    },
    [handleDeletePromotion]
  );

  function closeModal() {
    setIsOpen(false);
  }
  
  function openModal() {
    setIsOpen(true);
  }

  function closeEditModal() {
    setIsOpenEditModal(false);
  }

  function openEditModal() {
    setIsOpenEditModal(true);
  }

  const afterHandleDuplicatePromotion = useCallback(async () => {
    setLoading(false);
    await getAllPromotion();
  }, [getAllPromotion]);

  const handleDuplicatePromotion = useCallback(
    async (id: string) => {
      try {
        setLoading(true);
        await duplicatePromotion(id);
      } catch (error) {
        Toast.crema('ocorreu um erro ao duplicar promoção');
      } finally {
        await afterHandleDuplicatePromotion();
      }
    },
    [afterHandleDuplicatePromotion]
  );

  const handleImageError = () => {
    setLoadRetry(false);
    if (retryCount < 3) {
        setRetryCount(retryCount + 1);
        setLoadRetry(true);
    } else {
      setLoadRetry(true);
      setLoading(false);
      setError(true);
    }
  };

  return (
    promotion && (
      <div className="flex flex-col justify-between shadow-gray-50 shadow-sm border border-gray-100 rounded-lg p-4">
        <Modal
          isOpen={isOpen}
          promotion={promotion}
          closeModal={closeModal}
          action={() => deletePromotion(promotion)}
        />
        <EditPromotionModal
          isOpen={isOpenEditModal}
          promotion={promotion}
          closeModal={closeEditModal}
          afterSave={getAllPromotion}
        />
        <div className="flex justify-between items-center">
          <div className="flex text-xs text-gray-500">
            {promotion.status === 'paused' ? (
              <button
                onClick={async () => await postPausePromotion(promotion)}
                className="flex items-center gap-2 cursor-pointer hover:text-coral-500 mr-2"
              >
                <Icon.MdPlayArrow /> play
              </button>
            ) : promotion.status === 'actived' ? (
              <button
                onClick={async () => await postPausePromotion(promotion)}
                className="flex items-center gap-2 cursor-pointer hover:text-coral-500 mr-2"
              >
                <Icon.MdPause /> play
              </button>
            ) : (
              ''
            )}
            {promotion.status === 'deleted' ? (
              <button
                onClick={() => handleRestorePromotion(promotion)}
                className="flex items-center gap-1 cursor-pointer hover:text-coral-500"
              >
                <Icon.MdDelete /> Reativar
              </button>
            ) : (
              <button
                disabled={promotion.status === 'deleted'}
                onClick={openModal}
                className={classNames(
                  promotion.status === 'deleted'
                    ? 'cursor-not-allowed'
                    : 'cursor-pointer',
                  'flex items-center gap-1 hover:text-coral-500'
                )}
              >
                <Icon.MdDelete /> Deletar
              </button>
            )}
            <button
              onClick={openEditModal}
              className="flex items-center gap-1 hover:text-coral-500 ml-2"
            >
              <Icon.MdEdit /> Editar
            </button>
            <button
              onClick={() => handleDuplicatePromotion(promotion.id)}
              className="flex items-center gap-1 hover:text-coral-500 ml-2"
            >
              <Icon.MdViewComfy /> Duplicar
            </button>
          </div>
        </div>
        <div
          className={classNames(
            promotion.status === 'deleted' && 'opacity-50',
            'my-2 relative'
          )}
        >
          <div
            className={`object-cover w-full h-32 border border-gray-100 rounded ${
              !loading ? 'invisible absolute' : ''
            }`}
          >
            <Loading />
          </div>
          {loadRetry ? (
            <IonImg
              src={error ? NotFoundImage : promotion.banner || NotFoundImage}
              className={`object-cover w-full h-32 border border-gray-100 rounded ${
                loading ? 'invisible absolute' : ''
              }`}
              onIonError={handleImageError}
              onIonImgDidLoad={() => {
                setLoading(false);
              }}
            />
          ) : null}
          <div className="flex bottom-0 m-2 gap-3 absolute">
            <span className="flex items-center gap-1 text-xs text-gray-400 rounded-lg bg-white px-2 shadow-lg">
              <FiHeart className="h-3 w-3 text-neon-900" />
              {promotion.favorited_count}
            </span>
            <span className="flex items-center gap-1 text-xs text-gray-400 rounded-lg bg-white px-2 shadow-lg">
              <FiCheckCircle className="h-3 w-3 text-neon-900" />
              {promotion.activated_count}
            </span>
          </div>
        </div>
        <div
          className={classNames(promotion.status === 'deleted' && 'opacity-50')}
        >
          <h1 className="font-semibold text-sm text-gray-700 hover:text-neon-900 cursor-pointer line-clamp-1 truncate">
            {promotion.name}
          </h1>
          <p
            className="text-xs text-gray-500 truncate"
            dangerouslySetInnerHTML={{ __html: promotion.description }}
          />
          <Status status={promotion.status} />
        </div>
      </div>
    )
  );
};
export default PromotionCard;
