import { ReactNode } from 'react';
import EmptyState, { EmptyStateProps } from './EmptyState';
import { Loading } from './Loading';

interface ListViewProps<T> {
  loading: boolean;
  data: T[];
  empty: EmptyStateProps;
  children: (data: T[]) => ReactNode;
}

const ListView = <T extends any>({
  loading,
  data,
  empty,
  children,
}: ListViewProps<T>) => {
  if (loading) {
    return (
      <div className="h-full flex items-center">
        <Loading />
      </div>
    );
  }

  if (!loading && data.length === 0) {
    return <EmptyState {...empty} image="box" />;
  }

  return <section className="mt-5 px-5">{children(data)}</section>;
};

export default ListView;
