import { RefresherEventDetail } from "@ionic/core";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import { Icon } from "oialbert-ui";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Loading } from "../../components/Loading";

import useAuth from "../../hooks/useAuth";
import { useCompany } from "../../hooks/useCompany";

import Logo from "../../assets/logo.jpg";
import { api } from "../../services/config";
import { CardCompany } from "./components/CardCompany";

const SelectCompany = () => {
  const history = useHistory();
  const { setCompany, signOut, user } = useAuth();
  const { getCompanies, companies, loadingCompanies } = useCompany();
  const [reloading, setReloading] = useState<boolean>(false);

  const onRefresh = useCallback(
    async (event: CustomEvent<RefresherEventDetail>) => {
      await getCompanies(
        !(user && ["pdv", "delivery"].includes(user?.partner?.user_types))
      );
      setReloading(false);
      event.detail.complete();
    },
    [getCompanies, user]
  );

  const handleSelectCompany = useCallback(
    (selectedCompany) => {
      api.defaults.headers.common["company_id"] = selectedCompany.id;
      setCompany(selectedCompany);
      window.location.href = `${window.location.origin}/`;
    },
    [setCompany]
  );

  useEffect(() => {
    getCompanies(
      !(
        user && ["pdv", "delivery", "admin"].includes(user?.partner?.user_types)
      )
    );
  }, [getCompanies, user]);

  return (
    <IonPage>
      <IonHeader className="ion-no-border bg-white">
        <section className="flex flex-col mt-10 px-5 pb-5 space-y-8 items-center">
          <img src={Logo} className="h-28" alt="Logo" />
          <h1 className="text-lg font-bold text-gray-500">
            Selecionar empresa
          </h1>
        </section>
      </IonHeader>
      <IonContent>
        <IonRefresher
          slot="fixed"
          onIonRefresh={(e) => {
            onRefresh(e);
            setReloading(true);
          }}
          disabled={loadingCompanies}
        >
          <IonRefresherContent />
        </IonRefresher>

        <section className="w-full flex-1 flex flex-col items-center justify-between px-5 space-y-5 mt-5">
          {loadingCompanies && !reloading ? (
            <section className="w-full mt-5">
              <Loading />
            </section>
          ) : (
            <>
              <section className="w-full space-y-5">
                {companies.map((c, idx) => {
                  return (
                    <CardCompany
                      company={c}
                      setCompany={handleSelectCompany}
                      key={`company_${c.id}_${idx}`}
                    />
                  );
                })}
              </section>
              {false && (
                <section>
                  <Link
                    to="/create-company"
                    className="flex items-center justify-center p-4"
                  >
                    <section className="w-8 h-8 bg-crema-500 flex items-center justify-center rounded mr-3">
                      <Icon.MdAdd className="w-6 h-6 text-neon-900" />
                    </section>
                    <span className="text-base text-gray-500">
                      Adicionar empresa
                    </span>
                  </Link>
                </section>
              )}
            </>
          )}
          <section className="sm:mx-auto sm:w-full sm:max-w-xl w-full flex justify-between bg-gray-500 bg-opacity-5 shadow-lg rounded">
            <button
              className="w-full flex items-center justify-between px-4 py-3 border-0 bg-transparent"
              onClick={signOut}
            >
              <section className="flex items-center w-12 h-12">
                <span className="flex items-center justify-center w-full h-full overflow-hidden rounded-full w-12 h-12">
                  <Icon.MdLogout className="w-7 h-7 text-neon-900" />
                </span>
              </section>
              <p className="text-base font-bold flex-1 ml-4 mr-2 text-gray-500 flex flex-col">
                Sair
              </p>
            </button>
          </section>
        </section>
      </IonContent>
    </IonPage>
  );
};

export default SelectCompany;
