import { Icon } from 'oialbert-ui';
import { formatInCents } from '../../../../utils/money';
import { formatShort } from '../../../../utils/date';
import { Transaction } from '../../../../types/transactions';

type ExtractItemProps = {
  data: Transaction;
};

export const ExtractItem = ({ data }: ExtractItemProps) => (
  <section className="flex py-4">
    <Icon.MdOutlineArrowCircleDown className="w-6 h-6 text-success-500" />
    <section className="ml-2 flex-1">
      <h4 className="text-base font-bold mb-3.5">transferência recebida</h4>
      <p className="text-sm">
        {data.outgoing_wallet?.user?.customer?.full_name ??
          'Albert Intermediação Digital LTDA'}
      </p>
      <p className="text-sm">
        valor:{' '}
        <span className="font-bold uppercase">
          {formatInCents(data.value_in_cents ?? 0)}
        </span>
      </p>
      {data.tax_in_cents > 0 && (
        <p className="text-sm">
          imposto:{' '}
          <span className="text-neon-900 uppercase">
            {formatInCents(data.tax_in_cents)}
          </span>
        </p>
      )}
      {data?.observation !== '' && (
        <section className="mt-2">
          <p className="text-xs text-gray-500">{data.observation}</p>
        </section>
      )}
    </section>
    <section className="flex flex-col justify-between ml-2 text-right">
      {data.created_at && (
        <p className="text-xs text-gray-500 uppercase mb-3">
          {formatShort(data.created_at, false)}
        </p>
      )}
    </section>
  </section>
);
