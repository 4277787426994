import { useCallback, useState } from 'react';
import { Icon, Modal } from 'oialbert-ui';
import { formatShort } from '../../../utils/date';
import { fetchOrder } from '../../../services/orders';
import { Order } from '../types';
import RenderResume from '../../Orders/components/RenderResume';

type DetailsOrderProps = {
  selectedOrder: any;
  open: boolean;
  loading?: boolean;
  setToggleModal: (state: boolean) => void;
  setSelectedOrder?: (order: Order | null) => void;
  onRefresh?: () => void;
};

const DetailsOrder = ({
  selectedOrder,
  open,
  setToggleModal,
  setSelectedOrder,
}: DetailsOrderProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const getOrder = useCallback(async () => {
    setLoading(true);
    try {
      const { order } = await fetchOrder(selectedOrder?.id);
      setSelectedOrder && setSelectedOrder(order);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [selectedOrder?.id, setSelectedOrder]);

  return (
    <Modal
      type="dialog"
      open={open}
      onClose={() => {
        setToggleModal(false);
        setSelectedOrder && setSelectedOrder(null);
      }}
      height={247}
    >
      {selectedOrder && (
        <section className="h-full flex flex-col justify-between">
          <section className="space-y-2">
            <section className="flex items-center justify-between">
              <h4 className="font-bold">{selectedOrder.customer.full_name}</h4>
              {selectedOrder.updated_at && (
                <span className="flex lowercase text-xs text-gray-500 items-center">
                  <Icon.MdAccessTime size={16} className="mr-1" />
                  {formatShort(selectedOrder.updated_at)}
                </span>
              )}
            </section>
            <RenderResume showDetails={false} resume={selectedOrder?.resume} />
          </section>
          <section className="flex items-center justify-center">
            <button
              type="button"
              className="flex-1 flex items-center justify-center text-sm text-neon-900 p-2"
              onClick={() => setToggleModal(false)}
            >
              <Icon.MdClose size={24} className="mr-2" /> fechar
            </button>
            <button
              type="button"
              className="flex-1 flex items-center justify-center text-sm text-neon-900 p-2"
              onClick={() => (loading ? null : getOrder())}
              disabled={loading}
            >
              <Icon.MdReplay size={24} className="mr-2" />{' '}
              {loading ? 'aguarde...' : 'atualizar pedido'}
            </button>
          </section>
        </section>
      )}
    </Modal>
  );
};

export default DetailsOrder;
