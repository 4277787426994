import { useCallback, useEffect, useState } from 'react';
import { Modal, Button, Checkbox } from 'oialbert-ui';
import { ModalProps } from 'oialbert-ui/lib/components/Modal/types';
import { Loading } from '../../../components/Loading';

import { useCategory } from '../../../hooks/useCategory';

type Props = Pick<ModalProps, 'onClose' | 'open'> & {
  onSelect: (_value: { title: string; id: string }[]) => void;
  values: { title: string; id: string }[];
};

const Category = ({ onClose, open, onSelect, values }: Props) => {
  const { subcategories, getSubcategories, loadingSubcategories } =
    useCategory();

  const [selectedCategories, setSelectedCategories] = useState<
    { title: string; id: string }[]
  >(values ?? []);

  const toggle = useCallback(
    (category) => {
      if (selectedCategories.filter((c) => c.id === category.id).length) {
        setSelectedCategories(
          selectedCategories.filter((c) => c.id !== category.id)
        );
      } else {
        setSelectedCategories([
          ...selectedCategories,
          { id: category.id, title: category.title },
        ]);
      }
    },
    [selectedCategories]
  );

  const onSave = () => {
    onSelect(selectedCategories);
    onClose();
  };

  useEffect(() => {
    if (open) {
      getSubcategories();
    }
  }, [open, getSubcategories]);

  return (
    <Modal open={open} onClose={onClose}>
      {loadingSubcategories ? (
        <Loading />
      ) : (
        <>
          <section className="overflow-auto h-64">
            <section className="space-y-4">
              {subcategories?.map((category: any, idxc: number) => (
                <section className="space-y-2">
                  {!!category.subcategories.length && (
                    <p className="text-gray-500 font-bold text-lg">
                      {category.title}
                    </p>
                  )}
                  {category?.subcategories?.map(
                    (subcategory: any, idx: number) => (
                      <section
                        className="w-full flex items-center justify-between"
                        key={`category_${idx}_${idxc}`}
                      >
                        <Checkbox
                          value={subcategory.id}
                          name="subcategories"
                          label={subcategory.title}
                          id={`categoryId${idx}-${idxc}`}
                          onChange={() => toggle(subcategory)}
                          checked={
                            selectedCategories.find(
                              (s) => s.id === subcategory.id
                            ) !== undefined
                          }
                        />
                      </section>
                    )
                  )}
                </section>
              ))}
            </section>
          </section>

          <section className="w-full flex gap-4 max-w-sm mx-auto mt-4">
            <Button
              full
              normal
              onClick={onSave}
              disabled={!selectedCategories?.length}
              variant="outline"
              color="neon"
            >
              Salvar
            </Button>
            <Button
              full
              normal
              onClick={() => {
                onClose();
                setSelectedCategories([]);
              }}
              variant="light"
              color="neon"
            >
              Cancelar
            </Button>
          </section>
        </>
      )}
    </Modal>
  );
};

export default Category;
