import { Controller, useFormContext } from 'react-hook-form';
import { Panel } from '../../CreatePromotion/components/Panel';
import Radio from '../../CreatePromotion/components/Form/Radio';
import { discountAmount } from '../../../utils/is_number';
import { Icon, Input } from 'oialbert-ui';
import { TYPE } from '../types';
import classNames from 'classnames';
import { Description } from '../../CreatePromotion/components/Form/Description';
import { AvailabilityDays } from '../../CreatePromotion/components/Form/AvailabilityDays';
import { EndDate } from '../../CreatePromotion/components/Form/EndDate';
import { StartDate } from '../../CreatePromotion/components/Form/StartDate';

import { Tag } from '../../CreatePromotion/components/Form/Tag';
import { Banner } from '../../CreatePromotion/components/Form/Banner';
import { ChangeEvent, useEffect, useState } from 'react';
import { usePromotionContext } from '../../../contexts/PromotionContext';
import { Promotion } from '../../../types/promotion';
import { z } from 'zod';
import { promotionSchema } from './EditPromotionModal';

interface PromotionFormProps {
  promotion: Promotion;
}

export function PromotionForm({ promotion }: PromotionFormProps) {
  type PromotionForm = z.infer<typeof promotionSchema>;
  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext<PromotionForm>();
  const [watchStatus, watchType] = watch(['status', 'type']);
  const { applyPreview, preview } = usePromotionContext();

  const typeLabel =
    {
      [TYPE.combo]: 'do combo',
      [TYPE.offer]: 'da oferta',
      [TYPE.discount]: 'do desconto',
    }[watchType] || '';

  const isReadOnly = watchStatus === 'actived' || watchStatus === 'paused';
  const [previewBanner, setPreviewBanner] = useState<string | undefined>();

  useEffect(() => {
    if (promotion.banner) {
      applyPreview({
        banner: { url: promotion.banner },
      });
    }
  }, [promotion.banner]);

  return (
    <>
      <Panel title="tipo da promoção">
        <div className="text-gray-500 text-sm flex flex-col gap-1">
          <div>
            <strong>combo:</strong> adicione vários produtos ou serviços em uma única promoção,
            oferecendo uma única porcentagem de cashback sobre todos os produtos.
          </div>
          <div>
            <strong>oferta:</strong> escolha um único produto ou serviço que queira divulgar e
            aplique uma porcentagem de cashback especial sobre ele.
          </div>
          <div>
            <strong>desconto:</strong> porcentagem especial no estabelecimento todo. Porcentagem de
            desconto na sua loja.
          </div>
        </div>
        <Controller
          control={control}
          name="type"
          disabled={isReadOnly}
          render={({ field: { value, onChange, ...props } }) => (
            <Radio
              disabled={isReadOnly}
              value={value}
              onChange={onChange}
              {...props}
              error={errors.type?.message?.toString()}
            />
          )}
        />
      </Panel>
      <Panel title={`porcentagem ${typeLabel}`}>
        <Controller
          control={control}
          name="discount_amount"
          rules={{
            validate: (value) =>
              parseFloat(String(value)) >= 0 || 'o valor deve ser maior ou igual a 0',
          }}
          render={({ field: { value, onChange, ...props } }) => (
            <Input
              readOnly={isReadOnly}
              disabled={isReadOnly}
              type="number"
              value={value}
              placeholder="0.0"
              onChange={(e) => {
                const newValue = discountAmount(e.target.value);
                onChange(newValue);
              }}
              {...props}
              error={errors?.discount_amount?.message?.toString()}
            />
          )}
        />
      </Panel>
      <Panel title="nome da promoção">
        <Controller
          control={control}
          name="name"
          render={({ field: { value, ...props } }) => (
            <Input
              label=""
              placeholder="Nome da promoção"
              className={classNames('border border-gray-100', isReadOnly ? 'bg-gray-200' : '')}
              disabled={isReadOnly}
              value={value}
              error={errors?.name?.message?.toString()}
              {...props}
            />
          )}
        />
      </Panel>
      <Panel title="descrição">
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <Description
              value={field.value}
              onChange={(content) => field.onChange(content)}
              error={errors.description?.message}
              isReadOnly={isReadOnly}
            />
          )}
        />
      </Panel>
      <Panel title="regras">
        <Controller
          control={control}
          name="role"
          render={({ field: { value, ...props } }) => (
            <Description
              value={value ?? ''}
              placeholder="descreva as regras."
              {...props}
              error={errors.role?.message?.toString()}
              isReadOnly={isReadOnly}
            />
          )}
        />
      </Panel>
      <Panel title={`limite de utilização por associado`} description="informe 0 para ilimitado.">
        <Controller
          control={control}
          name="promotion_limit_by_customer"
          rules={{
            validate: (value) => value > 0 || 'o valor deve ser maior ou igual a 0',
          }}
          render={({ field: { value, onChange, onBlur, ...props } }) => (
            <Input
              type="number"
              value={value}
              placeholder="0"
              disabled
              onChange={(e) => {
                const newValue = e.target.value.replace(/[^0-9]/g, '');
                onChange(newValue ? Number(newValue) : '');
              }}
              onBlur={(e) => {
                const newValue = e.target.value.replace(/[^0-9]/g, '');
                onChange(newValue ? Number(newValue) : '');
                onBlur();
              }}
              {...props}
              error={errors?.promotion_limit_by_customer?.message?.toString()}
            />
          )}
        />
      </Panel>
      <Panel title={isReadOnly ? 'palavras-chave' : 'crie palavras-chave'}>
        <Controller
          control={control}
          name="tags"
          render={({ field: { value, onChange, ...props } }) => (
            <Tag
              value={value ?? ''}
              onChange={onChange}
              {...props}
              error={errors.tags?.message?.toString() || ''}
              isReadOnly={isReadOnly}
            />
          )}
        />
      </Panel>
      <Panel title="">
        <div className="flex gap-3  rounded-lg p-3 gap-3 border relative">
          <div className="flex flex-col w-1/2">
            <strong className="text-gray-700 text-xs">selecione a data inicial</strong>
            <Controller
              control={control}
              name="start_date"
              render={({ field: { value, onChange } }) => (
                <StartDate
                  loading={false}
                  onChange={onChange}
                  value={value}
                  error={errors.start_date?.message?.toString()}
                />
              )}
            />
          </div>
          <div className="flex flex-col w-1/2">
            <strong className="text-gray-700 text-xs">selecione a data final</strong>
            <Controller
              control={control}
              name="end_date"
              render={({ field: { value, onChange } }) => (
                <EndDate
                  loading={false}
                  value={value}
                  onChange={onChange}
                  error={errors.end_date?.message?.toString()}
                />
              )}
            />
          </div>
        </div>
      </Panel>
      <Panel
        title="disponibilidade"
        description="ative os dias em que essa promoção será veículada."
      >
        <Controller
          control={control}
          name="availability.days"
          render={({ field: { value, onChange, ...props } }) => (
            <AvailabilityDays
              {...props}
              onChange={onChange}
              error={errors?.availability?.days?.message?.toString()}
              value={value}
            />
          )}
        />
      </Panel>
      <Panel
        title={isReadOnly ? 'o upload do banner ja foi feito' : 'faça o upload do banner'}
        description={
          isReadOnly
            ? ''
            : 'certifique-se de que sua imagem esteja no formato JPG, JPEG ou PNG. recomendamos uma dimensão ideal de 1920x1080 pixels com uma proporção de 16:9.'
        }
      >
        <>
          <div className="relative mx-auto flex justify-center items-center">
            {!isReadOnly ? (
              <div className=" flex items-center justify-center border p-3 rounded w-full">
                <Icon.MdUploadFile className="h-5 w-5 text-gray-700 text-opacity-40 mr-3" />
                <span className="text-gray-700 text-opacity-50 text-xs">carregar outra imagem</span>
                <input
                  type="file"
                  id="desktop-user-photo"
                  name="user-photo"
                  onChange={(e: ChangeEvent) => {
                    const target = e.target as HTMLInputElement;
                    const file: File = (target.files as FileList)[0];
                    const fileUrl = URL.createObjectURL(file);
                    setPreviewBanner(fileUrl);
                    applyPreview({
                      banner: { url: fileUrl },
                    });
                  }}
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md p-2"
                />
              </div>
            ) : null}
          </div>
          {preview?.banner && (
            <div className="h-48 relative mt-5">
              <Controller
                control={control}
                name="banner"
                render={({ field: { onChange } }) => (
                  <Banner onChange={onChange} previewBanner={previewBanner || promotion.banner} />
                )}
              />
            </div>
          )}
        </>
      </Panel>
    </>
  );
}
