import { IonPage, IonContent } from '@ionic/react';
import { Modal } from 'oialbert-ui';

type ModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const InvoicingInfo = ({ open, setOpen }: ModalProps) => {
  const handleClose = () => setOpen(false);

  return (
    <Modal open={open} onClose={handleClose}>
      <IonPage>
        <IonContent>
          <div className="px-4 pt-10">
            <div className="box-border h-52 w-46 p-4 border-2 border-neon-900 rounded-md">
              <section className="pt-1 px-5">
                <h3 className="text-base font-bold">faturamento total</h3>
                <p className="pt-2 text-base font-medium">
                  o objetivo é potencializar as suas vendas! 💰
                </p>
              </section>
              <section className="pt-8 px-5">
                <p className="text-sm font-normal">
                  essa é a soma de todas as vendas que você já realizou por
                  aqui.
                </p>
              </section>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </Modal>
  );
};

export default InvoicingInfo;
