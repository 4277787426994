import { Toast } from 'oialbert-ui';
import { useCallback, useState } from 'react';
import errorHandling from '../utils/error_handling';

import {
  getAllCompanies,
  getCompanyById,
  createCompanies,
  updateCompanies,
  updateOpeningHours,
  uploadBanner,
  uploadFoto,
} from '../services/companies';
import type { CompanyDetailsData } from '../types/companies';
import useAuth from './useAuth';

export const useCompany = () => {
  const { setCompany } = useAuth();
  const [dataCompanies, setDataCompanies] = useState<CompanyDetailsData[]>([]);
  const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false);
  const [dataCompany, setDataCompany] = useState<CompanyDetailsData | null>(
    null
  );
  const [loadingCompany, setLoadingCompany] = useState<boolean>(false);

  const [loadingCreateCompany, setLoadingCreateCompany] =
    useState<boolean>(false);
  const [loadingUpdateCompany, setLoadingUpdateCompany] =
    useState<boolean>(false);

  const getDataCompanies = useCallback(async (onlyHeadOffice?: boolean) => {
    setLoadingCompanies(true);
    try {
      const response = await getAllCompanies();
      setDataCompanies(
        response.companies.data.filter(
          (company: CompanyDetailsData) =>
            !onlyHeadOffice || company.network_type === 'head_office'
        )
      );
    } catch (err) {
      errorHandling(err, 'erro ao buscar empresas', 'crema');
    } finally {
      setLoadingCompanies(false);
    }
  }, []);

  const getDataCompany = useCallback(async (uuid: string) => {
    setLoadingCompany(true);
    try {
      const response = await getCompanyById(uuid);
      setDataCompany(response.company);
      return response;
    } catch (err) {
      errorHandling(err, 'erro ao buscar empresa', 'crema');
    } finally {
      setLoadingCompany(false);
    }
  }, []);

  const getDataCompanyDetails = useCallback(async (uuid: string) => {
    setLoadingCompany(true);
    try {
      return await getCompanyById(uuid);
    } catch (err) {
      errorHandling(err, 'erro ao buscar empresa', 'crema');
    } finally {
      setLoadingCompany(false);
    }
  }, []);

  const create = useCallback(async (payload, onSuccess) => {
    setLoadingCreateCompany(true);
    try {
      await createCompanies(payload);
      Toast.success('empresa criada');
      onSuccess();
    } catch (err) {
      errorHandling(err, 'erro ao criar empresa', 'crema');
    } finally {
      setLoadingCreateCompany(false);
    }
  }, []);

  const updateHours = useCallback(async (payload, id) => {
    setLoadingUpdateCompany(true);
    try {
      const data = await updateOpeningHours({ id, payload });

      if (data) {
        setCompany(data);
        setDataCompany(data);
      }

      Toast.success('informação de horário atualizada');
      return data;
    } catch (err) {
      const {
        response: { data },
      }: any = err;
      if (data.errors[0].field === 'business_hours') {
        errorHandling(
          data.errors[0].messages,
          'Horario de fechamento não pode ser maior que o horario de abertura.',
          'crema'
        );
      } else {
        errorHandling(err, 'erro ao atualizar horário', 'crema');
      }
    } finally {
      setLoadingUpdateCompany(false);
    }
  }, []);

  const update = useCallback(async (payload, onSuccess?: any) => {
    setLoadingUpdateCompany(true);
    try {
      if (payload.photo instanceof Blob) {
        await uploadFoto(payload.id, payload.photo as File, payload);
      }

      if (payload.banner?.file instanceof Blob) {
        await uploadBanner(payload.id, payload.banner.file as File, payload);
      }

      const response = await updateCompanies(payload);

      Toast.success('empresa atualizada');

      onSuccess(response);
    } catch (err) {
      errorHandling(err, 'erro ao atualizar empresa', 'crema');
    } finally {
      setLoadingUpdateCompany(false);
    }
  }, []);

  return {
    getCompanies: getDataCompanies,
    companies: dataCompanies,
    loadingCompanies,
    getCompany: getDataCompany,
    getCompanyDetails: getDataCompanyDetails,
    company: dataCompany,
    loadingCompany,
    createCompany: create,
    loadingCreateCompany,
    updateCompany: update,
    updateHours,
    loadingUpdateCompany,
  };
};
