import { api } from './config';

export const offers = async (params = {}) => {
  const { status, data } = await api.get('/v1/partners/offers', { params });

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const getOffer = async (id: string) => {
  const { status, data } = await api.get(`/v1/partners/offers/${id}`);

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const createOffer = async (payload = {}) => {
  const { status, data } = await api.post('/v1/partners/offers', payload);

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const updateOffer = async (id: string, payload = {}) => {
  const { status, data } = await api.put(`/v1/partners/offers/${id}`, payload);

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const deleteOffer = async (id: string) => {
  const { status, data } = await api.delete(`/v1/partners/offers/${id}`);

  if (status === 200 && data) {
    return data;
  }

  return false;
};
