import * as z from 'zod';
import { isValidPhoneNumber } from '../utils/validate-phone-number';
import { isValidCPF } from '@brazilian-utils/brazilian-utils';

const userCreateSchema = z
  .object({
    full_name: z.string().nonempty({ message: 'campo obrigatório' }),
    email: z.string().nonempty({ message: 'campo obrigatório' }),
    cellphone: z
      .string()
      .nonempty({ message: 'campo obrigatório' })
      .refine((value) => isValidPhoneNumber(value), {
        message: 'número inválido',
      }),
    document_number: z.string().nonempty({ message: 'campo obrigatório' }),
    user_types: z.string().nonempty({ message: 'campo obrigatório' }),
    is_able_to_update_checkin_cashback: z.boolean(),
  })
  .refine((data) => isValidCPF(data.document_number), {
    message: 'cpf inválido',
    path: ['document_number'],
  });

const userUpdateSchema = z
  .object({
    full_name: z.string().nonempty({ message: 'campo obrigatório' }),
    email: z.string().nonempty({ message: 'campo obrigatório' }),
    cellphone: z
      .string()
      .nonempty({ message: 'campo obrigatório' })
      .refine((value) => isValidPhoneNumber(value), {
        message: 'número inválido',
      }),
    document_number: z.string().nonempty({ message: 'campo obrigatório' }),
    user_types: z.string().nonempty({ message: 'campo obrigatório' }),
    is_able_to_update_checkin_cashback: z.boolean(),
  })
  .refine((data) => isValidCPF(data.document_number), {
    message: 'cpf inválido',
    path: ['document_number'],
  });

export { userCreateSchema, userUpdateSchema };
