import { createRef } from 'react';
import { useHistory } from 'react-router';
import { Button, Toast } from 'oialbert-ui';

import Form from './components/Form';

import useAuth from '../../hooks/useAuth';
import { useCompany } from '../../hooks/useCompany';
import type { CompanyDetailsData } from '../../types/companies';
import errorHandling from '../../utils/error_handling';
import { LayoutBase } from '../../components/LayoutBase';

const CreateCompany = () => {
  const history = useHistory();
  const { company, setCompany } = useAuth();

  const { updateCompany, loadingUpdateCompany } = useCompany();

  const formRef: any = createRef();

  const onSubmitForm = () => {
    formRef?.current?.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  const formatPaymentTypes = (value: string[] | null) => {
    if (!value || !value?.length) return;

    let paymentTypes: { [key: string]: boolean } = {};

    value.forEach((key) => {
      paymentTypes[key] = true;
    });

    return paymentTypes;
  };

  const updateCompanyInfo = async ({ address, ...c }: CompanyDetailsData) => {
    if (!c.subcategories || c?.subcategories?.length === 0) {
      Toast.crema('subcategorias é obrigatório');
      return;
    }

    const { subcategories, photo, banner, ...restCompany }: any = company;

    const data = {
      ...c,
      id: restCompany?.id ?? '',
      document_number: c?.document_number?.replace(/[^a-zA-Z0-9]/g, ''),
      phone: c?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
      whatsapp: c?.whatsapp?.replace(/[^a-zA-Z0-9]/g, ''),
      ...address,
      address: {
        country: 'BR',
        state: address?.state,
        city: address?.city,
        neighborhood: address?.neighborhood,
        street: address?.street,
        street_number: address?.street_number,
        zip_code: address?.zip_code,
        complement: address?.complement,
      },
      country: 'BR',
      has_delivery: c.has_delivery === 'true',
      payment_types: c.payment_types
        ? Object.keys(c.payment_types)
            .map((key: any) => c?.payment_types?.[key] && key)
            .filter((payment) => payment)
        : [],
      subcategories: c.subcategories.map((sub: any) => sub.id),
    };

    try {
      await updateCompany(data, (companyData: CompanyDetailsData) => {
        setCompany(companyData);
        history.push('/company-profile');
      });
    } catch (error) {
      errorHandling(error, 'erro ao salvar perfil da empresa', 'crema');
    }
  };

  return (
    <LayoutBase title="Perfil da empresa">
      <Form
        formRef={formRef}
        onSubmit={updateCompanyInfo}
        loading={loadingUpdateCompany}
        defaultValues={{
          ...company,
          payment_types: formatPaymentTypes(company?.payment_types ?? []),
        }}
      />

      <section className="flex flex-col px-5 space-y-4 mt-8 mb-5">
        <Button
          full
          onClick={() => onSubmitForm()}
          disabled={loadingUpdateCompany}
          variant="solid"
          color="neon"
        >
          {loadingUpdateCompany ? 'Salvando...' : 'Salvar'}
        </Button>
      </section>
    </LayoutBase>
  );
};

export default CreateCompany;
