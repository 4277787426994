import { Input } from 'oialbert-ui';
import {
  Control,
  FieldErrors,
  useController,
  UseFormRegister,
} from 'react-hook-form';
import { useMaskHandler } from '../../../hooks/useMaskHandler';
import type { Characteristic } from '../types';

type OptionFeatureProps = {
  index: number;
  register: UseFormRegister<Characteristic>;
  errors: FieldErrors<Characteristic>;
  control: Control<Characteristic>;
};

const OptionFeature = ({
  index,
  register,
  errors,
  control,
}: OptionFeatureProps) => {
  const money = useController({
    control,
    name: `options.${index}.additional_price_in_cents`,
  });

  const errorsCollection = Array.isArray(errors.options)
    ? errors.options?.filter(Boolean)
    : [];

  const priceCashbackInCents = useMaskHandler<any>(
    `options.${index}.additional_price_in_cents`,
    control
  );

  return (
    <div>
      <div className="mb-4">
        <div className="flex justify-between itens-center w-full mb-4">
          <p className="text-base text-black font-bold">Descrição</p>
        </div>

        <Input
          placeholder="Nome da opção"
          {...register(`options.${index}.title`)}
          error={errorsCollection?.[index]?.title?.message || ''}
        />
      </div>

      <div className="flex space-x-4 mb-4">
        <Input
          type="number"
          placeholder="Qtd miníma"
          {...register(`options.${index}.min`, { valueAsNumber: true })}
          error={errorsCollection?.[index]?.min?.message || ''}
        />
        <Input
          type="number"
          placeholder="Qtd máxima"
          {...register(`options.${index}.max`, { valueAsNumber: true })}
          error={errorsCollection?.[index]?.max?.message || ''}
        />
      </div>

      <div>
        <label className="text-xs text-gray-500">Preço adicional</label>
        <Input
          mask="MONEY"
          placeholder="0,00"
          ref={money.field.ref}
          moneyProps={priceCashbackInCents}
          error={
            errorsCollection?.[index]?.additional_price_in_cents?.message || ''
          }
        />
      </div>
    </div>
  );
};

export default OptionFeature;
