import { Button } from 'oialbert-ui';
import { useMemo } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import { useOrderContext } from '../../../contexts/OrdersContext';

type StepTwoProps = {
  isUsedCashback: boolean;
  loading: boolean | undefined;
  onNext: () => void;
  closeModal: () => void;
  handleSubmit: UseFormHandleSubmit<any>;
  onSubmit: (_data: any) => void;
};

const StepTwo = ({
  isUsedCashback,
  closeModal,
  loading,
  handleSubmit,
  onSubmit,
}: StepTwoProps) => {
  const isloadingColor = useMemo(() => (!loading ? 'neon' : 'gray'), [loading]);
  const { preview, customer } = useOrderContext();

  return (
    <>
      <section className="w-full">
        <h3 className="text-md">{customer.customer.full_name}</h3>
        <p className="text-md font-medium">{customer.document_number}</p>
        <div className="w-full h-0.5 bg-gray-100 my-5"></div>
      </section>
      <section>
        {isUsedCashback === true ? (
          <>
            <h3 className="text-lg text-center ">
              Desconto aplicado:{' '}
              <span className="font-medium text-neon-900 uppercase">
                {preview.used_cashback} ({preview.discount_percent})
              </span>{' '}
            </h3>
            <h3 className="text-lg text-center ">
              cobrar do cliente:{' '}
              <span className="font-medium text-neon-900 uppercase">
                {preview.payment_value}
              </span>
            </h3>
          </>
        ) : (
          <section className="flex flex-col text-center">
            <p className="text-neon-900 text-xl font-medium mt-2">atenção! </p>
            <h3 className="text-lg text-center ">
              o associado não está utilizando cashback nesta compra
            </h3>
          </section>
        )}
      </section>
      <section className="flex flex-col mt-10 mb-14 text-lg">
        <h3 className="text-center mb-1">
          ao finalizar, o associado receberá:
        </h3>
        <section className="flex flex-1 border border-gray-100 rounded py-4 px-4 items-center justify-center">
          <h3 className="font-medium">
            <span className="font-semibold text-neon-900 uppercase">
              {isUsedCashback
                ? preview.next_buy_with_discount
                : preview.next_buy}
            </span>{' '}
            {'  '}
            para a próxima compra
          </h3>
        </section>
      </section>
      <section className="flex flex-row justify-center items-center gap-x-2">
        <Button
          type="button"
          onClick={closeModal}
          full
          normal
          variant="outline"
          color={isloadingColor}
          disabled={loading}
        >
          {'cancelar'}
        </Button>
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          full
          normal
          variant="solid"
          color={isloadingColor}
          disabled={loading}
        >
          {!loading ? 'finalizar' : 'finalizando...'}
        </Button>
      </section>
    </>
  );
};
export default StepTwo;
