/* eslint-disable react/jsx-no-undef */
import { useCallback, useEffect, useState } from 'react';
import { terms } from '../../services/terms';
import errorHandling from '../../utils/error_handling';
import { Loading } from '../../components/Loading';
import { LayoutBase } from '../../components/LayoutBase';

const MyTerm = () => {
  const [dataTerms, setDataTerms] = useState<string>('');
  const [loadingTerms, setLoadingTerms] = useState<boolean>(false);

  const getTerms = useCallback(async () => {
    setLoadingTerms(true);
    try {
      const response = await terms();
      setDataTerms(response.terms);
    } catch (err) {
      errorHandling(err, 'Erro ao buscar termos', 'crema');
    } finally {
      setLoadingTerms(false);
    }
  }, []);

  useEffect(() => {
    getTerms();
  }, [getTerms]);
  return (
    <>
      {loadingTerms ? (
        <Loading />
      ) : (
        <LayoutBase title="Termos">
          <section className="w-full flex-1 flex flex-col items-center justify-between px-5 space-y-5 mt-5">
            <section className="mt-5 px-5 mb-5 text-gray-500">
              <article dangerouslySetInnerHTML={{ __html: dataTerms }} />
            </section>
          </section>
        </LayoutBase>
      )}
    </>
  );
};

export default MyTerm;
