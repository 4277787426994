import { Toast } from 'oialbert-ui';
import { useState, useCallback } from 'react';
import useAuth from '../../hooks/useAuth';
import * as Api from '../../services/productCategories';
import errorHandling from '../../utils/error_handling';

import type { Category, Response } from './types';

export const useProductCategories = () => {
  const { company } = useAuth();
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);

  const [total, setTotal] = useState<number>(0);

  const getProductCategories = useCallback(async () => {
    setLoading(true);
    try {
      const response = await Api.fetchProductCategories<Response>({ page });
      setCategories(response ? response?.product_categories?.data : []);
      setTotal(response ? response?.product_categories?.meta.total : 0);
    } catch (err) {
      console.log('error getDataCompanies', err);
    } finally {
      setLoading(false);
    }
  }, [page]);

  const createProductCategory = async (data: Partial<Category>) => {
    return await Api.createProductCategory(data, company?.id);
  };

  const updateProductCategory = async (id: string, data: Partial<Category>) => {
    return await Api.updateProductCategory(id, data, company?.id);
  };

  const removeProductCategory = async (id: string) => {
    setLoadingDelete(true);
    try {
      await Api.removeProductCategory(id);
      Toast.success('categoria removida com sucesso');
    } catch (error) {
      errorHandling(error, 'erro ao remover categoria', 'crema');
    } finally {
      setLoadingDelete(false);
    }
  };

  return {
    createProductCategory,
    updateProductCategory,
    getProductCategories,
    removeProductCategory,
    categories,
    loading,
    loadingDelete,
    setPage,
    page,
    total,
  };
};
